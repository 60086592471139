import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialReportBuilderFormFields from './ReportBuilderFields';
import { cloneDeep } from '../../helpers/functions/utilities';
import { REPORT_SEARCH_FIELDS } from '../../helpers/constants/initialData';
import {
  checkValidity,
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';

const ReportFields = props => {

  const [formData, updateFormData] = useState( cloneDeep( initialReportBuilderFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );

  const { index:propsIndex, updateReportBuilderData, updateFullQueryData, overrideData } = props;

  useEffect( () => {
    if ( overrideData ) {
      console.log( 'overrideData: ', overrideData );
      updateFormData( overrideData );
      updateFormIsValid( cur => !cur );
    } else if ( overrideData == null ) {
      updateFormData( cur => setValuesFromResponse( cur, REPORT_SEARCH_FIELDS, updateFormIsValid ) );
    }
  }, [overrideData] );

  useEffect( () => {
    let isValid = true;
    updateFormData( cur => {
      const data = { ...cur };
      for ( const key in data ) {
        if ( data.hasOwnProperty( key ) ) {
          const fieldCopy = { ...data[key] };
          // eslint-disable-next-line
          fieldCopy.display = eval( fieldCopy.displayCondition );
          fieldCopy.isValid = checkValidity( fieldCopy );
          isValid = isValid && fieldCopy.isValid;
          data[key] = fieldCopy;
        }
      }
      return data;
    } );
    if ( isValid !== formIsValid ) {
      updateFormIsValid( isValid );
    }
  }, [formIsValid] );

  useEffect( () => {
    const reportQueryData = extractValues( formData );
    updateReportBuilderData( cur => {
      const arrayCopy = [...cur];
      arrayCopy[propsIndex] = { ...reportQueryData, valid: formIsValid };
      return arrayCopy;
    } );
    updateFullQueryData( cur => {
      const arrayCopy = [...cur];
      arrayCopy[propsIndex] = { ...formData };
      return arrayCopy;
    } );
  }, [formIsValid, updateReportBuilderData, propsIndex, formData, updateFullQueryData] );

  useEffect( () => {
    updateFormData( cur => setValuesFromResponse( cur, REPORT_SEARCH_FIELDS, updateFormIsValid ) );
  }, [] );

  useEffect( () => {
    updateFormData( cur => {
      const cur_copy = { ...cur };
      const where_copy = { ...cur_copy.where, value: '' };
      where_copy.attributes = {
        ...where_copy.attributes,
        options: props.recordType.where
      };
      cur_copy.where = where_copy;
      return cur_copy;
    } );
  }, [props.recordType] );

  useEffect( () => {
    const { multi_options, select_options } = formData.where.value;
    if ( multi_options || select_options ) {
      const field = multi_options ? 'where_multi_select' : 'where_operator';
      const options = multi_options || select_options;
      updateFormData( cur => ( {
        ...cur,
        [field]: {
          ...cur[field],
          value: overrideData ? cur[field].value : multi_options ? [] : '',
          attributes: {
            ...cur[field].attributes,
            options
          }
        }
      } ) );
    }
  }, [formData.where.value, overrideData] );

  return (
    <FormFieldGenerator
      formData={formData}
      formDataInputHandler={updateFormData}
      formEditedHandler={props.updateFormEdited}
      formSubmitted={props.formSubmitted}
      deleteButtonHandler={props.deleteButtonHandler}
      updateFormIsValid={updateFormIsValid}
    />
  );
};

ReportFields.propTypes = {
  deleteButtonHandler: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  overrideData: PropTypes.object,
  recordType: PropTypes.shape( {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    where: PropTypes.array.isRequired
  } ).isRequired,
  updateFormEdited: PropTypes.func.isRequired,
  updateFullQueryData: PropTypes.func.isRequired,
  updateReportBuilderData: PropTypes.func.isRequired
};

export default ReportFields;
