export default {
  name: {
    attributes: {},
    errorMessages: {
      required: { message: 'Package Name is required', key: 'pkn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Name',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
  price: {
    attributes: {
      step: 0.01
    },
    errorMessages: {
      currency: { message: 'Invalid amount provided', key: 'pkp-inv' },
      required: { message: 'Price is required', key: 'pkp-req' }
    },
    errors: [],
    isValid: false,
    label: 'Price',
    type: 'number',
    validation: {
      currency: true,
      required: true
    },
    value: ''
  },
  is_active: {
    attributes: {},
    css: {},
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Active',
    type: 'checkbox',
    validation: {},
    value: true
  }
};
