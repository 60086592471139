import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actionTypes from '../../store/actions/actions';

const SignOut = props => {

  const { logout } = props;

  useEffect( () => {
    async function handleLogout () {
      logout();
    }
    handleLogout().then( () => {} );
  }, [logout] );

  return props.signed_in ? null : <Redirect to='/signin'  />;
};

SignOut.propTypes = {
  logout: PropTypes.func.isRequired,
  signed_in: PropTypes.bool.isRequired
};

const mapStateToProps = state => ( {
  signed_in: state.user.signed_in
} );

const mapDispatchToProps = dispatch => ( {
  logout: () => dispatch( { type: actionTypes.BEGIN_LOGOUT } )
} );

export default connect( mapStateToProps, mapDispatchToProps ) ( SignOut );
