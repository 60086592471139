import { ACCOUNT_STATUSES, ACCOUNT_STAGES } from '../../helpers/constants/options';

export default {
  salesperson_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Salespeople...',
      noOptionsMessage: () => 'No Users Found',
      placeholder: 'Search for a salesperson'
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {
      required: { message: 'Salesperson is required', key: 'sp-req' }
    },
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Salesperson',
    type: 'async-select',
    validation: {
      required: true
    },
    value: ''
  },
  stage: {
    attributes: {
      options: ACCOUNT_STAGES.map( el => ( { label: el, value: el } ) ),
      placeholder: 'Stage'
    },
    errorMessages: {
      required: { message: 'Account Stage is required', key: 'as-req' }
    },
    errors: [],
    isValid: false,
    label: 'Account Stage',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  status: {
    attributes: {
      options: ACCOUNT_STATUSES.map( el => ( { label: el, value: el } ) ),
      placeholder: 'Status'
    },
    errorMessages: {
      required: { message: 'Account Status is required', key: 'st-req' }
    },
    errors: [],
    isValid: false,
    label: 'Account Status',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  }
};
