import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import classes from '../../App.module.css';
import SingleCollapse from '../Shared/SingleCollapse';
import PropTypes from 'prop-types';
import { addressColumnFormatter } from '../../helpers/functions/utilities';

const accountTableHeadings = [
  {
    dataField: 'id',
    text: 'ID', hidden: true
  },
  {
    dataField: 'address',
    text: 'Property Address',
    sort: false,
    headerStyle: { display: 'none' },
    align: 'left',
    classes: addressColumnFormatter
  }
];

const CustomerAccountsCollapse = props => (
  <SingleCollapse openByDefault={props.showAccounts} titleText='Accounts' accordionToggle={props.showAccountsHandler} cardBodyClasses='p-0'>
    <BootstrapTable
      bootstrap4
      bordered={false}
      columns={ accountTableHeadings }
      data={ props.customerAccounts }
      hover
      keyField='id'
      noDataIndication={() => 'No Accounts Found'}
      remote={false}
      rowClasses={classes.HoverCursor}
      rowEvents={{ onClick: props.accountSelectionHandler }}
    />
  </SingleCollapse>
);

CustomerAccountsCollapse.propTypes = {
  showAccounts: PropTypes.bool.isRequired,
  showAccountsHandler: PropTypes.func.isRequired,
  accountSelectionHandler: PropTypes.func.isRequired,
  customerAccounts: PropTypes.arrayOf(
    PropTypes.shape( {
      id: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired
    } )
  ).isRequired
};

export default CustomerAccountsCollapse;
