import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import ScheduleModalRowContent from '../ScheduleModalRowContent';
import { addressFormatter } from '../../../../helpers/functions/utilities';
import ScheduleModalButton from '../ScheduleModalButton';
import Aux from '../../../../hoc/Aux';

const CustomersTab = props => (
  props.customers ? props.customers.map( customer => (
    <Aux key={customer.customer_id}>
      <Row style={{ display: props.display ? 'flex' : 'none' }}>
        <ScheduleModalRowContent name='Name'>{`${customer.customer_first_name} ${customer.customer_last_name}`}</ScheduleModalRowContent>
        <ScheduleModalRowContent name='Address'>{addressFormatter( { address1: customer.customer_address1, address2: customer.customer_address2, city: customer.customer_city, state: customer.customer_state, zip: customer.customer_zip } )}</ScheduleModalRowContent>
        <ScheduleModalRowContent name='Phone'>{customer.customer_phone}</ScheduleModalRowContent>
        <ScheduleModalRowContent name='Email'>{customer.customer_email}</ScheduleModalRowContent>
        <ScheduleModalRowContent name='Notification Preference'>{customer.customer_notification_preference}</ScheduleModalRowContent>
      </Row>
      {
        props.display && props.showLink && customer.customer_id &&
          <ScheduleModalButton link={`/customers/${customer.customer_id}`}>View Customer</ScheduleModalButton>
      }
    </Aux>
  ) ) :
    <Row className='justify-content-center'>
      <p>No Customers Associated With This Account</p>
    </Row>
);

CustomersTab.defaultProps = {
  customers: null,
  showLink: 0
};

CustomersTab.propTypes = {
  customers: PropTypes.oneOfType( [
    PropTypes.oneOf( [null] ),
    PropTypes.arrayOf( PropTypes.shape( {
      customer_first_name: PropTypes.string,
      customer_last_name: PropTypes.string,
      customer_address1: PropTypes.string,
      customer_address2: PropTypes.string,
      customer_city: PropTypes.string,
      customer_state: PropTypes.string,
      customer_zip: PropTypes.string,
      customer_phone: PropTypes.string,
      customer_email: PropTypes.string,
      customer_notification_preference: PropTypes.string,
      customer_id: PropTypes.string
    } ) )
  ] ),
  display: PropTypes.bool.isRequired,
  showLink: PropTypes.number.isRequired
};

export default CustomersTab;
