import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { BsTrash } from 'react-icons/bs';
import { IconContext } from 'react-icons';

import AddButton from '../../Shared/AddButton';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import { search } from '../../../helpers/functions/utilities';
import { selectComponentStyles } from '../../../helpers/functions/utilities';

const Customers = props => {
  const [customerNames, data] = props.customersData.reduce( ( acc, cur, index ) => {
    const removeButton = props.allowAccountsUpdate ?
      <ListGroup.Item className='d-flex p-0 remove-customer-button-container'>
        <Button size="lg" variant='danger' onClick={( e ) => props.customerRemoveHandler( e, cur.id.value )} className='align-self-center remove-customer-button'>
          <IconContext.Provider value={{ size: 25 }}>
            <BsTrash />
          </IconContext.Provider>
        </Button>
      </ListGroup.Item> :
      null;
    acc[0].push(
      <ListGroup as={Col} className='pb-2 pr-0' horizontal key={`Tab${cur.id.value}`}>
        <ListGroup.Item
          action
          href={`#customer${index}`}
          className="d-flex justify-content-between align-items-center p-4 tab-item"
        >
          <p className="p-0 m-0 flex-grow-1">{cur.first_name.value} {cur.last_name.value}</p>
        </ListGroup.Item>
        {removeButton}
      </ListGroup>
    );
    acc[1].push(
      <Tab.Pane eventKey={`#customer${index}`} key={`Pane${cur.id.value}`}>
        <FormFieldGenerator
          formData={cur}
          formDataInputHandler={props.inputHandler}
          updateFormIsValid={props.updateCustomersFormIsValid}
          formSubmitted={props.formSubmitted}
          formEditedHandler={props.formEditedHandler}
        />
      </Tab.Pane>
    );
    return acc;
  }, [[], []] );

  const customerSearch = customerNames.length < 2 && props.allowAccountsUpdate ?
    <Col xs={12} md={props.allowCreateCustomer ? { span: 6, offset: 1 } : 6} className='px-0'>
      <Async
        cacheOptions
        value=''
        noOptionsMessage={() => 'No Customers Found'}
        loadOptions={( val, cb ) => search( val, cb, ['customers'] )}
        onChange={props.customerSelectHandler}
        loadingMessage={() => 'Getting Customers...'}
        placeholder='Search for a customer'
        styles={selectComponentStyles}
      />
    </Col> :
    null;

  const addCustomerButton = customerNames.length < 2 && props.allowAccountsUpdate && props.allowCreateCustomer ?
    <Col xs={12} sm='auto' className='d-flex px-0 pb-3'>
      <AddButton size='28px' clickHandler={props.handleAddNewCustomer} disabled={!props.allowAccountsUpdate} />
    </Col> :
    null;

  const colWidth = customerNames.length < 2 ? 6 : 12;

  return (
    <Tab.Container id="customers-list-group-tabs" defaultActiveKey="#customer0">
      <Row className='mx-auto pb-3'>
        {addCustomerButton}
        {customerSearch}
      </Row>
      <Row className='mx-auto pb-3'>
        <Col xs={12} md={colWidth} className='p-0'>
          <ListGroup horizontal='md'>
            {customerNames}
          </ListGroup>
        </Col>

      </Row>
      <Row>
        <Col xs={12}>
          <Tab.Content>
            {data}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

Customers.propTypes = {
  allowAccountsUpdate: PropTypes.number,
  allowCreateCustomer: PropTypes.number,
  customerRemoveHandler: PropTypes.func.isRequired,
  customersData: PropTypes.array,
  customerSelectHandler: PropTypes.func.isRequired,
  formEditedHandler: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  handleAddNewCustomer: PropTypes.func.isRequired,
  inputHandler: PropTypes.func.isRequired,
  updateCustomersFormIsValid: PropTypes.func.isRequired
};

export default Customers;
