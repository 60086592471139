import React from 'react';
import PropTypes from 'prop-types';

import { filterMap } from '../../store/initialSideDrawerFilters';
import Aux from '../../hoc/Aux';

const FilterGenerator = props => {
  // console.log( 'accounts filters: ', props.filtersValues );
  const filters = Object.keys( props.filtersValues ).map( key => {

    // console.log( 'filter: ', key, ' type: ', typeof key );
    const Component = filterMap[props.filtersValues[key].type];
    return <Component
      key={key}
      id={key}
      label={props.filtersValues[key].label}
      expand={props.filtersValues[key].show}
      getOptionsParams={props.filtersValues[key].getOptionsParams}
      onChangeHandler={props.changeHandler}
      filterValue={props.filtersValues[key].filter}
      options={props.filtersValues[key].options}
      dataType={props.filtersValues[key].dataType}
      step={props.filtersValues[key].step}
    />;
  } );

  return (
    <Aux>
      {filters}
    </Aux>
  );
};

FilterGenerator.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  filtersValues: PropTypes.shape( {

  } )
};

export default FilterGenerator;
