import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { STATES } from '../../helpers/constants/stateAbbreviations';
import { selectComponentStyles } from '../../helpers/functions/utilities';

const StateSelect = props => {

  return (
    <Select
      isClearable={props.clearable}
      value={props.value ? { label: props.value, value: props.value } : null }
      options={STATES.map( el => ( { label: el, value: el } ) )}
      onChange={( option, type ) => {
        props.onChange( props.field, type.action === 'clear' ? null : option.value );
      } }
      placeholder={props.placeholder ? props.placeholder : 'Select a State'}
      isDisabled={props.disabled}
      className={props.className}
      styles={selectComponentStyles}
    />
  );
};

StateSelect.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default StateSelect;
