import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UserCompaniesModal = props => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    size="lg"
    centered
    scrollable
  >
    <Modal.Header closeButton >You must be a member of a company to proceed</Modal.Header>
    <Modal.Body>
      <div>Username: {props.username}</div>
      <div>User ID: {props.userId}</div>
      <div>Provide your username and ID to your company in order for them to grant you permission to their records.</div>
      <div>Or select &apos;New Company&apos; below to create a new company.</div>
    </Modal.Body>
    <Modal.Footer>
      <Row className='w-100'>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button type='submit' onClick={props.onStartNewCompanySetup} size='lg' variant='success' className='w-100'>New Company</Button>
        </Col>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button onClick={props.onHide} size='lg' variant='primary' className='w-100'>OK</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
);

UserCompaniesModal.propTypes = {
  onStartNewCompanySetup: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
};

export default UserCompaniesModal;
