export default {
  username: {
    attributes: {
      placeholder: 'Username',
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      maxLength: { message: 'Username cannot be longer than 20 characters', key: 'un-max' },
      minLength: { message: 'Username must be at least 6 characters', key: 'un-min' },
      required: { message: 'Username is required', key: 'un-req' }
    },
    errors: [
      { message: 'Username is required', key: 'un-req' }
    ],
    isValid: false,
    label: 'Username',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 6,
      required: true
    },
    value: ''
  },
  first_heading: {
    text: '',
    element: 'Row',
    isValid: true
  },
  password: {
    attributes: {
      placeholder: 'Password',
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      maxLength: { message: 'Password cannot be longer than 32 characters', key: 'pw-max' },
      minLength: { message: 'Password must be at least 8 characters', key: 'pw-min' },
      password: { message: 'Password must contain at least 1 lowercase character, 1 uppercase character, 1 numeric character and 1 special character', key: 'pw-weak' },
      required: { message: 'Password is required', key: 'pw-req' }
    },
    errors: [
      { message: 'Password is required', key: 'pw-req' }
    ],
    isValid: false,
    label: 'Password',
    type: 'password',
    validation: {
      maxLength: 32,
      minLength: 8,
      password: true,
      required: true
    },
    value: ''
  },
};
