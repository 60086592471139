import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { IconContext } from 'react-icons';
import { BsPersonFill } from 'react-icons/bs';

import Aux from '../../../../hoc/Aux';
import classes from '../TopNav.module.css';
import PropTypes from 'prop-types';
import Notifications from './Notifications';

const SignedInRightNavItems = props => {

  const changeCompanyHandler = id => {
    props.changeCompanies( id );
  };

  // console.log( 'currentCompanyId: ', props.currentCompanyId );
  const companyName = props.companyOptions.find( el => el.id === props.currentCompanyId ).name;
  const companies = props.companyOptions.length > 1 ?
    <Aux>
      <Dropdown.Header>Switch To Company:</Dropdown.Header>
      {props.companyOptions
        .filter( el => el.id !== props.currentCompanyId )
        .map( el => <Dropdown.Item key={el.id} onClick={() => changeCompanyHandler( el.id )}>{el.name}</Dropdown.Item> )}
      <Dropdown.Divider />
    </Aux> : null;

  return (
    <Aux>
      <Notifications currentCompanyId={props.currentCompanyId} companies={props.companyOptions} />
      <Dropdown
        alignRight
      >
        <Dropdown.Toggle variant="transparent" id="user-dropdown">
          <IconContext.Provider value={{ size: 36, color: '#fff' }} >
            <BsPersonFill />
          </IconContext.Provider>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`position-absolute ${classes.UserDropdownContainer}`}>
          <LinkContainer to='/profile'>
            <Dropdown.Item>My Profile</Dropdown.Item>
          </LinkContainer>
          <Dropdown.Divider />
          <Dropdown.Header>Company: {companyName}</Dropdown.Header>
          <Dropdown.Divider />
          {companies}
          <LinkContainer to='/logout'>
            <Dropdown.Item>Log Out</Dropdown.Item>
          </LinkContainer>
        </Dropdown.Menu>
      </Dropdown>
    </Aux>
  );
};

SignedInRightNavItems.propTypes = {
  changeCompanies: PropTypes.func.isRequired,
  companyOptions: PropTypes.array,
  currentCompanyId: PropTypes.string,
};

export default SignedInRightNavItems;
