export const PRODUCT = {
  cost: 0.00,
  description: '',
  name: '',
  points: 0,
  price: 0,
  sku: '',
  is_fire: false,
  type: ''
};

export const LEAD = {
  address1: '',
  address2: '',
  assigned: false,
  assigned_at: '',
  assigned_to_id: '',
  assigned_to_name: '',
  city: '',
  first_name: '',
  last_name: '',
  phone: '',
  provider_id: '',
  provider_name: '',
  state: '',
  zip: ''
};

export const ACCOUNT = {
  primary_contact_id: '',
  primary_contact_name: '',
  secondary_contact_id: '',
  secondary_contact_name: '',
  salesperson_id: '',
  salesperson_name: '',
  online_at: '',
  billing_address1: '',
  billing_address2: '',
  billing_city: '',
  billing_state: '',
  billing_zip: '',
  company_type: '',
  company_name: '',
  property_address1: '',
  property_address2: '',
  property_city: '',
  property_state: '',
  property_zip: '',
  property_type: '',
  phone: '',
  stage: '',
  status: '',
  id: '-1'
};

export const CUSTOMER = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  prev_address1: '',
  prev_address2: '',
  prev_city: '',
  prev_state: '',
  prev_zip: '',
  dob_on: '',
  email: '',
  first_name: '',
  last_name: '',
  phone: ''
};

export const EVENT = {
  account_id: '',
  company_id: '',
  user_id: '',
  type: '',
  status: '',
  duration: 0,
  completed_at: '',
  end_at: '',
  estimate_on_site_at: '',
  in_route_at: '',
  on_site_at: '',
  property_address: '',
  start_at: ''
};

export const COMPANY = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  name: '',
  phone: '',
  state: '',
  website: '',
  zip: ''
};

export const LEAD_PROVIDER = {
  name: '',
  token: ''
};

export const USER = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: ''
};

export const CUSTOM_FIELD = {
  field_name: '',
  field_type: '',
  required: false,
  minLength: '',
  maxLength: '',
  options: ''
};

export const GROUP = {
  name: ''
};

export const PACKAGE = {
  name: '',
  price: 0.00
};

export const REPORT_HEADER_FIELDS = {
  record_type: {
    label: '',
    value: '',
    where: []
  },
  record_fields: ''
};

export const REPORT_SEARCH_FIELDS = {
  where: '',
  where_multi_select: [],
  where_operator: '',
  where_number: '',
  where_date: '',
  where_text: { valuesArray: [], inputValue: '' },
  'accounts.salesperson_id': [],
  'events.user_id': [],
  'leads.assigned_to_id': [],
  where_group_id: [],
  'leads.provider_id': [],
  'accounts.package_id': []
};

export const CREDENTIALS_FIELDS = {
  paypal: {
    paypal_client: '',
    paypal_secret: '',
    paypal_enabled: false
  },
};

export const PAYMENT_FIELDS = {
  provider: '',
  type: 'One Time',
  number_of_payments: '',
  payment_method: 'Bank Account',
  bank_account: '',
  bank_routing: '',
  card_account: '',
  card_expiration: '',
  card_cvv: '',
};
