import { STATES } from './stateAbbreviations';
import {
  ACCESS_BOOLEAN,
  ACCOUNT_STAGES,
  ACCOUNT_STATUSES,
  EVENT_STATUSES,
  EVENT_TYPES
} from './options';

export const REPORTING_DATE_OPERATORS = [
  { label: 'On', value: '=' },
  { label: 'On Or After', value: '>=' },
  { label: 'On Or Before', value: '<=' },
  { label: 'After', value: '>' },
  { label: 'Before', value: '<' }
];

export const REPORTING_NUMERIC_OPERATORS = [
  { label: 'Greater Than (>)', value: '>' },
  { label: 'Greater Than Or Equal To (>=)', value: '>=' },
  { label: 'Less Than (<)', value: '<' },
  { label: 'Less Than Or Equal To (<=)', value: '<=' },
  { label: 'Equal To (=)', value: '=' },
];

export const REPORTING_TEXT_OPERATORS = [
  { label: 'Is', value: 'is' },
  { label: 'Starts With', value: 'starts_with' },
  { label: 'Ends With', value: 'ends_with' },
  { label: 'Contains', value: 'contains' }
];

export const ACCOUNTS_WHERE = [
  { label: 'Online Date', value: 'accounts.online_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'Created Date', value: 'accounts.created_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'Salesperson', value: 'accounts.salesperson_id', type: 'salesperson' },
  { label: 'Account Status', value: 'accounts.status', multi_options: ACCOUNT_STATUSES.map( el => ( { label: el, value: el } ) ) },
  { label: 'Account Stage', value: 'accounts.stage', multi_options: ACCOUNT_STAGES.map( el => ( { label: el, value: el } ) ) },
  { label: 'Salesperson Is Part Of Group', value: 'accounts.salesperson_id', type: 'group' },
  { label: 'Package', value: 'accounts.package_id', type: 'package' },
  { label: 'RMR', value: 'accounts.rmr', type: 'number', select_options: REPORTING_NUMERIC_OPERATORS },
  { label: 'Property State', value: 'accounts.property_state', multi_options: STATES.map( el => ( { label: el, value: el } ) ) },
  { label: 'Property City', value: 'accounts.property_city', type: 'text', select_options: REPORTING_TEXT_OPERATORS },
  { label: 'Property Zip', value: 'accounts.property_zip', type: 'text', select_options: REPORTING_TEXT_OPERATORS }
];

export const EVENTS_WHERE = [
  { label: 'Event Status', value: 'events.status', multi_options: EVENT_STATUSES.map( el => ( { label: el, value: el } ) ) },
  { label: 'Event Type', value: 'events.type', multi_options: EVENT_TYPES.map( el => ( { label: el, value: el } ) ) },
  { label: 'Start Date', value: 'events.start_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'End Date', value: 'events.end_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'Created Date', value: 'events.created_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'Assigned User', value: 'events.user_id', type: 'event_user' }
];

export const LEADS_WHERE = [
  { label: 'Lead Provider', value: 'leads.provider_id', type: 'lead_provider' },
  { label: 'Created Date', value: 'leads.created_at', type: 'date', select_options: REPORTING_DATE_OPERATORS },
  { label: 'Assigned User', value: 'leads.assigned_to_id', type: 'lead_user' },
  { label: 'Converted', value: 'leads.converted', select_options: ACCESS_BOOLEAN },
  { label: 'Converted Date', value: 'leads.converted_at', type: 'date', select_options: REPORTING_DATE_OPERATORS }
];

export const CUSTOMERS_WHERE = [
  { label: 'State', value: 'customers.state', multi_options: STATES.map( el => ( { label: el, value: el } ) ) },
  { label: 'City', value: 'customers.city', type: 'text', select_options: REPORTING_TEXT_OPERATORS },
  { label: 'Zip', value: 'customers.zip', type: 'text', select_options: REPORTING_TEXT_OPERATORS },
  { label: 'Created Date', value: 'customers.created_at', type: 'date', select_options: REPORTING_DATE_OPERATORS }
];

export const REPORTING_TABLES = [
  { label: 'Accounts', value: 'accounts', where: ACCOUNTS_WHERE },
  { label: 'Customers', value: 'customers', where: CUSTOMERS_WHERE },
  { label: 'Leads', value: 'leads', where: LEADS_WHERE },
  { label: 'Events', value: 'events', where: EVENTS_WHERE }
];

const ACCOUNTS_FIELD_OPTIONS = {
  label: 'Account Fields',
  options: [
    { label: 'Salesperson', value: 'accounts.salesperson_id' },
    { label: 'Online Date', value: 'accounts.online_at' },
    { label: 'Billing Address 1', value: 'accounts.billing_address1' },
    { label: 'Billing Address 2', value: 'accounts.billing_address2' },
    { label: 'Billing City', value: 'accounts.billing_city' },
    { label: 'Billing State', value: 'accounts.billing_state' },
    { label: 'Billing Zip Code', value: 'accounts.billing_zip' },
    { label: 'Company Type', value: 'accounts.company_type' },
    { label: 'Company Name', value: 'accounts.company_name' },
    { label: 'Property Address 1', value: 'accounts.property_address1' },
    { label: 'Property Address 2', value: 'accounts.property_address2' },
    { label: 'Property City', value: 'accounts.property_city' },
    { label: 'Property State', value: 'accounts.property_state' },
    { label: 'Property Zip Code', value: 'accounts.property_zip' },
    { label: 'Phone Number', value: 'accounts.phone' },
    { label: 'RMR', value: 'accounts.rmr' },
    { label: 'Stage', value: 'accounts.stage' },
    { label: 'Status', value: 'accounts.status' },
    { label: 'Created Date', value: 'accounts.created_at' },
    { label: 'Last Updated Date', value: 'accounts.updated_at' },
    { label: 'Package Name', value: 'packages.name' },
    { label: 'Package Price', value: 'accounts.package_price' },
    { label: 'Property Type', value: 'accounts.property_type' },
  ]
};

const LEADS_FIELD_OPTIONS = {
  label: 'Lead Fields',
  options: [
    { label: 'Address 1', value: 'leads.address1' },
    { label: 'Address 2', value: 'leads.address2' },
    { label: 'Assigned', value: 'leads.assigned' },
    { label: 'Date Assigned', value: 'leads.assigned_at' },
    { label: 'Assigned To', value: 'leads.assigned_to_id' },
    { label: 'City', value: 'leads.city' },
    { label: 'First Name', value: 'leads.first_name' },
    { label: 'Last Name', value: 'leads.last_name' },
    { label: 'Phone Number', value: 'leads.phone' },
    { label: 'State', value: 'leads.state' },
    { label: 'Zip Code', value: 'leads.zip' },
    { label: 'Lead Provider', value: 'leads.provider_id' },
    { label: 'Created Date', value: 'leads.created_at' },
    { label: 'Last Updated Date', value: 'leads.updated_at' },
  ]
};

const CUSTOMERS_FIELD_OPTIONS = {
  label: 'Customer Fields',
  options: [
    { label: 'Address 1', value: 'customers.address1' },
    { label: 'Address 2', value: 'customers.address2' },
    { label: 'City', value: 'customers.city' },
    { label: 'State', value: 'customers.state' },
    { label: 'Zip Code', value: 'customers.zip' },
    { label: 'Date of Birth', value: 'customers.dob_on' },
    { label: 'Email', value: 'customers.email' },
    { label: 'First Name', value: 'customers.first_name' },
    { label: 'Last Name', value: 'customers.last_name' },
    { label: 'Phone Number', value: 'customers.phone' },
    { label: 'Created Date', value: 'customers.created_at' },
    { label: 'Last Updated Date', value: 'customers.updated_at' }
  ]
};

const EVENTS_FIELD_OPTIONS = {
  label: 'Event Fields',
  options: [
    { label: 'Assigned To', value: 'events.user_id' },
    { label: 'Type', value: 'events.type' },
    { label: 'Status', value: 'events.status' },
    { label: 'Duration', value: 'events.duration' },
    { label: 'Completed Date', value: 'events.completed_at' },
    { label: 'Scheduled End Date', value: 'events.end_at' },
    { label: 'In Route At', value: 'events.in_route_at' },
    { label: 'On Site At', value: 'events.on_site_at' },
    { label: 'Scheduled Start Date', value: 'events.start_at' },
    { label: 'Created Date', value: 'events.created_at' },
    { label: 'Last Updated Date', value: 'events.updated_at' },
  ]
};

export const REPORTING_FIELD_OPTIONS = {
  accounts: [
    ACCOUNTS_FIELD_OPTIONS,
    LEADS_FIELD_OPTIONS,
    EVENTS_FIELD_OPTIONS,
    CUSTOMERS_FIELD_OPTIONS
  ],
  customers: [
    CUSTOMERS_FIELD_OPTIONS,
    ACCOUNTS_FIELD_OPTIONS,
    { label: 'Lead Fields', options: [] },
    { label: 'Event Fields', options: [] }
  ],
  events: [
    EVENTS_FIELD_OPTIONS,
    { label: 'Customer Fields', options: [] },
    ACCOUNTS_FIELD_OPTIONS,
    { label: 'Lead Fields', options: [] }
  ],
  leads: [
    LEADS_FIELD_OPTIONS,
    ACCOUNTS_FIELD_OPTIONS
  ]
};
