export default {
  name: {
    errorMessages: {
      required: { message: 'Name is required', key: 'na-req' }
    },
    errors: [],
    isValid: false,
    label: 'Report Name',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  }
};
