import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import FormFieldGenerator from '../Shared/FormFieldGenerator';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UserEmailChangedVerificationModal = props => {

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Verify Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>A verification code has been sent to <strong>{props.email}</strong></h4>
        <FormFieldGenerator
          formData={props.validationData}
          formDataInputHandler={props.updateValidationData}
          formEditedHandler={props.updateFormEdited}
          formSubmitted={props.formSubmitted}
          updateFormIsValid={props.updateFormIsValid}
        />
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button type='submit' onClick={props.confirm} size='lg' variant='success' className='w-100'>Confirm</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} size='lg' variant='secondary' className='w-100'>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

UserEmailChangedVerificationModal.propTypes = {
  cancelButtonText: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  updateFormEdited: PropTypes.func.isRequired,
  updateFormIsValid: PropTypes.func.isRequired,
  updateValidationData: PropTypes.func.isRequired,
  validationData: PropTypes.object.isRequired
};

export default UserEmailChangedVerificationModal;
