import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PRODUCT } from '../../helpers/constants/initialData';
import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import Toasts from '../Shared/Toasts';
import {
  cloneDeep,
  convertCustomFieldsForDisplay,
  createRecord,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../helpers/functions/utilities';
import { extractValues, setValuesFromResponse } from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialProductFormFields from './ProductFormFields';

const Product = props => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [productData, updateProductData] = useState( cloneDeep( { ...initialProductFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [recordPermissions, updateRecordPermissions] = useState( { products_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Product</h3> : <h3>Last Updated: <em>{dateTimeFormatter( productData.updated_at )}</em></h3>;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.products_update ) {
      updateProductData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getProduct = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateProductData( cur => setValuesFromResponse( cur, { ...PRODUCT }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `products/${id}` );
        if ( response ) {
          updateProductData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          const { products_update } = response.user_auth;
          updateRecordPermissions( { products_update } );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getProduct()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async ( e ) => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const product = { ...extractValues( productData ) };
      if ( id === 'new' ) {
        const response = await createRecord( 'products', { product } );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `products/${id}`, { product } );
        if ( response ) {
          updateProductData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateFormSubmitted( false );  // @todo all forms should update these state properties
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const button = recordPermissions.products_update ?
    <Form.Row className='pt-3'>
      <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Container>
      <LoadingSpinner loading={loadingState}>
        {heading}
        <Form onSubmit={handleSubmit} className={loadingState ? classes.Loading : ''}>
          <FormFieldGenerator
            formData={productData}
            formDataInputHandler={updateProductData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
          {button}
        </Form>
      </LoadingSpinner>
      {toasts}
    </Container>
  );
};

Product.propTypes = {
  customFields: PropTypes.array.isRequired
};

export default Product;
