import React  from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ErrorModal = ( props ) => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
  >
    <Modal.Body className='mx-auto d-flex flex-column text-center'>
      <h2>An Error Has Occurred</h2>
      <h5>{props.children}</h5>
      <svg className="bi bi-exclamation-triangle mx-auto" width="6em" height="6em" viewBox="0 0 16 16" fill="#ffc107"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
          d="M7.938 2.016a.146.146 0 00-.054.057L1.027 13.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L8.12 2.073a.146.146 0 00-.054-.057A.13.13 0 008.002 2a.13.13 0 00-.064.016zm1.044-.45a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
          clipRule="evenodd"
        />
        <path d="M7.002 12a1 1 0 112 0 1 1 0 01-2 0zM7.1 5.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995z"/>
      </svg>
      <div>
        {typeof props.message === 'string' ? <p className='pre-wrap'>{props.message}</p> : props.message}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Row className='w-100'>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button onClick={props.onHide} size='lg' variant='warning' className='w-100'>OK</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
);

ErrorModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.any,
  message: PropTypes.oneOfType( [PropTypes.string, PropTypes.element] )
};

export default ErrorModal;
