import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import User from '../Users/User';

const UserProfileModal = props => {

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
      scrollable
    >
      <Modal.Header closeButton>{props.newUser ? 'Create ' : 'Update ' }User Profile</Modal.Header>
      <Modal.Body>
        <User
          newUser={props.newUser}
          onSubmit={props.userDataUpdated}
          profileUpdateNeeded={true}
          userId={props.userId}
          username={props.username}
        />
      </Modal.Body>
    </Modal>
  );
};

UserProfileModal.propTypes = {
  newUser: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  userDataUpdated: PropTypes.func.isRequired,
  userId: PropTypes.string,
  username: PropTypes.string.isRequired
};

export default UserProfileModal;
