import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapTable from 'react-bootstrap-table-next';

import SingleCollapse from '../../../Shared/SingleCollapse';
import AddButton from '../../../Shared/AddButton';
import CustomFieldModal from '../../../Modal/CustomFieldModal';
import Aux from '../../../../hoc/Aux';
import { TABLE_NAMES } from '../../../../helpers/constants/options';
import classes from '../../../../App.module.css';

const table_headings = [
  {
    dataField: 'id',
    text: 'ID',
    hidden: true
  },
  {
    dataField: 'field_data.label',
    text: 'Name',
    sort: true,
    align: 'center'
  },
  {
    dataField: 'field_data.type',
    text: 'Type',
    sort: true,
    align: 'center'
  },
  {
    dataField: 'field_data.validation.required',
    text: 'Required',
    sort: true,
    align: 'center',
    formatter: ( cell ) => cell ? 'Yes' : 'No'
  },
];

const CustomFieldsContainer = props => {

  const [showModal, updateShowModal] = useState( false );
  const [tableName, updateTableName] = useState( '' );
  const [selectedFieldId, updateSelectedFieldId] = useState( null );

  const addNewFieldHandler = ( selectedTableName, fieldId ) => {
    updateTableName( selectedTableName );
    updateSelectedFieldId( fieldId );
    updateShowModal( true );
  };

  const closeModal = () => {
    updateShowModal( false );
    updateSelectedFieldId( null );
  };

  return (
    <Aux>
      {
        props.showFields ? TABLE_NAMES.map( table => {

          const addButton = props.customFieldsCreate ?
            <Row className='py-3'>
              <Col xs={12} sm='auto'>
                <AddButton size='28px' clickHandler={() => addNewFieldHandler( table )}/>
              </Col>
            </Row> :
            null;

          return (
            <SingleCollapse
              key={table}
              titleText={table.replace( '_', ' ' )}
              openByDefault={false}
              cardBodyClasses='pt-0'
              className='mb-3'
            >
              {addButton}
              <BootstrapTable
                bootstrap4
                bordered
                columns={table_headings}
                data={props.customFields.hasOwnProperty( table ) ? props.customFields[table] : []}
                hover
                keyField='id'
                noDataIndication={() => 'No Fields Found'}
                remote={false}
                rowClasses={classes.HoverCursor}
                rowEvents={{ onClick: ( e, row ) => addNewFieldHandler( table, row.id ) }}
              />
            </SingleCollapse>
          );
        } ) : null
      }
      <CustomFieldModal
        onHide={closeModal}
        show={showModal}
        tableName={tableName}
        fieldId={selectedFieldId}
        customFields={props.customFields}
      />
    </Aux>
  );
};

CustomFieldsContainer.propTypes = {
  customFields: PropTypes.object.isRequired,
  customFieldsCreate: PropTypes.number,
  showFields: PropTypes.bool.isRequired
};

const mapStateToProps = state => ( {
  customFields: state.company.customFields,
  customFieldsCreate: state.company.auth.custom_fields_create
} );

export default connect( mapStateToProps )( CustomFieldsContainer );
