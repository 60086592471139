export default {
  where: {
    attributes: {
      options: []
    },
    displayCondition: 'true',
    errorMessages: {
      required: { message: 'Please select an option from the list', key: 'whr-req' },
    },
    errors: [],
    isValid: false,
    label: 'Where',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  where_multi_select: {
    attributes: {
      options: []
    },
    displayCondition: 'data.where.value && data.where.value.multi_options',
    errorMessages: {
      arrayMinLength: { message: 'Please select an option from the list', key: 'wms-req' },
    },
    errors: [],
    isValid: false,
    label: 'Is',
    type: 'multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  where_operator: {
    attributes: {
      options: []
    },
    displayCondition: 'data.where.value && data.where.value.select_options',
    errorMessages: {
      required: { message: 'Please select an option from the list', key: 'who-req' },
    },
    errors: [],
    isValid: false,
    label: 'Comparison',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  where_number: {
    attributes: {
      step: 0.01
    },
    displayCondition: 'data.where.value && data.where.value.type === "number"',
    errorMessages: {
      required: { message: 'Value is required', key: 'whn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Value',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
  where_date: {
    attributes: {
      closeOnSelect: true,
      dateFormat: 'MM/DD/YYYY',
      timeFormat: false
    },
    displayCondition: 'data.where.value && data.where.value.type === "date"',
    errorMessages: {
      required: { message: 'A date is required', key: 'whd-req' }
    },
    errors: [],
    isValid: false,
    label: 'Date',
    type: 'datetime',
    validation: {
      required: true
    },
    value: ''
  },
  where_text: {
    attributes: {},
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    displayCondition: 'data.where.value && data.where.value.type === "text"',
    errorMessages: {
      creatableMinLength: { message: 'Please enter at least one option', key: 'wht-req' }
    },
    errors: [],
    isValid: false,
    label: 'Value',
    type: 'creatable-select',
    validation: {
      creatableMinLength: 1
    },
    value: { valuesArray: [], inputValue: '' }
  },
  'accounts.salesperson_id': {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Salesperson...',
      noOptionsMessage: () => 'No Salesperson Found',
      placeholder: 'Search for a salesperson',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "salesperson"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a salesperson from the list', key: 'mwc-req' },
    },
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Salesperson',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  'events.user_id': {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting User...',
      noOptionsMessage: () => 'No User Found',
      placeholder: 'Search for a user',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "event_user"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a user from the list', key: 'whu-req' },
    },
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Event Assigned To',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  'leads.assigned_to_id': {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting User...',
      noOptionsMessage: () => 'No User Found',
      placeholder: 'Search for a user',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "lead_user"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a user from the list', key: 'whl-req' },
    },
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Lead Assigned To',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  where_group_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Groups...',
      noOptionsMessage: () => 'No Group Found',
      placeholder: 'Search for a group',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "group"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a group from the list', key: 'whg-req' },
    },
    errors: [],
    getOptionsParams: ['groups'],
    isValid: false,
    label: 'Group',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  'leads.provider_id': {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Lead Providers...',
      noOptionsMessage: () => 'No Lead Provider Found',
      placeholder: 'Search for a lead provider',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "lead_provider"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a lead provider from the list', key: 'wlp-req' },
    },
    errors: [],
    getOptionsParams: ['leadProviders'],
    isValid: false,
    label: 'Lead Provider',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  'accounts.package_id': {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Packages...',
      noOptionsMessage: () => 'No Packages Found',
      placeholder: 'Search for a package',
      isMulti: true
    },
    displayCondition: 'data.where.value && data.where.value.type === "package"',
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a package from the list', key: 'whp-req' },
    },
    errors: [],
    getOptionsParams: ['packages'],
    isValid: false,
    label: 'Package',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  },
  condition_remove_button: {
    css: {
      className: 'd-flex align-items-end',
      xs: 12,
      md: 1,
      lg: 1
    },
    displayCondition: 'true',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: '',
    type: 'deleteButton',
    validation: {},
    value: ''
  }
};
