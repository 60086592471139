import React  from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DeleteModal = ( props ) => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Are You Sure?
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {props.message}
    </Modal.Body>
    <Modal.Footer>
      <Row className='w-100'>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button onClick={props.onConfirmDelete} size='lg' variant='danger' className='w-100'>Delete</Button>
        </Col>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button onClick={props.onHide} size='lg' variant='secondary' className='w-100'>Cancel</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
);

DeleteModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType( [PropTypes.object, PropTypes.string] )
};

export default DeleteModal;
