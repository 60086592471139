import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignUp from '../components/Auth/SignUp';
import SignIn from '../components/Auth/SignIn';
import ResetPassword from '../components/Auth/ResetPassword';
import Verify from '../components/Auth/Verify';
// import Landing from '../components/Landing/Landing';
import VerifyEmail from '../components/Auth/VerifyEmail';

export default function AuthRoutes () {
  return (
    <Switch>
      {/*<Route path='/' exact component={Landing} />*/}
      <Route path='/signup' exact component={SignUp} />
      <Route path='/signin' exact component={SignIn} />
      <Route path='/passwordreset' exact component={ResetPassword} />
      <Route path='/verify' exact component={Verify} />
      <Route path='/verifyEmail' exact component={VerifyEmail} />
      <Route path='*' component={SignIn} />
    </Switch>
  );
}
