import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';

import sideDrawerReducer from './reducers/sideDrawer';
import errorReducer from './reducers/error';
import successReducer from './reducers/success';
import companyReducer from './reducers/company';
import userReducer from './reducers/user';

const rootReducer = combineReducers( {
  sideDrawer: sideDrawerReducer,
  error: errorReducer,
  success: successReducer,
  company: companyReducer,
  user: userReducer
} );

const logger = store => {
  return next => {
    return action => {
      // console.log( '[Middleware] Dispatching ', action );
      const result = next( action );
      // console.log( '[Middleware] next state ', store.getState() );
      return result;
    };
  };
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.hasOwnProperty( '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' ) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore( rootReducer, composeEnhancers( applyMiddleware( logger, sagaMiddleware ) ) );

sagaMiddleware.run( sagas );

export default store;
