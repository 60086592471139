import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

import { createFormArray, inputHandler } from '../../helpers/functions/formValidator';
import FormInput from '../FormInputs/FormInput';

const FormFieldGenerator = props => {

  const elementMap = {
    Row: Row
  };

  const formElementsArray = createFormArray( props.formData );

  return (
    <Form.Row>
      {formElementsArray.map( el => (
        el.id.endsWith( '_heading' ) ?
          el.element ?
            elementMap[el.element] :
            <Col xs={12} key={el.id}><h3 className={el.config.elClassName}>{el.config.text}</h3></Col> :
          <Form.Group
            key={el.id}
            as={Col}
            xs={el.config.css && el.config.css.xs ? el.config.css.xs : 12}
            sm={el.config.css && el.config.css.sm ? el.config.css.sm : 12}
            md={el.config.css && el.config.css.md ? el.config.css.md : 4}
            lg={el.config.css && el.config.css.lg ? el.config.css.lg : 3}
            className={
              el.config.type === 'checkbox' ?
                `my-md-auto left-label ${el.config.css && el.config.css.className ? el.config.css.className : ''}` :
                `left-label ${el.config.css && el.config.css.className ? el.config.css.className : ''}`
            }
          >
            <FormInput
              attributes={el.config.attributes}
              controlKey={el.id}
              currency={el.config.validation.currency}
              deleteButtonHandler={props.deleteButtonHandler}
              disabled={el.config.disabled}
              displayFormatter={el.config.displayFormatter}
              errors={el.config.errors}
              getOptionsParams={el.config.getOptionsParams}
              inputType={el.config.type}
              invalid={!el.config.isValid}
              label={el.config.label}
              mutedText={el.config.mutedText}
              onChange={( e, value ) => el.config.inputHandler && typeof el.config.inputHandler === 'function' ?
                el.config.inputHandler( {
                  field: el.id,
                  event: e,
                  value,
                  formData: props.formData,
                  updateFormData: props.formDataInputHandler,
                  updateFormIsValid: props.updateFormIsValid,
                  updateFormEdited: props.formEditedHandler
                } ) :
                inputHandler( el.id, { e, value }, props.formData, props.formDataInputHandler, props.updateFormIsValid, props.formEditedHandler )}
              shouldValidate={props.formSubmitted && Object.keys( el.config.validation ).length > 0}
              value={el.config.value}
            />
          </Form.Group>
      ) )}
    </Form.Row>
  );
};

FormFieldGenerator.propTypes = {
  formData: PropTypes.object.isRequired,
  formDataInputHandler: PropTypes.func.isRequired,
  formEditedHandler: PropTypes.func,
  formSubmitted: PropTypes.bool.isRequired,
  deleteButtonHandler: PropTypes.func,
  updateFormIsValid: PropTypes.func.isRequired
};

export default FormFieldGenerator;
