import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import classes from '../../../App.module.css';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import { dateTimeFormatterFromString } from '../../../helpers/functions/utilities';
import axios, { generateCancelToken } from '../../../axios-instances/internal-api';
import widgetWebsocketUpdates from './WidgetWebsocketUpdates';

const TodaysEvents = ( { companyId: id, show, userId } ) => {

  const [loadingState, updateLoadingState] = useState( true );
  const [data, updateData] = useState( [] );

  const history = useHistory();

  const headings = [
    { dataField: 'id', hidden: true },
    { dataField: 'type', text: 'Type', sort: true },
    { dataField: 'start_at', text: 'Scheduled Start Time', sort: true, formatter: dateTimeFormatterFromString },
    { dataField: 'address', text: 'Address', sort: false },
    { dataField: 'status', text: 'Status', sort: true },
    { dataField: 'duration', text: 'Duration', sort: true }
  ];

  useEffect( () => {
    let source;
    const getTodaysEvents = async () => {
      updateLoadingState( true );
      source = generateCancelToken();
      const data = {
        filters: {
          'events.start_at': {
            filter: 'On',
            show: true,
            type: 'date',
            value: moment().endOf( 'day' ).format( 'YYYY-MM-DD' ),
            offset: moment().utcOffset()
            // value: moment().startOf( 'day' ).subtract( moment().utcOffset(), 'minutes' ).format( 'YYYY-MM-DDTHH:mm:ss.SSS' ) + 'Z'
            // this is one proposed solution to handle timezones properly.  The users timezone must be used in order to determine the appropriate offset
            // on the server.  This same logic will need to be applied to the schedule, filtering and reporting
          }
        },
        page: 1,
        sizePerPage: 1000,
        sortField: 'start_at',
        sortOrder: 'asc',
        todaysEventsWidget: true
      };
      const response = await axios.post( '/events/list', data, { cancelToken: source.token } );
      updateData( response.data );
      updateLoadingState( false );
    };
    if ( id && show ) {
      getTodaysEvents()
        .then( () => {} );
    }
    // console.log( 'offset: ', moment().utcOffset() );
    return () => {
      if ( source && loadingState ) {
        source.cancel();
        updateData( [] );
      }
    };
  }, [id, show] );

  const addEventCheck = event => {
    const endOfDay = moment( new Date() ).endOf( 'day' ).local();
    const eventStart = moment.utc( event.start_at ).local();
    return eventStart < endOfDay;
  };

  const removeEventCheck = event => {
    if ( event.hasOwnProperty( 'start_at' ) ) {
      const endOfDay = moment( new Date() ).local().endOf( 'day' );
      const eventStart = moment( event.start_at ).local();
      return eventStart > endOfDay;
    }
    return true;
  };

  widgetWebsocketUpdates( {
    addEventCheck,
    addEventPrefix: 'te_widget_add_',
    id: `${id}_${userId}`,
    removeEventCheck,
    removeEventPrefix: 'te_widget_remove_',
    updateData
  } );

  const rowEvents = {
    onClick: ( e, row ) => {
      history.push( `/events/${row.id}` );
    }
  };

  return show ?
    <WidgetContainer title='Today&apos;s Events'>
      <BootstrapTable
        bootstrap4
        bordered={false}
        classes='ClickableTableRows TableComponent'
        columns={headings}
        data={data}
        defaultSorted={[{
          dataField: 'start_at',
          order: 'asc'
        }]}
        hover
        keyField='id'
        loading={loadingState}
        noDataIndication={() => 'No Records Found'}
        remote={false}
        rowEvents={rowEvents}
        striped
        wrapperClasses={loadingState ? `TableWrapper ${classes.Loading} m-0` : 'TableWrapper m-0'}
      />
    </WidgetContainer> :
    null;
};

TodaysEvents.propTypes = {
  companyId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired
};

export default TodaysEvents;
