import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LEAD } from '../../helpers/constants/initialData';
import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import Toasts from '../Shared/Toasts';
import {
  cloneDeep,
  convertCustomFieldsForDisplay,
  createRecord,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../helpers/functions/utilities';
import ComponentNotes from '../Shared/ComponentNotes';
import Aux from '../../hoc/Aux';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialLeadFormFields from './LeadFormFields';
import Watchlist from '../Shared/Watchlist';

const Lead = props => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [leadData, updateLeadData] = useState( cloneDeep( { ...initialLeadFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ) );

  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );

  const [recordPermissions, updateRecordPermissions] = useState( { leads_assign: 0, leads_convert: 1, leads_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Lead</h3> : <Watchlist recordId={id} recordType='leads' />;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.leads_update || leadData.account_id.value ) {  // if you cannot update leads, disable all fields
      updateLeadData( cur => disableFormFields( cur ) );
    } else if ( !recordPermissions.leads_assign ) { // if you can update leads, but cannot assign them, disable that field
      updateLeadData( cur => {
        const { assigned_to_id } = cur;
        assigned_to_id.attributes.disabled = true;
        return { ...cur, assigned_to_id };
      } );
    } else if ( !leadData.account_id.value || leadData.account_id.value === '' ) { // if you can update and assign, make sure the lead hasn't been converted before enabling field
      updateLeadData( cur => {
        const { assigned_to_id } = cur;
        assigned_to_id.attributes.disabled = false;
        return { ...cur, assigned_to_id };
      } );
    }
  }, [recordPermissions, leadData.account_id.value] );

  useEffect( () => {
    const getLead = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        const defaults = { ...LEAD };
        try {
          const response = await getSingleRecord( 'leads/check' );
          defaults.assigned_to_id = response.user_id;
          defaults.assigned_to_label = response.name;
          updateRecordPermissions( cur => ( { ...cur, leads_assign: response.leads_assign } ) );
          updateLeadData( cur => setValuesFromResponse( cur, defaults, updateFormIsValid ) );
        } catch ( e ) {
          updateLoadingState( false );
        }
      } else {
        try {
          const response = await getSingleRecord( `leads/${id}` );
          if ( typeof response.account_id === 'number' ) {
            updateLeadData( cur => disableFormFields( cur ) );
          }
          updateLeadData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          const { leads_assign, leads_convert, leads_update } = response.user_auth;
          updateRecordPermissions( { leads_assign, leads_convert, leads_update } );
        } catch ( e ) {
          updateLoadingState( false );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getLead()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async ( e ) => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const data = { ...extractValues( leadData ) };
      if ( data.assigned_to_id === '' ) {
        data.assigned_to_id = null;
      }
      if ( id === 'new' ) {
        const response = await createRecord( 'leads', { lead: data } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `leads/${id}`, { lead: data } );
        if ( response ) {
          updateLeadData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateFormSubmitted( false );
          updateFormEdited( false );
          const { leads_convert } = response.user_auth;
          updateRecordPermissions( cur => ( { ...cur, leads_convert } ) );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const convertLead = async () => {
    const data = extractValues( leadData );
    if ( formEdited ) {
      if ( data.assigned_to_id === '' ) {
        data.assigned_to_id = null;
      }
      await updateRecord( `leads/${id}`, { lead: data } );
    }
    const response = await updateRecord( `leads/${id}/convert` );
    if ( response ) {
      history.push( `/accounts/${response.account_id}/details` );
    }
  };

  const updateButton = leadData.account_id.value || !recordPermissions.leads_update ?
    null :
    <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
      {buttonText}
    </Button>;

  const convertButtonText = formEdited ? 'Update and Convert to Account' : 'Convert to Account';

  const convertButton = id === 'new' ?
    null :
    leadData.account_id.value ?
      <Link to={`/accounts/${leadData.account_id.value.value}/details`}>
        <Button variant='success' type='button' disabled={loadingState} className='w-100'>View Account</Button>
      </Link> : !recordPermissions.leads_convert ?
        null :
        <Button variant='success' type='button' disabled={loadingState} onClick={convertLead} className='w-100'>
          {convertButtonText}
        </Button>;

  const leadNotes = id === 'new' ? null : <ComponentNotes id={id} component='leads' />;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      {heading}
      <Container>
        <LoadingSpinner loading={loadingState}>
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''}>
            <FormFieldGenerator
              formData={leadData}
              formDataInputHandler={updateLeadData}
              formEditedHandler={updateFormEdited}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
            />
            <Form.Row className='pt-3'>
              <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='ml-auto'>
                {updateButton}
              </Form.Group>
              <Form.Group as={Col} xs={12} sm='auto' className='mr-auto'>
                {convertButton}
              </Form.Group>
            </Form.Row>
          </Form>
        </LoadingSpinner>
      </Container>
      {leadNotes}
      {toasts}
    </Aux>
  );
};

Lead.propTypes = {
  customFields: PropTypes.array.isRequired
};

export default Lead;
