import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

import classes from './WidgetContainer.module.css';

const WidgetContainer = props => (
  <Card className={`${classes.WidgetWidth} m-lg-2 my-3 w-100`}>
    <Card.Header as='h3'>{props.title}</Card.Header>
    <Card.Body className='p-0'>
      {props.children}
    </Card.Body>
  </Card>
);

WidgetContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default WidgetContainer;
