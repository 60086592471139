import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import MainTable from '../Tables/MainTable';
import AddButton from '../Shared/AddButton';
import Aux from '../../hoc/Aux';
import DeleteModal from '../Modal/DeleteModal';
import {
  addressColumnFormatter,
  addressFormatter,
  closeDeleteModalHandler,
  confirmDeleteHandler, dateTimeFormatterFromString,
  tableDeleteButton,
  tableDeleteHandler,
  tableEditButton
} from '../../helpers/functions/utilities';

const route = 'customers';

const Customers = props => {

  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [customerDeleted, updateCustomerDeleted] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, route ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'customer_first_name', text: 'Name', sort: true, formatter: ( cell, row ) => `${row.customer_first_name} ${row.customer_last_name}` },
    { dataField: 'address1', text: 'Address', sort: true, formatter: ( cell, row ) => addressFormatter( row ), classes: addressColumnFormatter },
    { dataField: 'num_accounts', text: '# of Accounts', sort: true },
    { dataField: 'phone', text: 'Phone Number', sort: true },
    { dataField: 'created_at', text: 'Date Created', sort: true, formatter: dateTimeFormatterFromString },
    { dataField: 'updated_at', text: 'Date Last Updated', sort: true, formatter: dateTimeFormatterFromString },
  ];

  if ( props.auth.del ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: ( cell, row ) => tableDeleteButton( () => tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal ) )
    } );
  }

  const addButton = props.auth.create ?
    <Row className='py-3 justify-content-start'>
      <Col xs={12} sm={{ span: 'auto', offset: 1 }}>
        <AddButton route={route} size='28px' />
      </Col>
    </Row> :
    null;

  return (
    <Aux>
      {addButton}
      <MainTable
        headings={ headings }
        route={route}
        remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
        refreshNeeded={customerDeleted}
        updateRefreshNeeded={updateCustomerDeleted}
      />
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.customer_first_name} {recordToDelete.customer_last_name}.</p><p>They will be removed from all accounts that they are currently associated with. -- @todo</p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updateCustomerDeleted )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
    </Aux>
  );
};

Customers.propTypes = {
  auth: PropTypes.shape( {
    create: PropTypes.number,
    del: PropTypes.number
  } )
};

export default Customers;
