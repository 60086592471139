import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';

import { storePrevious } from '../helpers/functions/utilities';
import Account from '../components/Accounts/Account';
import Accounts from '../components/Accounts/Accounts';
import Customer from '../components/Customers/Customer';
import Customers from '../components/Customers/Customers';
import Event from '../components/Events/Event';
import Events from '../components/Events/Events';
import Group from '../components/Groups/Group';
import Home from '../components/Home/Home';
import Leaderboard from '../components/Leaderboard/Leaderboard';
import Lead from '../components/Leads/Lead';
import LeadProvider from '../components/LeadProviders/LeadProvider';
import Leads from '../components/Leads/Leads';
import Notifications from '../components/Notifications/Notifications';
import Package from '../components/Packages/Package';
import Product from '../components/Products/Product';
import Schedule from '../components/Schedule/Schedule';
import Company from '../components/Company/Company';
import SignOut from '../components/Auth/SignOut';
import PendingUser from '../components/Users/PendingUsers/PendingUser';
import User from '../components/Users/User';

const Routes = props => {

  const { internalApiErrorCode } = props;
  const prevStatusCode = storePrevious( internalApiErrorCode );
  const history = useHistory();

  useEffect( () => {
    if ( prevStatusCode === 403 && !internalApiErrorCode ) {
      history.push( '/home' );
    }
  }, [prevStatusCode, internalApiErrorCode, history] );

  const singleRoutes = [
    {
      Component: User,
      exact: true,
      path: '/profile',
      userId: props.userId
    },
    {
      companyId: props.companyId,
      Component: Notifications,
      exact: true,
      path: '/notifications'
    },
    {
      companyId: props.companyId,
      Component: Home,
      exact: true,
      path: '/home',
      userId: props.userId
    }
  ];

  if ( props.companyGroupsAccess ) {
    singleRoutes.push(
      {
        Component: Group,
        exact: true,
        path: '/groups/:id'
      }
    );
  }

  if ( props.companyLeadProvidersAccess ) {
    singleRoutes.push(
      {
        Component: LeadProvider,
        customFields: props.customFields.hasOwnProperty( 'lead_providers' ) ? props.customFields.lead_providers : [],
        exact: true,
        path: '/leadProviders/:id'
      }
    );
  }

  if ( props.companyPackagesAccess ) {
    singleRoutes.push(
      {
        Component: Package,
        exact: true,
        path: '/packages/:id'
      }
    );
  }

  if ( props.companyProductsAccess ) {
    singleRoutes.push(
      {
        Component: Product,
        customFields: props.customFields.hasOwnProperty( 'products' ) ? props.customFields.products : [],
        exact: true,
        path: '/products/:id'
      }
    );
  }

  if ( props.companyUsersAccess ) {
    singleRoutes.push(
      {
        Component: PendingUser,
        exact: true,
        path: '/pendingUsers/:id'
      },
      {
        companyId: props.companyId,
        Component: User,
        exact: true,
        path: '/users/:id',
        userId: props.userId
      }
    );
  }

  if ( props.leadsAccess ) {
    singleRoutes.push(
      {
        Component: Lead,
        customFields: props.customFields.hasOwnProperty( 'leads' ) ? props.customFields.leads : [],
        exact: true,
        path: '/leads/:id'
      },
      {
        auth: { create: props.leadsCreate, del: props.leadsDelete },
        Component: Leads,
        exact: true,
        path: '/leads'
      },
    );
  }

  if ( props.accountsAccess ) {
    singleRoutes.push(
      {
        Component: Account,
        customFields: props.customFields,
        exact: true,
        path: '/accounts/:id/:tab',
      },
      {
        auth: { create: props.accountsCreate, del: props.accountsDelete },
        Component: Accounts,
        exact: true,
        path: '/accounts'
      }
    );
  }
  const accountRedirect = props.accountsAccess ?
    <Redirect path='/accounts/:id' to='/accounts/:id/details' exact={true} /> :
    null;

  if ( props.customersAccess ) {
    singleRoutes.push(
      {
        Component: Customer,
        customFields: props.customFields.hasOwnProperty( 'customers' ) ? props.customFields.customers : [],
        exact: true,
        path: '/customers/:id'
      },
      {
        auth: { create: props.customersCreate, del: props.customersDelete },
        Component: Customers,
        exact: true,
        path: '/customers'
      }
    );
  }

  if ( props.eventsAccess ) {
    singleRoutes.push(
      {
        Component: Event,
        customFields: props.customFields.hasOwnProperty( 'events' ) ? props.customFields.events : [],
        exact: true,
        path: '/events/:id'
      },
      {
        auth: { create: props.eventsCreate, del: props.eventsDelete },
        Component: Events,
        exact: true,
        path: '/events'
      }
    );
  }

  if ( props.scheduleAccess ) {
    singleRoutes.push(
      {
        Component: Schedule,
        exact: true,
        path: '/schedule'
      }
    );
  }

  if ( props.companyAccess ) {
    singleRoutes.push(
      {
        companyId: props.companyId,
        Component: Company,
        customFields: props.customFields,
        exact: true,
        path: '/company/:tab'
      }
    );
  }

  return (
    <Switch>
      <Route path='/leaderboard' exact component={Leaderboard}/>
      <Route path='/logout' exact component={SignOut}/>
      {accountRedirect}
      {singleRoutes.map( ( { auth, Component, customFields = [], path, companyId, userId, strict = false, exact = false }, index ) => <Route key={index} path={path} exact={exact} strict={strict} render={() => <Component auth={auth} companyId={companyId} userId={userId} customFields={customFields} />} /> )}
      <Redirect path='*' to='/home' />
    </Switch>
  );
};

Routes.propTypes = {
  accountsAccess: PropTypes.number.isRequired,
  accountsCreate: PropTypes.number,
  accountsDelete: PropTypes.number,
  companyAccess: PropTypes.number.isRequired,
  companyGroupsAccess: PropTypes.number.isRequired,
  companyLeadProvidersAccess: PropTypes.number.isRequired,
  companyId: PropTypes.string.isRequired,
  companyPackagesAccess: PropTypes.number.isRequired,
  companyProductsAccess: PropTypes.number.isRequired,
  companyUsersAccess: PropTypes.number.isRequired,
  customersAccess: PropTypes.number.isRequired,
  customersCreate: PropTypes.number,
  customersDelete: PropTypes.number,
  customFields: PropTypes.object.isRequired,
  eventsAccess: PropTypes.number.isRequired,
  eventsCreate: PropTypes.number,
  eventsDelete: PropTypes.number,
  internalApiErrorCode: PropTypes.number,
  leadsAccess: PropTypes.number.isRequired,
  leadsCreate: PropTypes.number,
  leadsDelete: PropTypes.number,
  scheduleAccess: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ( {
  accountsAccess: state.company.auth.accounts_view,
  accountsCreate: state.company.auth.accounts_create,
  accountsDelete: state.company.auth.accounts_delete,
  companyAccess: state.company.auth.company_tab,
  companyGroupsAccess: state.company.auth.groups_view,
  companyLeadProvidersAccess: state.company.auth.lead_providers_view,
  companyId: state.company.id,
  companyPackagesAccess: state.company.auth.packages_view,
  companyProductsAccess: state.company.auth.products_view,
  companyUsersAccess: state.company.auth.users_view,
  customersAccess: state.company.auth.customers_view,
  customersCreate: state.company.auth.customers_create,
  customersDelete: state.company.auth.customers_delete,
  customFields: state.company.customFields,
  eventsAccess: state.company.auth.events_view,
  eventsCreate: state.company.auth.events_create,
  eventsDelete: state.company.auth.events_delete,
  internalApiErrorCode: state.error.statusCode,
  leadsAccess: state.company.auth.leads_view,
  leadsCreate: state.company.auth.leads_create,
  leadsDelete: state.company.auth.leads_delete,
  scheduleAccess: state.company.auth.schedule_view,
  userId: state.user.id
} );

export default connect( mapStateToProps )( Routes );
