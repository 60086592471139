export default {
  name: {
    attributes: {},
    errorMessages: {
      required: { message: 'Lead Provider Name is required', key: 'pn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Name',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
  token: {
    attributes: {
      disabled: true
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'API Token',
    type: 'text',
    validation: {},
    value: ''
  },
  is_active: {
    attributes: {},
    css: {},
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Active',
    type: 'checkbox',
    validation: {},
    value: false
  }
};
