import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Type } from 'react-bootstrap-table2-editor';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Aux from '../../../hoc/Aux';
import '../../Tables/Tables.css';
import {
  currencyFormatter,
  dateTimeFormatterFromString,
  getSingleRecord,
  selectComponentStyles,
  tableDeleteButton
} from '../../../helpers/functions/utilities';
import ProductSearchWithTable from '../../Products/ProductSearchWithTable';
import axios from '../../../axios-instances/internal-api';
import AddButton from '../../Shared/AddButton';

const Equipment = props => {

  const [selectedPackage, updateSelectedPackage] = useState( null );
  const [selectedPackageChanged, updateSelectedPackageChanged] = useState( false );
  const [companyPackages, updateCompanyPackages] = useState( [] );
  const [companyPackagesRetrieved, updateCompanyPackagesRetrieved] = useState( false );

  const { accountPackage } = props;

  useEffect( () => {
    const setPackage = () => {
      if ( accountPackage != null && accountPackage.value ) {
        const pkg = companyPackages.find( el => el.value === accountPackage.value );
        if ( pkg ) {
          updateSelectedPackage( pkg );
        }
      }
    };
    if ( !companyPackagesRetrieved ) {
      updateCompanyPackagesRetrieved( true );
      console.log( 'getting company packages' );
      axios.get( 'packages/all' )
        .then( response => {
          updateCompanyPackages( response.data );
          setPackage();
        } )
        .catch( e => {
          console.log( 'error retrieving packages: ', e );
        } );
    } else {
      setPackage();
    }
  }, [accountPackage, companyPackagesRetrieved, companyPackages] );

  const updateSelectedPackageHandler = pkg => {
    console.log( 'pkg: ', pkg );
    updateSelectedPackage( pkg );
    updateSelectedPackageChanged( true );
    if ( !pkg ) {
      removePackageHandler();
    }
  };

  const addPackageHandler = async () => {
    console.log( 'add equipment from package' );
    const response = await getSingleRecord( `accounts/${props.accountId}/package/${selectedPackage.value}` );
    if ( response ) {
      // console.log( 'response: ', response );
      props.updateEquipmentData( cur => {
        const packageProducts = response.products.map( el => {
          const prod = { ...el, from_package: 1, price: 0, id: `pkg_${el.id}` };
          delete prod.package_id;
          return prod;
        } );
        const existingEquipment = [...cur.filter( prod => !( prod.hasOwnProperty( 'from_package' ) && !!prod.from_package ) )];
        const packageName = [{
          id: 'pkg9999',
          name: selectedPackage.label,
          price: selectedPackage.price,
          quantity: 1,
          points: 0,
          description: 'Package',
          type: 'Package',
          sku: `Package - ${selectedPackage.label}`,
          is_fire: 0,
          from_package: 1,
          product_id: 'pkg9999'
        }];
        return [...packageName, ...existingEquipment, ...packageProducts];
      } );
      props.updateEquipmentEdited( true );
    }
    props.updateAccountData( cur => ( {
      ...cur,
      package_id: { ...cur.package_id, value: selectedPackage.value },
      package_price: { ...cur.package_price, value: selectedPackage.price }
    } ) );
    props.updateAccountEdited( true );
    updateSelectedPackageChanged( false );
  };

  const removePackageHandler = () => {
    props.updateAccountData( cur => ( {
      ...cur,
      package_id: { ...cur.package_id, value: '' },
      package_price: { ...cur.package_price, value: 0 }
    } ) );
    props.updateAccountEdited( true );
    props.updateEquipmentData( cur => [...cur.filter( prod => !( prod.hasOwnProperty( 'from_package' ) && !!prod.from_package ) )] );
    props.updateEquipmentEdited( true );
  };

  const removeEquipmentHandler = product => {
    let pkg = product.id === 'pkg9999';
    props.updateEquipmentData( cur => {
      let products = [...cur];
      const index = cur.findIndex( el => el.id === product.id );
      if ( index !== -1 ) {
        products.splice( index, 1 );
        props.updateEquipmentEdited( true );
      }
      return products;
    } );
    if ( pkg ) {
      updateSelectedPackageHandler( null );
    }
  };

  const validQuantity = ( newValue, row ) => {
    if ( row.id === 'pkg9999' ) {
      return newValue === '1' ? true : {
        valid: false,
        message: 'Only one package is allowed per account'
      };
    }
    const isInvalid = !newValue.length || newValue.match( /\D/ );
    props.updateEquipmentEdited( true );
    return isInvalid ?
      {
        valid: false,
        message: 'Quantity must only contain numbers'
      } :
      true;
  };

  const validPrice = ( newValue, row ) => {
    console.log( 'new value: ', newValue );
    if ( row.id === 'pkg9999' ) {
      return {
        valid: false,
        message: 'Package price cannot be changed'
      };
    }
    const pattern = /^\d+\.?\d{0,2}?$/;
    const isValid = pattern.test( newValue );
    console.log( 'valid: ', isValid );
    return isValid ? isValid : {
      valid: false,
      message: 'Invalid amount, either enter a whole number or up to two decimal places'
    };
  };

  const addPackageButton = props.allowAccountsUpdate ?
    <Col xs={12} sm={4} md='auto' className='pb-3'>
      <AddButton size='28px' text='Add' clickHandler={addPackageHandler} disabled={!selectedPackageChanged || selectedPackage == null} />
    </Col> :
    null;

  const packageInput = props.allowAccountsUpdate ?
    <Select
      isClearable
      placeholder='Select a Package'
      value={selectedPackage}
      options={companyPackages}
      onChange={updateSelectedPackageHandler}
      disabled={selectedPackage}
      styles={selectComponentStyles}
    /> :
    selectedPackage ?
      <Form.Control
        type='text'
        value={selectedPackage.label ? selectedPackage.label : ''}
        disabled
      /> :
      null;

  const packageSelect = companyPackages.length ?
    <Row>
      <Col xs={12} sm={8} md={6} className='pb-2'>
        {packageInput}
      </Col>
      {addPackageButton}
    </Row> : null;

  const editableRows = props.allowAccountsUpdate ?
    [
      {
        dataField: 'price',
        text: 'Price',
        sort: false,
        validator: validPrice,
        editor: { type: Type.TEXT },
        editCellStyle: { width: '80vw', height: '100px' },
        formatter: currencyFormatter
      },
      {
        dataField: 'quantity',
        text: 'Quantity',
        sort: false,
        validator: validQuantity,
        editor: { type: Type.TEXT },
        editCellStyle: { width: '80vw', height: '100px' }
      }
    ] :
    [
      { dataField: 'price', text: 'Price', sort: false, editable: false, formatter: currencyFormatter },
      { dataField: 'quantity', text: 'Quantity', sort: false, editable: false }
    ];

  const headings = [
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'name', text: 'Name', sort: false, editable: false },
    ...editableRows,
    { dataField: 'points', text: 'Points', sort: false, editable: false, formatter: ( cell, row ) => row.quantity * row.points },
    { dataField: 'type', text: 'Type', sort: false, editable: false },
    { dataField: 'sku', text: 'SKU', sort: false, editable: false },
    { dataField: 'is_fire', text: 'Fire Device', sort: false, editable: false, formatter: cell => cell ? 'Yes' : 'No' },
    { dataField: 'description', text: 'Description', sort: false, editable: false },
    { dataField: 'created_at', text: 'Date Added', sort: true, formatter: dateTimeFormatterFromString, editable: false }
  ];

  if ( props.allowAccountsUpdate ) {
    headings.push( { dataField: 'delete', text: 'Delete', isDummyField: true, editable: false, formatter: ( cell, row ) => tableDeleteButton( () => removeEquipmentHandler( row ) ) } );
  }

  return (
    <Aux>
      {packageSelect}
      <ProductSearchWithTable
        headings={headings}
        hideSearch={!props.allowAccountsUpdate}
        products={props.equipmentData}
        routePrefix={`accounts/${props.accountId}/equipment`}
        updateFormEdited={props.updateEquipmentEdited}
        updateProducts={props.updateEquipmentData}
      />
    </Aux>
  );
};

Equipment.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountPackage: PropTypes.oneOfType( [PropTypes.object, PropTypes.string] ),
  allowAccountsUpdate: PropTypes.number,
  equipmentData: PropTypes.array,
  updateAccountData: PropTypes.func.isRequired,
  updateAccountEdited: PropTypes.func.isRequired,
  updateEquipmentData: PropTypes.func.isRequired,
  updateEquipmentEdited: PropTypes.func.isRequired,
};

export default Equipment;
