import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import initialAccountHeaderFormFields from './AccountHeaderFormFields';
import initialAccountDetailsFormFields from './AccountTabs/AccountDetailsFormFields';
import AddNewCustomerToAccountModal from '../Modal/AddNewCustomerToAccountModal';
import classes from '../../App.module.css';
import Toasts from '../Shared/Toasts';
import Watchlist from '../Shared/Watchlist';
import {
  cloneDeep,
  convertCustomFieldsForDisplay,
  convertTimeStringsToMoments,
  createRecord,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../helpers/functions/utilities';
import axios from '../../axios-instances/internal-api';
import ComponentNotes from '../Shared/ComponentNotes';
import AccountTabContainer from './AccountTabs/AccountTabContainer';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import Aux from '../../hoc/Aux';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialCustomerFormFields from '../Customers/CustomerFormFields';
import { ACCOUNT } from '../../helpers/constants/initialData';

const Account = props => {

  const { id, tab } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [initialLoad, updateInitialLoad] = useState( true );

  const [accountHeaderData, updateAccountHeaderData] = useState( cloneDeep( initialAccountHeaderFormFields ) );
  const [headerFormIsValid, updateHeaderFormIsValid] = useState( false );

  const [accountData, updateAccountData] = useState( cloneDeep( { ...initialAccountDetailsFormFields, ...convertCustomFieldsForDisplay( props.customFields.hasOwnProperty( 'accounts' ) ? props.customFields.accounts : [] ) } ) );
  const [detailsFormIsValid, updateDetailsFormIsValid] = useState( false );

  const [accountEdited, updateAccountEdited] = useState( false );

  const [customersData, updateCustomersData] = useState( [] );
  const [customersFormIsValid, updateCustomersFormIsValid] = useState( true );
  const [customersEdited, updateCustomersEdited] = useState( false, );
  const [showCustomerModal, updateShowCustomerModal] = useState( false );

  const [eventsData, updateEventsData] = useState( [] );

  const [equipmentData, updateEquipmentData] = useState( [] );
  const [equipmentEdited, updateEquipmentEdited] = useState( false );

  const [attachmentData, updateAttachmentData] = useState( [] );

  const [formSubmitted, updateFormSubmitted] = useState( false );

  const [recordPermissions, updateRecordPermissions] = useState( { accounts_update: 1, accounts_assign: 0, accounts_update_customers: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const [currentTab, updateCurrentTab] = useState( tab );

  const [paymentProcessors, updatePaymentProcessors] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Account</h3> : <Watchlist recordId={id} recordType='accounts' />;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.accounts_update ) {
      updateAccountHeaderData( cur => disableFormFields( cur ) );
      updateAccountData( cur => disableFormFields( cur ) );
    } else {
      updateAccountHeaderData( cur => {
        const { salesperson_id } = cur;
        salesperson_id.attributes.disabled = !recordPermissions.accounts_assign;
        return { ...cur, salesperson_id };
      } );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getAccount = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        const defaults = { ...ACCOUNT };
        const response = await getSingleRecord( 'accounts/check' );
        if ( response ) {
          defaults.salesperson_id = response.user_id;
          defaults.salesperson_label = response.name;
          updateRecordPermissions( cur => ( { ...cur, accounts_assign: response.accounts_assign } ) );
        }
        updateAccountHeaderData( cur => setValuesFromResponse( cur, defaults, updateHeaderFormIsValid ) );
        updateAccountData( cur => setValuesFromResponse( cur, defaults, updateDetailsFormIsValid ) );
      } else {
        const response = await getSingleRecord( `accounts/${id}` );
        if ( response ) {
          updateAccountHeaderData( cur => setValuesFromResponse( cur, response, updateHeaderFormIsValid ) );
          updateAccountData( cur => setValuesFromResponse( cur, response, updateDetailsFormIsValid ) );
          const {
            accounts_assign,
            accounts_create_attachments,
            accounts_create_customers,
            accounts_create_events,
            accounts_delete_attachments,
            accounts_delete_events,
            accounts_download_attachments,
            accounts_take_payments,
            accounts_update,
            accounts_update_accounting,
            accounts_update_customers,
            accounts_view_accounting,
            accounts_view_attachments,
            accounts_view_payments,
            payment_processors,
          } = response.user_auth;
          updateRecordPermissions( {
            accounts_assign,
            accounts_create_attachments,
            accounts_create_customers,
            accounts_create_events,
            accounts_delete_attachments,
            accounts_delete_events,
            accounts_download_attachments,
            accounts_update,
            accounts_update_accounting,
            accounts_update_customers,
            accounts_view_accounting,
            accounts_view_attachments,
            accounts_view_payments,
            accounts_take_payments
          } );
          updatePaymentProcessors( payment_processors );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getAccount()
        .then( () => {} );
    }
  }, [id, prevId] );

  const getCustomers = async () => {
    if ( customersData.length ) {
      console.log( 'customers already retrieved' );
      return;
    }
    let url = `accounts/${id}/customers`;
    const response = await axios.get( url );
    if ( response.data && response.data.length ) {
      const customersData = response.data.map( customer => {
        convertTimeStringsToMoments( customer );
        let updatedCustomerForm = setValuesFromResponse( cloneDeep( { ...initialCustomerFormFields, ...convertCustomFieldsForDisplay( props.customFields.hasOwnProperty( 'customers' ) ? props.customFields.customers : [] ) } ), customer, updateCustomersFormIsValid );
        if ( !recordPermissions.accounts_update || !recordPermissions.accounts_update_customers ) {
          updatedCustomerForm = disableFormFields( updatedCustomerForm );
        }
        return updatedCustomerForm;
      } );
      updateCustomersData( customersData );
    }
  };

  const getEvents = async ( updated = false ) => {
    if ( eventsData.length ) {
      console.log( 'events already retrieved' );
      if ( !updated ) {
        return;
      }
    }
    const response = await axios.get( `/accounts/${id}/events` );
    if ( response.data ) {
      const events = response.data.map( event => {
        convertTimeStringsToMoments( event );
        return event;
      } );
      updateEventsData( events );
    }
  };

  const getEquipment = async () => {
    if ( equipmentData.length ) {
      console.log( 'equipment already retrieved' );
    } else {
      const response = await axios.get( `/accounts/${id}/equipment` );
      if ( response.data ) {
        let pkg = [];
        if ( accountData.package_id.value ) {
          pkg.push( {
            description: 'Package',
            from_package: 1,
            id: 'pkg9999',
            is_fire: 0,
            name: accountData.package_id.value.label,
            points: 0,
            price: accountData.package_price.value,
            product_id: 'pkg9999',
            quantity: 1,
            sku: `Package - ${accountData.package_id.value.label}`,
            type: 'Package'
          } );
        }
        updateEquipmentData( [...pkg, ...response.data] );
      }
    }
  };

  const getAttachments = async () => {
    if ( attachmentData.length ) {
      console.log( 'attachments already retrieved' );
    } else {
      try {
        const response = await axios.get( `/accounts/${id}/attachments` );
        if ( response.data ) {
          updateAttachmentData( response.data );
        }
      } catch ( e ) {
        console.log( 'error retrieving attachments: ', e );
      }
    }
  };

  const keyMap = {
    get_attachments: getAttachments,
    get_customers: getCustomers,
    get_events: getEvents,
    get_equipment: getEquipment
  };

  const tabSelectHandler = useCallback( ( tab ) => {
    console.log( 'tab select: ', tab );
    history.replace( { pathname: `/accounts/${id}/${tab}` } );
    updateCurrentTab( tab );
    const func = keyMap[`get_${tab}`];
    if ( typeof func === 'function' ) {
      func();
    } else {
      console.log( 'function does not exist' );
    }
  }, [history, keyMap, id] );

  useEffect( () => {
    if ( initialLoad ) {
      updateInitialLoad( false );
      tabSelectHandler( tab );
    }
  }, [tabSelectHandler, tab, initialLoad] );

  const handleSubmit = ( e ) => {
    e.preventDefault();
    updateFormSubmitted( true );
    const formIsValid = [headerFormIsValid, detailsFormIsValid, customersFormIsValid].every( el => el );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields and tabs for errors.', id: Date.now() }] );
      return;
    }
    if ( !accountEdited && !customersEdited && !equipmentEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    let promises = [];
    console.log( 'account is edited: ', accountEdited );
    updateLoadingState( true );
    if ( accountEdited ) {
      promises.push( updateAccount() );
    }
    console.log( 'customer is edited: ', customersEdited );
    if ( customersEdited ) {
      promises.push( updateCustomers() );
    }
    console.log( 'equipment is edited: ', equipmentEdited );
    if ( equipmentEdited ) {
      promises.push( updateEquipment() );
    }
    Promise.all( promises )
      .then( () => {
        updateLoadingState( false );
        updateEquipmentEdited( false );
        updateCustomersEdited( false );
        updateAccountEdited( false );
        updateFormSubmitted( false );
        const heading = id === 'new' ? 'Record Created' : 'Record Updated';
        updateToastData( cur => [...cur, { heading, id: Date.now() }] );
      } )
      .catch( () => {
        updateLoadingState( false );
        updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
      } );
  };

  const updateAccount = async () => {
    const account = {
      ...extractValues( accountData ),
      ...extractValues( accountHeaderData ),
      package_id: accountData.package_id.value,
      package_price: accountData.package_price.value
    };
    if ( account.package_id &&
      typeof account.package_id === 'object' &&
      account.package_id.hasOwnProperty( 'value' )
    ) {
      account.package_id = account.package_id.value;
    }
    if ( id === 'new' ) {
      const response = await createRecord( 'accounts', { account } );
      if ( response ) {
        history.push( `/accounts/${response.id}/details` );
      }
    } else {
      const response = await updateRecord( `accounts/${id}`, { account } );
      if ( response ) {
        updateAccountHeaderData( cur => setValuesFromResponse( cur, response, updateHeaderFormIsValid ) );
        updateAccountData( cur => setValuesFromResponse( cur, response, updateDetailsFormIsValid ) );
        updateAccountEdited( false );
      }
    }
  };

  const updateCustomers = async () => {
    const customers = customersData.filter( el => el.hasOwnProperty( 'updated' ) && el.updated.value );
    if ( customers.length ) {
      for ( const customer of customers ) {
        const customerData = {
          ...extractValues( customer ),
          id: customer.id.value
        };
        delete customerData.updated;
        const response = await updateRecord( `customers/${customerData.id}`, { customer: customerData } );
        if ( response ) {
          const updatedCustomer = setValuesFromResponse( cloneDeep( { ...initialCustomerFormFields, ...convertCustomFieldsForDisplay( props.customFields.hasOwnProperty( 'customers' ) ? props.customFields.customers : [] ) } ), response, updateCustomersFormIsValid );
          const index = customersData.findIndex( customer => customer.id.value === updatedCustomer.id.value );
          updateCustomersData( cur => (
            [
              ...cur.slice( 0, index ),
              updatedCustomer,
              ...cur.slice( index + 1 )
            ]
          ) );
        }
      }
      updateCustomersEdited( false );
    }
  };

  const updateEquipment = async () => {
    const data = {
      equipment: equipmentData.filter( el => el.id !== 'pkg9999' )
    };
    const response = await updateRecord( `accounts/${id}/equipment`, data );
    if ( response ) {
      updateEquipmentData( cur => {
        const pkg = cur.filter( el => el.type === 'Package' );
        return [...pkg, ...response];
      } );
    }
    updateEquipmentEdited( false );
  };

  const createCustomer = async customer => {
    updateLoadingState( true );
    const customerData = { ...extractValues( customer ) };
    delete customerData.id;
    const response = await createRecord( 'customers', { customer: customerData } );
    if ( response && response.hasOwnProperty( 'id' ) ) {
      updateShowCustomerModal( false );
      addCustomerToAccount( { value: response.id } )
        .then( () => {} );
    }
    updateLoadingState( false );
  };

  const addCustomerToAccount = async ( { value:customerId } ) => {
    updateLoadingState( true );
    const { data } = await axios.post( `accounts/${id}/customers`, { customerId } );
    if ( data ) {
      let newCustomer = await getSingleRecord( `customers/${customerId}` );
      newCustomer = setValuesFromResponse( cloneDeep( { ...initialCustomerFormFields, ...convertCustomFieldsForDisplay( props.customFields.hasOwnProperty( 'customers' ) ? props.customFields.customers : [] ) } ), newCustomer, updateCustomersFormIsValid );
      if ( !recordPermissions.accounts_update || !recordPermissions.accounts_update_customers ) {
        newCustomer = disableFormFields( newCustomer );
      }
      updateCustomersData( cur => [...cur, newCustomer] );
    }
    updateLoadingState( false );
  };

  const removeCustomerFromAccount = async ( e, customerId ) => {
    e.stopPropagation();
    updateLoadingState( true );
    await axios.delete( `accounts/${id}/customers/${customerId}` );
    updateCustomersData( cur => {
      const arr = [...cur];
      const index = arr.findIndex( el => el.id.value === customerId );
      arr.splice( index, 1 );
      return arr;
    } );
    updateLoadingState( false );
  };

  const accountNotes = id === 'new' ? null : <ComponentNotes id={id} component='accounts' />;

  const button = ( tab === 'details' || tab === 'customers' || tab === 'equipment' ) && recordPermissions.accounts_update ?
    <Form.Row className='pt-3'>
      <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      {heading}
      <Container>
        <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''}>
          <FormFieldGenerator
            formData={accountHeaderData}
            formDataInputHandler={updateAccountHeaderData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateHeaderFormIsValid}
            formEditedHandler={updateAccountEdited}
          />
          <AccountTabContainer
            accountData={accountData}
            accountId={id}
            activeTab={currentTab}
            addExistingCustomerHandler={addCustomerToAccount}
            addNewCustomerHandler={() => updateShowCustomerModal( true )}
            attachmentData={attachmentData}
            updateAttachmentData={updateAttachmentData}
            customFields={props.customFields}
            customerRemoveHandler={removeCustomerFromAccount}
            customersData={customersData}
            customerTabError={formSubmitted && !customersFormIsValid}
            detailTabError={formSubmitted && !detailsFormIsValid}
            disabled={id === 'new'}
            equipmentData={equipmentData}
            eventsData={eventsData}
            formSubmitted={formSubmitted}
            paymentProcessors={paymentProcessors}
            recordPermissions={recordPermissions}
            refreshEvents={getEvents}
            tabSelectHandler={tabSelectHandler}
            updateAccountData={updateAccountData}
            updateAccountEdited={updateAccountEdited}
            updateCustomersData={updateCustomersData}
            updateCustomersEdited={updateCustomersEdited}
            updateCustomersFormIsValid={updateCustomersFormIsValid}
            updateDetailsFormIsValid={updateDetailsFormIsValid}
            updateEquipmentData={updateEquipmentData}
            updateEquipmentEdited={updateEquipmentEdited}
          />
          {button}
        </Form>
      </Container>
      {accountNotes}
      <AddNewCustomerToAccountModal
        customFields={props.customFields.hasOwnProperty( 'customers' ) ? props.customFields.customers : []}
        show={showCustomerModal}
        onHide={() => updateShowCustomerModal( false )}
        onSubmit={createCustomer}
        loading={loadingState}
      />
      {toasts}
    </Aux>
  );
};

Account.propTypes = {
  customFields: PropTypes.object.isRequired
};

export default Account;
