import axios from 'axios';
import store from '../store/store';
import * as actionTypes from '../store/actions/actions';
import { Auth } from 'aws-amplify';

const instance = axios.create( {
  baseURL: process.env.REACT_APP_API_ADDRESS
} );

export const generateCancelToken = () => {
  return axios.CancelToken.source();
};

instance.interceptors.request.use( request => {
  store.dispatch( { type: actionTypes.ERROR_RESET } );
  let token = document.cookie.split( ';' ).find( ( item ) => item.trim().includes( 'accessToken=' ) );
  token = token ? token.split( '=' )[1] : '';
  request.headers['access-token'] = token;
  return request;
} );

instance.interceptors.response.use( response => {
  if ( response.hasOwnProperty( 'data' ) && response.data.hasOwnProperty( 'user_auth' ) ) {
    store.dispatch( { type: actionTypes.UPDATE_COMPANY_AUTH, payload: response.data.user_auth } );
  }
  return response;
}, error => {
  if ( axios.isCancel( error ) ) {
    return {};
  }
  const payload = {
    message: error.response.data,
    serverMessage: error.message,
    statusCode: error.response.status
  };
  if ( payload.message === 'Access Token missing from header' ) {
    store.dispatch( { type: actionTypes.BEGIN_LOGOUT } );
  } else if ( payload.message === 'jwt expired' ) {
    const originalRequest = error.config;
    if ( !originalRequest._retry ) {
      originalRequest._retry = true;
      try {
        return Auth.currentSession()
          .then( currentSession => {
            originalRequest.headers['access-token'] = currentSession.getAccessToken().getJwtToken();
            return axios( originalRequest );
          } );
      } catch ( e ) {
        store.dispatch( { type: actionTypes.BEGIN_LOGOUT } );
      }
    } else {
      store.dispatch( { type: actionTypes.BEGIN_LOGOUT } );
    }
  } else {
    store.dispatch( { type: actionTypes.INTERNAL_API_ERROR_OCCURRED, payload } );
    throw error;
  }
} );

export default instance;
