import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { cloneDeep, disableFormFields } from '../../../../../helpers/functions/utilities';
import initialPaypalFormFields from './PaypalFormFields';
import FormFieldGenerator from '../../../../Shared/FormFieldGenerator';
import classes from '../../../../../App.module.css';
import { extractValues, setValuesFromResponse } from '../../../../../helpers/functions/formValidator';
import Toasts from '../../../../Shared/Toasts';
import axios from '../../../../../axios-instances/internal-api';

const Paypal = props => {

  const [paypalData, updatePaypalData] = useState( cloneDeep( initialPaypalFormFields ) );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [loading, updateLoading] = useState( false );
  const [errorMessage, updateErrorMessage] = useState( '' );
  const [toastData, updateToastData] = useState( [] );

  const { credentials, detailsUpdate } = props;

  useEffect( () => {
    updateLoading( true );
    updatePaypalData( cur => {
      let data = setValuesFromResponse( cur, credentials, updateFormIsValid );
      if ( credentials.paypal_enabled ) {
        data.paypal_client.attributes.disabled = false;
        data.paypal_secret.attributes.disabled = false;
      }
      return data;
    } );
    updateLoading( false );
  }, [credentials] );

  useEffect( () => {
    if ( detailsUpdate != null && !detailsUpdate ) {
      updatePaypalData( cur => disableFormFields( cur ) );
    }
  }, [detailsUpdate] );

  const formSubmitHandler = async e => {
    e.preventDefault();
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoading( true );
    if ( !paypalData.paypal_enabled.value ) {
      try {
        await axios.get( '/integrations/payments/paypal/disable' );
        updateLoading( false );
        updateFormEdited( false );
        return;
      } catch ( e ) {
        updateErrorMessage( 'Unable to disable. Please try again' );
        return;
      }
    }
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      updateLoading( false );
      return;
    }
    const { paypal_client, paypal_secret, paypal_enabled } = extractValues( paypalData );
    const data = { paypal_client, paypal_secret, paypal_enabled };
    const response = await axios.post( '/integrations/payments/paypal/verify', data );
    updateLoading( false );
    updateFormEdited( false );
    updateFormSubmitted( false );
    if ( response.hasOwnProperty( 'data' ) && response.data.success ) {
      updateToastData( cur => [...cur, { heading: 'Success', body: 'PayPal is now available', id: Date.now() }] );
    } else {
      updateErrorMessage( response.data.error );
    }
  };

  const buttonText = !paypalData.paypal_enabled.value ? 'Disable' : 'Verify & Save';

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  const error = errorMessage ?
    <Form.Row>
      <Form.Group controlId='error' as={Col} xs={12} sm='auto' className='mx-auto'>
        <div className='invalid-feedback d-block'>{errorMessage}</div>
      </Form.Group>
    </Form.Row> :
    null;

  const updateButton = detailsUpdate ?
    <Form.Row>
      <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loading || props.credentialsLoading || !formEdited} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  return (
    <Form onSubmit={formSubmitHandler} className={loading || props.credentialsLoading ? classes.Loading : ''}>
      <FormFieldGenerator
        formData={paypalData}
        formDataInputHandler={updatePaypalData}
        formEditedHandler={updateFormEdited}
        formSubmitted={formSubmitted}
        updateFormIsValid={updateFormIsValid}
        updateLoading
      />
      {updateButton}
      {error}
      {toasts}
    </Form>
  );
};

Paypal.propTypes = {
  credentials: PropTypes.shape( {
    paypal_client: PropTypes.string.isRequired,
    paypal_secret: PropTypes.string.isRequired,
    paypal_enabled: PropTypes.bool.isRequired
  } ),
  credentialsLoading: PropTypes.bool.isRequired,
  detailsUpdate: PropTypes.number
};

export default Paypal;
