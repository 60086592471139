export const updateObject = ( oldObject, newValues ) => ( {
  ...oldObject,
  ...newValues
} );

export const initialCompanyAuthState = {
  accounts_create: 0,
  accounts_delete: 0,
  accounts_history: 0,
  accounts_update: 0,
  accounts_view: 0,
  company_tab: 0,
  customers_create: 0,
  customers_delete: 0,
  customers_history: 0,
  customers_update: 0,
  customers_view: 0,
  custom_fields_create: 0,
  custom_fields_view: 0,
  details_history: 0,
  details_update: 0,
  events_create: 0,
  events_delete: 0,
  events_history: 0,
  events_update: 0,
  events_view: 0,
  groups_create: 0,
  groups_delete: 0,
  groups_history: 0,
  groups_update: 0,
  groups_view: 0,
  leads_create: 0,
  leads_delete: 0,
  leads_history: 0,
  leads_update: 0,
  leads_view: 0,
  leadProviders_history: 0,
  lead_providers_create: 0,
  lead_providers_delete: 0,
  lead_providers_update: 0,
  lead_providers_view: 0,
  packages_create: 0,
  packages_delete: 0,
  packages_history: 0,
  packages_update: 0,
  packages_view: 0,
  pendingUsers_history: 0,
  products_create: 0,
  products_delete: 0,
  products_history: 0,
  products_update: 0,
  products_view: 0,
  schedule_view: 0,
  users_create: 0,
  users_delete: 0,
  users_history: 0,
  users_update: 0,
  users_view: 0,
  payroll_view: 0,
  payroll_create: 0,
  payroll_update: 0,
  reports_view: 0,
};
