import React from 'react';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';

const Toasts = props => {

  const removeToast = id => {
    props.updateData( cur => {
      const arr = [...cur];
      const index = cur.findIndex( el => el.id === id );
      if ( index !== -1 ) {
        arr.splice( index, 1 );
      }
      return arr;
    } );
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        minHeight: '50px',
        width: '350px',
        position: 'fixed',
        right: 0,
        top: '100px',
        zIndex: '100'
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          backgroundColor: '#fff'
        }}
      >
        {props.dataArray.map( toast => (
          <Toast key={toast.id} autohide delay={5000} onClose={() => removeToast( toast.id )}>
            <Toast.Header>
              <strong className="mr-auto">{toast.heading ? toast.heading : ''}</strong>
            </Toast.Header>
            {toast.body ? <Toast.Body>{toast.body}</Toast.Body> : null}
          </Toast>
        ) ) }
      </div>
    </div>
  );
};

Toasts.propTypes = {
  dataArray: PropTypes.arrayOf(
    PropTypes.shape( {
      id: PropTypes.number.isRequired,
      heading: PropTypes.string.isRequired,
      body: PropTypes.string
    } )
  ),
  updateData: PropTypes.func.isRequired
};

export default Toasts;
