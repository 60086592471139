import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';

import ErrorModal from '../Modal/ErrorModal';
import initialResetPasswordFormFields from './ResetPasswordFormFields';
import initialVerifyResetPasswordFormFields from './VerifyResetPasswordFormFields';
import Aux from '../../hoc/Aux';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { cloneDeep } from '../../helpers/functions/utilities';
import LoadingSpinner from '../../hoc/LoadingSpinner';

const ResetPassword = () => {
  const [formData, updateFormData] = useState( cloneDeep( initialResetPasswordFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [showErrorModal, updateShowErrorModal] = useState( false );
  const [errorMessage, updateErrorMessage] = useState( '' );
  const [showToast, updateShowToast] = useState( false );
  const [username, updateUsername] = useState( null );
  const [loadingState, updateLoadingState] = useState( false );

  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      return;
    }
    if ( username ) {
      if ( formData.password.value !== formData.confirmPassword.value ) {
        updateFormData( cur => {
          const updatedData = { ...cur };
          const password = { ...updatedData.password };
          password.isValid = false;
          password.errors = [{ message: 'Passwords do not match', key: 'pw-match' }];
          updatedData.password = password;
          return updatedData;
        } );
        return;
      }
      const code = formData.code.value;
      const new_password = formData.password.value;
      updateLoadingState( true );
      try {
        await Auth.forgotPasswordSubmit( username, code, new_password );
        history.push( '/signin' );
      } catch ( error ) {
        console.log( error );
        updateLoadingState( false );
        if ( error.code === 'CodeMismatchException' ) {
          updateFormData( cur => {
            const updatedData = { ...cur };
            const code = { ...updatedData.code };
            code.isValid = false;
            code.errors = [{ message: error.message, key: 'vc-inv' }];
            updatedData.code = code;
            return updatedData;
          } );
        } else {
          updateErrorMessage( error.message );
          updateShowErrorModal( true );
        }
      }
    } else {
      const username = formData.username.value;
      updateLoadingState( true );
      try {
        const data = await Auth.forgotPassword( username );
        console.log( data );
        updateLoadingState( false );
        updateShowToast( true );
        updateUsername( username );
        updateFormData( cur => {
          cur.username.attributes.disabled = true;
          return { ...cur, ...cloneDeep( initialVerifyResetPasswordFormFields ) };
        } );
        updateFormSubmitted( false );
      } catch ( error ) {
        console.log( error );
        updateLoadingState( false );
        updateErrorMessage( error.message );
        updateShowErrorModal( true );
      }
    }
  };

  return loadingState ? <LoadingSpinner loading={true} /> : (
    <Aux>
      <Aux>
        <Form onSubmit={handleSubmit} noValidate>
          <FormFieldGenerator
            formData={formData}
            formDataInputHandler={updateFormData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
          <Form.Row className='justify-content-between flex-column-reverse flex-sm-row pt-2'>
            <Col xs={8} sm={9} className='my-auto'>
              <Link to='/signin/'>Back to Sign In</Link>
            </Col>
            <Form.Group as={Col} controlId="submit" xs={12} sm={3} className='d-flex justify-content-start justify-content-sm-end'>
              <Button variant="primary" type="submit" className='b-width'>Submit</Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <ErrorModal
          show={showErrorModal}
          onHide={() => updateShowErrorModal( false )}
          message={errorMessage}
        >
          Error
        </ErrorModal>
      </Aux>
      <Toast show={showToast} autohide delay={5000} onClose={() => updateShowToast( false )}>
        <Toast.Header>Email Sent</Toast.Header>
        <Toast.Body>Please check your email for the new code</Toast.Body>
      </Toast>
    </Aux>
  );
};

export default ResetPassword;
