import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import { IconContext } from 'react-icons';
import { BsDownload } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';

import Aux from '../../../hoc/Aux';
import '../../Tables/Tables.css';
import internalAxios from '../../../axios-instances/internal-api';
import AddButton from '../../Shared/AddButton';
import LoadingSpinner from '../../../hoc/LoadingSpinner';
import classes from '../../../App.module.css';
import {
  dateTimeFormatterFromString,
  tableDeleteButton
} from '../../../helpers/functions/utilities';

const Attachments = props => {

  const [chosenFile, updateChosenFile] = useState( '' );
  const [loadingState, updateLoadingState] = useState( false );

  const headings = [
    { dataField: 'id', hidden: true },
    { dataField: 'name', text: 'Name', sort: false },
    { dataField: 'size', text: 'Size', sort: true },
    { dataField: 'type', text: 'Type', sort: true },
    { dataField: 'created_at', text: 'Date Uploaded', sort: true, formatter: dateTimeFormatterFromString }
  ];

  const ref = useRef();

  if ( props.allowDownloadAttachments ) {
    headings.push( { dataField: 'action', text: 'Download', sort: false, formatter: ( cell, row ) => downloadS3File( row ) } );
  }

  if ( props.allowDeleteAttachments ) {
    headings.push( { dataField: 'delete', text: 'Delete', isDummyField: true, formatter: ( cell, row ) => tableDeleteButton( () => removeAttachmentHandler( row ) ) } );
  }

  const handleDownload = async attachment => {
    const url = `/attachments/${attachment.id}`;
    try {
      const response = await internalAxios.get( url );
      try {
        const options = {
          responseType: 'blob'
        };
        const result = await axios.get( response.data.signedUrl, options );
        const url = window.URL.createObjectURL( new Blob( [result.data] ) );
        const link = document.createElement( 'a' );
        link.href = url;
        link.setAttribute( 'download', attachment.name ); //or any other extension
        document.body.appendChild( link );
        link.click();
      } catch ( e ) {
        console.log( 'error: ' + JSON.stringify( e ) );
      }
    } catch ( e ) {
      console.log( 'error: ', e );
    }
  };

  const downloadS3File = attachment => (
    <Button size="sm" variant='primary' onClick={() => handleDownload( attachment )}>
      <IconContext.Provider value={24}>
        <BsDownload />
      </IconContext.Provider>
    </Button>
  );

  const handleUpload = async () => {
    updateLoadingState( true );
    try {
      const url = `/attachments/new/accounts/${props.accountId}`;
      const data = {
        name: chosenFile.name,
        type: chosenFile.type,
        size: chosenFile.size
      };
      const response = await internalAxios.post( url, data );
      const options = {
        headers: {
          'Content-Type': chosenFile.type
        }
      };
      try {
        await axios.put( response.data.signedUrl, chosenFile, options );
        const finalResponse = await internalAxios.patch( `attachments/${response.data.id}` );
        props.updateAttachmentData( cur => [...cur, finalResponse.data] );
        updateChosenFile( '' );
        ref.current.value = '';
        updateLoadingState( false );
      } catch ( e ) {
        console.error( 'error: ' + JSON.stringify( e ) );
        updateLoadingState( false );
      }
    } catch ( e ) {
      console.log( 'error: ', e );
      updateLoadingState( false );
    }
  };

  const fileChosen = e => {
    updateChosenFile( e.target.files[0] );
  };

  const removeAttachmentHandler = async attachment => {
    try {
      const url = `attachments/${attachment.id}`;
      await internalAxios.delete( url );
      props.updateAttachmentData( cur => {
        const copy = [...cur];
        const index = copy.findIndex( el => el.id === attachment.id );
        if ( index !== -1 ) {
          copy.splice( index, 1 );
        }
        return copy;
      } );
    } catch ( e ) {
      console.log( 'error: ', e );
    }
  };

  const selectAttachmentRow = props.allowCreateAttachments ?
    <Form.Row>
      <Col xs={12} sm='auto' className='d-flex px-0 pb-3'>
        <AddButton
          clickHandler={handleUpload}
          text='Upload File'
          disabled={!chosenFile}
        />
      </Col>
      <Form.Group as={Col} xs={12} md={6}>
        <input
          id="account-attachment"
          type="file"
          onChange={fileChosen}
          ref={ref}
        />
      </Form.Group>
    </Form.Row> :
    null;

  return (
    <Aux>
      {selectAttachmentRow}
      <LoadingSpinner loading={loadingState}>
        <BootstrapTable
          bootstrap4
          bordered={false}
          classes='TableComponent'
          columns={headings}
          data={props.attachmentData}
          defaultSorted={[{
            dataField: 'created_at',
            order: 'asc'
          }]}
          hover
          keyField='id'
          noDataIndication={() => 'No Attachments Found'}
          remote={false}
          striped
          wrapperClasses={loadingState ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
        />
      </LoadingSpinner>
    </Aux>
  );
};

Attachments.propTypes = {
  accountId: PropTypes.string.isRequired,
  allowCreateAttachments: PropTypes.number,
  allowDeleteAttachments: PropTypes.number,
  allowDownloadAttachments: PropTypes.number,
  attachmentData: PropTypes.array.isRequired,
  updateAttachmentData: PropTypes.func.isRequired
};

export default Attachments;
