import React from 'react';
import PropTypes from 'prop-types';

import SignedOutRightNavItems from './SignedOutNav/SignedOutRightNavItems';
import SignedInRightNavItems from './SignedInNav/SignedInRightNavItems';

const RightItems = props => (
  props.isSignedIn ?
    <SignedInRightNavItems
      changeCompanies={props.changeCompanies}
      companyOptions={props.companyOptions}
      currentCompanyId={props.currentCompanyId}
    /> :
    <SignedOutRightNavItems />
);

RightItems.propTypes = {
  changeCompanies: PropTypes.func.isRequired,
  companyOptions: PropTypes.array,
  currentCompanyId: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired
};

export default RightItems;
