import React from 'react';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import Aux from '../../hoc/Aux';

const SelectFilter = props => {

  const options = props.options ? props.options : [
    <option key='all' value='All'>All</option>,
    <option key='yes' value='1'>Yes</option>,
    <option key='no' value='0'>No</option>
  ];

  return (
    <Aux>
      <Form.Row className="align-items-start">
        <Form.Group controlId={props.id + '_expand'}>
          <Form.Check
            id={props.id + '_expand'}
            type="switch"
            label={props.label}
            onChange={() => props.onChangeHandler( props.id, 'show', !props.expand )}
          />
        </Form.Group>
      </Form.Row>
      <Collapse in={props.expand}>
        <Form.Row>
          <Form.Group as={Col} xs={6}>
            <Form.Control
              id={props.id + '_filter'}
              as='select'
              size='sm'
              onChange={e => props.onChangeHandler( props.id, 'filter', e.target.value )}
            >
              {options}
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Collapse>
    </Aux>
  );
};

SelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  options: PropTypes.array
};

export default SelectFilter;
