import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import Toasts from '../Shared/Toasts';
import {
  cloneDeep,
  convertCustomFieldsForDisplay,
  createRecord,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../helpers/functions/utilities';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { LEAD_PROVIDER } from '../../helpers/constants/initialData';
import initialProviderFormFields from '../LeadProviders/LeadProviderFormFields';
import LeadProviderLeadsCollapse from './LeadProviderLeadsCollapse';
import Aux from '../../hoc/Aux';

const LeadProvider = props => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [providerData, updateProviderData] = useState( cloneDeep( { ...initialProviderFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ) );
  const [showLeads, updateShowLeads] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [recordPermissions, updateRecordPermissions] = useState( { lead_providers_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Lead Provider</h3> : <h3>Last Updated: <em>{dateTimeFormatter( providerData.updated_at )}</em></h3>;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.lead_providers_update ) {
      updateProviderData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getProvider = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateProviderData( cur => setValuesFromResponse( cur, { ...LEAD_PROVIDER }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `leadProviders/${id}` );
        if ( response ) {
          updateProviderData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          const { lead_providers_update } = response.user_auth;
          updateRecordPermissions( { lead_providers_update } );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getProvider()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const provider = { ...extractValues( providerData ) };
      if ( id === 'new' ) {
        const response = await createRecord( 'leadProviders', { provider } );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `leadProviders/${id}`, { provider } );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const leadSelectionHandler = ( e, row ) => {
    history.push( `/leads/${row.id}` );
  };

  const collapsible = id === 'new' ? null :
    <LeadProviderLeadsCollapse
      showLeads={showLeads}
      showLeadsHandler={() => updateShowLeads( cur => !cur ) }
      leadSelectionHandler={leadSelectionHandler}
      leadProviderId={id}
    />;

  const button = recordPermissions.lead_providers_update ?
    <Form.Row className='pt-3'>
      <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Container>
        <LoadingSpinner loading={loadingState}>
          {heading}
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''} noValidate>
            <FormFieldGenerator
              formData={providerData}
              formDataInputHandler={updateProviderData}
              formEditedHandler={updateFormEdited}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
            />
            {button}
          </Form>
        </LoadingSpinner>
      </Container>
      {collapsible}
      {toasts}
    </Aux>
  );

};

LeadProvider.propTypes = {
  customFields: PropTypes.array.isRequired
};

export default LeadProvider;
