import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import MainTable from '../../../Tables/MainTable';
import AddButton from '../../../Shared/AddButton';
import Aux from '../../../../hoc/Aux';
import DeleteModal from '../../../Modal/DeleteModal';
import {
  addressColumnFormatter,
  addressFormatter,
  closeDeleteModalHandler,
  confirmDeleteHandler,
  tableDeleteButton,
  tableDeleteHandler,
  tableEditButton
} from '../../../../helpers/functions/utilities';
import AddExistingUserToCompanyModal from '../../../Modal/AddExistingUserToCompany/AddExistingUserToCompany';

const Users = props => {

  const history = useHistory();

  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [userDeleted, updateUserDeleted] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );
  const [showAddExistingUserModal, updateShowAddExistingUserModal] = useState( false );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, props.route ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'first_name', text: 'Name', sort: true, formatter: ( cell, row ) => `${row.first_name} ${row.last_name}` },
    { dataField: 'email', text: 'Email', sort: true },
    { dataField: 'phone', text: 'Phone', sort: true },
    {
      dataField: 'address1',
      text: 'Address',
      sort: true,
      formatter: ( cell, row ) => addressFormatter( row ),
      classes: addressColumnFormatter
    },
  ];

  if ( props.route === 'users' ) {
    headings.splice( 11, 0,
      { dataField: 'is_active', text: 'Active', sort: true, formatter: ( cell ) => cell ? 'Yes' : 'No' },
    );
  }

  if ( props.auth.del ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: ( cell, row ) => tableDeleteButton( () => tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal ) )
    } );
  }

  const closeAddExistingUserModal = userId => {
    updateShowAddExistingUserModal( false );
    if ( typeof userId === 'string' ) {
      setTimeout( () => history.push( `/users/${userId}` ), 300 ); // delay to avoid warning about updating state on unmounted component
    }
  };

  const table = props.showUsersTable ?
    <MainTable
      headings={ headings }
      route={props.route}
      remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
      refreshNeeded={userDeleted}
      updateRefreshNeeded={updateUserDeleted}
    /> :
    null;

  const addNewButtons = props.route === 'users' && props.auth.create ?
    <Row className='py-3'>
      <Col xs={12} sm={6} md='auto'>
        <AddButton route={props.route} size='28px' />
      </Col>
      <Col xs={12} sm={6} md='auto xs-pt-3'>
        <Button variant='outline-success' className='AddNew w-100' onClick={() => updateShowAddExistingUserModal( true )} >
          <span className='pr-2 AddButtonText'>Add Existing</span>
          <svg key='plus' className="bi bi-plus-square-fill addButtonIcon" width='28px' height='28px' viewBox="0 0 16 16" fill='#28a745' xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd" />
          </svg>
        </Button>
      </Col>
    </Row> :
    null;

  return (
    <Aux>
      {addNewButtons}
      {table}
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.first_name} {recordToDelete.last_name}.</p><p>All Leads, Accounts, and Events that the user is currently assigned to will still show this user.  -- @todo need to use soft deletes to retain info </p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${props.route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updateUserDeleted )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
      <AddExistingUserToCompanyModal
        onHide={closeAddExistingUserModal}
        show={showAddExistingUserModal}
      />
    </Aux>
  );

};

Users.propTypes = {
  auth: PropTypes.shape( {
    create: PropTypes.number,
    del: PropTypes.number
  } ),
  route: PropTypes.string.isRequired,
  showUsersTable: PropTypes.bool.isRequired
};

export default Users;
