import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';

import classes from '../../../App.module.css';
import Toasts from '../../Shared/Toasts';
import {
  cloneDeep,
  convertCompanyUserAccessFieldsForApi,
  convertCompanyUserAccessFieldsForDisplay,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../../helpers/functions/utilities';
import initialUserFormFields from '../UserFormFields';
import initialCompanyUserFormFields from '../UserRoleFields';
import {
  extractValues,
  setValuesFromResponse
} from '../../../helpers/functions/formValidator';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import LoadingSpinner from '../../../hoc/LoadingSpinner';
import axios from '../../../axios-instances/internal-api';

const PendingUser = () => {

  let { id } = useParams();

  const userFormFields = cloneDeep( initialUserFormFields );
  for ( const prop in userFormFields ) {
    if ( userFormFields.hasOwnProperty( prop ) ) {
      if ( prop === 'username' ) {
        userFormFields[prop].displayCondition = 'false';
        userFormFields[prop].display = false;
      }
      if ( prop !== 'email' ) {
        userFormFields[prop].validation = {};
      }
    }
  }

  const [loadingState, updateLoadingState] = useState( false );
  const [userData, updateUserData] = useState( userFormFields );
  const [userDataEdited, updateUserDataEdited] = useState( false );
  const [companyUserData, updateCompanyUserData] = useState( cloneDeep( initialCompanyUserFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [existingEmail, updateExistingEmail] = useState( '' );
  const [recordPermissions, updateRecordPermissions] = useState( { users_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.users_update ) {
      updateCompanyUserData( cur => disableFormFields( cur ) );
      updateUserData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getUser = async () => {
      updateLoadingState( true );
      const response = await getSingleRecord( `pendingUsers/${id}` );
      if ( response ) {
        convertCompanyUserAccessFieldsForDisplay( response );
        updateUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
        updateCompanyUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
        updateExistingEmail( response.email );
        const { users_update } = response.user_auth;
        updateRecordPermissions( { users_update } );
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getUser()
        .then( () => {} );
    }
  }, [id, prevId] );

  const sendEmailHandler = async () => {
    updateLoadingState( true );
    await axios.get( `pendingUsers/${id}/resend` );
    updateLoadingState( false );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !userDataEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const data = {
        user: {
          ...extractValues( userData ),
          roles: convertCompanyUserAccessFieldsForApi( extractValues( companyUserData ) )
        }
      };
      const response = await updateRecord( `pendingUsers/${id}`, data );
      if ( response ) {
        updateUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
        updateFormSubmitted( false );
        updateUserDataEdited( false );
        updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  let buttonText = 'Update';
  let resendButton = (
    <Form.Group as={Col} controlId="resend" xs={12} sm='auto' className='mx-auto'>
      <Button variant='secondary' type='button' disabled={loadingState} onClick={sendEmailHandler} className='w-100'>Resend Email</Button>
    </Form.Group>
  );

  if ( userDataEdited && existingEmail !== userData.email.value ) {
    buttonText = 'Update & Resend Email';
    resendButton = null;
  }

  const updateButton = recordPermissions.users_update ?
    <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
      <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
        {buttonText}
      </Button>
    </Form.Group> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Container>
      <LoadingSpinner loading={loadingState}>
        <h3>Last Updated: <em>{dateTimeFormatter( userData.updated_at )}</em></h3>
        <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''} noValidate>
          <FormFieldGenerator
            formData={userData}
            formDataInputHandler={updateUserData}
            formEditedHandler={updateUserDataEdited}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
          <h5>Company Permissions</h5>
          <FormFieldGenerator
            formData={companyUserData}
            formDataInputHandler={updateCompanyUserData}
            formEditedHandler={updateUserDataEdited}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
          <Form.Row className='pt-3'>
            {updateButton}
            {resendButton}
          </Form.Row>
        </Form>
      </LoadingSpinner>
      {toasts}
    </Container>
  );
};

export default PendingUser;
