import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Select from 'react-select';
import moment from 'moment';

import axios from '../../axios-instances/internal-api';
import classes from '../../App.module.css';
import Aux from '../../hoc/Aux';
import Toasts from '../Shared/Toasts';
import ReportFields from './ReportFields';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialReportHeaderFormFields from './ReportHeaderFields';
import ReportResultsTable from './ReportResultsTable';
import { REPORT_HEADER_FIELDS } from '../../helpers/constants/initialData';
import { REPORTING_FIELD_OPTIONS } from '../../helpers/constants/reportingOptions';
import SaveReportModal from '../Modal/SaveReportModal/SaveReportModal';
import ShareReportModal from '../Modal/ShareReportModal/ShareReportModal';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import {
  cloneDeep,
  dateStringFormatter,
  dateTimeFormatterFromString,
  selectComponentStyles,
  tableEditButton,
  createRecord,
  getSingleRecord,
  updateRecord
} from '../../helpers/functions/utilities';
import DeleteModal from '../Modal/DeleteModal';

const Reporting = props => {

  const { customFields:propsCustomFields } = props;

  const buildCustomFieldOptions = () => {
    const customFields = {};
    Object.entries( propsCustomFields ).forEach( entry => {
      const [label, fields] = entry;
      customFields[label] = fields.map( field => ( {
        label: field.field_data.label,
        value: `custom_fields_data.${field.table_name}-${field.field_data.type}-${field.id}-${field.field_name}`
      } ) );
    } );
    return customFields;
  };

  const resetRecordFieldsValue = () => {
    updateHeaderFields( cur => ( {
      ...cur,
      record_fields: {
        ...cur.record_fields,
        value: ''
      }
    } ) );
    updateReportBuilderData( [] );
    updateRows( [0] );
    updateSavedReportBuilderData( [] );
  };

  const [customFields] = useState( buildCustomFieldOptions );

  const [loadingState, updateLoadingState] = useState( true );

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [headerFields, updateHeaderFields] = useState( cloneDeep( { ...initialReportHeaderFormFields, record_type: { ...initialReportHeaderFormFields.record_type, customChangeHandler: resetRecordFieldsValue } } ) );
  const [rows, updateRows] = useState( [0] );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [reportBuilderData, updateReportBuilderData] = useState( [] );
  const [fullQueryData, updateFullQueryData] = useState( [] );
  const [reportResults, updateReportResults] = useState( [] );
  const [showResultsTable, updateShowResultsTable] = useState( false );
  const [reportHeadings, updateReportHeadings] = useState( [] );
  const [toastData, updateToastData] = useState( [] );

  const [savedReports, updateSavedReports] = useState( [] );
  const [selectedReport, updateSelectedReport] = useState( '' );
  const [savedReportBuilderData, updateSavedReportBuilderData] = useState( [] );
  const [canSaveReport, updateCanSaveReport] = useState( false );

  const [expandable, updateExpandable] = useState( false );
  const [expandableHeadings, updateExpandableHeadings] = useState( [] );

  const [showSaveReportModal, updateShowSaveReportModal] = useState( false );
  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [showShareModal, updateShowShareModal] = useState( false );

  useEffect( () => {
    const getReports = async () => axios.get( '/reporting/list' );
    getReports()
      .then( reports => {
        updateSavedReports( reports.data );
        updateLoadingState( false );
      } );
  }, [] );

  useEffect( () => {
    updateHeaderFields( cur => setValuesFromResponse( cur, REPORT_HEADER_FIELDS, updateFormIsValid ) );
  }, [] );

  useEffect( () => {
    if ( headerFields.record_type.value.value.length ) {
      const fields = [
        ...REPORTING_FIELD_OPTIONS[headerFields.record_type.value.value],
        {
          label: 'Custom Fields',
          options: [...customFields[headerFields.record_type.value.value]]
        }
      ];
      updateHeaderFields( cur => ( {
        ...cur,
        record_fields: {
          ...cur.record_fields,
          attributes: {
            ...cur.record_fields.attributes,
            options: fields
          },
          value: selectedReport === '' ? '' : cur.record_fields.value
        }
      } ) );
    }
  }, [headerFields.record_type.value.value, customFields, selectedReport] );

  useEffect( () => {
    if ( formEdited ) {
      updateCanSaveReport( false );
    }
  }, [formEdited] );

  const changeSelectedReport = async report => {
    // console.log( 'changeSelectedReport value changed: ', report );
    updateSelectedReport( report ? report : '' );
    if ( report == null ) {
      updateRows( [0] );
      updateReportBuilderData( [] );
      updateHeaderFields( cur => setValuesFromResponse( cur, REPORT_HEADER_FIELDS, updateFormIsValid ) );
      updateSavedReportBuilderData( [] );
      updateFormEdited( false );
      updateFormSubmitted( false );
      updateFormIsValid( false );
      updateCanSaveReport( false );
      updateFullQueryData( [] );
      return;
    }
    try {
      const data = await getSingleRecord( `/reporting/${report.value}` );
      data.fullQueryData = data.fullQueryData.map( fields => {
        if ( fields.where_date.value !== '' ) {
          fields.where_date.value = moment.utc( fields.where_date.value, 'YYYY-MM-DDTHH:mm:ss.SSS' ).local();
        }
        return fields;
      } );
      updateHeaderFields( {
        ...data.headerFields,
        record_type: {
          ...data.headerFields.record_type,
          customChangeHandler: resetRecordFieldsValue
        }
      } );
      updateRows( data.fullQueryData.map( ( el, index ) => index ) );
      updateSavedReportBuilderData( data.fullQueryData );
      updateFormEdited( true );
      updateFormSubmitted( false );
      updateFormIsValid( true );
      updateCanSaveReport( false );
    } catch ( e ) {
      console.log( 'error: ', e );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Error retrieving report, please try again.', id: Date.now() }] );
    }
  };

  const addWhereHandler = () => {
    updateRows( cur => [...cur, reportBuilderData.length] );
    updateCanSaveReport( false );
  };

  const removeWhereHandler = index => {
    const rowIndex = rows.findIndex( el => el === index );
    if ( rowIndex !== -1 ) {
      updateRows( cur => {
        const copy = [...cur];
        copy.splice( rowIndex, 1 );
        return copy;
      } );
      updateFormEdited( true );
      updateCanSaveReport( false );
    }
  };

  const generateHeadings = dataArray => {
    let nestedFields;
    const nestedHeadings = [];
    if ( dataArray.length && dataArray[0].hasOwnProperty( '_nesting_' ) ) {
      updateExpandable( true );
      nestedFields = Object.keys( dataArray[0]['_nesting_'][0] );
      nestedHeadings.push( { dataField: 'id', text: 'ID', hidden: true } );
    } else {
      updateExpandable( false );
    }
    const recordType = headerFields.record_type.value.value;
    const baseHeadings = [
      { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, recordType, recordType === 'accounts' ? '/details' : '', true ) },
      { dataField: 'id', text: 'ID', hidden: true }
    ];
    headerFields.record_fields.value.forEach( field => {
      const heading = { dataField: field.value.replace( '.', '_' ), text: field.label, sort: true };
      if ( field.value.endsWith( '_at' ) ) {
        heading.formatter = dateTimeFormatterFromString;
      } else if ( field.value.endsWith( '_on' ) ) {
        heading.formatter = dateStringFormatter;
      } else if ( field.value === 'leads_assigned' || field.value.endsWith( 'checkbox' ) ) {
        heading.formatter = val => val ? 'Yes' : 'No';
      }
      if ( Array.isArray( nestedFields ) && nestedFields.includes( heading.dataField ) ) {
        heading.id = nestedHeadings.length;
        nestedHeadings.push( heading );
      } else {
        baseHeadings.push( heading );
      }
    } );
    updateReportHeadings( baseHeadings );
    updateExpandableHeadings( nestedHeadings );
  };

  const hideSaveReportModal = () => {
    updateShowSaveReportModal( false );
  };

  const displaySaveReportModal = () => {
    updateShowSaveReportModal( true );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please update a field first.', id: Date.now() }] );
      return;
    }
    updateFormSubmitted( true );
    const builderData = reportBuilderData
      .filter( ( el, index ) => rows.includes( index ) )
      .map( el => {
        if ( el.hasOwnProperty( 'where_date' ) && el.where_date.length ) {
          el.where_date = moment( el.where_date ).format( 'YYYY-MM-DD' );
          el.offset = moment().utcOffset();
        }
        return el;
      } );
    if ( !formIsValid || !builderData.every( group => group.valid ) ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    updateShowResultsTable( false );
    updateLoadingState( true );
    const headerFieldValues = extractValues( headerFields );
    try {
      const response = await axios.post( '/reporting/runReport', { onTable: headerFieldValues.record_type, query_conditions: builderData, select_fields: headerFieldValues.record_fields } );
      generateHeadings( response.data.rows );
      updateReportResults( response.data.rows );
      updateFormSubmitted( false );
      updateFormEdited( false );
      updateShowResultsTable( true );
      updateCanSaveReport( true );
    } catch ( e ) {
      console.log( 'error: ', e );
      updateShowResultsTable( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Error running report, please check inputs and try again.', id: Date.now() }] );
    }
    updateLoadingState( false );
  };

  const saveReport = async name => {
    updateLoadingState( true );
    try {
      const reportData = { headerFields, fullQueryData, name };
      if ( selectedReport === '' ) {
        const { id } = await createRecord( '/reporting/', reportData );
        updateSavedReports( cur => [...cur, { label: name, value: id }] );
      } else {
        await updateRecord( `/reporting/${selectedReport.value}`, reportData );
        if ( name !== selectedReport.label ) {
          updateSavedReports( cur => {
            const copy = [...cur];
            const reportIndex = copy.findIndex( el => el.value === selectedReport.value );
            if ( reportIndex !== -1 ) {
              const reportCopy = { ...copy[reportIndex] };
              reportCopy.label = name;
              copy.splice( reportIndex, 1, reportCopy );
            }
            return copy;
          } );
          updateSelectedReport( cur => ( {
            ...cur,
            label: name
          } ) );
        }
      }
      updateToastData( cur => [...cur, { heading: 'Success', body: 'Report Saved.', id: Date.now() }] );
      hideSaveReportModal();
    } catch ( e ) {
      console.log( 'error: ', e );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Error saving report, please try again.', id: Date.now() }] );
    }
    updateLoadingState( false );
  };

  const displayDeleteModal = () => {
    updateShowDeleteModal( true );
  };

  const hideDeleteModal = () => {
    updateShowDeleteModal( false );
  };

  const deleteReport = async () => {
    try {
      const response = await axios.delete( `/reporting/${selectedReport.value}` );
      if ( response.data.success ) {
        updateSavedReports( cur => {
          const copy = [...cur];
          const reportIndex = copy.findIndex( el => el.value === selectedReport.value );
          if ( reportIndex !== -1 ) {
            copy.splice( reportIndex, 1 );
          }
          return copy;
        } );
        updateSelectedReport( '' );
        updateToastData( cur => [...cur, { heading: 'Success', body: 'Report Deleted.', id: Date.now() }] );
      }
    } catch ( e ) {
      console.log( 'error: ', e );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Error deleting the report, please try again.', id: Date.now() }] );
    }
    hideDeleteModal();
  };

  const displayShareReportModal = () => {
    updateShowShareModal( true );
  };

  const hideShareModal = () => {
    updateShowShareModal( false );
  };

  const shareReport = async userIdArray => {
    try {
      const response = await axios.post( `/reporting/${selectedReport.value}`, userIdArray );
      if ( response.data.success ) {
        updateToastData( cur => [...cur, { heading: 'Success', body: 'Report Shared.', id: Date.now() }] );
      }
    } catch ( e ) {
      console.log( 'error: ', e );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Error sharing the report, please try again.', id: Date.now() }] );
    }
    hideShareModal();
  };

  const saveButtonText = selectedReport === '' ? 'Save Report' : 'Update Report';

  const resultsTable = showResultsTable ?
    <ReportResultsTable
      headings={reportHeadings}
      data={reportResults}
      loadingState={loadingState}
      expandable={expandable}
      expandableHeadings={expandableHeadings}
    /> :
    null;

  const reportFieldForms = rows.length ?
    rows.map( index => (
      <ReportFields
        key={index}
        formSubmitted={formSubmitted}
        updateFormEdited={updateFormEdited}
        updateReportBuilderData={updateReportBuilderData}
        recordType={headerFields.record_type.value}
        index={index}
        deleteButtonHandler={() => removeWhereHandler( index )}
        updateFullQueryData={updateFullQueryData}
        overrideData={savedReportBuilderData[index]}
      />
    ) ) :
    null;

  const saveReportButton = canSaveReport ?
    <Form.Group controlId='1' as={Col} xs={12} sm='auto' className='mt-3 mb-0'>
      <Button onClick={displaySaveReportModal} variant='primary' type='button' disabled={loadingState} className='w-100'>
        {saveButtonText}
      </Button>
    </Form.Group> :
    null;

  const deleteReportButton = selectedReport === '' ?
    null :
    <Form.Group controlId='2' as={Col} xs={12} sm='auto' className='mt-3 mb-0'>
      <Button onClick={displayDeleteModal} variant='danger' type='button' disabled={loadingState} className='w-100'>
        Delete Report
      </Button>
    </Form.Group>;

  const shareReportButton = selectedReport === '' ?
    null :
    <Form.Group controlId='3' as={Col} xs={12} sm='auto' className='mt-3 mb-0'>
      <Button onClick={displayShareReportModal} variant='primary' type='button' disabled={loadingState} className='w-100'>
        Share Report
      </Button>
    </Form.Group>;

  const storedReportsFields = savedReports.length ?
    <Form.Row className='pb-3 align-items-center'>
      <Col xs={12} md={3} className='left-label form-group'>
        <Form.Label>Available Reports</Form.Label>
        <Select
          isClearable
          options={savedReports}
          value={selectedReport}
          onChange={changeSelectedReport}
          styles={selectComponentStyles}
        />
      </Col>
      {saveReportButton}
      {deleteReportButton}
      {shareReportButton}
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Container>
        <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''}>
          {storedReportsFields}
          <FormFieldGenerator
            formSubmitted={formSubmitted}
            formData={headerFields}
            formDataInputHandler={updateHeaderFields}
            formEditedHandler={updateFormEdited}
            updateFormIsValid={updateFormIsValid}
          />
          {reportFieldForms}
          <Form.Row className='pt-3'>
            <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='my-auto py-4'>
              <Button variant='success' type='submit' disabled={loadingState} className='w-100'>
                Run Report
              </Button>
            </Form.Group>
            <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='my-auto py-4'>
              <Button onClick={addWhereHandler} variant='primary' type='button' disabled={loadingState} className='w-100'>
                Add And Where Conditions
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Container>
      {resultsTable}
      {toasts}
      <SaveReportModal
        show={showSaveReportModal}
        onHide={hideSaveReportModal}
        onComplete={saveReport}
        existingName={selectedReport.label}
        existingReportNames={savedReports.map( el => el.label )}
      />
      <DeleteModal
        message={<div><p>You are about to delete {selectedReport.label}.</p></div>}
        onConfirmDelete={deleteReport}
        onHide={hideDeleteModal}
        show={showDeleteModal}
      />
      <ShareReportModal
        reportName={selectedReport.label}
        onHide={hideShareModal}
        show={showShareModal}
        onSubmit={shareReport}
      />
    </Aux>
  );
};

Reporting.propTypes = {
  customFields: PropTypes.object.isRequired
};

export default Reporting;
