export default {
  provider: {
    attributes: {
      options: []
    },
    displayCondition: 'data.provider.attributes.options.length > 1',
    errorMessages: {
      required: { message: 'Please select a payment processor from the list', key: 'pv-req' },
    },
    errors: [],
    isValid: false,
    label: 'Payment Processor',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  type: {
    attributes: {
      options: ['One Time', 'Recurring']
    },
    errorMessages: {
      required: { message: 'Please select a payment type', key: 'pt-req' },
    },
    errors: [],
    isValid: false,
    label: 'Payment Type',
    type: 'radio',
    validation: {
      required: true
    },
    value: ''
  },
  number_of_payments: {
    displayCondition: 'data.type.value === "Recurring"',
    errorMessages: {
      required: { message: 'Number of Payments is required', key: 'np-req' }
    },
    errors: [],
    isValid: false,
    label: 'Number of Payments',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
  payment_method: {
    attributes: {
      options: ['Bank Account', 'Credit/Debit']
    },
    errorMessages: {
      required: { message: 'Please select a payment method', key: 'pm-req' },
    },
    errors: [],
    isValid: false,
    label: 'Payment Method',
    type: 'radio',
    validation: {
      required: true
    },
    value: ''
  },
  bank_account: {
    displayCondition: 'data.payment_method.value === "Bank Account"',
    errorMessages: {
      required: { message: 'Bank Account Number is required', key: 'ba-req' }
    },
    errors: [],
    isValid: false,
    label: 'Bank Account Number',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
  bank_routing: {
    displayCondition: 'data.payment_method.value === "Bank Account"',
    errorMessages: {
      required: { message: 'Bank Routing Number is required', key: 'br-req' }
    },
    errors: [],
    isValid: false,
    label: 'Bank Routing Number',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
  card_account: {
    displayCondition: 'data.payment_method.value === "Credit/Debit"',
    errorMessages: {
      required: { message: 'Credit Card Number is required', key: 'ccn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Credit Card Number',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
  card_expiration: {
    attributes: {
      closeOnSelect: true,
      dateFormat: 'MM/YYYY',
      timeFormat: false
    },
    displayCondition: 'data.payment_method.value === "Credit/Debit"',
    errorMessages: {
      required: { message: 'Expiration Date is required', key: 'ed-req' }
    },
    errors: [],
    isValid: false,
    label: 'Expiration Date',
    type: 'datetime',
    validation: {
      required: true
    },
    value: ''
  },
  card_cvv: {
    displayCondition: 'data.payment_method.value === "Credit/Debit"',
    errorMessages: {
      required: { message: 'CVV is required', key: 'cvv-req' }
    },
    errors: [],
    isValid: false,
    label: 'CVV',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  },
};
