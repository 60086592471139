import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './Tables.css';
import axios from '../../axios-instances/internal-api';
import * as actionTypes from '../../store/actions/actions';
import classes from '../../App.module.css';
import { convertTimeStringsToMoments, concatCustomers } from '../../helpers/functions/utilities';

const MainTable = ( props ) => {

  const { applyFilters, filters, route, filtersApplied, refreshNeeded, updateRefreshNeeded, routeSuffix } = props;

  const [initialLoad, updateInitialLoad] = useState( true );
  const [tableData, updateTableData] = useState( [] );
  const [loadingState, updateLoadingState] = useState( false );
  const [tableCount, updateTableCount] = useState( 0 );
  const [currentPage, updateCurrentPage] = useState( 1 );
  const [currentSizePerPage, updateCurrentSizePerPage] = useState( props.defaultSizePerPage ? props.defaultSizePerPage : 25 );
  const [appliedFilters, updateAppliedFilters] = useState( {} );
  const [currentRoute] = useState( route.split( /(?=[A-Z])/ ).join( '_' ).toLowerCase() );

  const location = useLocation();

  const getData = useCallback( async ( type = '', newState = {}, filters = appliedFilters ) => {
    const { sortField, sortOrder, sizePerPage = currentSizePerPage, page = 1 } = newState;
    console.log( 'new state: ', newState );
    console.log( 'filters: ', filters );
    updateLoadingState( true );
    const data = {
      filters,
      page,
      sizePerPage,
      sortField,
      sortOrder
    };
    let dataRoute = `${route}/list`;
    if ( routeSuffix ) {
      dataRoute = dataRoute.concat( `/${routeSuffix}` );
    }
    const response = await axios.post( dataRoute, data );
    console.log( 'response: ', response );
    if ( response.data ) {
      const responseData = response.data.map( row => {
        convertTimeStringsToMoments( row );
        concatCustomers( row );
        return row;
      } );
      console.log( 'response data: ', responseData );
      updateTableData( responseData );
    }
    if ( applyFilters ) {
      updateCurrentPage( 1 );
      filtersApplied();
    }
    updateLoadingState( false );
  }, [updateLoadingState, applyFilters, route, filtersApplied, appliedFilters, currentSizePerPage, updateTableData, updateCurrentPage, routeSuffix] );

  const getCount = useCallback( async ( filters = {} ) => {
    if ( !loadingState && ( location.pathname.endsWith( currentRoute ) || route.startsWith( 'notes' ) || ( route === 'leads' && location.pathname.startsWith( '/leadProviders' ) ) ) ) {
      updateLoadingState( true );
      let countRoute = `${route}/list/count`;
      if ( routeSuffix ) {
        countRoute = countRoute.concat( `/${routeSuffix}` );
      }
      try {
        const response = await axios.post( countRoute, { filters } );
        if ( response && response.hasOwnProperty( 'data' ) ) {
          updateTableCount( response.data.hasOwnProperty( 'count' ) ? response.data.count : response.data );
          await getData( undefined, undefined, filters );
        } else {
          if ( applyFilters ) {
            filtersApplied();
          }
          updateLoadingState( false );
        }
      } catch ( e ) {
        console.error( 'Error getting count: ', e );
      }
    }
  }, [updateLoadingState, applyFilters, route, filtersApplied, updateTableCount, getData, loadingState, routeSuffix, currentRoute, location.pathname] );

  useEffect( () => {
    if ( !loadingState && ( applyFilters || refreshNeeded ) ) {
      if ( refreshNeeded ) {
        updateRefreshNeeded( false );
      }
      const appliedFilters = {};
      for ( let name in filters ) {
        if ( filters.hasOwnProperty( name ) &&
          filters[name].show &&
          filters[name].filter !== 'all' &&
          ( filters[name].value || typeof filters[name].filter === 'string' ) ) {
          appliedFilters[name] = { ...filters[name] };
        }
      }
      updateAppliedFilters( appliedFilters );
      getCount( appliedFilters )
        .then( () => {} );
    }
  }, [applyFilters, filters, updateAppliedFilters, getCount, refreshNeeded, updateRefreshNeeded, loadingState] );

  const sizePerPageChangeHandler = ( sizePerPage, page ) => {
    updateCurrentSizePerPage( sizePerPage );
    updateCurrentPage( page );
  };

  const pageChangeHandler = ( page, sizePerPage ) => {
    updateCurrentSizePerPage( sizePerPage );
    updateCurrentPage( page );
  };

  const paginationData = {
    page: currentPage, // Specify the current page. It's necessary when remote is enabled
    sizePerPage: currentSizePerPage, // Specify the size per page. It's necessary when remote is enabled
    totalSize: tableCount, // Total data size. It's necessary when remote is enabled
    paginationSize: 5,  // the pagination bar size, default is 5
    showTotal: true, // display pagination information
    sizePerPageList: props.sizePerPageList ? props.sizePerPageList : [10, 25, 50, 100], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
    withFirstAndLast: true, // hide the going to first and last page button
    alwaysShowAllBtns: false, // always show the next and previous page button
    firstPageText: 'First', // the text of first page button
    prePageText: 'Prev', // the text of previous page button
    nextPageText: 'Next', // the text of next page button
    lastPageText: 'Last', // the text of last page button
    nextPageTitle: 'Go to next', // the title of next page button
    prePageTitle: 'Go to previous', // the title of previous page button
    firstPageTitle: 'Go to first', // the title of first page button
    lastPageTitle: 'Go to last', // the title of last page button
    hideSizePerPage: false, // hide the size per page dropdown
    hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
    onPageChange: pageChangeHandler, // callback function when page was changing
    onSizePerPageChange: sizePerPageChangeHandler, // callback function when page size was changing
    //paginationTotalRenderer: (from, to, size) => { ... }  // custom the pagination total
  };

  if ( initialLoad ) {
    updateInitialLoad( false );
    getCount()
      .then( () => {} );
  }

  const defaultSort = props.defaultSorted ? [props.defaultSorted] : null;

  return (
    <BootstrapTable
      bootstrap4
      bordered={ false }
      classes='TableComponent'
      columns={ props.headings }
      data={ props.remote ? tableData : props.data }
      defaultSorted={defaultSort}
      hover
      keyField='id'
      loading={ loadingState }
      noDataIndication={() => 'No Records Found'}
      onTableChange={ getData }
      pagination={ paginationFactory( paginationData ) }
      remote={ props.remote ? props.remote : false }
      striped
      wrapperClasses={loadingState ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
      cellEdit={props.cellEdit}
    />
  );
};

MainTable.propTypes = {
  applyFilters: PropTypes.bool.isRequired,
  cellEdit: PropTypes.object,
  data: PropTypes.array,
  defaultSizePerPage: PropTypes.number,
  defaultSorted: PropTypes.shape( {
    dataField: PropTypes.string,
    order: PropTypes.string
  } ),
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.func.isRequired,
  headings: PropTypes.array.isRequired,
  refreshNeeded: PropTypes.bool,
  remote: PropTypes.object,
  route: PropTypes.string.isRequired,
  routeSuffix: PropTypes.string,
  sizePerPageList: PropTypes.arrayOf( PropTypes.number ),
  updateRefreshNeeded: PropTypes.func
};

const mapStateToProps = state => ( {
  applyFilters: state.sideDrawer.applyFilters,
  filters: state.sideDrawer.filters
} );

const mapDispatchToProps = dispatch => ( {
  filtersApplied: () => dispatch( { type: actionTypes.SIDE_DRAWER_FILTERS_APPLIED } )
} );

export default connect( mapStateToProps, mapDispatchToProps )( MainTable );
