import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import FormFieldGenerator from '../Shared/FormFieldGenerator';
import CustomFieldFormFields from '../Company/CompanyTabs/CustomFields/CustomFieldFormFields';
import { cloneDeep } from '../../helpers/functions/utilities';
import { extractValues, setValuesFromResponse } from '../../helpers/functions/formValidator';
import { CUSTOM_FIELD } from '../../helpers/constants/initialData';
import axios from '../../axios-instances/internal-api';
import Toasts from '../Shared/Toasts';
import classes from '../../App.module.css';

const CustomFieldModal = props => {

  const [loadingState, updateLoadingState] = useState( false );
  const [fieldData, updateFieldData] = useState( cloneDeep( CustomFieldFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [toastData, updateToastData] = useState( [] );

  const { show, customFields, tableName, fieldId } = props;

  useEffect( () => {
    const flattenFieldData = ( field ) => (
      {
        field_name: field.field_data.label,
        field_type: field.field_data.type,
        required: field.field_data.validation.hasOwnProperty( 'required' ),
        minLength: field.field_data.validation.hasOwnProperty( 'minLength' ) ? field.field_data.validation.minLength : '',
        maxLength: field.field_data.validation.hasOwnProperty( 'maxLength' ) ? field.field_data.validation.maxLength : '',
        options: field.field_data.attributes.hasOwnProperty( 'options' ) && Array.isArray( field.field_data.attributes.options ) ?
          field.field_data.attributes.options.map( option => ( typeof option === 'object' ? option.label : option ) ).join( ', ' ) : '',
        id: field.id
      }
    );
    const data = fieldId ? flattenFieldData( customFields[tableName].find( el => el.id === fieldId ) ) : CUSTOM_FIELD;
    updateFieldData( setValuesFromResponse( cloneDeep( CustomFieldFormFields ), { ...data }, updateFormIsValid ) );
    updateFormSubmitted( false );
    updateFormEdited( false );
  }, [show, fieldId, tableName, customFields] );

  const checkFormValidity = async e => {
    // @todo field name cannot end with ' at', ' on', ' id', ' label'.  may want to include some additional reserved words
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      let data = extractValues( fieldData );
      data = {
        data,
        field_name: `custom_${data.field_name.split( ' ' ).join( '_' )}`,
        table_name: props.tableName
      };
      console.log( 'field data: ', data );
      if ( data.data.field_type === 'datetime' ) {
        data.field_name = data.field_name.concat( '_at' );
      }
      if ( data.data.field_type === 'date' ) {
        data.field_name = data.field_name.concat( '_on' );
      }
      let response;
      if ( props.fieldId ) {
        response = await axios.patch( `/customFields/${props.fieldId}`, data );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
        }
      } else {
        response = await axios.post( '/customFields', data );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      console.log( 'create custom field response: ', response );
      props.onHide();
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName='h-100'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.fieldId ? 'Update' : 'Create'} {props.tableName.charAt( 0 ).toUpperCase().concat( props.tableName.substring( 1 ) )} Custom Field
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={loadingState ?  classes.Loading : ''}>
          <FormFieldGenerator
            formData={fieldData}
            formDataInputHandler={updateFieldData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button
              type='submit'
              disabled={!formEdited}
              onClick={checkFormValidity}
              size='lg'
              variant='primary'
              className='w-100'
            >Save
            </Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} size='lg' variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
      {toasts}
    </Modal>
  );
};

CustomFieldModal.propTypes = {
  customFields: PropTypes.object.isRequired,
  fieldId: PropTypes.number,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  tableName: PropTypes.string.isRequired
};

export default CustomFieldModal;
