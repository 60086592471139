import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Toasts from '../../Shared/Toasts';

import { cloneDeep } from '../../../helpers/functions/utilities';
import {
  extractValues,
  setValuesFromResponse
} from '../../../helpers/functions/formValidator';
import initialReportModalFormFields from './SaveReportModalFormFields';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';

const SaveReportModal = props => {

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [reportName, updateReportName] = useState( cloneDeep( initialReportModalFormFields ) );
  const [toastData, updateToastData] = useState( [] );

  const { show, existingName = '' } = props;

  useEffect( () => {
    updateReportName( setValuesFromResponse( cloneDeep( initialReportModalFormFields ), { name: existingName }, updateFormIsValid ) );
    updateFormSubmitted( false );
    updateFormEdited( false );
  }, [show, existingName] );

  const verifyValidity = () => {
    updateFormSubmitted( true );
    if ( !formEdited && existingName === '' ) {
      console.log( 'form has not been edited' );
      return;
    }
    if ( !formIsValid ) {
      console.log( 'form is invalid' );
      return;
    }
    const { name } = extractValues( reportName );
    if ( props.existingReportNames.includes( name ) && name !== props.existingName ) {
      updateReportName( cur => ( {
        ...cur,
        name: {
          ...cur.name,
          errors: [{ message: 'Report name must be unique', key: 'nm-unq' }],
          isValid: false
        }
      } ) );
      return;
    }
    props.onComplete( name );
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Modal
      show={show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormFieldGenerator
            formData={reportName}
            formDataInputHandler={updateReportName}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button variant='primary' type='submit' onClick={verifyValidity} className='w-100'>Save Report</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
      {toasts}
    </Modal>
  );

};

SaveReportModal.propTypes = {
  existingName: PropTypes.string,
  existingReportNames: PropTypes.arrayOf( PropTypes.string ),
  onComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default SaveReportModal;
