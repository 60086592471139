import moment from 'moment';

import DateFilter from '../../src/components/Filters/DateFilter';
import NumberFilter from '../../src/components/Filters/NumberFilter';
import SelectFilter from '../../src/components/Filters/SelectFilter';
import TextFilter from '../../src/components/Filters/TextFilter';
import AsyncSelectFilter from '../../src/components/Filters/AsyncSelectFilter';
import { optionBuilder } from '../helpers/functions/utilities';
import { STATES } from '../helpers/constants/stateAbbreviations';
import {
  ACCOUNT_STAGES,
  ACCOUNT_STATUSES,
  EVENT_STATUSES,
  EVENT_TYPES,
  PRODUCT_TYPES
} from '../helpers/constants/options';

const eventStatusOptions = optionBuilder( ['All', ...EVENT_STATUSES] );
const eventTypeOptions = optionBuilder( ['All', ...EVENT_TYPES] );
const productTypeOptions = optionBuilder( ['All', ...PRODUCT_TYPES] );
const stageOptions = optionBuilder( ['All', ...ACCOUNT_STAGES] );
const statusOptions = optionBuilder( ['All', ...ACCOUNT_STATUSES] );
const stateOptions = optionBuilder( ['All', ...STATES] );

export const ASYNC_FILTER = { show: false, filter: 'All', value: null, type: 'asyncSelect' };
export const DATE_FILTER = { show: false, filter: 'All', value: null, secondValue: null, type: 'date', offset: moment().utcOffset() };
export const NUMBER_FILTER = { show: false, filter: 'All', value: null, secondValue: null, type: 'number' };
export const SELECT_FILTER = { show: false, filter: 'All', type: 'select' };
export const TEXT_FILTER = { show: false, filter: 'All', value: null, type: 'text' };

export const filterMap = {
  asyncSelect: AsyncSelectFilter,
  date: DateFilter,
  number: NumberFilter,
  select: SelectFilter,
  text: TextFilter
};

export const FILTERS = {
  ACCOUNTS: {
    'accounts.property_address1': { ...TEXT_FILTER, label: 'Property Address' },
    'accounts.property_address2': { ...TEXT_FILTER, label: 'Property Address 2' },
    'accounts.property_city': { ...TEXT_FILTER, label: 'Property City' },
    'accounts.property_state': { ...SELECT_FILTER, label: 'Property State', options: stateOptions },
    'accounts.property_zip': { ...TEXT_FILTER, label: 'Property Zip Code' },
    'accounts.phone': { ...TEXT_FILTER, label: 'Property Phone Number' },
    'accounts.billing_address1': { ...TEXT_FILTER, label: 'Billing Address' },
    'accounts.billing_address2': { ...TEXT_FILTER, label: 'Billing Address 2' },
    'accounts.billing_city': { ...TEXT_FILTER, label: 'Billing City' },
    'accounts.billing_state': { ...SELECT_FILTER, label: 'Billing State', options: stateOptions },
    'accounts.billing_zip': { ...TEXT_FILTER, label: 'Billing Zip Code' },
    'accounts.company_type': { ...TEXT_FILTER, label: 'Company Type' },
    'accounts.company_name': { ...TEXT_FILTER, label: 'Company Name' },
    'accounts.online_at': { ...DATE_FILTER, label: 'Online At' },
    'accounts.status': { ...SELECT_FILTER, label: 'Account Status', options: statusOptions },
    'accounts.stage': { ...SELECT_FILTER, label: 'Account Stage', options: stageOptions },
    'accounts.created_at': { ...DATE_FILTER, label: 'Created At' },
    'accounts.updated_at': { ...DATE_FILTER, label: 'Last Updated At' },
    'users.first_name': { ...TEXT_FILTER, label: 'Salesperson First Name' },
    'users.last_name': { ...TEXT_FILTER, label: 'Salesperson Last Name' },
  },
  CUSTOMERS: {
    'customers.first_name': { ...TEXT_FILTER, label: 'First Name' },
    'customers.last_name': { ...TEXT_FILTER, label: 'Last Name' },
    'customers.address1': { ...TEXT_FILTER, label: 'Address' },
    'customers.address2': { ...TEXT_FILTER, label: 'Address 2' },
    'customers.city': { ...TEXT_FILTER, label: 'City' },
    'customers.state': { ...SELECT_FILTER, label: 'State', options: stateOptions },
    'customers.zip': { ...TEXT_FILTER, label: 'Zip Code' },
    'customers.phone': { ...TEXT_FILTER, label: 'Phone Number' },
    'customers.email': { ...TEXT_FILTER, label: 'Email' },
    'customers.created_at': { ...DATE_FILTER, label: 'Created At' },
  },
  EVENTS: {
    'events.type': { ...SELECT_FILTER, label: 'Event Type', options: eventTypeOptions },
    'events.status': { ...SELECT_FILTER, label: 'Event Status', options: eventStatusOptions },
    'events.duration': { ...NUMBER_FILTER, label: 'Duration' },
    'events.completed_at': { ...DATE_FILTER, label: 'Date Completed' },
    'events.end_at': { ...DATE_FILTER, label: 'Scheduled End Date' },
    'events.estimate_on_site_at': { ...DATE_FILTER, label: 'Estimated Travel Time' },
    'events.in_route_at': { ...DATE_FILTER, label: 'In Route At' },
    'events.on_site_at': { ...DATE_FILTER, label: 'On Site Date' },
    'events.start_at': { ...DATE_FILTER, label: 'Scheduled Start Date' },
    'users.first_name': { ...TEXT_FILTER, label: 'Assigned User First Name' },
    'users.last_name': { ...TEXT_FILTER, label: 'Assigned User Last Name' },
    'accounts.property_address1': { ...TEXT_FILTER, label: 'Property Address' },
    'accounts.property_address2': { ...TEXT_FILTER, label: 'Property Address 2' },
    'accounts.property_city': { ...TEXT_FILTER, label: 'Property City' },
    'accounts.property_state': { ...SELECT_FILTER, label: 'Property State', options: stateOptions },
    'accounts.property_zip': { ...TEXT_FILTER, label: 'Property Zip' },
    'accounts.stage': { ...SELECT_FILTER, label: 'Account Stage', options: stageOptions },
    'accounts.status': { ...SELECT_FILTER, label: 'Account Status', options: statusOptions },
    'events.created_at': { ...DATE_FILTER, label: 'Event Created At' },
    'events.updated_at': { ...DATE_FILTER, label: 'Event Last Updated At' },
  },
  GROUPS: {
    'groups.name': { ...TEXT_FILTER, label: 'Name' },
    'num_members': { ...NUMBER_FILTER, label: 'Number of Members', step: '1' },
    'num_managers': { ...NUMBER_FILTER, label: 'Number of Managers', step: '1' },
    'groups.created_at': { ...DATE_FILTER, label: 'Created At' }
  },
  LEAD_PROVIDERS: {
    'lead_providers.name': { ...TEXT_FILTER, label: 'name' },
    'lead_providers.token': { ...TEXT_FILTER, label: 'token' },
    'lead_providers.is_active': { ...SELECT_FILTER, label: 'Active' },
    'num_leads': { ...NUMBER_FILTER, label: 'Number of Leads', step: '1' },
    'lead_providers.created_at': { ...DATE_FILTER, label: 'Created At' },
  },
  LEADS: {
    'leads.first_name': { ...TEXT_FILTER, label: 'First Name' },
    'leads.last_name': { ...TEXT_FILTER, label: 'Last Name' },
    'leads.address1': { ...TEXT_FILTER, label: 'Address' },
    'leads.address2': { ...TEXT_FILTER, label: 'Address 2' },
    'leads.assigned': { ...SELECT_FILTER, label: 'Assigned' },
    'leads.assigned_at': { ...DATE_FILTER, label: 'Date Assigned' },
    'leads.city': { ...TEXT_FILTER, label: 'City' },
    'leads.created_at': { ...DATE_FILTER, label: 'Created At' },
    'leads.phone': { ...TEXT_FILTER, label: 'Phone Number' },
    'leads.state': { ...SELECT_FILTER, label: 'State', options: stateOptions },
    'leads.zip': { ...TEXT_FILTER, label: 'Zip Code' },
    'lead_providers.name': { ...TEXT_FILTER, label: 'Lead Provider Name' },
    'users.first_name': { ...TEXT_FILTER, label: 'Assigned To First Name' },
    'users.last_name': { ...TEXT_FILTER, label: 'Assigned To Last Name' },
  },
  PACKAGES: {
    'packages.name': { ...TEXT_FILTER, label: 'Name' },
    'packages.price': { ...NUMBER_FILTER, label: 'Price' },
    'packages.is_active': { ...SELECT_FILTER, label: 'Active' },
    'num_products': { ...NUMBER_FILTER, label: 'Number of Products', step: '1' },
  },
  PENDING_USERS: {
    'pending_users.first_name': { ...TEXT_FILTER, label: 'First Name' },
    'pending_users.last_name': { ...TEXT_FILTER, label: 'Last Name' },
    'pending_users.email': { ...TEXT_FILTER, label: 'Email' },
    'pending_users.phone': { ...TEXT_FILTER, label: 'Phone Number' },
    'pending_users.created_at': { ...DATE_FILTER, label: 'Joined Date' },
    'pending_users.address1': { ...TEXT_FILTER, label: 'Address' },
    'pending_users.address2': { ...TEXT_FILTER, label: 'Address 2' },
    'pending_users.city': { ...TEXT_FILTER, label: 'City' },
    'pending_users.state': { ...SELECT_FILTER, label: 'State', options: stateOptions },
    'pending_users.zip': { ...TEXT_FILTER, label: 'Zip Code' }
  },
  PRODUCTS: {
    'products.name': { ...TEXT_FILTER, label: 'Name' },
    'products.description': { ...TEXT_FILTER, label: 'Description' },
    'products.sku': { ...TEXT_FILTER, label: 'SKU' },
    'products.cost': { ...NUMBER_FILTER, label: 'Cost' },
    'products.points': { ...NUMBER_FILTER, label: 'Points' },
    'products.price': { ...NUMBER_FILTER, label: 'Price' },
    'products.type': { ...SELECT_FILTER, label: 'Type', options: productTypeOptions },
    'products.is_fire': { ...SELECT_FILTER, label: 'Fire Device' },
    'products.created_at': { ...DATE_FILTER, label: 'Created At' },
  },
  SCHEDULE: {
    'events.type': { ...SELECT_FILTER, label: 'Event Type', options: eventTypeOptions },
    'events.status': { ...SELECT_FILTER, label: 'Event Status', options: eventStatusOptions },
    'users.uid': { ...ASYNC_FILTER, label: 'User', getOptionsParams: ['users'] },
    'groups.uid': { ...ASYNC_FILTER, label: 'Group', getOptionsParams: ['groups'] },
    'accounts.property_zip': { ...TEXT_FILTER, label: 'Property Zip Code' },
    'accounts.property_city': { ...TEXT_FILTER, label: 'Property City' },
    'accounts.property_state': { ...SELECT_FILTER, label: 'Property State', options: stateOptions },
  },
  USERS: {
    'companies_users.is_active': { ...SELECT_FILTER, label: 'Active' },
    'users.first_name': { ...TEXT_FILTER, label: 'First Name' },
    'users.last_name': { ...TEXT_FILTER, label: 'Last Name' },
    'users.email': { ...TEXT_FILTER, label: 'Email' },
    'users.phone': { ...TEXT_FILTER, label: 'Phone Number' },
    'companies_users.created_at': { ...DATE_FILTER, label: 'Joined Date' },
    'users.address1': { ...TEXT_FILTER, label: 'Address' },
    'users.address2': { ...TEXT_FILTER, label: 'Address 2' },
    'users.city': { ...TEXT_FILTER, label: 'City' },
    'users.state': { ...SELECT_FILTER, label: 'State', options: stateOptions },
    'users.zip': { ...TEXT_FILTER, label: 'Zip Code' },
  }
};
