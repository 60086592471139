import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from '../../../App.module.css';
import { dateTimeFormatterFromString } from '../../../helpers/functions/utilities';
import axios, { generateCancelToken } from '../../../axios-instances/internal-api';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import widgetWebsocketUpdates from './WidgetWebsocketUpdates';

const AccountsCallForSurvey = ( { companyId: id, show } ) => {

  const [loadingState, updateLoadingState] = useState( true );
  const [data, updateData] = useState( [] );

  const history = useHistory();

  const headings = [
    { dataField: 'id', hidden: true },
    { dataField: 'customer_label', text: 'Customer Name', sort: false },
    { dataField: 'phone', text: 'Phone Number', sort: true },
    { dataField: 'updated_at', text: 'Last Updated', sort: true, formatter: dateTimeFormatterFromString }
  ];

  useEffect( () => {
    let source;
    const getAccountsCallForSurvey = async () => {
      updateLoadingState( true );
      source = generateCancelToken();
      const data = {
        filters: {
          'accounts.status': {
            filter: 'Call For Survey',
            show: true,
            type: 'select'
          }
        },
        page: 1,
        sizePerPage: 1000,
        sortField: 'updated_at',
        sortOrder: 'asc',
        accountsCallForSurveyWidget: true
      };
      const response = await axios.post( '/accounts/list', data, { cancelToken: source.token } );
      updateData( response.data );
      updateLoadingState( false );
    };
    if ( id && show ) {
      getAccountsCallForSurvey()
        .then( () => {} );
    }

    return () => {
      if ( source && loadingState ) {
        source.cancel();
        updateData( [] );
      }
    };
  }, [id, show] );

  widgetWebsocketUpdates( {
    addEventPrefix: 'cfs_widget_add_',
    id,
    removeEventPrefix: 'cfs_widget_remove_',
    updateData
  } );

  const rowEvents = {
    onClick: ( e, row ) => {
      history.push( `/accounts/${row.id}/details` );
    }
  };

  return show ?
    <WidgetContainer title='Call For Survey'>
      <BootstrapTable
        bootstrap4
        bordered={false}
        classes='ClickableTableRows TableComponent'
        columns={headings}
        data={data}
        defaultSorted={[{
          dataField: 'updated_at',
          order: 'asc'
        }]}
        hover
        keyField='id'
        loading={loadingState}
        noDataIndication={() => 'No Records Found'}
        remote={false}
        rowEvents={rowEvents}
        striped
        wrapperClasses={loadingState ? `TableWrapper ${classes.Loading} m-0` : 'TableWrapper m-0'}
      />
    </WidgetContainer> :
    null;
};

AccountsCallForSurvey.propTypes = {
  companyId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
};

export default AccountsCallForSurvey;
