import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapTable from 'react-bootstrap-table-next';

import axios from '../../../axios-instances/internal-api';
import Toasts from '../../Shared/Toasts';
import classes from '../../../App.module.css';
import LoadingSpinner from '../../../hoc/LoadingSpinner';
import { tableCheckbox } from '../../../helpers/functions/utilities';
import Aux from '../../../hoc/Aux';

const SendNotificationsModal = props => {

  const [loadingState, updateLoadingState] = useState( true );
  const [data, updateData] = useState( [] );
  const [toastData, updateToastData] = useState( [] );

  const sendNotifications = async () => {
    updateLoadingState( true );
    try {
      const response = await axios.post( `/events/${eventId}/sendNotifications`, { notifications: data } );
      const errors = Object.values( response.data );
      if ( errors.length ) {
        updateToastData( cur => [...cur, ...errors.map( ( el, index ) => ( { heading: el[0], body: el[1], id: Date.now() + index } ) )] );
      } else {
        // updateToastData( cur => [...cur, { heading: 'Success', body: 'Notifications Sent.', id: Date.now() }] );
        props.onHide( true );
      }
    } catch ( e ) {
      console.log( 'error: ', e );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
    updateLoadingState( false );
  };

  const { eventId } = props;

  useEffect( () => {
    const getNotificationDetails = async () => {
      try {
        const response = await axios.get( `/events/${eventId}/notificationDetails` );
        updateData( response.data.notification_data.map( el => {
          el.notify_text = el.notification_preference.includes( 'Text' );
          el.notify_email = el.notification_preference.includes( 'Email' );
          if ( el.type !== 'customer' ) {
            el.notify_internal = el.notification_preference.includes( 'Internal' );
          }
          return el;
        } ) );
        updateLoadingState( false );
      } catch ( e ) {
        console.log( 'error: ', e );
      }
    };
    if ( props.show ) {
      getNotificationDetails()
        .then( () => {} );
    }
  }, [props.show, eventId] );

  const notificationChangeHandler = ( value, row, field ) => {
    updateData( cur => (
      cur.map( el => {
        const copy = { ...el };
        if ( copy.id === row.id ) {
          copy[field] = value;
        }
        return copy;
      } )
    ) );
  };

  const headings = [
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'type', text: 'Type', sort: false, style: { textTransform: 'capitalize' } },
    { dataField: 'name', text: 'Name', sort: false },
    { dataField: 'actions_text', text: 'Text', isDummyField: true, formatter: ( cell, row ) => tableCheckbox( row, 'notify_text', notificationChangeHandler ), style: { textAlign: 'center' } },
    { dataField: 'actions_email', text: 'Email', isDummyField: true, formatter: ( cell, row ) => tableCheckbox( row, 'notify_email', notificationChangeHandler ), style: { textAlign: 'center' } },
    { dataField: 'actions_internal', text: 'Internal', isDummyField: true, formatter: ( cell, row ) => tableCheckbox( row, 'notify_internal', notificationChangeHandler ), style: { textAlign: 'center' } },
    { dataField: 'phone', text: 'Phone', sort: false },
    { dataField: 'email', text: 'Email', sort: false }
  ];

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        scrollable
      >
        {toasts}
        <Modal.Header closeButton >Send notifications to:</Modal.Header>
        <Modal.Body>
          <LoadingSpinner loading={loadingState}>
            <BootstrapTable
              bootstrap4
              bordered={false}
              classes='TableComponent'
              columns={headings}
              data={data}
              hover
              keyField='id'
              noDataIndication={() => 'No Customers or Users Found'}
              remote={false}
              striped
              wrapperClasses={loadingState ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
            />
          </LoadingSpinner>
        </Modal.Body>
        <Modal.Footer>
          <Row className='w-100'>
            <Col xs={12} sm={6} md='auto' className='mx-auto add-member-container'>
              <Button type='button' onClick={sendNotifications} size='lg' variant='success' className='w-100' disabled={loadingState}>Send</Button>
            </Col>
            <Col xs={12} sm={6} md='auto' className='mx-auto add-member-container'>
              <Button onClick={props.onHide} size='lg' variant='secondary' className='w-100'>Cancel</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Aux>
  );
};

SendNotificationsModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default SendNotificationsModal;
