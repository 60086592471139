import { CUSTOMER_NOTIFICATION_OPTIONS } from '../../helpers/constants/options';

export default {
  first_name: {
    errorMessages: {
      maxLength: { message: 'First Name cannot be longer than 20 characters', key: 'fn-max' },
      minLength: { message: 'First Name must be at least 2 characters', key: 'fn-min' },
      required: { message: 'First Name is required', key: 'fn-req' }
    },
    errors: [],
    isValid: false,
    label: 'First Name',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 2,
      required: true
    },
    value: ''
  },
  last_name: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Last Name',
    type: 'text',
    validation: {},
    value: ''
  },
  phone: {
    attributes: {
      placeholder: '(555) 555-5555'
    },
    errorMessages: {
      phone: { message: 'Invalid phone number', key: 'ph-inv' }
    },
    errors: [],
    isValid: false,
    label: 'Phone',
    type: 'tel',
    validation: {
      phone: true
    },
    value: ''
  },
  email: {
    errorMessages: {
      email: { message: 'Invalid Email address', key: 'em-inv' }
    },
    errors: [],
    isValid: false,
    label: 'Email',
    type: 'email',
    validation: {
      email: true
    },
    value: ''
  },
  dob_on: {
    attributes: {
      closeOnSelect: true,
      dateFormat: 'MM/DD/YYYY',
      timeFormat: false
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Date of Birth',
    type: 'datetime',
    validation: {},
    value: ''
  },
  notification_preference: {
    attributes: {
      options: CUSTOMER_NOTIFICATION_OPTIONS
    },
    errors: [],
    isValid: true,
    label: 'Notification Preference',
    type: 'select',
    validation: {},
    value: 'Email'
  },
  address_heading: {
    text: 'Address',
    isValid: true
  },
  address1: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address',
    type: 'text',
    validation: {},
    value: ''
  },
  address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'City',
    type: 'text',
    validation: {},
    value: ''
  },
  state: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'State',
    type: 'state',
    validation: {},
    value: ''
  },
  zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' }
    },
    errors: [],
    isValid: false,
    label: 'Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      number: true
    },
    value: ''
  },
  prev_heading: {
    text: 'Previous Address',
    isValid: true
  },
  prev_address1: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Previous Address',
    type: 'text',
    validation: {},
    value: ''
  },
  prev_address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Previous Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  prev_city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Previous City',
    type: 'text',
    validation: {},
    value: ''
  },
  prev_state: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Previous State',
    type: 'state',
    validation: {},
    value: ''
  },
  prev_zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' }
    },
    errors: [],
    isValid: false,
    label: 'Previous Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      number: true
    },
    value: ''
  },
  id: {
    display: false,
    displayCondition: 'false',
    isValid: true,
    validation: {},
    value: ''
  }
};
