// Side Drawer Actions:

export const UPDATE_SIDE_DRAWER_FILTERS = 'UPDATE_SIDE_DRAWER_FILTERS';
export const SIDE_DRAWER_COMPONENT_CHANGED = 'SIDE_DRAWER_COMPONENT_CHANGED';
export const APPLY_SIDE_DRAWER_FILTERS = 'APPLY_SIDE_DRAWER_FILTERS';
export const SIDE_DRAWER_FILTERS_APPLIED = 'SIDE_DRAWER_FILTERS_APPLIED';

// Axios actions:
export const INTERNAL_API_ERROR_OCCURRED = 'INTERNAL_API_ERROR_OCCURRED';
export const ERROR_RESET = 'ERROR_RESET';

// Modal trigger actions:
export const DELETE_SUCCESSFUL = 'DELETE_SUCCESSFUL';
export const SUCCESS_RESET = 'SUCCESS_RESET';

// Auth actions:
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_PROFILE_NOT_FOUND = 'USER_PROFILE_NOT_FOUND';
export const USER_PROFILE_INCOMPLETE = 'USER_PROFILE_INCOMPLETE';
export const USER_HAS_NO_COMPANIES = 'USER_HAS_NO_COMPANIES';
export const CHECK_FOR_TOKEN = 'CHECK_FOR_TOKEN';
export const BEGIN_LOGOUT = 'BEGIN_LOGOUT';
export const USER_CHANGED_COMPANIES = 'USER_CHANGED_COMPANIES';
export const UPDATE_COMPANY_AUTH = 'UPDATE_COMPANY_AUTH';

export const applySideDrawerFilters = () => {
  return {
    type: APPLY_SIDE_DRAWER_FILTERS
  };
};

