import React, { useState }  from 'react';
import MainTable from '../Tables/MainTable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';

import { dateTimeFormatterFromString, updateRecord } from '../../helpers/functions/utilities';
import Pencil from '../Shared/Pencil';
import Button from 'react-bootstrap/Button';

const route = 'notifications';

const Notifications = props => {

  const [notificationChanged, updateNotificationChanged] = useState( false );

  const viewRecordButton = row => (
    props.companyId === row.company_id ?
      <Link to={row.url}>
        <IconContext.Provider value={24}>
          <Pencil />
        </IconContext.Provider>
      </Link> :
      null
  );

  const markAsReadHandler = async id => {
    try {
      await updateRecord( `/notifications/${id}/markAsRead` );
      updateNotificationChanged( true );
    } catch ( e ) {
      console.log( 'error: ', e );
    }
  };

  const markAsUnreadHandler = async id => {
    try {
      await updateRecord( `/notifications/${id}/markAsUnread` );
      updateNotificationChanged( true );
    } catch ( e ) {
      console.log( 'error: ', e );
    }
  };

  const updateRecordButton = row => (
    row.viewed ?
      <Button size="sm" variant='primary' onClick={() => markAsUnreadHandler( row.id )}>
        Mark As Unread
      </Button> :
      <Button size="sm" variant='success' onClick={() => markAsReadHandler( row.id )}>
        Mark As Read
      </Button>
  );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => viewRecordButton( row ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'message', text: 'Message', sort: false, classes: 'note-text-area' },
    { dataField: 'from_user', text: 'From', sort: true },
    { dataField: 'company_name', text: 'Company', sort: true },
    { dataField: 'viewed', text: 'Viewed', sort: true, formatter: ( cell ) => cell ? 'Yes' : 'No' },
    { dataField: 'update', text: 'Update', sort: false, formatter: ( cell, row ) => updateRecordButton( row ) },
    { dataField: 'created_at', text: 'Created Date', sort: true, formatter: dateTimeFormatterFromString }
  ];

  return (
    <MainTable
      defaultSorted={{ dataField: 'created_at', order: 'desc' }}
      route={route}
      remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
      headings={headings}
      refreshNeeded={notificationChanged}
      updateRefreshNeeded={updateNotificationChanged}
    />
  );
};

Notifications.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default Notifications;
