import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import Container from 'react-bootstrap/Container';

import TopNav from './containers/Layout/TopNav/TopNav';
import Main from './containers/Layout/Main';
import AuthRoutes from './Routes/AuthRoutes';
import UserProfileModal from './components/Modal/UserProfileModal';
import UserCompaniesModal from './components/Modal/UserCompaniesModal';
import CreateCompanyModal from './components/Modal/CreateCompanyModal';
import * as actionTypes from './store/actions/actions';

Amplify.configure( {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
      expires: 1,
      secure: false
    }
  }
} );

Auth.configure();

const App = props => {

  const [showNewCompanyModal, updateShowNewCompanyModal] = useState( false );
  const [companyCreationComplete, updateCompanyCreationComplete] = useState( false );

  const { checkForToken } = props;

  useEffect( () => {
    checkForToken();
  }, [checkForToken] );

  const newCompanyCreated = id => {
    updateShowNewCompanyModal( false );
    props.retrieveUserData( id );
    updateCompanyCreationComplete( true );
  };

  const cancelCompanyCreation = () => {
    updateShowNewCompanyModal( false );
    props.logout();
  };

  const main = props.signedIn ?
    <Main
      isSignedIn={props.signedIn}
      currentCompanyId={props.currentCompanyId}
    /> :
    <Container className='auth-container py-5 nav-offset'>
      <AuthRoutes />
    </Container>;

  const modal = props.profileUpdateRequired || props.newUser ?
    <UserProfileModal
      newUser={props.newUser}
      onHide={props.logout}
      show={true}
      userDataUpdated={props.retrieveUserData}
      userId={props.userId}
      username={props.username}
    /> :
    props.userHasNoCompanies && !showNewCompanyModal && !companyCreationComplete ?
      <UserCompaniesModal
        userId={props.userId}
        username={props.username}
        show={true}
        onHide={props.logout}
        onStartNewCompanySetup={() => updateShowNewCompanyModal( true )}
      /> :
      showNewCompanyModal ?
        <CreateCompanyModal
          show={showNewCompanyModal}
          onHide={cancelCompanyCreation}
          onComplete={newCompanyCreated}
        /> :
        null;

  return (
    <BrowserRouter>
      <TopNav
        isSignedIn={props.signedIn}
        companyOptions={props.companies}
        currentCompanyId={props.currentCompanyId}
        changeCompanies={props.changeCompanies}
        companyAuth={{
          accounts: props.companyAuth.accounts_view,
          company: props.companyAuth.company_tab,
          customers: props.companyAuth.customers_view,
          events: props.companyAuth.events_view,
          leads: props.companyAuth.leads_view,
          schedule: props.companyAuth.schedule_view
        }}
      />
      {main}
      {modal}
    </BrowserRouter>
  );
};

App.propTypes = {
  changeCompanies: PropTypes.func.isRequired,
  checkForToken: PropTypes.func.isRequired,
  companies: PropTypes.array,
  companyAuth: PropTypes.shape( {
    accounts_view: PropTypes.number,
    company_tab: PropTypes.number,
    customers_view: PropTypes.number,
    events_view: PropTypes.number,
    leads_view: PropTypes.number,
    schedule_view: PropTypes.number
  } ),
  currentCompanyId: PropTypes.string,
  logout: PropTypes.func.isRequired,
  newUser: PropTypes.bool.isRequired,
  profileUpdateRequired: PropTypes.bool.isRequired,
  retrieveUserData: PropTypes.func.isRequired,
  signedIn: PropTypes.bool.isRequired,
  userHasNoCompanies: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  username: PropTypes.string
};

const mapStateToProps = state => ( {
  companies: state.user.companies,
  companyAuth: state.company.auth,
  currentCompanyId: state.company.id,
  newUser: state.user.new_user,
  profileUpdateRequired: state.user.profile_update_needed,
  signedIn: state.user.signed_in,
  userHasNoCompanies: state.user.no_companies,
  userId: state.user.id,
  username: state.user.username
} );

const mapDispatchToProps = dispatch => ( {
  checkForToken: () => dispatch( { type: actionTypes.CHECK_FOR_TOKEN } ),
  changeCompanies: id => dispatch( { type: actionTypes.USER_CHANGED_COMPANIES, id } ),
  logout: () => dispatch( { type: actionTypes.BEGIN_LOGOUT } ),
  retrieveUserData: () => dispatch( { type: actionTypes.USER_TOKEN_SET } )
} );

export default connect( mapStateToProps, mapDispatchToProps )( App );
