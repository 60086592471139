import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from '../../App.module.css';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { extractValues, setValuesFromResponse } from '../../helpers/functions/formValidator';
import initialCompanyFormFields from '../Company/CompanyTabs/Details/CompanyDetailsFormFields';
import { cloneDeep, createRecord } from '../../helpers/functions/utilities';
import { COMPANY } from '../../helpers/constants/initialData';
import Toasts from '../Shared/Toasts';

const CreateCompanyModal = props => {

  const [loading, updateLoading] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [companyData, updateCompanyData] = useState( setValuesFromResponse( cloneDeep( initialCompanyFormFields ), COMPANY, updateFormIsValid ) );
  const [toastData, updateToastData] = useState( [] );

  const handleSubmitCompany = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoading( true );
    try {
      const data = extractValues( companyData );
      console.log( 'company data: ', data );
      const response = await createRecord( 'companies', { company: data } );
      if ( response ) {
        updateFormSubmitted( false );
        updateFormEdited( false );
        updateToastData( cur => [...cur, { heading: 'Company Created', id: Date.now() }] );
        props.onComplete( response.id );
      }
      updateLoading( false );
    } catch ( error ) {
      updateLoading( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>Create A New Company</Modal.Header>
      <Modal.Body>
        <Form className={loading ? classes.Loading : ''}>
          <FormFieldGenerator
            formData={companyData}
            formDataInputHandler={updateCompanyData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button variant='primary' disabled={loading} type='submit' onClick={handleSubmitCompany} className='w-100'>Create Company</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
      {toasts}
    </Modal>
  );
};

CreateCompanyModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default CreateCompanyModal;
