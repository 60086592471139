import { PRODUCT_TYPES } from '../../helpers/constants/options';

export default {
  name: {
    errorMessages: {
      maxLength: { message: 'Name cannot be longer than 100 characters', key: 'fn-max' },
      minLength: { message: 'Name must be at least 2 characters', key: 'fn-min' },
      required: { message: 'Name is required', key: 'fn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Name',
    type: 'text',
    validation: {
      maxLength: 100,
      minLength: 2,
      required: true
    },
    value: ''
  },
  description: {
    errorMessages: {
      maxLength: { message: 'Description cannot be longer than 200 characters', key: 'fn-max' },
    },
    errors: [],
    isValid: false,
    label: 'Description',
    type: 'text',
    validation: {
      maxLength: 200
    },
    value: ''
  },
  type: {
    attributes: {
      options: PRODUCT_TYPES.map( el => ( { label: el, value: el } ) ),
      placeholder: 'Type',
      isClearable: true
    },
    errorMessages: {
      required: { message: 'Type is required', key: 'pt-req' }
    },
    errors: [],
    isValid: false,
    label: 'Type',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  price: {
    attributes: {
      step: 0.01
    },
    errorMessages: {
      currency: { message: 'Invalid amount provided', key: 'pp-inv' },
      required: { message: 'Price is required', key: 'pp-req' }
    },
    errors: [],
    isValid: false,
    label: 'Price',
    type: 'number',
    validation: {
      currency: true,
      required: true
    },
    value: ''
  },
  points: {
    attributes: {
      step: 1
    },
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Points',
    type: 'number',
    validation: {},
    value: ''
  },
  cost: {
    errorMessages: {
      currency: { message: 'Invalid amount provided', key: 'pc-inv' }
    },
    errors: [],
    isValid: true,
    label: 'Cost',
    mutedText: 'This value will only be displayed on internal reports and purchase orders',
    type: 'number',
    validation: {
      currency: true
    },
    value: ''
  },
  sku: {
    errorMessage: {},
    errors: [],
    isValid: true,
    label: 'SKU',
    type: 'text',
    validation: {},
    value: ''
  },
  subtype_heading: {
    element: 'Row',
    isValid: true,
    text: ''
  },
  is_fire: {
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Fire Device',
    type: 'checkbox',
    validation: {},
    value: ''
  }
};
