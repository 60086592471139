import React from 'react';
import PropTypes from 'prop-types';
import classes from './SideDrawer.module.css';

const SideDrawerToggle = props => (
  <div className={classes.SideDrawerToggleContainer}>
    <button className={classes.SideDrawerToggleButton} onClick={() => props.sideDrawerToggleHandler( cur => !cur )}>{`${props.showSideDrawer ? 'Close' : 'Open'} ${props.buttonText}`}</button>
  </div>
);

SideDrawerToggle.propTypes = {
  buttonText: PropTypes.string.isRequired,
  showSideDrawer: PropTypes.bool.isRequired,
  sideDrawerToggleHandler: PropTypes.func.isRequired
};

export default SideDrawerToggle;
