import React from 'react';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import Aux from '../../../hoc/Aux';

const ScheduleModalRowContent = props => (
  <Aux>
    <Col xs={4}>
      <p style={{ wordWrap: 'normal' }} className='mb-1'><strong>{props.name}:</strong></p>
    </Col>
    <Col xs={8}>
      <p style={{ whiteSpace: 'pre' }} className='mb-1'>{props.children}</p>
    </Col>
  </Aux>
);

ScheduleModalRowContent.propTypes = {
  children: PropTypes.oneOfType( [PropTypes.string, PropTypes.arrayOf( PropTypes.string )] ).isRequired,
  name: PropTypes.string.isRequired
};

export default ScheduleModalRowContent;
