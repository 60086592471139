import { useEffect } from 'react';
import openSocket from 'socket.io-client';

const socket = openSocket( process.env.REACT_APP_API_ADDRESS, { withCredentials: true, transports: ['websocket', 'polling'] } );

const widgetWebsocketUpdates = ( { addEventPrefix = false, removeEventPrefix = false, updateData, id, addEventCheck = false, removeEventCheck = false } ) => {
  useEffect( () => {
    if ( addEventPrefix ) {
      socket.on( `${addEventPrefix}${id}`, data => {
        if ( typeof addEventCheck === 'function' && !addEventCheck( data ) ) {
          return;
        }
        updateData( cur => {
          const index = cur.findIndex( el => el.id === data.id );
          return index === -1 ? [...cur, data] : cur.splice( index, 1, data );
        } );
      } );
    }

    if ( removeEventPrefix ) {
      socket.on( `${removeEventPrefix}${id}`, data => {
        if ( typeof removeEventCheck === 'function' && !removeEventCheck( data ) ) {
          return;
        }
        updateData( cur => {
          const copy = [...cur];
          const index = copy.findIndex( el => el.id === data.id );
          if ( index !== -1 ) {
            copy.splice( index, 1 );
          }
          return copy;
        } );
      } );
    }

    return () => {
      if ( addEventPrefix ) {
        socket.off( `${addEventPrefix}${id}` );
      }
      if ( removeEventPrefix ) {
        socket.off( `${removeEventPrefix}${id}` );
      }
    };
  }, [id] );
};

export default widgetWebsocketUpdates;
