export default {
  username: {
    attributes: {
      placeholder: 'Username',
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      maxLength: { message: 'Username cannot be longer than 20 characters', key: 'un-max' },
      minLength: { message: 'Username must be at least 6 characters', key: 'un-min' },
      required: { message: 'Username is required', key: 'un-req' }
    },
    errors: [
      { message: 'Username is required', key: 'un-req' }
    ],
    isValid: false,
    label: 'Username',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 6,
      required: true
    },
    value: ''
  }
};
