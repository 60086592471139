import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';

import Aux from '../../hoc/Aux';
import axios from '../../axios-instances/internal-api';
import {
  currencyFormatter,
  selectComponentStyles
} from '../../helpers/functions/utilities';
import './Leaderboard.css';
import classes from '../../App.module.css';

const timeframes = [
  { label: 'This Year', value: 'year' },
  { label: 'This Quarter', value: 'quarter' },
  { label: 'This Month', value: 'month' },
  { label: 'This Week', value: 'week' },
  { label: 'Today', value: 'day' },
  { label: 'Last Year', value: 'prev_year' },
  { label: 'Last Quarter', value: 'prev_quarter' },
  { label: 'Last Month', value: 'prev_month' },
  { label: 'Last Week', value: 'prev_week' },
  { label: 'Yesterday', value: 'prev_day' },
];

const criteria = [
  { label: 'Total Sales', value: 'sales' },
  { label: 'Average RMR', value: 'average_rmr' },
  { label: 'Total RMR', value: 'total_rmr' },
  { label: 'Average Equipment Price', value: 'average_equipment' },
  { label: 'Total Equipment Price', value: 'total_equipment' },
];

const LeaderBoard = () => {

  const [timeframe, updateTimeframe] = useState( timeframes[0] );
  const [selectedCriteria, updateSelectedCriteria] = useState( criteria[0] );
  const [leaderboardData, updateLeaderboardData] = useState( [] );
  const [loadingState, updateLoadingState] = useState( false );

  const headings = [
    { dataField: 'rank', text: 'Rank', sort: false, formatter: ( cell, row, rowIndex ) => rowIndex + 1 },
    { dataField: 'salesperson', text: 'Name', sort: false },
    {
      dataField: 'sales',
      text: 'Sales',
      sort: false,
      hidden: selectedCriteria.value !== 'sales'
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: false,
      hidden: !selectedCriteria.value.endsWith( '_equipment' ),
      formatter: currencyFormatter
    },
    {
      dataField: 'total',
      text: 'Total',
      sort: false,
      hidden: selectedCriteria.value !== 'total_rmr',
      formatter: currencyFormatter
    },
    {
      dataField: 'average',
      text: 'Average',
      sort: false,
      hidden: selectedCriteria.value !== 'average_rmr',
      formatter: currencyFormatter
    }
  ];

  useEffect( () => {
    updateLoadingState( true );
    updateLeaderboardData( [] );
    axios.get( `/leaderboard/${selectedCriteria.value}/${timeframe.value}` )
      .then( response => {
        if ( response.data ) {
          updateLeaderboardData( response.data );
        }
        updateLoadingState( false );
      } )
      .catch( () => {
        updateLoadingState( false );
      } );
  }, [timeframe, selectedCriteria] );

  return (
    <Aux>
      <Row className='mt-2 mb-4'>
        <div className='leaderboard-select'>
          <Select
            value={timeframe}
            options={timeframes}
            onChange={ val => updateTimeframe( val )}
            styles={selectComponentStyles}
          />
        </div>
        <div className='leaderboard-select leaderboard-criteria-select'>
          <Select
            value={selectedCriteria}
            options={criteria}
            onChange={ val => updateSelectedCriteria( val )}
            styles={selectComponentStyles}
          />
        </div>
      </Row>
      <Row>
        <Col xs={12}>
          <BootstrapTable
            bootstrap4
            bordered={true}
            columns={headings}
            data={leaderboardData}
            hover
            keyField='salesperson'
            loading={loadingState}
            noDataIndication={() => 'No Sales Records Found'}
            remote={false}
            striped
            wrapperClasses={loadingState ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
          />
        </Col>
      </Row>
    </Aux>
  );
};

export default LeaderBoard;
