import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ErrorModal from '../Modal/ErrorModal';
import initialSignInFormFields from './SignInFormFields';
import * as actionTypes from '../../store/actions/actions';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { cloneDeep } from '../../helpers/functions/utilities';
import axios from '../../axios-instances/internal-api';
import Aux from '../../hoc/Aux';
import LoadingSpinner from '../../hoc/LoadingSpinner';

const SignIn = props => {

  const history = useHistory();

  const signinFields = cloneDeep( initialSignInFormFields );
  const values = queryString.parse( history.location.search );
  const username = values.username ? values.username : false;
  const email = values.email ? values.email : false;

  if ( username && email ) {
    signinFields.username.value = username;
    signinFields.username.isValid = true;
    signinFields.username.errors = [];
  }

  /** signin?email=test123%40test.com&username=testUser
   * this will be passed if a pending_user attempts to follow the link from the email,
   * then enters their existing username and follows the link from the modal.
   */

  const [formData, updateFormData] = useState( signinFields );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [showErrorModal, updateShowErrorModal] = useState( false );
  const [errorMessage, updateErrorMessage] = useState( '' );
  const [pendingEmail, updatePendingEmail] = useState( email );
  const [existingUsername, updateExistingUsername] = useState( username );
  const [errorMessageTitle, updateErrorMessageTitle] = useState( 'Error signing in' );
  const [loadingState, updateLoadingState] = useState( false );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      return;
    }
    updateLoadingState( true );
    try {
      const username = formData.username.value;
      const password = formData.password.value;
      let response = await Auth.signIn( username, password );
      updateLoadingState( false );
      if ( response.hasOwnProperty( 'attributes' ) && response.attributes.hasOwnProperty( 'email_verified' ) && !response.attributes.email_verified ) {
        history.push( `/verifyEmail?email=${encodeURIComponent( response.attributes.email )}` );
      } else {
        if ( pendingEmail && existingUsername && existingUsername === username ) {
          const response = await axios.post( '/completeSignup/existing', { pendingEmail, existingUsername } );
          if ( response.status !== 200 ) {
            let message = response.response.data;
            if ( message.toLowerCase().startsWith( 'duplicate' ) ) {
              message = 'You are already associated with company you are attempting to join.';
            }
            updateErrorMessage( message );
            updateErrorMessageTitle( 'Error adding you to the new company' );
            updateShowErrorModal( true );
            updateLoadingState( false );
            return;
          }
        }
        redirectAfterLogin();
      }
    } catch ( error ) {
      console.log( 'error signing in', error );
      updateLoadingState( false );
      updateErrorMessageTitle( 'Error signing in' );
      if ( error.code === 'UserNotConfirmedException' ) {
        updateErrorMessage( 'You must verify your account to continue.  You will be redirected in 3 seconds' );
        updateShowErrorModal( true );
        setTimeout( () => {
          history.push( `/verify?username=${encodeURIComponent( formData.username.value )}` );
        }, 3000 );
      } else if ( error.message ) {
        updateErrorMessage( error.message );
        updateShowErrorModal( true );
      }
    }
  };

  const closeErrorModal = () => {
    updateShowErrorModal( false );
    if ( pendingEmail && existingUsername ) {
      updatePendingEmail( false );
      updateExistingUsername( false );
      redirectAfterLogin();
    }
  };

  const redirectAfterLogin = () => {
    const path = window.location.pathname;
    props.retrieveUserData();
    if ( path.includes( '/signin' ) || path.includes( '/logout' ) ) {
      history.push( '/home' );
    } else {
      history.push( path );
    }
  };

  return loadingState ? <LoadingSpinner loading={true} /> : (
    <Aux>
      <Form onSubmit={handleSubmit} noValidate>
        <FormFieldGenerator
          formData={formData}
          formDataInputHandler={updateFormData}
          formSubmitted={formSubmitted}
          updateFormIsValid={updateFormIsValid}
        />
        <Form.Row className='justify-content-between flex-column-reverse flex-sm-row pt-2'>
          <Col xs={8} sm={9} className='my-auto'>
            Don&#39;t have an account? <Link to='/signup/'>Sign Up</Link>
          </Col>
          <Form.Group as={Col} controlId="submit" xs={12} sm={3} className='d-flex justify-content-start justify-content-sm-end'>
            <Button variant="primary" type="submit" className='b-width'>
              Sign In
            </Button>
          </Form.Group>
        </Form.Row>
        <Form.Row className='justify-content-start'>
          <Col xs={12}>
            <Link to='/passwordreset/'>Forgot your password?</Link>
          </Col>
        </Form.Row>
      </Form>
      <ErrorModal
        show={showErrorModal}
        onHide={closeErrorModal}
        message={errorMessage}
      >
        {errorMessageTitle}
      </ErrorModal>
    </Aux>
  );
};

SignIn.propTypes = {
  retrieveUserData: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ( {
  retrieveUserData: () => dispatch( { type: actionTypes.USER_TOKEN_SET } )
} );

export default connect( null, mapDispatchToProps ) ( SignIn );
