import React from 'react';
import PropTypes from 'prop-types';

import classes from './SideDrawer.module.css';
import Timeline from '../../../components/SideDrawerTimeline/Timeline';
import SideDrawerFilterContainer from '../../../components/SideDrawerFilterContainer/SideDrawerFilterContainer';

const SideDrawer = props => {

  let sideDrawerClasses = [classes.SideDrawer, classes.Close];
  if ( props.showSideDrawer ) {
    sideDrawerClasses = [classes.SideDrawer, classes.Open];
  }
  let location = props.pathname.substring( 1 ).replace( /\//g, ' ' ).trim();
  const wrapperClasses = sideDrawerClasses.join( ' ' );
  const showTimeline = props.pathname === '/profile' ||
    ( props.pathMatch && ( ( props.pathMatch.params.name === 'company' && props.pathMatch.params.id === 'details' ) || props.pathMatch.params.id.length > 30 ) );
  return showTimeline ?
    <Timeline
      companyId={props.companyId}
      location={location}
      showSideDrawer={props.showSideDrawer}
      sideDrawerToggleHandler={props.sideDrawerToggleHandler}
      userId={props.userId}
      wrapperClasses={wrapperClasses}
    /> :
    <SideDrawerFilterContainer
      location={location}
      showSideDrawer={props.showSideDrawer}
      sideDrawerToggleHandler={props.sideDrawerToggleHandler}
      wrapperClasses={wrapperClasses}
    />;
};

SideDrawer.propTypes = {
  companyId: PropTypes.string.isRequired,
  historyAuth: PropTypes.shape( {
    accounts_history: PropTypes.number,
    customers_history: PropTypes.number,
    details_history: PropTypes.number,
    events_history: PropTypes.number,
    groups_history: PropTypes.number,
    lead_providers_history: PropTypes.number,
    leads_history: PropTypes.number,
    packages_history: PropTypes.number,
    products_history: PropTypes.number,
    users_history: PropTypes.number
  } ),
  pathMatch: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  showSideDrawer: PropTypes.bool.isRequired,
  sideDrawerToggleHandler: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

export default SideDrawer;
