import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import Aux from '../../hoc/Aux';
import initialUserEmailChangedVerificationFormFields from '../Users/UserEmailChangedVerificationFormFields';
import { cloneDeep } from '../../helpers/functions/utilities';
import { extractValues } from '../../helpers/functions/formValidator';
import UserEmailChangedVerificationModal from '../Modal/UserEmailChangeVerificationModal';
import SuccessModal from '../Modal/SuccessModal';
import * as actionTypes from '../../store/actions/actions';
import LoadingSpinner from '../../hoc/LoadingSpinner';

const VerifyEmail = props => {

  const [formData, updateFormData] = useState( cloneDeep( initialUserEmailChangedVerificationFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [showEmailVerificationModal, updateShowEmailVerificationModal] = useState( true );
  const [showSuccessModal, updateShowSuccessModal] = useState( false );
  const [loadingState, updateLoadingState] = useState( false );

  const values = queryString.parse( props.location.search );
  const email = values.email ? values.email : '';

  const history = useHistory();

  const redirect = () => {
    props.retrieveUserData();
    history.push( '/home' );
  };

  if ( email === '' ) {
    redirect();
  }

  const submitVerificationCode = async () => {
    updateFormSubmitted( true );
    const { code } = extractValues( formData );
    if ( formIsValid && formEdited ) {
      updateLoadingState( true );
      try {
        await Auth.verifyCurrentUserAttributeSubmit( 'email', code );
        updateShowEmailVerificationModal( false );
        updateFormSubmitted( false );
        updateFormEdited( false );
        updateLoadingState( false );
        updateShowSuccessModal( true );
      } catch ( e ) {
        updateLoadingState( false );
        updateFormData( cur => {
          const updatedData = { ...cur };
          const code = { ...updatedData.code };
          code.isValid = false;
          code.errors = [{ message: e.message, key: 'amp-er' }];
          updatedData.code = code;
          return updatedData;
        } );
      }
    }
  };

  return loadingState ? <LoadingSpinner loading={true} /> : (
    <Aux>
      <UserEmailChangedVerificationModal
        show={showEmailVerificationModal}
        confirm={submitVerificationCode}
        email={email}
        onHide={redirect}
        formSubmitted={formSubmitted}
        updateFormIsValid={updateFormIsValid}
        updateFormEdited={updateFormEdited}
        updateValidationData={updateFormData}
        validationData={formData}
        cancelButtonText='Remind Me Next Time'
      />
      <SuccessModal
        show={showSuccessModal}
        onHide={redirect}
        message='Your Email has been successfully verified'
      />
    </Aux>
  );
};

VerifyEmail.propTypes = {
  location: PropTypes.object.isRequired,
  retrieveUserData: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ( {
  retrieveUserData: () => dispatch( { type: actionTypes.USER_TOKEN_SET } )
} );

export default connect( null, mapDispatchToProps ) ( withRouter( VerifyEmail ) );
