import * as actionTypes from '../actions/actions';
import { updateObject, initialCompanyAuthState } from '../utilities';

const initialCompanyState = {
  auth: initialCompanyAuthState,
  customFields: {},
  id: null,
  name: null
};

const setCompanyData = ( state, action ) => {
  const { payload } = action;
  const { name, id, custom_fields, auth } = payload;
  return {
    ...state,
    auth,
    customFields: custom_fields,
    id,
    name
  };
};

const updateCompanyAuth = ( state, action ) => {
  const { payload } = action;
  const updatedAuth = updateObject( state.auth, payload );
  return updateObject( state, { auth: updatedAuth } );
};

const reducer = ( state = initialCompanyState, action ) => {
  switch ( action.type ) {
    case actionTypes.SET_COMPANY_DATA :
      return setCompanyData( state, action );
    case actionTypes.USER_LOGGED_OUT :
      return initialCompanyState;
    case actionTypes.UPDATE_COMPANY_AUTH :
      return updateCompanyAuth( state, action );
    default:
      return state;
  }
};

export default reducer;
