import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

import { cloneDeep, createRecord, updateRecord } from '../../../helpers/functions/utilities';
import { extractValues } from '../../../helpers/functions/formValidator';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import initialNoteModalFormFields from './NoteModalFormFields';
import classes from '../../../App.module.css';

const NoteModal = props => {

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [formFields, updateFormFields] = useState( cloneDeep( initialNoteModalFormFields ) );
  const [loadingState, updateLoadingState] = useState( false );

  useEffect( () => {
    const defaultNotifiedUsers = props.note.notifications && props.note.notifications.users ?
      props.note.notifications.users : [];
    const defaultMessage = props.note.message ? props.note.message : '';

    updateFormEdited( false );
    updateFormSubmitted( false );
    updateFormIsValid( false );
    updateFormFields( cur => ( {
      notifications: {
        ...cur.notifications,
        value: defaultNotifiedUsers
      },
      message: {
        ...cur.message,
        value: defaultMessage
      }
    } ) );
  }, [props.show, props.note.message, props.note.notifications] );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formEdited ) {
      console.log( 'form has not been edited' );
      props.sendMessageHandler( 'Invalid input(s).  Check fields for errors.' );
      return;
    }
    if ( !formIsValid ) {
      console.log( 'form is invalid' );
      props.sendMessageHandler( 'Invalid input(s).  Check fields for errors.' );
      return;
    }
    updateLoadingState( true );
    try {
      const { message, notifications } = extractValues( formFields );
      if ( props.note.id ) {
        const response = await updateRecord( `notes/${props.note.id}`, { note: { message }, notifications } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          props.onComplete();
        }
      } else {
        const response = await createRecord( props.route, { note: { message }, notifications } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          props.onComplete();
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      props.sendMessageHandler( 'Error saving note, please try again.' );
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>{props.note.id ? 'Edit Note' : 'Create Note'}</Modal.Header>
      <Modal.Body>
        <Form className={loadingState ?  classes.Loading : ''}>
          <FormFieldGenerator
            formData={formFields}
            formDataInputHandler={updateFormFields}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button variant='primary' type='submit' className='w-100' onClick={handleSubmit} disabled={loadingState}>Save Note</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

NoteModal.propTypes = {
  note: PropTypes.shape( {
    id: PropTypes.string,
    message: PropTypes.string,
    notifications: PropTypes.shape( {
      users: PropTypes.arrayOf( PropTypes.shape( {
        label: PropTypes.string,
        value: PropTypes.string
      } ) )
    } )
  } ),
  onComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  sendMessageHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default NoteModal;
