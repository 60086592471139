import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import {
  disableFormFields,
  getSingleRecord,
  updateRecord,
  createRecord,
  storePrevious
} from '../../helpers/functions/utilities';
import CustomerAccountsCollapse from './CustomerAccountsCollapse';
import ComponentNotes from '../Shared/ComponentNotes';
import initialCustomerFormFields from './CustomerFormFields';
import { extractValues, setValuesFromResponse } from '../../helpers/functions/formValidator';
import Aux from '../../hoc/Aux';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { cloneDeep, convertCustomFieldsForDisplay } from '../../helpers/functions/utilities';
import { CUSTOMER } from '../../helpers/constants/initialData';
import Toasts from '../Shared/Toasts';
import Watchlist from '../Shared/Watchlist';

const Customer = props => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [showAccounts, updateShowAccounts] = useState( false );
  const [customerData, updateCustomerData] = useState( cloneDeep( { ...initialCustomerFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [customerAccounts, updateCustomerAccounts] = useState( [] );
  const [recordPermissions, updateRecordPermissions] = useState( { customers_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Customer</h3> : <Watchlist recordId={id} recordType='customers' />;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.customers_update ) {
      updateCustomerData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getCustomer = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateCustomerData( cur => setValuesFromResponse( cur, { ...CUSTOMER }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `customers/${id}` );
        if ( response ) {
          updateCustomerData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateCustomerAccounts( response.accounts );
          updateShowAccounts( true );
          const { customers_update } = response.user_auth;
          updateRecordPermissions( { customers_update } );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getCustomer()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async ( e ) => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const customer = { ...extractValues( customerData ) };
      if ( id === 'new' ) {
        const response = await createRecord( 'customers', { customer } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `customers/${id}`, { customer } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const showAccountsToggle = () => {
    updateShowAccounts( state => !state );
  };

  const accountSelectionHandler = ( e, row ) => {
    if ( row.accounts_view ) {
      history.push( `/accounts/${row.id}/details` );
    } else {
      updateToastData( cur => [...cur, { heading: 'Restricted', body: 'You do not have access to view this account', id: Date.now() }] );
    }
  };

  const collapsible = id === 'new' ? null :
    <Aux>
      <CustomerAccountsCollapse
        showAccounts={showAccounts}
        showAccountsHandler={showAccountsToggle}
        accountSelectionHandler={accountSelectionHandler}
        customerAccounts={customerAccounts}
      />
      <ComponentNotes id={id} component='customers' />
    </Aux>;

  const customerUpdateButton = recordPermissions.customers_update ?
    <Form.Row className='pt-3'>
      <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      {heading}
      <Container>
        <LoadingSpinner loading={loadingState}>
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''}>
            <FormFieldGenerator
              formData={customerData}
              formDataInputHandler={updateCustomerData}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
              formEditedHandler={updateFormEdited}
            />
            {customerUpdateButton}
          </Form>
        </LoadingSpinner>
      </Container>
      {collapsible}
      {toasts}
    </Aux>
  );
};

Customer.propTypes = {
  customFields: PropTypes.array.isRequired
};

export default Customer;
