import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import Async from 'react-select/async';
import PropTypes from 'prop-types';
import cellEditFactory from 'react-bootstrap-table2-editor';

import AddButton from '../Shared/AddButton';
import Aux from '../../hoc/Aux';
import '../Tables/Tables.css';
import {
  getSingleRecord,
  search,
  selectComponentStyles
} from '../../helpers/functions/utilities';
import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';

const ProductSearchWithTable = props => {

  const [loadingState, updateLoadingState] = useState( false );
  const [selectedProduct, updateSelectedProduct] = useState( {} );

  const addProductHandler = async () => {
    updateLoadingState( true );
    const existingProductIndex = props.products.findIndex( el => el.product_id === selectedProduct.value && !el.from_package );
    if ( existingProductIndex === -1 ) {
      const response = await getSingleRecord( `${props.routePrefix}/${selectedProduct.value}` );
      if ( response ) {
        delete response.user_auth;
        props.updateProducts( cur => [...cur, { ...response, quantity: 1, id: `new_${response.id}` }] );
      }
      updateLoadingState( false );
    } else {
      props.updateProducts( cur => {
        return cur.map( ( prod, i ) => {
          if ( i === existingProductIndex ) {
            prod.quantity++;
          }
          return prod;
        } );
      } );
      updateLoadingState( false );
    }
    updateSelectedProduct( {} );
    props.updateFormEdited( true );
  };

  const quantityUpdated = ( oldValue, newValue ) => {
    if ( oldValue !== newValue ) {
      props.updateFormEdited( true );
    }
  };

  const productSearch = selectedProduct.value ?
    <Select
      isClearable
      value={selectedProduct}
      options={[selectedProduct]}
      onChange={() => updateSelectedProduct( {} )}
      styles={selectComponentStyles}
    /> :
    <Async
      cacheOptions
      value={selectedProduct.value}
      noOptionsMessage={() => 'No Equipment Found'}
      loadOptions={( val, cb ) => search( val, cb, ['products'] )}
      onChange={val => updateSelectedProduct( val )}
      loadingMessage={() => 'Getting Equipment...'}
      placeholder='Search for a product'
      styles={selectComponentStyles}
    />;

  const searchRow = props.hideSearch ? null :
    <Row>
      <Col xs={12} sm={8} md={6} className='pb-2'>
        {productSearch}
      </Col>
      <Col xs={12} sm={4} md='auto' className='pb-3'>
        <AddButton size='28px' text='Add' clickHandler={addProductHandler} disabled={!selectedProduct.value} />
      </Col>
    </Row>;

  return (
    <Aux>
      <LoadingSpinner loading={loadingState}>
        {searchRow}
        <BootstrapTable
          bootstrap4
          bordered={false}
          cellEdit={ cellEditFactory( { mode: 'click', blurToSave: true, afterSaveCell: quantityUpdated  } ) }
          classes='ClickableTableRows TableComponent'
          columns={ props.headings }
          data={props.products}
          defaultSorted={[{
            dataField: 'created_at',
            order: 'asc'
          }]}
          hover
          keyField='id'
          noDataIndication={() => 'No Equipment Found'}
          remote={false}
          striped
          wrapperClasses={loadingState ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
        />
      </LoadingSpinner>
    </Aux>
  );
};

ProductSearchWithTable.propTypes = {
  headings: PropTypes.array.isRequired,
  hideSearch: PropTypes.bool,
  products: PropTypes.array.isRequired,
  routePrefix: PropTypes.string.isRequired,
  updateFormEdited: PropTypes.func.isRequired,
  updateProducts: PropTypes.func.isRequired,
};

export default ProductSearchWithTable;
