import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import queryString from 'query-string';

import ErrorModal from '../Modal/ErrorModal';
import initialSignUpFormFields from './SignUpFormFields';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { cloneDeep } from '../../helpers/functions/utilities';
import axios from '../../axios-instances/internal-api';
import Aux from '../../hoc/Aux';
import LoadingSpinner from '../../hoc/LoadingSpinner';

const SignUp = () => {

  const history = useHistory();
  const values = queryString.parse( history.location.search );
  const emailString = values.email ? values.email : false;
  const signupFormFields = cloneDeep( initialSignUpFormFields );
  let email = false;
  if ( emailString ) {
    email = emailString.trim();
    signupFormFields.email.value = email;
    signupFormFields.email.attributes.disabled = true;
    signupFormFields.email.isValid = true;
    signupFormFields.email.validation = {};
    signupFormFields.email.errors = [];
  }

  const [formData, updateFormData] = useState( signupFormFields );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [showErrorModal, updateShowErrorModal] = useState( false );
  const [errorMessage, updateErrorMessage] = useState( '' );
  const [emailParam] = useState( email );
  const [loadingState, updateLoadingState] = useState( false );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      return;
    }
    updateLoadingState( true );
    const data = {
      username: formData.username.value,
      password: formData.password.value,
      attributes: {
        email: emailParam ? emailParam : formData.email.value
      }
    };

    try {
      const user = await Auth.signUp( data );
      if ( user && !user.userConfirmed ) {
        if ( emailParam ) {
          const response = await axios.post( 'completeSignup', { email: emailParam, username: data.username } );
          if ( response.hasOwnProperty( 'data' ) && response.data.hasOwnProperty( 'error' ) ) {
            updateErrorMessage( response.data.error );
            updateLoadingState( false );
            updateShowErrorModal( true );
            return;
          }
        }
        history.push( `/verify?username=${encodeURIComponent( formData.username.value )}` );
      }
    } catch ( error ) {
      console.log( 'error signing up:', error );
      updateErrorMessage( error.message );
      updateLoadingState( false );
      updateShowErrorModal( true );
      if ( error.code === 'UsernameExistsException' ) {
        updateFormData( cur => {
          const updatedData = { ...cur };
          const user = { ...updatedData.username };
          user.isValid = false;
          user.errors = [{ message: 'Username already in use, please choose a different name', key: 'un-unq' }];
          updatedData.username = user;
          return updatedData;
        } );
        if ( emailString ) {
          updateErrorMessage( <Container>
            <Row>
              <p className='mx-auto'>{error.message}</p>
            </Row>
            <Row>
              <span>Is this you?&nbsp;</span>
              <Link to={`/signin?email=${encodeURIComponent( emailString )}&username=${encodeURIComponent( formData.username.value )}`}>Sign In</Link>
              <span>&nbsp;to add the company to your existing account</span>
            </Row>
          </Container> );
        }
      }
    }
  };

  return loadingState ? <LoadingSpinner loading={true} /> : (
    <Aux>
      <Form onSubmit={handleSubmit} noValidate>
        <FormFieldGenerator
          formData={formData}
          formDataInputHandler={updateFormData}
          updateFormIsValid={updateFormIsValid}
          formSubmitted={formSubmitted}
        />
        <Form.Row className='justify-content-between flex-column-reverse flex-sm-row pt-2'>
          <Col xs={7} sm={8} className='my-auto'>
            Have an account? <Link to='/signin/'>Sign In</Link>
          </Col>
          <Form.Group as={Col} controlId="submit" xs={12} sm={4} className='d-flex justify-content-start justify-content-sm-end'>
            <Button variant="primary" type="submit" className='b-width'>
              Sign Up
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
      <ErrorModal
        show={showErrorModal}
        onHide={() => updateShowErrorModal( false )}
        message={errorMessage}
      >
        Error creating account
      </ErrorModal>
    </Aux>
  );
};

export default SignUp;
