import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LeftItems from './LeftNavItems';
import RightItems from './RightNavItems';
import getWindowSize from '../getWindowSize';
import classes from './TopNav.module.css';

const TopNav = props => {

  const { width } = getWindowSize();

  const [expanded, updateExpanded] = useState( false );

  const navToggleHandler = () => {
    if ( width < 768 ) {
      updateExpanded( cur => !cur );
    }
  };

  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" fixed="top" className="w-100 top-nav py-2 px-0" expanded={expanded}>
      <Nav className={`flex-row justify-content-between justify-content-lg-start ${classes.MobileLogoSpacing}`}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='ml-3' onClick={navToggleHandler} />
        <Navbar.Brand className='mr-0 ml-3'>
          <Link to="/home"><img src='/logo.svg' alt="" height={60} className={classes.LogoSize} /></Link>
        </Navbar.Brand>
      </Nav>
      <Navbar.Collapse id="responsive-navbar-nav" className={classes.CollapsibleContainer}>
        <Nav className="mx-auto bg-dark pl-3 pl-md-0">
          <LeftItems
            isSignedIn={props.isSignedIn}
            linkSelectedHandler={navToggleHandler}
            companyAuth={props.companyAuth}
          />
        </Nav>
      </Navbar.Collapse>
      <Nav className={`d-flex flex-row ${classes.MobileMarginLeft}`}>
        <RightItems
          changeCompanies={props.changeCompanies}
          companyOptions={props.companyOptions}
          currentCompanyId={props.currentCompanyId}
          isSignedIn={props.isSignedIn}
        />
      </Nav>
    </Navbar>
  );
};

TopNav.propTypes = {
  changeCompanies: PropTypes.func.isRequired,
  companyAuth: PropTypes.shape( {
    accounts: PropTypes.number,
    company: PropTypes.number,
    customers: PropTypes.number,
    events: PropTypes.number,
    leads: PropTypes.number,
    schedule: PropTypes.number
  } ),
  companyOptions: PropTypes.array,
  currentCompanyId: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
};

export default TopNav;
