import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PropTypes from 'prop-types';

import MainTable from '../Tables/MainTable';
import AddButton from '../Shared/AddButton';
import Aux from '../../hoc/Aux';
import DeleteModal from '../Modal/DeleteModal';
import {
  addressFormatter,
  dateTimeFormatterFromString,
  tableDeleteButton,
  tableEditButton,
  tableDeleteHandler,
  confirmDeleteHandler,
  closeDeleteModalHandler, addressColumnFormatter
} from '../../helpers/functions/utilities';

const route = 'events';

const Events = props => {

  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [eventDeleted, updateEventDeleted] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, route ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'type', text: 'Type', sort: true },
    { dataField: 'event_status', text: 'Event Status', sort: true },
    {
      dataField: 'start_at',
      text: 'Scheduled Date/Time',
      sort: true,
      formatter: ( cell, row ) => (
        `${dateTimeFormatterFromString( row.start_at )} ${moment.isMoment( row.end_at ) ? 
          ` - ${dateTimeFormatterFromString( row.end_at )}` :
          '' }`
      )
    },
    { dataField: 'user_first_name', text: 'User Name', sort: true, formatter: ( cell, row ) => `${row.user_first_name} ${row.user_last_name}` },
    {
      dataField: 'property_address1',
      text: 'Property Address',
      sort: true,
      formatter: ( cell, row ) => addressFormatter( row ),
      classes: addressColumnFormatter
    },
    { dataField: 'created_at', text: 'Date Created', sort: true, formatter: dateTimeFormatterFromString },
    { dataField: 'updated_at', text: 'Date Last Updated', sort: true, formatter: dateTimeFormatterFromString },
  ];

  if ( props.auth.del ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: ( cell, row ) => tableDeleteButton( () => tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal ) )
    } );
  }

  const addButton = props.auth.create ?
    <Row className='py-3 justify-content-start'>
      <Col xs={12} sm={{ span: 'auto', offset: 1 }}>
        <AddButton route={route} size='28px' />
      </Col>
    </Row> :
    null;

  return (
    <Aux>
      {addButton}
      <MainTable
        headings={ headings }
        route={route}
        remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
        refreshNeeded={eventDeleted}
        updateRefreshNeeded={updateEventDeleted}
      />
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.type}.</p><p>If a user is assigned they will be notified -- @todo.</p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updateEventDeleted )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
    </Aux>
  );
};

Events.propTypes = {
  auth: PropTypes.shape( {
    create: PropTypes.number,
    del: PropTypes.number
  } )
};

export default Events;
