import { put, call, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import * as actionTypes from '../actions/actions';
import axios from '../../axios-instances/internal-api';

function* checkAuthState () {
  const token = document.cookie.split( ';' ).some( ( item ) => item.trim().includes( 'accessToken=' ) );
  console.log( 'token check: ', token );
  if ( token ) {
    try {
      // get user data from db
      const userData = yield call( getUserDataSaga );
      console.log( 'user data: ', userData );
      if ( userData.data.new_user ) {
        // if userData is empty array, they should be shown a modal where they create their profile
        yield put( {
          type: actionTypes.USER_PROFILE_NOT_FOUND,
          payload: { ...userData.data }
        } );
      } else if ( userData.data.profile_update_needed ) {
        // if they need to update their profile, this should be the priority before anything else
        yield put( {
          type: actionTypes.USER_PROFILE_INCOMPLETE,
          payload: { ...userData.data }
        } );
      } else if ( userData.data.companies.length === 0 ) {
        // if they are not part of any companies, they should be shown a modal explaining that they need to provide their username to the company to continue, then logout on confirm
        yield put( {
          type: actionTypes.USER_HAS_NO_COMPANIES,
          payload: { ...userData.data, no_companies: true, new_user: false }
        } );
      } else {
        // otherwise get their current companies data
        yield call( changeCompanies, { id: userData.data.current_company_id ? userData.data.current_company_id : userData.data.companies[0].id } );
        // update state with user and token
        yield put( {
          type: actionTypes.SET_USER_DATA,
          payload: { ...userData.data, new_user: false }
        } );
        // trigger use effect in App.js
        yield put( {
          type: actionTypes.USER_SIGN_IN_SUCCESS
        } );
        // continue to route unless it is /signin, in which case push to /home
      }
    } catch ( error ) {
      console.log( 'error: ', error );
      yield call( logout );
    }
  } else {
    // clear all cookies just to be safe
    yield call( logout );
  }
}

function* logout () {
  yield Auth.signOut();
  yield put( {
    type: actionTypes.USER_LOGGED_OUT
  } );
}

function* changeCompanies ( { id = null } ) {
  try {
    const companyData = yield call( getCompanyDataSaga, id );
    axios.defaults.headers.common['Company'] = id;
    // update state with company data
    yield put( {
      type: actionTypes.SET_COMPANY_DATA,
      payload: companyData.data
    } );
  } catch ( e ) {
    console.log( 'error retrieving company data: ', e );
  }
}

function getUserDataSaga () {
  return axios.get( '/users/userData' );
}

function getCompanyDataSaga ( companyId ) {
  return axios.get( `/companies/${companyId}/data` );
}

export function* loginWatcherSaga () {
  yield takeLatest( actionTypes.USER_TOKEN_SET, checkAuthState );
  yield takeLatest( actionTypes.CHECK_FOR_TOKEN, checkAuthState );
  yield takeLatest( actionTypes.BEGIN_LOGOUT, logout );
  yield takeLatest( actionTypes.USER_CHANGED_COMPANIES, changeCompanies );
}
