export default {
  notifications: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Users...',
      noOptionsMessage: () => 'No User Found',
      placeholder: 'Search for a user',
      isMulti: true
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {},
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Notify Users',
    type: 'async-multi-select',
    validation: {},
    value: []
  },
  message: {
    attributes: {
      rows: 8
    },
    css: {
      className: 'd-flex flex-column',
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      required: { message: 'Message is required', key: 'mg-req' }
    },
    errors: [],
    isValid: false,
    label: 'Message',
    type: 'textarea',
    validation: {
      required: true
    },
    value: ''
  }
};
