import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import Event from '../Events/Event';

const EventModal = props => {

  const accountEventUpdated = () => {
    props.refreshAccountEvents( true );
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Event
          accountId={props.accountId}
          accountAddress={props.accountAddress}
          accountEventUpdatedHandler={accountEventUpdated}
          customFields={props.customFields}
          eventId={props.eventId}
        />
      </Modal.Body>
    </Modal>
  );

};

EventModal.propTypes = {
  accountAddress: PropTypes.string,
  accountId: PropTypes.string,
  customFields: PropTypes.array.isRequired,
  eventId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  refreshAccountEvents: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default EventModal;
