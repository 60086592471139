import React, { useState } from 'react';
import Select from 'react-select';
import Async from 'react-select/async/dist/react-select.esm';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';

import Aux from '../../hoc/Aux';
import {
  search,
  selectComponentStyles
} from '../../helpers/functions/utilities';


const AsyncSelectFilter = props => {

  const [value, updateValue] = useState( null );

  const onChange = val => {
    console.log( 'value: ', val );
    updateValue( val );
    const newVal = val == null ? val : val.value;
    props.onChangeHandler( props.id, 'value', newVal );
    props.onChangeHandler( props.id, 'filter', newVal ? 'Is ID' : 'All' );
  };

  const input = value ?
    <Select
      id={props.id + '_filter'}
      isClearable
      value={value}
      options={[value]}
      onChange={() => onChange( null )}
      styles={selectComponentStyles}
      clearable
    /> :
    <Async
      cacheOptions
      isClearable
      loadingMessage={() => `Getting ${props.label}s...`}
      noOptionsMessage={() => `No ${props.label}s Found`}
      placeholder='All'
      id={props.id + '_filter'}
      value={value}
      loadOptions={( val, cb ) => search( val, cb, props.getOptionsParams )}
      onChange={onChange}
      styles={selectComponentStyles}
    />;

  return (
    <Aux>
      <Form.Row className="align-items-start">
        <Form.Group controlId={props.id + '_expand'}>
          <Form.Check
            id={props.id + '_expand'}
            type="switch"
            label={props.label}
            onChange={() => props.onChangeHandler( props.id, 'show', !props.expand )}
          />
        </Form.Group>
      </Form.Row>
      <Collapse in={props.expand}>
        <Form.Row>
          <Form.Group as={Col} xs={12}>
            {input}
          </Form.Group>
        </Form.Row>
      </Collapse>
    </Aux>
  );
};

AsyncSelectFilter.propTypes = {
  getOptionsParams: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default AsyncSelectFilter;
