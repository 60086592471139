import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import paginationFactory from 'react-bootstrap-table2-paginator';

import '../Tables/Tables.css';
import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';

const ReportResultsTable = props => {

  const [currentPage, updateCurrentPage] = useState( 1 );
  const [currentSizePerPage, updateCurrentSizePerPage] = useState( 25 );

  const sizePerPageChangeHandler = ( sizePerPage, page ) => {
    updateCurrentSizePerPage( sizePerPage );
    updateCurrentPage( page );
  };

  const pageChangeHandler = ( page, sizePerPage ) => {
    updateCurrentSizePerPage( sizePerPage );
    updateCurrentPage( page );
  };

  const paginationData = {
    page: currentPage, // Specify the current page. It's necessary when remote is enabled
    sizePerPage: currentSizePerPage, // Specify the size per page. It's necessary when remote is enabled
    totalSize: props.data.length, // Total data size. It's necessary when remote is enabled
    paginationSize: 5,  // the pagination bar size, default is 5
    showTotal: true, // display pagination information
    sizePerPageList: [10, 25, 50, 100, { text: 'All', value: props.data.length }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
    withFirstAndLast: true, // hide the going to first and last page button
    alwaysShowAllBtns: false, // always show the next and previous page button
    firstPageText: 'First', // the text of first page button
    prePageText: 'Prev', // the text of previous page button
    nextPageText: 'Next', // the text of next page button
    lastPageText: 'Last', // the text of last page button
    nextPageTitle: 'Go to next', // the title of next page button
    prePageTitle: 'Go to previous', // the title of previous page button
    firstPageTitle: 'Go to first', // the title of first page button
    lastPageTitle: 'Go to last', // the title of last page button
    hideSizePerPage: false, // hide the size per page dropdown
    hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
    onPageChange: pageChangeHandler, // callback function when page was changing
    onSizePerPageChange: sizePerPageChangeHandler, // callback function when page size was changing
    //paginationTotalRenderer: (from, to, size) => { ... }  // custom the pagination total
  };

  const getExpandableHeadings = () => {
    return props.expandableHeadings;
  };

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: row => {
      const columns = getExpandableHeadings();
      console.log( 'columns: ', columns );
      return (
        <BootstrapTable
          bootstrap4
          bordered={false}
          classes='TableComponent'
          columns={columns}
          data={row['_nesting_']}
          hover
          keyField='id'
          noDataIndication={() => 'No results found matching the selected criteria'}
          remote={false}
          striped
        />
      );
    },
    showExpandColumn: true
  };

  return (
    <LoadingSpinner loading={props.loadingState || !props.headings.length}>
      <BootstrapTable
        bootstrap4
        bordered={false}
        classes='TableComponent'
        columns={props.headings}
        data={props.data}
        // defaultSorted={[{
        //   dataField: 'created_at',
        //   order: 'asc'
        // }]}
        pagination={ paginationFactory( paginationData ) }
        hover
        keyField='id'
        noDataIndication={() => 'No results found matching the selected criteria'}
        remote={false}
        striped
        expandRow={props.expandable ? expandRow : {}}
        wrapperClasses={props.loadingState || !props.headings.length ? `TableWrapper ${classes.Loading}` : 'TableWrapper'}
      />
    </LoadingSpinner>
  );
};

ReportResultsTable.propTypes = {
  expandable: PropTypes.bool.isRequired,
  expandableHeadings: PropTypes.array.isRequired,
  headings: PropTypes.array.isRequired,
  loadingState: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired
};

export default ReportResultsTable;
