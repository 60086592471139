import { FIELD_TYPES } from '../../../../helpers/constants/options';

export default {
  field_name: {
    errorMessages: {
      maxLength: { message: 'Field Name cannot be longer than 40 characters', key: 'fn-max' },
      minLength: { message: 'Field Name must be at least 2 characters', key: 'fn-min' },
      required: { message: 'Field Name is required', key: 'fn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Field Name',
    mutedText: 'Field Name must be unique',
    type: 'text',
    validation: {
      maxLength: 40,
      minLength: 2,
      required: true
    },
    value: ''
  },
  field_type: {
    attributes: {
      options: FIELD_TYPES.map( el => ( { label: el, value: el } ) )
    },
    errorMessages: {
      required: { message: 'Field Type is required', key: 'ft-req' }
    },
    errors: [],
    isValid: false,
    label: 'Field Type',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  required: {
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Required',
    type: 'checkbox',
    validation: {},
    value: ''
  },
  minLength: {
    displayCondition: 'data.field_type.value.value === "text"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Minimum Length',
    type: 'number',
    validation: {},
    value: ''
  },
  maxLength: {
    displayCondition: 'data.field_type.value.value === "text"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Maximum Length',
    type: 'number',
    validation: {},
    value: ''
  },
  options: {
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      minLength: { message: 'Options must be at least 2 characters', key: 'op-min' },
      required: { message: 'Options are required', key: 'op-req' }
    },
    displayCondition: 'data.field_type.value.value === "select" || data.field_type.value.value === "radio"',
    errors: [],
    isValid: true,
    label: 'Field Options',
    mutedText: 'Options must be separated by a comma (,)',
    type: 'text',
    validation: {
      minLength: 2,
      required: true
    },
    value: ''
  }
  /**
   * number: max, min, step, currency, decimal
   * radio: options
   * datetime: time/date/datetime, prevent in the past, prevent in the future
   * select: options, multiple, clearable, max selectable, min selectable
   * (can multiple be supported? possibly as a string with a separator, but that wouldn't be searchable for reports)
   *
   * instructions(muted) text
   * default values?
   *
   * apply proper display formatter
   * store by company, table, enforce unique combination of company_id-table-field_name
   * table that holds the values will have column for each different format, none of which can be required
   * when fields are combined on front end, will need to add a custom: true property to know to pull them off for saving, as well as the field_id
   * if a field has previously been stored, a [field_name]_cid will be passed back along with its value and type, which will then be return for updating as [field_name]_[type]_cid: value
   */
};
