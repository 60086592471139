import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AccountsCallForSurvey from './Widgets/AccountsCallForSurvey';
import EventsToBeScheduled from './Widgets/EventsToBeScheduled';
import TodaysEvents from './Widgets/TodaysEvents';
import Watchlist from './Widgets/Watchlist';
import WidgetContainer from './WidgetContainer/WidgetContainer';
import axios from '../../axios-instances/internal-api';

const Home = props => {

  const [showTodaysEventsWidget, updateShowTodaysEventsWidget] = useState( false );
  const [showCallForSurveyWidget, updateShowCallForSurveyWidget] = useState( false );
  const [showEventsToBeScheduledWidget, updateShowEventsToBeScheduleWidget] = useState( false );

  const { companyId } = props;

  useEffect( () => {
    const getAuthFields = async () => {

      try {
        const response = await axios.get( '/users/widgets' );
        updateShowTodaysEventsWidget( response.data.todaysEvents );
        updateShowCallForSurveyWidget( response.data.callForSurvey );
        updateShowEventsToBeScheduleWidget( response.data.toBeScheduled );
      } catch ( e ) {
        console.log( 'error checking auth: ', e );
      }
    };
    getAuthFields()
      .then( () => {} );
  }, [companyId] );

  return (
    <div className='d-flex m-2'>
      <div className='d-flex flex-column flex-lg-row flex-wrap justify-content-center w-100'>
        <WidgetContainer title='My Watchlist'>
          <Watchlist companyId={companyId} />
        </WidgetContainer>

        <TodaysEvents companyId={props.companyId} show={showTodaysEventsWidget} userId={props.userId} />
        <AccountsCallForSurvey companyId={props.companyId} show={showCallForSurveyWidget} />
        <EventsToBeScheduled companyId={props.companyId} show={showEventsToBeScheduledWidget} />
      </div>
    </div>
  );
};

Home.propTypes = {
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
};

export default Home;
