import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Users from './Users/Users';
import LeadProviders from './LeadProviders/LeadProviders';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import CustomFieldsContainer from './CustomFields/CustomFieldsContainer';
import Groups from './Groups/Groups';
import Packages from './Packages/Packages';
import classes from '../../../App.module.css';
import Products from './Products/Products';
import Payroll from '../../Payroll/Payroll';
import Reporting from '../../Reporting/Reporting';
import Integrations from './Integrations/Integrations';

const CompanyTabContainer = props => {

  const usersTab = props.usersAccess ?
    <Tab eventKey="users" title="Users">
      <Users
        auth={{ create: props.usersCreate, del: props.usersDelete }}
        route='users'
        showUsersTable={props.showUsersTable}
      />
    </Tab> :
    null;

  const pendingUsersTab = props.showPendingUsersTab && props.usersAccess ?
    <Tab eventKey="pending_users" title="Pending Users">
      <Users
        auth={{ del: props.usersDelete }}
        route='pendingUsers'
        showUsersTable={props.showPendingUsersTable}
      />
    </Tab> :
    null;

  const leadProvidersTab = props.leadProvidersAccess ?
    <Tab eventKey="lead_providers" title="Lead Providers">
      <LeadProviders
        auth={{ create: props.leadProvidersCreate, del: props.leadProvidersDelete }}
        showProvidersTable={props.showProvidersTable}
      />
    </Tab> :
    null;

  const customFieldsTab = props.customFieldsAccess ?
    <Tab eventKey="fields" title="Custom Fields">
      <CustomFieldsContainer showFields={props.showFields} />
    </Tab> :
    null;

  const groupsTab = props.groupsAccess ?
    <Tab eventKey="groups" title="Groups">
      <Groups
        auth={{ create: props.groupsCreate, del: props.groupsDelete }}
        showGroupsTable={props.showGroupsTable}
      />
    </Tab> :
    null;

  const packagesTab = props.packagesAccess ?
    <Tab eventKey="packages" title="Packages">
      <Packages
        auth={{ create: props.packagesCreate, del: props.packagesDelete }}
        showPackagesTable={props.showPackagesTable}
      />
    </Tab> :
    null;

  const productsTab = props.productsAccess ?
    <Tab eventKey="products" title="Products">
      <Products
        auth={{ create: props.productsCreate, del: props.productsDelete }}
        showProductsTable={props.showProductsTable}
      />
    </Tab> :
    null;

  const payrollTab = props.payrollAccess ?
    <Tab eventKey="payroll" title="Payroll">
      <Payroll
        auth={{ create: props.payrollCreate, update: props.payrollUpdate }}
      />
    </Tab> :
    null;

  const reportingTab = props.reportsAccess ?
    <Tab eventKey="reporting" title="Reporting">
      <Reporting
        customFields={props.customFields}
      />
    </Tab> :
    null;

  const detailsUpdateButton = props.detailsUpdate ?
    <Form.Row>
      <Form.Group controlId='submit' as={Col} xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={props.loading} className='w-100'>
          Update
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  return (
    <Tabs fill defaultActiveKey="details" id="company-tabs" onSelect={props.tabSelectHandler} mountOnEnter activeKey={props.activeTab} className='mb-3'>
      <Tab eventKey="details" title="Details">
        <Form onSubmit={props.formSubmitHandler} className={props.loading ? classes.Loading : ''}>
          <FormFieldGenerator
            formData={props.companyData}
            formDataInputHandler={props.updateCompanyData}
            formSubmitted={props.formSubmitted}
            updateFormIsValid={props.updateFormIsValid}
            formEditedHandler={props.updateCompanyEdited}
          />
          {detailsUpdateButton}
        </Form>
        <Integrations detailsUpdate={props.detailsUpdate} />
      </Tab>
      {usersTab}
      {pendingUsersTab}
      {leadProvidersTab}
      {customFieldsTab}
      {groupsTab}
      {packagesTab}
      {productsTab}
      {payrollTab}
      {reportingTab}
    </Tabs>
  );
};

CompanyTabContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companyData: PropTypes.object.isRequired,
  customFields: PropTypes.object.isRequired,
  customFieldsAccess: PropTypes.number.isRequired,
  detailsUpdate: PropTypes.number,
  formSubmitHandler: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  groupsAccess: PropTypes.number.isRequired,
  groupsCreate: PropTypes.number,
  groupsDelete: PropTypes.number,
  leadProvidersAccess: PropTypes.number.isRequired,
  leadProvidersCreate: PropTypes.number,
  leadProvidersDelete: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  packagesAccess: PropTypes.number.isRequired,
  packagesCreate: PropTypes.number,
  packagesDelete: PropTypes.number,
  productsAccess: PropTypes.number.isRequired,
  productsCreate: PropTypes.number,
  productsDelete: PropTypes.number,
  showFields: PropTypes.bool.isRequired,
  showGroupsTable: PropTypes.bool.isRequired,
  showPackagesTable: PropTypes.bool.isRequired,
  showPendingUsersTab: PropTypes.bool.isRequired,
  showPendingUsersTable: PropTypes.bool.isRequired,
  showProductsTable: PropTypes.bool.isRequired,
  showProvidersTable: PropTypes.bool.isRequired,
  showUsersTable: PropTypes.bool.isRequired,
  tabSelectHandler: PropTypes.func.isRequired,
  updateCompanyData: PropTypes.func.isRequired,
  updateCompanyEdited: PropTypes.func.isRequired,
  updateFormIsValid: PropTypes.func.isRequired,
  updateFormSubmitted: PropTypes.func.isRequired,
  usersAccess: PropTypes.number.isRequired,
  usersCreate: PropTypes.number,
  usersDelete: PropTypes.number,
  payrollAccess: PropTypes.number,
  payrollCreate: PropTypes.number,
  payrollUpdate: PropTypes.number,
  reportsAccess: PropTypes.number,
};

const mapStateToProps = state => ( {
  customFieldsAccess: state.company.auth.custom_fields_view,
  detailsUpdate: state.company.auth.details_update,
  groupsAccess: state.company.auth.groups_view,
  groupsCreate: state.company.auth.groups_create,
  groupsDelete: state.company.auth.groups_delete,
  leadProvidersAccess: state.company.auth.lead_providers_view,
  leadProvidersCreate: state.company.auth.lead_providers_create,
  leadProvidersDelete: state.company.auth.lead_providers_delete,
  packagesAccess: state.company.auth.packages_view,
  packagesCreate: state.company.auth.packages_create,
  packagesDelete: state.company.auth.packages_delete,
  productsAccess: state.company.auth.products_view,
  productsCreate: state.company.auth.products_create,
  productsDelete: state.company.auth.products_delete,
  usersAccess: state.company.auth.users_view,
  usersCreate: state.company.auth.users_create,
  usersDelete: state.company.auth.users_delete,
  payrollAccess: state.company.auth.payroll_view,
  payrollCreate: state.company.auth.payroll_create,
  payrollUpdate: state.company.auth.payroll_update,
  reportsAccess: state.company.auth.reports_view,
} );

export default connect( mapStateToProps )( CompanyTabContainer );
