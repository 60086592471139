import React from 'react';
import NavItem from 'react-bootstrap/NavItem';
import { LinkContainer } from 'react-router-bootstrap';

import Aux from '../../../../hoc/Aux';

const SignedOutRightNavItems = () => (
  <Aux>
    <LinkContainer to='/signin'>
      <NavItem as='a' className='nav-link'>Log In</NavItem>
    </LinkContainer>
    <LinkContainer to='/signup'>
      <NavItem as='a' className='nav-link mx-4'>Sign Up</NavItem>
    </LinkContainer>
  </Aux>
);

export default SignedOutRightNavItems;
