export default {
  name: {
    attributes: {},
    errorMessages: {
      required: { message: 'Group Name is required', key: 'gn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Name',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
};
