import React from 'react';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import Aux from '../../hoc/Aux';

const TextFilter = props => {

  const optionFields = ['All', 'Is', 'Contains', 'Starts With', 'Ends With'];
  const options = optionFields.map( option => <option key={option}>{option}</option> );

  const valueInput = props.filterValue === 'All' ? null : (
    <Form.Group as={Col} xs={6} className='mx-auto'>
      <Form.Control
        id={props.id + '_value'}
        type="text"
        placeholder={props.label}
        size='sm'
        onChange={e => props.onChangeHandler( props.id, 'value', e.target.value )}
      />
    </Form.Group>
  );

  return (
    <Aux>
      <Form.Row className='align-items-start'>
        <Form.Group controlId={props.id + '_expand'}>
          <Form.Check
            id={props.id + '_expand'}
            type="switch"
            label={props.label}
            onChange={() => props.onChangeHandler( props.id, 'show', !props.expand )}
          />
        </Form.Group>
      </Form.Row>
      <Collapse in={props.expand}>
        <Form.Row>
          <Form.Group as={Col} xs={6}>
            <Form.Control
              id={props.id + '_filter'}
              as="select"
              size='sm'
              onChange={e => props.onChangeHandler( props.id, 'filter', e.target.value )}
            >
              {options}
            </Form.Control>
          </Form.Group>
          {valueInput}
        </Form.Row>
      </Collapse>
    </Aux>
  );
};

TextFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  filterValue: PropTypes.string.isRequired
};

export default TextFilter;
