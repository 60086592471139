import React, { useState, useEffect } from 'react';
import { BsStar, BsStarFill, BsArrowRepeat } from 'react-icons/bs';
import PropTypes from 'prop-types';

import axios from '../../axios-instances/internal-api';
import classes from './Watchlist.module.css';

const Watchlist = props => {

  const [onWatchlist, updateOnWatchlist] = useState( false );
  const [loading, updateLoading] = useState( true );

  useEffect( () => {
    const checkWatchlist = async () => {
      try {
        const response = await axios.get( `/watchlist/${props.recordId}` );
        if ( response.data ) {
          updateOnWatchlist( true );
        }
      } catch ( e ) {
        console.log( 'error: ', e );
      }
    };
    checkWatchlist()
      .then( () => {
        updateLoading( false );
      } );
  }, [] );

  const starHandler = async () => {
    if ( loading ) {
      return;
    }
    updateLoading( true );
    const value = !onWatchlist;
    const action = value ? 'add' : 'remove';
    await axios.get( `/watchlist/${props.recordId}/${action}/${props.recordType}` );
    updateOnWatchlist( value );
    updateLoading( false );
  };

  const icon = loading ?
    <BsArrowRepeat className={classes.LoadingIcon} /> :
    onWatchlist ?
      <BsStarFill
        onClick={starHandler}
        className={classes.StarFull}
      /> :
      <BsStar
        onClick={starHandler}
        className={classes.Star}
      />;

  return (
    <p className='d-flex container'> {icon}{onWatchlist ? 'Remove From' : 'Add To'} Watchlist </p>
  );
};

Watchlist.propTypes = {
  recordId: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired
};

export default Watchlist;
