import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import Nav from 'react-bootstrap/Nav';

import classes from './ScheduleModal.module.css';
import appClasses from '../../../App.module.css';
import LoadingSpinner from '../../../hoc/LoadingSpinner';
import CustomersTab from './Tabs/CustomersTab';
import AccountTab from './Tabs/AccountTab';
import UserTab from './Tabs/UserTab';
import NotesTab from './Tabs/NotesTab';
import EventTab from './Tabs/EventTab';
import Aux from '../../../hoc/Aux';
import GoogleMap from '../../Shared/GoogleMap';

const tabs = {
  account: 'account',
  customers: 'customers',
  event: 'event',
  map: 'map',
  notes: 'notes',
  user: 'user'
};

const ScheduleModal = props => {

  const [tab, updateTab] = useState( tabs.event );

  const tabHandler = key => {
    updateTab( key );
  };

  const closeHandler = () => {
    updateTab( tabs.event );
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={closeHandler}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.data.status} {props.data.type} From {moment( props.data.start_at ).local().format( 'l LT' )} {props.data.end_at ? `To ${moment( props.data.end_at ).local().format( 'l LT' )}` : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <LoadingSpinner loading={!Object.keys( props.data ).length}>
          <Card className='border-0'>
            <Card.Header>
              <Nav fill variant='tabs' defaultActiveKey={tabs.event}>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.event} onSelect={tabHandler}>Event</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.map} onSelect={tabHandler}>Map</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.customers} onSelect={tabHandler}>Customers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.account} onSelect={tabHandler}>Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.user} onSelect={tabHandler}>Assigned User</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabs.notes} onSelect={tabHandler}>Recent Event Notes</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body className={classes.cardBody}>
              <div className={[classes.section, 'justify-content-between h-100', !Object.keys( props.data ).length ? appClasses.Loading : ''].join( ' ' )}>
                {
                  props.data.hasOwnProperty( 'user_auth' ) && props.data.user_auth.events_view ?
                    <Aux>
                      <AccountTab display={tab === tabs.account} data={props.data} showLink={props.data.user_auth.accounts_view} />
                      <CustomersTab display={tab === tabs.customers} customers={props.data.customers} showLink={props.data.user_auth.customers_view} />
                      <EventTab display={tab === tabs.event} data={props.data} showLink={props.data.user_auth.events_view} />
                      <GoogleMap display={tab === tabs.map} data={props.data} />
                      <NotesTab display={tab === tabs.notes} notes={props.data.notes} event_id={props.data.id} showLink={props.data.user_auth.events_view} />
                      <UserTab display={tab === tabs.user} data={props.data} showLink={props.data.user_auth.users_view} />
                    </Aux> :
                    <p>Loading...</p>
                }
              </div>
            </Card.Body>
          </Card>
        </LoadingSpinner>
      </Modal.Body>
    </Modal>
  );
};

ScheduleModal.propTypes = {
  data: PropTypes.shape( {
    account_id: PropTypes.string,
    account_stage: PropTypes.string,
    account_status: PropTypes.string,
    billing_address1: PropTypes.string,
    billing_address2: PropTypes.string,
    billing_city: PropTypes.string,
    billing_state: PropTypes.string,
    billing_zip: PropTypes.string,
    company_name: PropTypes.string,
    company_type: PropTypes.string,
    completed_at: PropTypes.string,
    created_at: PropTypes.string,
    customers: PropTypes.arrayOf( PropTypes.shape( {
      customer_address1: PropTypes.string,
      customer_address2: PropTypes.string,
      customer_city: PropTypes.string,
      customer_email: PropTypes.string,
      customer_first_name: PropTypes.string,
      customer_id: PropTypes.string,
      customer_last_name: PropTypes.string,
      customer_notification_preference: PropTypes.string,
      customer_phone: PropTypes.string,
      customer_state: PropTypes.string,
      customer_zip: PropTypes.string,
    } ) ),
    duration: PropTypes.number,
    end_at: PropTypes.string,
    estimate_on_site_at: PropTypes.string,
    id: PropTypes.string,
    in_route_at: PropTypes.string,
    notes: PropTypes.arrayOf( PropTypes.shape( {
      created_at: PropTypes.string,
      id: PropTypes.string,
      message: PropTypes.string,
      name: PropTypes.string
    } ) ),
    on_site_at: PropTypes.string,
    property_address1: PropTypes.string,
    property_address2: PropTypes.string,
    property_city: PropTypes.string,
    property_phone: PropTypes.string,
    property_state: PropTypes.string,
    property_type: PropTypes.string,
    property_zip: PropTypes.string,
    salesperson_id: PropTypes.string,
    start_at: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string,
    user_auth: PropTypes.shape( {
      accounts_view: PropTypes.number,
      customers_view: PropTypes.number,
      events_view: PropTypes.number,
      users_view: PropTypes.number
    } ),
    user_email: PropTypes.string,
    user_first_name: PropTypes.string,
    user_id: PropTypes.string,
    user_last_name: PropTypes.string,
    user_notification_preference: PropTypes.string,
    user_phone: PropTypes.string,
  } ),
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ScheduleModal;
