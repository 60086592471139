import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { IconContext } from 'react-icons';
import { connect } from 'react-redux';

import MainTable from '../Tables/MainTable';
import {
  dateTimeFormatterFromString,
  getSingularText
} from '../../helpers/functions/utilities';
import './ComponentNotes.css';
import SingleCollapse from './SingleCollapse';
import Toasts from '../Shared/Toasts';
import Pencil from './Pencil';
import NoteModal from '../Modal/NoteModal/NoteModal';

const ComponentNotes = props => {

  const [refreshNotes, updateRefreshNotes] = useState( false );
  const [showTable, updateShowTable] = useState( false );
  const [toastData, updateToastData] = useState( [] );
  const [showNoteModal, updateShowNoteModal] = useState( false );
  const [noteToEdit, updateNoteToEdit] = useState( {} );

  const route = `notes/${props.component}/${props.id}`;

  const noteEditSelected = note => {
    console.log( 'note selected: ', note );
    updateNoteToEdit( note );
    updateShowNoteModal( true );
  };

  const noteEditButton = note => {
    console.log( 'note: ', note );
    return (
      note.user_id === props.userId ?
        <Button size="sm" onClick={() => noteEditSelected( note ) } variant='transparent'>
          <IconContext.Provider value={24}>
            <Pencil />
          </IconContext.Provider>
        </Button> :
        null
    );
  };

  const headings = [
    { dataField: 'actions', text: 'Edit', isDummyField: true, formatter: ( cell, row ) => noteEditButton( row ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'name', text: 'From', sort: false },
    { dataField: 'message', text: 'Note', sort: false, classes: 'note-text-area' },
    { dataField: 'notifications.names', text: 'Notified Users', sort: false, classes: 'note-user-notifications' },
    { dataField: 'created_at', text: 'Created', sort: true, formatter: dateTimeFormatterFromString },
    { dataField: 'updated_at', text: 'Last Edited', sort: true, formatter: dateTimeFormatterFromString },
  ];

  const addNote = async () => {
    updateNoteToEdit( {} );
    updateShowNoteModal( true );
  };

  const noteSavedHandler = () => {
    updateToastData( cur => [...cur, { heading: 'Note Saved', id: Date.now() }] );
    updateNoteToEdit( {} );
    updateRefreshNotes( true );
    updateShowNoteModal( false );
  };

  const accordionToggleHandler = () => {
    updateShowTable( state => !state );
  };

  const handleMessageFromModal = message => {
    updateToastData( cur => [...cur, { heading: message, id: Date.now() }] );
  };

  const table = showTable ? (
    <MainTable
      defaultSorted={{ dataField: 'created_at', order: 'desc' }}
      headings={ headings }
      route={route}
      remote={{ filter: false, pagination: true, sort: true, cellEdit: false }}
      refreshNeeded={refreshNotes}
      updateRefreshNeeded={updateRefreshNotes}
      sizePerPageList={[5, 10, 25]}
      defaultSizePerPage={5}
    />
  ) : null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <SingleCollapse titleText={getSingularText( props.component ) + ' notes'} accordionToggle={accordionToggleHandler} cardBodyClasses='vertical-padding'>
      <Row className='py-3 justify-content-start'>
        <Col xs={12} sm='auto' className='d-flex'>
          <Button variant='outline-success' onClick={addNote} className='AddNew w-100'>
            <span className='pr-2 AddButtonText'>New Note</span>
            <svg key='plus' className="bi bi-plus-square-fill addButtonIcon" width='28px' height='28px' viewBox="0 0 16 16" fill='#28a745' xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd" />
            </svg>
          </Button>
        </Col>
      </Row>
      {table}
      {toasts}
      <NoteModal
        note={noteToEdit}
        onHide={() => updateShowNoteModal( false )}
        onComplete={noteSavedHandler}
        route={route}
        sendMessageHandler={handleMessageFromModal}
        show={showNoteModal}
      />
    </SingleCollapse>
  );
};

ComponentNotes.propTypes = {
  component: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ( {
  userId: state.user.id
} );

export default connect( mapStateToProps )( ComponentNotes );
