import * as actionTypes from '../actions/actions';
import { updateObject } from '../utilities';

const initialUserState = {
  companies: [],
  first_name: null,
  id: null,
  last_name: null,
  new_user: false,
  no_companies: false,
  profile_update_needed: false,
  signed_in: false,
  username: null
};

const setUserData = ( state, action ) => {
  const { payload } = action;
  return updateObject( state, { ...payload } );
};

const reducer = ( state = initialUserState, action ) => {
  switch ( action.type ) {
    case actionTypes.SET_USER_DATA :
      return setUserData( state, action );
    case actionTypes.USER_LOGGED_OUT :
      return initialUserState;
    case actionTypes.USER_SIGN_IN_SUCCESS :
      return updateObject( state, { signed_in: true } );
    case actionTypes.USER_PROFILE_NOT_FOUND :
      return updateObject( state, { ...action.payload } );
    case actionTypes.USER_HAS_NO_COMPANIES :
      return updateObject( state, { ...action.payload } );
    case actionTypes.USER_PROFILE_INCOMPLETE :
      return updateObject( state, { ...action.payload } );
    default:
      return state;
  }
};

export default reducer;
