import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import Aux from '../../hoc/Aux';
import StateSelect from '../Shared/StateSelect';
import Select from 'react-select';
import Async from 'react-select/async/dist/react-select.esm';
import CreatableSelect from 'react-select/creatable';
import { tableDeleteButton } from '../../helpers/functions/utilities';
import {
  dateStringFormatter,
  dateTimeFormatterFromString,
  search,
  selectComponentStyles
} from '../../helpers/functions/utilities';

const FormInput = props => {

  let input;
  let errorMessages = null;

  if ( props.invalid && props.shouldValidate ) {
    errorMessages = props.errors.map( ( { message, key } ) => <Form.Control.Feedback key={key} type='invalid'>{message}</Form.Control.Feedback> );
  }
  switch ( props.inputType ) {
    case 'text' :
    case 'password' :
    case 'tel':
    case 'email':
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Form.Control
            key={props.controlKey}
            id={props.controlKey}
            type={props.inputType}
            onChange={props.onChange}
            value={props.displayFormatter ? props.displayFormatter( props.value ) : props.value}
            isInvalid={props.invalid && props.shouldValidate}
            {...props.attributes}
          />
          {errorMessages}
          {props.mutedText ?
            <Form.Text className='text-muted'>
              {props.mutedText}
            </Form.Text> : null}
        </Aux>
      );
      break;

    case 'textarea':
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <textarea
            key={props.controlKey}
            id={props.controlKey}
            rows={props.attributes.rows ? props.attributes.rows : 4}
            onChange={props.onChange}
            value={props.value}
            {...props.attributes}
          />
          {errorMessages}
        </Aux>
      );
      break;

    case 'number' :
    case 'decimal' :
    case 'integer' :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <InputGroup>
            {props.currency ?
              <InputGroup.Prepend>
                <InputGroup.Text id={`${props.controlKey}CurrencyPrepend`}>$</InputGroup.Text>
              </InputGroup.Prepend> : null}
            <Form.Control
              key={props.controlKey}
              id={props.controlKey}
              type='number'
              onChange={props.onChange}
              value={props.displayFormatter ? props.displayFormatter( props.value ) : props.value}
              isInvalid={props.invalid && props.shouldValidate}
              {...props.attributes}
            />
            {errorMessages}
          </InputGroup>
          {props.mutedText ?
            <Form.Text className='text-muted'>
              {props.mutedText}
            </Form.Text> : null}
        </Aux>
      );
      break;

    case 'state' :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <StateSelect
            key={props.controlKey}
            onChange={props.onChange}
            value={props.value}
            field={props.controlKey}
            clearable={props.clearable}
            className={errorMessages ? 'is-invalid invalid-react-select' : ''}
            {...props.attributes}
          />
          {errorMessages}
          {props.mutedText ?
            <Form.Text className='text-muted'>
              {props.mutedText}
            </Form.Text> : null}
        </Aux>
      );
      break;

    case 'radio' :
      input = (
        <Aux>
          <Form.Group>
            <Form.Check.Label as="legend">{props.label}</Form.Check.Label>
            {
              props.attributes.options.map( option => (
                <Form.Check
                  key={option}
                  type="radio"
                  label={option}
                  name={props.label}
                  onChange={() => props.onChange( option )}
                  checked={props.value === option}
                  id={option}
                  disabled={props.attributes.disabled}
                />
              ) )
            }
          </Form.Group>
        </Aux>
      );
      break;

    case 'checkbox' :
      input = (
        <Aux>
          <Form.Check type={props.inputType} id={props.controlKey} inline={props.attributes && props.attributes.inline}>
            <Form.Check.Input
              type={props.inputType}
              isInvalid={props.invalid && props.shouldValidate}
              checked={props.value && props.value !== '0'}
              onChange={props.onChange}
              {...props.attributes}
            />
            <Form.Check.Label>{props.label}</Form.Check.Label>
            {errorMessages}
            {props.mutedText ?
              <Form.Text className='text-muted'>
                {props.mutedText}
              </Form.Text> : null}
          </Form.Check>
        </Aux>
      );
      break;

    case 'datetime' :
    case 'date':
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          {props.hasOwnProperty( 'attributes' ) && props.attributes.disabled ?
            <Form.Control
              key={props.controlKey}
              id={props.controlKey}
              type='text'
              onChange={props.onChange}
              value={props.inputType === 'date' ? dateStringFormatter( props.value ) : dateTimeFormatterFromString( props.value )}
              isInvalid={props.invalid && props.shouldValidate}
              disabled
            /> :
            <Datetime
              className={errorMessages ? 'is-invalid' : ''}
              inputProps={{ className: errorMessages ? 'form-control is-invalid' : 'form-control' }}
              key={props.controlKey}
              onChange={props.onChange}
              value={props.displayFormatter ? props.displayFormatter( props.value ) : props.value}
              displayFormatter={props.inputType === 'date' ? dateStringFormatter : dateTimeFormatterFromString}
              {...props.attributes}
            />
          }
          {errorMessages}
          {props.mutedText ?
            <Form.Text className='text-muted'>
              {props.mutedText}
            </Form.Text> : null}
        </Aux>
      );
      break;

    case 'async-select' :
      input = props.value ?
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Select
            key={props.controlKey}
            {...props.attributes}
            value={typeof props.value === 'object' ? props.value : { label: props.value, value: props.value }}
            options={[props.value]}
            onChange={() => props.onChange( null )}
            className={errorMessages ? 'is-invalid invalid-react-select' : ''}
            isDisabled={props.attributes.disabled}
            styles={selectComponentStyles}
          />
          {errorMessages}
        </Aux> :
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Async
            key={props.controlKey}
            value={props.value}
            loadOptions={( val, cb ) => search( val, cb, props.getOptionsParams )}
            onChange={props.onChange}
            {...props.attributes}
            className={errorMessages ? 'is-invalid invalid-react-select' : ''}
            isDisabled={props.attributes.disabled}
            styles={selectComponentStyles}
          />
          {errorMessages}
        </Aux>;
      break;

    case 'select' :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Select
            key={props.controlKey}
            {...props.attributes}
            value={typeof props.value === 'object' ? props.value : { label: props.value, value: props.value }}
            onChange={props.onChange}
            className={errorMessages ? 'is-invalid invalid-react-select' : ''}
            isDisabled={props.attributes.disabled}
            styles={selectComponentStyles}
          />
          {errorMessages}
        </Aux>
      );
      break;

    case 'multi-select' :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Select
            isMulti
            key={props.controlKey}
            {...props.attributes}
            value={props.value}
            name={props.controlKey}
            onChange={props.onChange}
            isDisabled={props.attributes.disabled}
            className={errorMessages ? 'is-invalid invalid-react-select basic-multi-select' : 'basic-multi-select'}
            classNamePrefix="select"
            closeMenuOnSelect={false}
          />
          {errorMessages}
        </Aux>
      );
      break;

    case 'async-multi-select':
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Async
            isMulti
            key={props.controlKey}
            {...props.attributes}
            value={props.value}
            loadOptions={( val, cb ) => search( val, cb, props.getOptionsParams )}
            name={props.controlKey}
            onChange={props.onChange}
            isDisabled={props.attributes.disabled}
            className={errorMessages ? 'is-invalid invalid-react-select basic-multi-select' : 'basic-multi-select'}
            classNamePrefix="select"
          />
          {errorMessages}
        </Aux>
      );
      break;

    case 'creatable-select' :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <CreatableSelect
            components={{ DropdownIndicator: null }}
            inputValue={props.value.inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={props.onChange}
            onInputChange={props.onChange}
            onKeyDown={props.onChange}
            placeholder="Type something and press enter..."
            value={props.value.valuesArray}
            className={errorMessages ? 'is-invalid invalid-react-select' : ''}
          />
          {errorMessages}
        </Aux>
      );
      break;

    case 'deleteButton' :
      input = (
        tableDeleteButton( props.deleteButtonHandler )
      );
      break;

    case 'button' :
      input = (
        <Button
          key={props.controlKey}
          onClick={props.onChange}
          disabled={props.attributes.disabled ? props.attributes.disabled : false}
          size={props.attributes.size ? props.attributes.size : ''}
          variant={props.attributes.variant ? props.attributes.variant : 'primary'}
          className='w-100'
          type='button'
        >{props.label}
        </Button>
      );
      break;

    default :
      input = (
        <Aux>
          <Form.Label>{props.label}</Form.Label>
          <Form.Control
            key={props.controlKey}
            id={props.controlKey}
            {...props.attributes}
            type='text'
            onChange={props.onChange}
            value={props.displayFormatter ? props.displayFormatter( props.value ) : props.value}
            isInvalid={props.invalid && props.shouldValidate}
          />
          {errorMessages}
          {props.mutedText ?
            <Form.Text className='text-muted'>
              {props.mutedText}
            </Form.Text> : null}
        </Aux>
      );
  }

  return input;
};

FormInput.propTypes = {
  attributes: PropTypes.object,
  clickHandler: PropTypes.func,
  controlKey: PropTypes.string.isRequired,
  currency: PropTypes.bool,
  deleteButtonHandler: PropTypes.func,
  displayFormatter: PropTypes.func,
  errors: PropTypes.arrayOf( PropTypes.shape( { message: PropTypes.string.isRequired, key: PropTypes.string.isRequired } ) ),
  getOptionsParams: PropTypes.array,
  inputType: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  mutedText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  shouldValidate: PropTypes.bool,
  value: PropTypes.any
};

export default FormInput;
