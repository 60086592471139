export default {
  paypal_heading: {
    text: 'PayPal',
    isValid: true,
    elClassName: 'text-start'
  },
  paypal_client: {
    attributes: {
      disabled: true
    },
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      required: { message: 'Client ID is required', key: 'sc-req' },
      minLength: { message: 'Client ID must be at least 10 digits', key: 'sc-min' },
    },
    errors: [],
    isValid: false,
    label: 'Client ID',
    type: 'password',
    validation: {
      required: true,
      minLength: 10
    },
    value: ''
  },
  paypal_secret: {
    attributes: {
      disabled: true
    },
    css: {
      xs: 12,
      md: 5,
      lg: 5
    },
    errorMessages: {
      required: { message: 'Secret is required', key: 'sc-req' },
      minLength: { message: 'Secret must be at least 10 digits', key: 'sc-min' },
    },
    errors: [],
    isValid: false,
    label: 'Secret',
    type: 'password',
    validation: {
      required: true,
      minLength: 10
    },
    value: ''
  },
  paypal_enabled: {
    css: {
      xs: 12,
      md: 2,
      lg: 2,
    },
    errorMessages: {},
    errors: [],
    inputHandler: ( { field, event, formData, updateFormData, updateFormEdited } ) => {
      const updatedValue = event.currentTarget.checked;
      const updatedForm = { ...formData };
      const updatedObj = { ...updatedForm[field] };
      updatedObj.value = updatedValue;
      updatedForm[field] = updatedObj;
      const updated_paypal_client = { ...updatedForm.paypal_client };
      const updated_paypal_secret = { ...updatedForm.paypal_secret };
      updated_paypal_client.attributes.disabled = !updatedValue;
      updated_paypal_secret.attributes.disabled = !updatedValue;
      updatedForm.paypal_client = updated_paypal_client;
      updatedForm.paypal_secret = updated_paypal_secret;
      updateFormData( updatedForm );
      updateFormEdited( true );
    },
    isValid: false,
    label: 'Enabled',
    type: 'checkbox',
    validation: {},
    value: ''
  },
};
