import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

import AddButton from '../../Shared/AddButton';
import EventModal from '../../Modal/EventModal';
import Toasts from '../../Shared/Toasts';
import {
  closeDeleteModalHandler,
  confirmDeleteHandler,
  dateTimeFormatterFromString,
  tableDeleteButton,
  tableDeleteHandler
} from '../../../helpers/functions/utilities';
import Aux from '../../../hoc/Aux';
import DeleteModal from '../../Modal/DeleteModal';
import '../../Tables/Tables.css';

const route = 'events';

const Events = props => {

  const [selectedEventId, updateSelectedEventId] = useState( null );
  const [showEventModal, updateShowEventModal] = useState( false );
  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [eventsChanged, updateEventsChanged] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );
  const [toastData, updateToastData] = useState( [] );

  const { refreshEvents } = props;

  useEffect( () => {
    if ( eventsChanged ) {
      updateEventsChanged( false );
      refreshEvents( true );
    }
  }, [eventsChanged, updateEventsChanged, refreshEvents] );

  if ( !props.eventsData ) {
    return null;
  }

  const handleDelete = ( e, row ) => {
    e.stopPropagation();
    tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal );
  };

  const headings = [
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'type', text: 'Type', sort: true },
    { dataField: 'status', text: 'Status', sort: true },
    { dataField: 'assigned_to_name', text: 'User Name', sort: true },
    { dataField: 'start_at', text: 'Scheduled Start Date', sort: true, formatter: dateTimeFormatterFromString },
  ];

  if ( props.allowEventsDelete ) {
    headings.push( { dataField: 'delete', text: 'Delete', isDummyField: true, formatter: ( cell, row ) => tableDeleteButton( ( e ) => handleDelete( e, row ) ) } );
  }

  const eventClickHandler = ( e, id = '-1' ) => {
    updateSelectedEventId( id );
    updateShowEventModal( true );
  };

  const rowEvents = {
    onClick: ( e, row ) => {
      if ( row.user_auth.events_view ) {
        eventClickHandler( e, row.id );
      } else {
        updateToastData( cur => [...cur, { heading: 'Restricted', body: 'You do not have access to view this event', id: Date.now() }] );
      }
    }
  };

  const addEventButton = props.allowEventsCreate ?
    <Row className='py-3 justify-content-start'>
      <Col xs={12} sm='auto'>
        <AddButton size='28px' clickHandler={eventClickHandler} />
      </Col>
    </Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Tab.Container id="events-list-group-tabs" defaultActiveKey="#event0">
        {addEventButton}
        <BootstrapTable
          bootstrap4
          bordered={false}
          classes='ClickableTableRows TableComponent'
          columns={ headings }
          data={props.eventsData}
          hover
          keyField='id'
          noDataIndication={() => 'No Records Found'}
          remote={false}
          striped
          rowEvents={rowEvents}
          wrapperClasses="TableWrapper"
        />
      </Tab.Container>
      <EventModal
        accountAddress={props.accountAddress}
        accountId={props.accountId}
        customFields={props.customFields}
        eventId={selectedEventId}
        onHide={() => updateShowEventModal( false )}
        refreshAccountEvents={updateEventsChanged}
        show={showEventModal}
      />
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.type}.</p><p>If a user is assigned they will be notified -- @todo.</p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updateEventsChanged )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
      {toasts}
    </Aux>
  );
};

Events.propTypes = {
  accountAddress: PropTypes.string,
  accountId: PropTypes.string,
  customFields: PropTypes.array.isRequired,
  allowEventsCreate: PropTypes.number,
  eventsData: PropTypes.array,
  allowEventsDelete: PropTypes.number,
  refreshEvents: PropTypes.func.isRequired,
};

export default Events;
