export const EVENT_TYPES = [
  'Install',
  'Service',
  'Call',
  'Estimate'
];

export const EVENT_STATUSES = [
  'Requested',
  'Scheduled',
  'In Route',
  'On Site',
  'Complete',
  'Cancelled',
  'Rescheduled'
];

export const PRODUCT_TYPES = [
  'Panels',
  'Life Safety',
  'Z-Wave',
  'CCTV',
  'Install',
  'Qolsys',
  'Power G',
  '2Gig',
  'Interlogix',
  'Cameras',
  'Misc'
];

export const ACCOUNT_STATUSES = [
  'Pending Install',
  'Call for Survey',
  'Online',
  'Offline',
  'On Test',
  'Delinquent'
];

export const ACCOUNT_STAGES = [
  'Prospect',
  'Estimate Sent',
  'Customer',
  'Cancelled',
  'Lost To Competitor',
  'Sold'
];

export const FIELD_TYPES = [
  'text',
  'integer',
  'decimal',
  'checkbox',
  'radio',
  'datetime',
  'date',
  'select'
];

export const TABLE_NAMES = [
  'accounts',
  'customers',
  'events',
  'lead_providers',
  'leads',
  'products'
];

export const ACCESS_OPTIONS = [
  { label: 'No', value: 'No' },
  { label: 'Full Access', value: 'Full Access' },
  { label: 'Assigned To User', value: 'Assigned To User' },
  { label: 'Assigned To Fellow Group Members', value: 'Assigned To Fellow Group Members' },
  { label: 'Groups User Manages', value: 'Groups User Manages' }
];

export const ACCESS_BOOLEAN = [
  { label: 'No', value: 'No' },
  { label: 'Yes', value: 'Yes' },
];

export const PROPERTY_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
];

export const CUSTOMER_NOTIFICATION_OPTIONS = [
  { label: 'Email & Text', value: 'Email & Text' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
  { label: 'None', value: 'None' }
];

export const USER_NOTIFICATION_OPTIONS = [
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
  { label: 'Internal', value: 'Internal' },
  { label: 'Email & Text', value: 'Email & Text' },
  { label: 'Email & Internal', value: 'Email & Internal' },
  { label: 'Text & Internal', value: 'Text & Internal' },
  { label: 'Email, Text & Internal', value: 'Email, Text & Internal' },
  { label: 'None', value: 'None' }
];
