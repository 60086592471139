import React from 'react';
import NavItem from 'react-bootstrap/NavItem';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

import classes from '../TopNav.module.css';

const SignedInLeftNavItems = props => {
  const links = [];
  if ( props.companyAuth.leads ) {
    links.push( { path: '/leads', label: 'Leads' } );
  }
  if ( props.companyAuth.accounts ) {
    links.push( { path: '/accounts', label: 'Accounts' } );
  }
  if ( props.companyAuth.customers ) {
    links.push( { path: '/customers', label: 'Customers' } );
  }
  if ( props.companyAuth.events ) {
    links.push( { path: '/events', label: 'Events' } );
  }

  links.push( { path: '/leaderboard', label: 'Leaderboard' } );

  if ( props.companyAuth.schedule ) {
    links.push( { path: '/schedule', label: 'Schedule' } );
  }
  if ( props.companyAuth.company ) {
    links.push( { path: '/company/details', label: 'Company' } );
  }
  return links.map( link => (
    <LinkContainer key={link.label} to={link.path} onClick={props.linkSelectedHandler}>
      <NavItem as='a' className={'nav-link ' + classes.NavItems}>
        {link.label}
      </NavItem>
    </LinkContainer>
  ) );
};

SignedInLeftNavItems.propTypes = {
  companyAuth: PropTypes.shape( {
    accounts: PropTypes.number.isRequired,
    company: PropTypes.number,
    customers: PropTypes.number,
    events: PropTypes.number,
    leads: PropTypes.number,
    schedule: PropTypes.number
  } ),
  linkSelectedHandler: PropTypes.func.isRequired
};

export default SignedInLeftNavItems;
