import React  from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

import classes from './SingleCollapse.module.css';

const SingleCollapse = props => (
  <Accordion defaultActiveKey={props.openByDefault ? '0' : null} className='pb-3'>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0" className={classes.CardHeaderTitle} onClick={props.accordionToggle}>
        {props.titleText}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body className={props.cardBodyClasses}>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
);

SingleCollapse.propTypes = {
  accordionBodyClasses: PropTypes.string,
  accordionToggle: PropTypes.func,
  cardBodyClasses: PropTypes.string,
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] ).isRequired,
  openByDefault: PropTypes.bool,
  titleText: PropTypes.string.isRequired
};

export default SingleCollapse;

