import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import ScheduleModalRowContent from '../ScheduleModalRowContent';
import { addressFormatter } from '../../../../helpers/functions/utilities';
import Aux from '../../../../hoc/Aux';
import ScheduleModalButton from '../ScheduleModalButton';

const AccountTab = props => (
  <Aux>
    <Row style={{ display: props.display ? 'flex' : 'none' }}>
      <ScheduleModalRowContent name='Property Address'>
        {addressFormatter( {
          address1: props.data.property_address1,
          address2: props.data.property_address2,
          city: props.data.property_city,
          state: props.data.property_state,
          zip: props.data.property_zip
        } )}
      </ScheduleModalRowContent>
      <ScheduleModalRowContent name='Billing Address'>
        {addressFormatter( {
          address1: props.data.billing_address1,
          address2: props.data.billing_address2,
          city: props.data.billing_city,
          state: props.data.billing_state,
          zip: props.data.billing_zip
        } )}
      </ScheduleModalRowContent>
      <ScheduleModalRowContent name='Stage'>{props.data.account_stage}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Status'>{props.data.account_status}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Type'>{props.data.property_type}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Phone'>{props.data.property_phone}</ScheduleModalRowContent>
      {
        props.data.property_type === 'Commercial' &&
        <Aux>
          {
            props.data.company_name && props.data.company_type ?
              <ScheduleModalRowContent name='Company Name'>{`${props.data.company_name}, ${props.data.company_type}`}</ScheduleModalRowContent> :
              <ScheduleModalRowContent name='Company Name'>{props.data.company_name}</ScheduleModalRowContent>
          }
        </Aux>
      }
    </Row>
    {
      props.display && props.showLink && props.data.account_id &&
        <ScheduleModalButton link={`/accounts/${props.data.account_id}/details`}>View Account</ScheduleModalButton>
    }
  </Aux>
);

AccountTab.propTypes = {
  data: PropTypes.shape( {
    account_id: PropTypes.string.isRequired,
    account_stage: PropTypes.string.isRequired,
    account_status: PropTypes.string.isRequired,
    billing_address1: PropTypes.string.isRequired,
    billing_address2: PropTypes.string.isRequired,
    billing_city: PropTypes.string.isRequired,
    billing_state: PropTypes.string.isRequired,
    billing_zip: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    company_type: PropTypes.string.isRequired,
    property_address1: PropTypes.string.isRequired,
    property_address2: PropTypes.string.isRequired,
    property_city: PropTypes.string.isRequired,
    property_phone: PropTypes.string.isRequired,
    property_state: PropTypes.string.isRequired,
    property_type: PropTypes.string.isRequired,
    property_zip: PropTypes.string.isRequired
  } ),
  display: PropTypes.bool.isRequired,
  showLink: PropTypes.number.isRequired
};

export default AccountTab;
