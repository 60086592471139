import * as actionTypes from '../actions/actions';

const successState = {
  showModal: false,
  message: ''
};

const deleteSuccessful = ( state, action ) => {
  return {
    showModal: true,
    message: action.payload.message ? action.payload.message : ''
  };
};

const successReducer = ( state = successState, action ) => {
  switch ( action.type ) {
    case actionTypes.DELETE_SUCCESSFUL :
      return deleteSuccessful( state, action );
    case actionTypes.SUCCESS_RESET :
      return successState;
    default :
      return state;
  }
};

export default successReducer;
