import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import MainTable from '../../../Tables/MainTable';
import AddButton from '../../../Shared/AddButton';
import Aux from '../../../../hoc/Aux';
import DeleteModal from '../../../Modal/DeleteModal';
import {
  closeDeleteModalHandler,
  confirmDeleteHandler,
  currencyFormatter,
  dateTimeFormatterFromString,
  tableDeleteButton,
  tableDeleteHandler,
  tableEditButton,
} from '../../../../helpers/functions/utilities';

const route = 'products';

const Products = props => {

  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [productDeleted, updateProductDeleted] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, route ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'type', text: 'Type', sort: true },
    { dataField: 'price', text: 'Price', sort: true, formatter: currencyFormatter },
    { dataField: 'cost', text: 'Cost', sort: true, formatter: currencyFormatter },
    { dataField: 'created_at', text: 'Date Created', sort: true, formatter: dateTimeFormatterFromString },
    { dataField: 'updated_at', text: 'Date Last Updated', sort: true, formatter: dateTimeFormatterFromString }
  ];

  if ( props.auth.del ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: ( cell, row ) => tableDeleteButton( () => tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal ) )
    } );
  }

  const table = props.showProductsTable ?
    <MainTable
      headings={ headings }
      route={route}
      remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
      refreshNeeded={productDeleted}
      updateRefreshNeeded={updateProductDeleted}
    /> :
    null;

  const addButton = props.auth.create ?
    <Row className='py-3 justify-content-start'>
      <Col xs={12} sm='auto'>
        <AddButton route={route} size='28px' />
      </Col>
    </Row> :
    null;

  return (
    <Aux>
      {addButton}
      {table}
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.name}.</p><p>It will be removed from any packages that it is included in.</p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updateProductDeleted )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
    </Aux>
  );
};

Products.propTypes = {
  auth: PropTypes.shape( {
    create: PropTypes.number,
    del: PropTypes.number
  } ),
  showProductsTable: PropTypes.bool.isRequired
};

export default Products;
