import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Form from 'react-bootstrap/Form';

import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import { cloneDeep } from '../../../helpers/functions/utilities';
// import classes from '../../../App.module.css';
import PaymentFormFields from '../../Payments/PaymentFormFields';
import { setValuesFromResponse } from '../../../helpers/functions/formValidator';
import { PAYMENT_FIELDS } from '../../../helpers/constants/initialData';

const Payments = props => {

  const [paymentData, updatePaymentData] = useState( cloneDeep( PaymentFormFields ) );
  const [formEdited, updateFormEdited] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [loadingState, updateLoadingState] = useState( false );

  const { processors } = props;

  useEffect( () => {
    console.log( 'set processors use effect' );
    if ( props.processors.length ) {
      updatePaymentData( cur => ( {
        ...cur,
        provider: {
          ...cur.provider,
          attributes: {
            ...cur.provider.attributes,
            options: processors.map( el => ( { label: el, value: el } ) )
          },
          displayCondition: props.processors.length > 1 ? cur.provider.displayCondition : 'false',
          display: props.processors.length > 1,
          value: processors[0]
        }
      } ) );
    }
  }, [processors] );

  useEffect( () => {
    console.log( 'set values use effect' );
    updatePaymentData( cur => setValuesFromResponse( cur, { ...PAYMENT_FIELDS }, updateFormIsValid ) );
  }, [] );
  //
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   updateFormSubmitted( true );
  //   if ( !formEdited || !formIsValid ) {
  //     return;
  //   }
  //   console.log( 'submit payment' );
  // };

  if ( !props.processors.length ) {
    return <p className='text-center'>Set Up A Payment Processor To Take Payments</p>;
  }

  return (
    <>
      <div>
        <p>Payments tab</p>
        {props.processors.map( el => <p key={el}>{el}</p> )}

      </div>
      <FormFieldGenerator
        formData={paymentData}
        formDataInputHandler={updatePaymentData}
        formSubmitted={formSubmitted}
        updateFormIsValid={updateFormIsValid}
        formEditedHandler={updateFormEdited}
      />
    </>
  );
};

Payments.propTypes = {
  acceptPayments: PropTypes.number,
  processors: PropTypes.arrayOf( PropTypes.string ).isRequired
};

export default Payments;
