import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import Aux from './Aux';

const LoadingSpinner = props => {
  return props.loading ? (
    <Aux>
      {props.children}
      <div className='spinner-div'>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </Aux>
  ) : props.children;
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType( [PropTypes.object, PropTypes.array] )
};

export default LoadingSpinner;
