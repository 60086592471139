import { dateTimeFormatterFromString } from '../../../helpers/functions/utilities';
import { PROPERTY_TYPES } from '../../../helpers/constants/options';

export default {
  online_at: {
    attributes: {
      disabled: true
    },
    displayFormatter: dateTimeFormatterFromString,
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Account Online Date/Time',
    type: 'text',
    validation: {},
    value: ''
  },
  phone: {
    attributes: {
      placeholder: '(555) 555-5555'
    },
    errorMessages: {
      minLength: { message: 'Phone number must be at least 10 digits', key: 'ph-min' },
      phone: { message: 'Invalid phone number', key: 'ph-inv' },

    },
    errors: [],
    isValid: false,
    label: 'Phone',
    type: 'tel',
    validation: {
      minLength: 10,
      phone: true
    },
    value: ''
  },
  property_heading: {
    text: 'Property Information',
    isValid: true
  },
  property_address1: {
    errorMessages: {
      required: { message: 'Property Address is required', key: 'pa-req' }
    },
    errors: [],
    isValid: false,
    label: 'Property Address',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
  property_address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Property Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  property_city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Property City',
    type: 'text',
    validation: {},
    value: ''
  },
  property_state: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Property State',
    type: 'state',
    validation: {},
    value: ''
  },
  property_zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      minLength: { message: 'Zip must be at least 5 numbers', key: 'zc-min' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' }
    },
    errors: [],
    isValid: false,
    label: 'Property Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      minLength: 5,
      number: true
    },
    value: ''
  },
  property_type: {
    attributes: {
      options: PROPERTY_TYPES
    },
    errorMessages: {
      required: { message: 'Property Type is required', key: 'pt-req' }
    },
    errors: [],
    isValid: true,
    label: 'Property Type',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  company_name: {
    displayCondition: 'data.property_type.value === "Commercial" || data.property_type.value.value === "Commercial"',
    errorMessages: {
      required: { message: 'Company Name is required', key: 'cn-req' }
    },
    errors: [],
    isValid: false,
    label: 'Company Name',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
  company_type: {
    attributes: {
      placeholder: 'LLC'
    },
    displayCondition: 'data.property_type.value === "Commercial" || data.property_type.value.value === "Commercial"',
    errorMessages: {
      required: { message: 'Company Type is required', key: 'ct-req' }
    },
    errors: [],
    isValid: false,
    label: 'Company Type',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  },
  billing_heading: {
    text: 'Billing Information',
    isValid: true
  },
  billing_address1: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Billing Address',
    type: 'text',
    validation: {},
    value: ''
  },
  billing_address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Billing Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  billing_city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Billing City',
    type: 'text',
    validation: {},
    value: ''
  },
  billing_state: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Billing State',
    type: 'state',
    validation: {},
    value: ''
  },
  billing_zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'bz-max' },
      minLength: { message: 'Zip must be at least 5 numbers', key: 'bz-min' },
      number: { message: 'Zip should only be numbers', key: 'bz-num' }
    },
    errors: [],
    isValid: false,
    label: 'Billing Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      minLength: true,
      number: true
    },
    value: ''
  },
  rmr: {
    errorMessages: {
      currency: { message: 'Invalid amount provided', key: 'rmr-inv' },
    },
    errors: [],
    isValid: true,
    label: 'RMR',
    type: 'decimal',
    attributes: {
      step: 0.01,
      min: 0.00
    },
    validation: {
      currency: true
    },
    value: ''
  },
  primary_contact_id: {
    display: false,
    displayCondition: 'false',
    validation: {},
    value: ''
  },
  secondary_contact_id: {
    display: false,
    displayCondition: 'false',
    validation: {},
    value: ''
  },
  id: {
    display: false,
    displayCondition: 'false',
    isValid: true,
    validation: {},
    value: ''
  },
  package_id: {
    display: false,
    displayCondition: 'false',
    isValid: true,
    validation: {},
    value: ''
  },
  package_price: {
    display: false,
    displayCondition: 'false',
    isValid: true,
    validation: {},
    value: 0
  }
};
