import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Type } from 'react-bootstrap-table2-editor';

import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import Aux from '../../hoc/Aux';
import '../Tables/Tables.css';
import Toasts from '../Shared/Toasts';
import {
  cloneDeep,
  createRecord,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  tableDeleteButton,
  updateRecord
} from '../../helpers/functions/utilities';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { PACKAGE } from '../../helpers/constants/initialData';
import initialPackageFormFields from './PackageFormFields';
import ProductSearchWithTable from '../Products/ProductSearchWithTable';

const Package = () => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [packageData, updatePackageData] = useState( cloneDeep( initialPackageFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [products, updateProducts] = useState( [] );
  const [recordPermissions, updateRecordPermissions] = useState( { packages_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Package</h3> : <h3>Last Updated: <em>{dateTimeFormatter( packageData.updated_at )}</em></h3>;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.packages_update ) {
      updatePackageData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getPackage = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updatePackageData( cur => setValuesFromResponse( cur, { ...PACKAGE }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `packages/${id}` );
        if ( response ) {
          updatePackageData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateProducts( response.products );
          const { packages_update } = response.user_auth;
          updateRecordPermissions( { packages_update } );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getPackage()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const data = {
        pkg: { ...extractValues( packageData ) },
        products: products.map( prod => ( { id: prod.id, quantity: prod.quantity } ) )
      };
      if ( id === 'new' ) {
        const response = await createRecord( 'packages', data );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `packages/${id}`, data );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const removeProductHandler = ( product ) => {
    updateProducts( cur => {
      let products = [...cur];
      const index = cur.findIndex( el => el.id === product.id );
      if ( index !== -1 ) {
        products.splice( index, 1 );
        updateFormEdited( true );
      }
      return products;
    } );
  };

  const validQuantity = ( newValue ) => {
    const isInvalid = newValue.match( /\D/ );
    return isInvalid ?
      {
        valid: false,
        message: 'Quantity must only contain numbers'
      } :
      true;
  };

  const quantityColumn = recordPermissions.packages_update ?
    { dataField: 'quantity', text: 'Quantity', sort: false, validator: validQuantity, editor: { type: Type.TEXT }, editCellStyle: { width: '80vw', height: '100px' },  } :
    { dataField: 'quantity', text: 'Quantity', sort: false, editable: false };

  const headings = [
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'name', text: 'Name', sort: false, editable: false },
    quantityColumn,
    { dataField: 'description', text: 'Description', sort: false, editable: false },
    { dataField: 'type', text: 'Type', sort: false, editable: false },
    { dataField: 'sku', text: 'SKU', sort: false, editable: false },
    { dataField: 'is_fire', text: 'Fire Device', sort: false, editable: false, formatter: cell => cell ? 'Yes' : 'No' }
  ];

  if ( recordPermissions.packages_update ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      editable: false,
      formatter: ( cell, row ) => tableDeleteButton( () => removeProductHandler( row ) )
    } );
  }

  const button = recordPermissions.packages_update ?
    <Form.Row className='pt-3'>
      <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Container>
        <LoadingSpinner loading={loadingState}>
          {heading}
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''} noValidate>
            <FormFieldGenerator
              formData={packageData}
              formDataInputHandler={updatePackageData}
              formEditedHandler={updateFormEdited}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
            />
            <ProductSearchWithTable
              headings={headings}
              hideSearch={!recordPermissions.packages_update}
              products={products}
              routePrefix='packages/product'
              updateFormEdited={updateFormEdited}
              updateProducts={updateProducts}
            />
            {button}
          </Form>
        </LoadingSpinner>
      </Container>
      {toasts}
    </Aux>
  );
};

export default Package;
