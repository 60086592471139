import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import initialVerifyFormFields from './VerifyFormFields';
import Aux from '../../hoc/Aux';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { cloneDeep } from '../../helpers/functions/utilities';
import LoadingSpinner from '../../hoc/LoadingSpinner';

const Verify = props => {

  const [formData, updateFormData] = useState( cloneDeep( initialVerifyFormFields ) );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [showToast, updateShowToast] = useState( false );
  const [loadingState, updateLoadingState] = useState( false );

  const values = queryString.parse( props.location.search );
  const username = values.username ? values.username : '';

  const history = useHistory();
  if ( username === '' ) {
    history.push( '/signin' );
  }

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      return;
    }
    updateLoadingState( true );
    const code = formData.code.value;
    try {
      const response = await Auth.confirmSignUp( username, code );
      console.log( 'verify response: ', response );
      if ( response ) {
        history.push( '/signin' );
      }
    } catch ( error ) {
      updateLoadingState( false );
      console.log( error );
      if ( error.message ) {
        updateFormData( cur => {
          const updatedData = { ...cur };
          const code = { ...updatedData.code };
          code.isValid = false;
          code.errors = [{ message: error.message, key: 'vc-inv' }];
          updatedData.code = code;
          return updatedData;
        } );
      }
    }
  };

  const handleNewCodeRequest = async () => {
    updateLoadingState( true );
    try {
      await Auth.resendSignUp( username );
      updateShowToast( true );
    } catch ( err ) {
      console.log( 'error resending code: ', err );
    }
    updateLoadingState( false );
  };

  return loadingState ? <LoadingSpinner loading={true} /> : (
    <Aux>
      <Aux>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Row>
            <Form.Group controlId='username' as={Col} xs={12} className='mx-auto'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                value={username}
                disabled
              />
            </Form.Group>
          </Form.Row>
          <FormFieldGenerator
            formData={formData}
            formDataInputHandler={updateFormData}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
          <Form.Row className='justify-content-between flex-column-reverse flex-sm-row pt-2'>
            <Col xs={8} sm={9} className='d-flex flex-column my-auto'>
              <div>Cant find your code?</div>
              <Button className='p-0 align-self-start' onClick={handleNewCodeRequest} variant={'link'}>Request a new one</Button>
            </Col>
            <Form.Group as={Col} controlId="submit" xs={12} sm={3} className='d-flex justify-content-start justify-content-sm-end'>
              <Button variant="primary" type="submit" className='b-width'>Verify</Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Aux>
      <Toast show={showToast} autohide delay={5000} onClose={() => updateShowToast( false )}>
        <Toast.Header>Email Sent</Toast.Header>
        <Toast.Body>Please check your email for the new code</Toast.Body>
      </Toast>
    </Aux>
  );
};

Verify.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter( Verify );
