import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

import { cloneDeep } from '../../../helpers/functions/utilities';
import initialShareReportModalFormFields from './ShareReportModalFormFields';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import {
  extractValues,
  setValuesFromResponse
} from '../../../helpers/functions/formValidator';

const ShareReportModal = props => {

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [formFields, updateFormFields] = useState( cloneDeep( initialShareReportModalFormFields ) );

  const { show, reportName } = props;

  useEffect( () => {
    updateFormFields( setValuesFromResponse( cloneDeep( initialShareReportModalFormFields ), { user_id_array: [] }, updateFormIsValid ) );
    updateFormSubmitted( false );
    updateFormEdited( false );
  }, [show, reportName] );

  const verifyValidity = () => {
    updateFormSubmitted( true );
    if ( !formEdited ) {
      console.log( 'form has not been edited' );
      return;
    }
    if ( !formIsValid ) {
      console.log( 'form is invalid' );
      return;
    }
    const data = extractValues( formFields );
    props.onSubmit( data );
  };

  return (
    <Modal
      show={show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      contentClassName='h-100'
    >
      <Modal.Header closeButton>Share Report: {props.reportName}</Modal.Header>
      <Modal.Body>
        <Form>
          <FormFieldGenerator
            formData={formFields}
            formDataInputHandler={updateFormFields}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
            formEditedHandler={updateFormEdited}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button variant='primary' type='submit' onClick={verifyValidity} className='w-100'>Share Report</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );

};

ShareReportModal.propTypes = {
  reportName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ShareReportModal;
