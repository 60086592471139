export default {
  username: {
    attributes: {},
    css: {
      xs: 12,
      md: 12,
      lg: 4
    },
    errorMessages: {
      maxLength: { message: 'Username cannot be longer than 20 characters', key: 'un-max' },
      minLength: { message: 'Username must be at least 6 characters', key: 'un-min' },
      required: { message: 'Username is required', key: 'un-req' }
    },
    errors: [],
    isValid: false,
    label: 'Username',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 6,
      required: true
    },
    value: ''
  },
  userId: {
    attributes: {},
    css: {
      xs: 12,
      md: 12,
      lg: 8
    },
    errorMessages: {
      maxLength: { message: 'User ID must be 36 characters', key: 'id-max' },
      minLength: { message: 'User ID must be 36 characters', key: 'id-min' },
      required: { message: 'User ID is required', key: 'id-req' }
    },
    errors: [],
    isValid: false,
    label: 'User ID',
    mutedText: 'Example User ID: c1ac07e4-59f0-11eb-bfd7-bf2061867cda',
    type: 'text',
    validation: {
      minLength: 36,
      required: true
    },
    value: ''
  }
};
