import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';

const ScheduleModalButton = props => {

  const history = useHistory();

  const clickHandler = () => {
    history.push( props.link );
  };

  return (
    <Row className='pb-4'>
      <Col xs={12} sm='auto' className='mx-auto pt-3'>
        <Button className='w-100' onClick={clickHandler}>{props.children}</Button>
      </Col>
    </Row>
  );
};

ScheduleModalButton.propTypes = {
  children: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default ScheduleModalButton;
