import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import { BsBell, BsBellFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import Aux from '../../../../hoc/Aux';
import Toasts from '../../../../../src/components/Shared/Toasts';
import classes from '../TopNav.module.css';
import openSocket from 'socket.io-client';
import axios from '../../../../axios-instances/internal-api';
import {
  defaultStyles,
  updateRecord
} from '../../../../helpers/functions/utilities';

const Notifications = props => {

  const [notifications, updateNotifications] = useState( [] );
  const [notificationCount, updateNotificationCount] = useState( 0 );
  const [toastData, updateToastData] = useState( [] );

  const { userId } = props;

  useEffect( () => {
    const getNotifications = async () => {
      const response = await axios.get( '/notifications/list/latestUnread' );
      updateNotificationCount( response.data.unreadCount );
      updateNotifications( response.data.notifications );
    };
    getNotifications()
      .then( () => {} );
  }, [] );

  useEffect( () => {
    // console.log( 'socket opened' );
    const socket = openSocket( process.env.REACT_APP_API_ADDRESS, { withCredentials: true, transports: ['websocket', 'polling'] } );
    socket.on( `notify_${userId}`, data => {
      updateNotifications( cur => {
        let copy = [...cur];
        const existingNoteIndex = copy.findIndex( el => el.id === data.id );
        if ( existingNoteIndex !== -1 ) {
          copy.splice( existingNoteIndex, 1, { ...copy[existingNoteIndex], viewed: false }  );
        } else {
          copy = [...copy, data];
        }
        return copy;
      } );
      updateNotificationCount( cur => cur + 1 );
    } );
    socket.on( `notify_${userId}_remove`, data => {
      updateNotifications( cur => {
        const copy = [...cur];
        const noteIndex = copy.findIndex( el => el.id === data.id );
        if ( noteIndex !== -1 ) {
          copy.splice( noteIndex, 1, { ...copy[noteIndex], viewed: true }  );
        }
        return copy;
      } );
      updateNotificationCount( cur => cur - 1 );
    } );
  }, [userId] );

  const markNoteAsRead = async id => {
    try {
      await updateRecord( `/notifications/${id}/markAsRead` );
    } catch ( e ) {
      console.log( 'error: ', e );
    }
  };

  const differentCompanyHandler = note => {
    const company = props.companies.find( el => el.id === note.company_id );
    const body = company ? `Switch to ${company.name} to view this record` : '';
    updateToastData( cur => [...cur, { heading: 'Record is not associated with the current company', body, id: Date.now() }] );
  };

  const notificationBadge = notificationCount ?
    <Badge pill variant="danger" className={`position-absolute ${classes.NotificationBadge}`}>
      {notificationCount}
    </Badge> :
    null;

  const notificationIcon = notificationCount ? <BsBellFill /> : <BsBell />;

  const dropDownHeader = notifications.length ?
    <Dropdown.Header className='pr-3 d-flex justify-content-end'>
      <p className='m-0'>Read</p>
    </Dropdown.Header> :
    null;

  const generateNotificationItem = notification => {
    const message = notification.message || notification.note_message;
    return props.currentCompanyId === notification.company_id ?
      <LinkContainer to={notification.url} activeStyle={defaultStyles} className='p-0'>
        <Dropdown.Item>{message}</Dropdown.Item>
      </LinkContainer> :
      <Dropdown.Item
        className='p-0'
        onClick={() => differentCompanyHandler( notification )}
      >
        {message}
      </Dropdown.Item>;
  };

  const notificationList = notifications.map( notification => (
    <Aux key={notification.id}>
      <Collapse in={!notification.viewed}>
        <div>
          <Row className='w-100 m-auto'>
            <Col xs={10}>
              {generateNotificationItem( notification )}
              <Dropdown.ItemText className={`p-0 ${classes.NotificationDropdownFrom}`}>From: {notification.from_user}</Dropdown.ItemText>
            </Col>
            <Col xs={2}>
              <Form.Check type='checkbox' inline={true}>
                <Form.Check.Input
                  type='checkbox'
                  checked={notification.viewed}
                  onChange={() => markNoteAsRead( notification.id )}
                />
              </Form.Check>
            </Col>
          </Row>
          <Dropdown.Divider />
        </div>
      </Collapse>
    </Aux>
  ) );

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Dropdown alignRight className={classes.NotificationDropdownContainer}>
      <Dropdown.Toggle variant="transparent" id="notification-dropdown">
        <IconContext.Provider value={{ size: 28, color: '#fff' }} >
          {notificationIcon}
        </IconContext.Provider>
        {notificationBadge}
      </Dropdown.Toggle>
      <Dropdown.Menu className={`position-absolute ${classes.UserDropdownContainer} ${classes.NotificationDropdownMenu}`}>
        {dropDownHeader}
        {notificationList}
        <LinkContainer to='/notifications' activeStyle={defaultStyles}>
          <Dropdown.Item>View All</Dropdown.Item>
        </LinkContainer>
      </Dropdown.Menu>
      {toasts}
    </Dropdown>
  );
};

Notifications.propTypes = {
  companies: PropTypes.array,
  currentCompanyId: PropTypes.string,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ( {
  userId: state.user.id
} );

export default connect( mapStateToProps )( Notifications );
