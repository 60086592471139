import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import initialUserFormFields from './AddExistingUserFormFields';
import { cloneDeep } from '../../../helpers/functions/utilities';
import classes from '../../../App.module.css';
import { extractValues } from '../../../helpers/functions/formValidator';
import axios from '../../../axios-instances/internal-api';
import Toasts from '../../Shared/Toasts';

const AddExistingUserToCompany = props => {

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [userData, updateUserData] = useState( cloneDeep( initialUserFormFields ) );
  const [loadingState, updateLoadingState] = useState( false );
  const [toastData, updateToastData] = useState( [] );

  const submitHandler = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const data = { ...extractValues( userData ) };
      console.log( 'data: ', data );
      const response = await axios.post( `/users/${data.userId}`, data );
      console.log( 'response: ', response );
      if ( response.data ) {
        updateFormEdited( false );
        updateFormSubmitted( false );
        updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
        props.onHide( data.userId );
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Existing User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={loadingState ?  classes.Loading : ''}>
          <FormFieldGenerator
            formData={userData}
            formDataInputHandler={updateUserData}
            formEditedHandler={updateFormEdited}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button type='submit' onClick={submitHandler} className='w-100'>Add User</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
      {toasts}
    </Modal>
  );
};

AddExistingUserToCompany.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AddExistingUserToCompany;
