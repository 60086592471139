import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialCustomerFormFields from '../Customers/CustomerFormFields';
import { cloneDeep, convertCustomFieldsForDisplay } from '../../helpers/functions/utilities';
import classes from '../../App.module.css';
import { setValuesFromResponse } from '../../helpers/functions/formValidator';
import { CUSTOMER } from '../../helpers/constants/initialData';
import Toasts from '../Shared/Toasts';

const AddNewCustomerToAccountModal = props => {

  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [customerData, updateCustomerData] = useState( cloneDeep( { ...initialCustomerFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ) );
  const [toastData, updateToastData] = useState( [] );

  useEffect( () => {
    updateCustomerData( cur => setValuesFromResponse( cur, { ...CUSTOMER }, updateFormIsValid ) );

  }, [] );

  const checkFormValidity = e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    props.onSubmit( customerData );
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={props.loading ?  classes.Loading : ''}>
          <FormFieldGenerator
            formData={customerData}
            formDataInputHandler={updateCustomerData}
            formEditedHandler={updateFormEdited}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100'>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button type='submit' onClick={checkFormValidity} className='w-100'>Create</Button>
          </Col>
          <Col xs={12} sm='auto' className='mx-auto pt-3'>
            <Button onClick={props.onHide} variant='secondary' className='w-100'>Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
      {toasts}
    </Modal>
  );
};

AddNewCustomerToAccountModal.propTypes = {
  customFields: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AddNewCustomerToAccountModal;
