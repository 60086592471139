import * as actionTypes from '../actions/actions';
import { updateObject } from '../utilities';
import { optionBuilder } from '../../helpers/functions/utilities';
import { FILTERS, DATE_FILTER, NUMBER_FILTER, SELECT_FILTER, TEXT_FILTER } from '../initialSideDrawerFilters';

const initialFiltersState = {
  filters: {},
  filterType: null,
  applyFilters: false
};

const updateSideDrawerFilters = ( state, action ) => {
  const { field, prop, value } = action.payload;
  const updatedFilter = updateObject( state.filters[field], { [prop]: value } );
  const updatedFilters = updateObject( state.filters, { [field]: updatedFilter } );
  return updateObject( state, { filters: updatedFilters } );
};

const setSideDrawerFilters = ( state, action ) => {
  let [component, id] = action.payload.component.split( ' ' );
  if ( component === 'company' ) {
    // if ( id === 'pending_users' ) {
    //   component = 'users';
    if ( id ) {
    // } else if ( id ) {
      component = id;
    }
  } else if ( id ) {
    component = 'TIMELINE';
  }
  const initialFilters = FILTERS[component.toUpperCase()];
  let customFilters = {};
  if ( action.payload.customFields ) {
    action.payload.customFields.forEach( field => {
      let value;
      switch ( field.field_data.type ) {
        case 'datetime':
        case 'date':
          value = { ...DATE_FILTER };
          break;
        case 'number':
        case 'integer':
        case 'decimal':
          value = { ...NUMBER_FILTER };
          break;
        case 'select':
        case 'radio':
          value = { ...SELECT_FILTER, options: optionBuilder( ['All', ...field.field_data.attributes.options] ) };
          break;
        case 'checkbox':
          value = { ...SELECT_FILTER };
          break;
        default:
          value = { ...TEXT_FILTER };
          break;
      }
      customFilters[`custom_fields_data.${field.table_name}.${field.field_data.type}.${field.id}`] = { ...value, label: field.field_data.label };
    } );
  }
  return updateObject( state, { filters: { ...initialFilters, ...customFilters }, filterType: action.payload.component } );
};

const reducer = ( state = initialFiltersState, action ) => {
  switch ( action.type ) {
    case actionTypes.UPDATE_SIDE_DRAWER_FILTERS :
      return updateSideDrawerFilters( state, action );
    case actionTypes.SIDE_DRAWER_COMPONENT_CHANGED :
      return setSideDrawerFilters( state, action );
    case actionTypes.APPLY_SIDE_DRAWER_FILTERS :
      return updateObject( state, { applyFilters: true } );
    case actionTypes.SIDE_DRAWER_FILTERS_APPLIED :
      return updateObject( state, { applyFilters: false } );
    default :
      return state;
  }
};

export default reducer;
