export default {
  name: {
    errorMessages: {
      maxLength: { message: 'Company Name cannot be longer than 50 characters', key: 'ln-max' },
      minLength: { message: 'Company Name must be at least 2 characters', key: 'ln-min' },
      required: { message: 'Company Name is required', key: 'ln-req' }
    },
    errors: [],
    isValid: false,
    label: 'Company Name',
    type: 'text',
    validation: {
      maxLength: 50,
      minLength: 2,
      required: true
    },
    value: ''
  },
  email: {
    errorMessages: {
      email: { message: 'Invalid Email address', key: 'em-inv' },
      required: { message: 'Email is required', key: 'em-req' }
    },
    errors: [],
    isValid: false,
    label: 'Email',
    type: 'email',
    validation: {
      email: true,
      required: true
    },
    value: ''
  },
  phone: {
    attributes: {
      placeholder: '(555) 555-5555'
    },
    errorMessages: {
      phone: { message: 'Invalid phone number', key: 'ph-inv' },
      required: { message: 'Phone is required', key: 'ph-req' }
    },
    errors: [],
    isValid: false,
    label: 'Phone',
    type: 'tel',
    validation: {
      phone: true,
      required: true
    },
    value: ''
  },
  address1: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address',
    type: 'text',
    validation: {},
    value: ''
  },
  address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'City',
    type: 'text',
    validation: {},
    value: ''
  },
  state: {
    errorMessages: {
      required: { message: 'State is required', key: 'st-req' },
    },
    errors: [],
    isValid: false,
    label: 'State',
    type: 'state',
    validation: {
      required: true
    },
    value: ''
  },
  zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      minLength: { message: 'Zip must be at least 5 numbers', key: 'zc-min' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' },
      required: { message: 'Zip is required', key: 'zc-req' }
    },
    errors: [],
    isValid: false,
    label: 'Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      minLength: 5,
      number: true,
      required: true
    },
    value: ''
  },
  website: {
    errorMessages: {
      required: { message: 'Website address is required', key: 'web-req' }
    },
    errors: [],
    isValid: false,
    label: 'Website',
    type: 'text',
    validation: {
      required: true
    },
    value: ''
  }
};
