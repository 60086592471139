import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from '../../../App.module.css';
import { dateTimeFormatterFromString, storePrevious } from '../../../helpers/functions/utilities';
import axios from '../../../axios-instances/internal-api';
import widgetWebsocketUpdates from './WidgetWebsocketUpdates';

const Watchlist = props => {

  const [loadingState, updateLoadingState] = useState( true );
  const [data, updateData] = useState( [] );

  const { companyId: id } = props;
  const prevId = storePrevious( id );

  const history = useHistory();

  const headings = [
    { dataField: 'id', hidden: true },
    { dataField: 'record_type', text: 'Type', sort: true, style: { textTransform: 'capitalize' } },
    { dataField: 'status', text: 'Status', sort: true },
    { dataField: 'name', text: 'Name or Address', sort: false, style: { textAlign: 'left' } },
    { dataField: 'updated_at', text: 'Last Updated Date', sort: true, formatter: dateTimeFormatterFromString },
  ];

  useEffect( () => {
    const getWatchlist = async () => {
      const response = await axios.get( '/watchlist/list' );
      updateData( response.data );
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getWatchlist()
        .then( () => {} );
    }
  }, [id, prevId] );

  widgetWebsocketUpdates( {
    id,
    removeEventPrefix: 'wl_widget_remove_',
    updateData
  } );

  const rowEvents = {
    onClick: ( e, row ) => {
      history.push( `/${row.record_type}/${row.id}` );
    }
  };

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes='ClickableTableRows TableComponent'
      columns={headings}
      data={data}
      hover
      keyField='id'
      loading={loadingState}
      noDataIndication={() => 'No Records Found'}
      remote={false}
      rowEvents={rowEvents}
      striped
      wrapperClasses={loadingState ? `TableWrapper ${classes.Loading} m-0` : 'TableWrapper m-0'}
    />
  );
};

Watchlist.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default Watchlist;
