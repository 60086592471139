import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Paypal from './Payments/Paypal';
import axios from '../../../../axios-instances/internal-api';
import { CREDENTIALS_FIELDS } from '../../../../helpers/constants/initialData';
import { cloneDeep } from '../../../../helpers/functions/utilities';

const Integrations = props => {

  const [credentials, updateCredentials] = useState( cloneDeep( CREDENTIALS_FIELDS ) );
  const [loading, updateLoading] = useState( false );

  useEffect( () => {
    updateLoading( true );
    axios.get( '/integrations/credentials' )
      .then( response => {
        if ( response.hasOwnProperty( 'data' ) ) {
          updateCredentials( response.data );
        }
        updateLoading( false );
      } )
      .catch( e => {
        console.log( 'error: ', e.message );
        updateLoading( false );
      } );
  }, [] );

  return (
    <Container className='mb-5 p-0'>
      <Row>
        <Col xs={12}>
          <h2>Integrations</h2>
        </Col>
      </Row>
      <Paypal credentials={credentials.paypal} credentialsLoading={loading} detailsUpdate={props.detailsUpdate} />
    </Container>
  );
};

Integrations.propTypes = {
  detailsUpdate: PropTypes.number
};

export default Integrations;
