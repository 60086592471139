import React from 'react';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import PropTypes from 'prop-types';

import Aux from '../../../../hoc/Aux';
import ScheduleModalRowContent from '../ScheduleModalRowContent';
import ScheduleModalButton from '../ScheduleModalButton';

const NotesTab = props => (
  props.notes && props.notes.length ?
    <Aux>
      <Row style={{ display: props.display ? 'flex' : 'none' }}>
        {
          props.notes.map( note =>
            <ScheduleModalRowContent
              key={note.id}
              name={`${note.name} on ${moment( note.created_at ).local().format( 'l [at] LT' )}`}
            >{note.message}
            </ScheduleModalRowContent> )
        }
      </Row>
      {
        props.display && props.showLink && props.event_id &&
          <ScheduleModalButton link={`/events/${props.event_id}`}>View Event</ScheduleModalButton>
      }
    </Aux> :
    <Aux>
      <Row className='justify-content-center' style={{ display: props.display ? 'flex' : 'none' }}>
        <p>No Notes Available</p>
      </Row>
      {
        props.display && props.showLink && props.event_id &&
          <ScheduleModalButton link={`/events/${props.event_id}`}>View Event</ScheduleModalButton>
      }
    </Aux>
);

NotesTab.defaultProps = {
  event_id: null,
  notes: null,
  showLink: 0
};

NotesTab.propTypes = {
  display: PropTypes.bool.isRequired,
  event_id: PropTypes.oneOfType( [
    PropTypes.oneOf( [null] ),
    PropTypes.string
  ] ),
  notes: PropTypes.oneOfType( [
    PropTypes.oneOf( [null] ),
    PropTypes.arrayOf( PropTypes.shape( {
      id: PropTypes.string,
      name: PropTypes.string,
      created_at: PropTypes.string,
      message: PropTypes.string
    } ) )
  ] ),
  showLink: PropTypes.number.isRequired
};

export default NotesTab;
