import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import Toasts from '../Shared/Toasts';
import {
  cloneDeep,
  convertCompanyUserAccessFieldsForApi,
  convertCompanyUserAccessFieldsForDisplay,
  createRecord,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  storePrevious,
  updateRecord
} from '../../helpers/functions/utilities';
import initialUserFormFields from './UserFormFields';
import initialCompanyUserFormFields from './UserRoleFields';
import initialUserEmailChangedVerificationFormFields from '../Users/UserEmailChangedVerificationFormFields';
import {
  checkValidity,
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import Aux from '../../hoc/Aux';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { USER } from '../../helpers/constants/initialData';
import UserEmailChangedVerificationModal from '../Modal/UserEmailChangeVerificationModal';
import SuccessModal from '../Modal/SuccessModal';

const User = props => {

  let { id } = useParams();
  const history = useHistory();

  let userFormFields = cloneDeep( initialUserFormFields );
  if ( history.location.pathname === '/profile' || ( !props.newUser && props.profileUpdateNeeded ) ) {
    id = props.userId;
  } else if ( id === 'new' ) {
    for ( const prop in userFormFields ) {
      if ( userFormFields.hasOwnProperty( prop ) ) {
        if ( prop === 'username' ) {
          userFormFields[prop].displayCondition = 'false';
        }
        if ( prop !== 'email' ) {
          userFormFields[prop].validation = {};
        }
      }
    }
    initialCompanyUserFormFields.is_active.value = 'Yes';
  } else if ( id !== props.userId && !props.newUser ) {
    userFormFields = disableFormFields( userFormFields );
  }

  const [loadingState, updateLoadingState] = useState( false );
  const [userData, updateUserData] = useState( userFormFields );
  const [userDataEdited, updateUserDataEdited] = useState( false );
  const [companyUserData, updateCompanyUserData] = useState( cloneDeep( initialCompanyUserFormFields ) );
  const [companyDataEdited, updateCompanyDataEdited] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [recordPermissions, updateRecordPermissions] = useState( { users_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  // below used for updating existing user auth email
  const [codeValidationData, updateCodeValidationData] = useState( cloneDeep( initialUserEmailChangedVerificationFormFields ) );
  const [validationFormIsValid, updateValidationFormIsValid] = useState( false );
  const [validationFormSubmitted, updateValidationFormSubmitted] = useState( false );
  const [validationFormEdited, updateValidationFormEdited] = useState( false );
  const [showEmailVerificationModal, updateShowEmailVerificationModal] = useState( false );
  const [existingEmail, updateExistingEmail] = useState( '' );
  const [newEmail, updateNewEmail] = useState( '' );
  const [showSuccessModal, updateShowSuccessModal] = useState( false );
  const [successModalMessage, updateSuccessModalMessage] = useState( 'Your Email has been successfully verified' );

  const buttonText = ( id === 'new' || props.newUser ) ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New User</h3> : <h3>Last Updated: <em>{dateTimeFormatter( userData.updated_at )}</em></h3>;

  const prevId = storePrevious( id );
  const { username:propsUsername, companyId:propsCompanyId } = props;

  useEffect( () => {
    if ( !recordPermissions.users_update ) {
      updateCompanyUserData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getUser = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateUserData( cur => setValuesFromResponse( cur, { ...USER }, updateFormIsValid ) );
      } else {
        const route = propsCompanyId && history.location.pathname !== '/profile' ? `users/${id}` : 'users/userData/profile';
        const response = await getSingleRecord( route );
        if ( response ) {
          convertCompanyUserAccessFieldsForDisplay( response );
          updateUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateCompanyUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateExistingEmail( response.email );
          if ( propsCompanyId && history.location.pathname !== '/profile' ) {
            const { users_update } = response.user_auth;
            updateRecordPermissions( { users_update } );
          }
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getUser()
        .then( () => {} );
    }
  }, [id, prevId, propsCompanyId, history.location.pathname] );

  useEffect( () => {
    if ( !id ) {
      updateUserData( cur => {
        const data = { ...cur };
        const { username } = data;
        username.value = propsUsername;
        for ( const key in data ) {
          if ( data.hasOwnProperty( key ) ) {
            data[key].isValid = checkValidity( data[key] );
          }
        }
        return data;
      } );
    }
  }, [id, propsUsername] );

  const handleSubmit = e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !userDataEdited && !companyDataEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    let promises = [];
    updateLoadingState( true );
    if ( userDataEdited ) {
      promises.push( submitUserData() );
    }
    if ( companyDataEdited ) {
      promises.push( submitCompanyData() );
    }
    Promise.all( promises )
      .then( () => {
        updateLoadingState( false );
        updateFormSubmitted( false );
        updateUserDataEdited( false );
        updateCompanyDataEdited( false );

        const heading = id === 'new' ? 'Record Created' : 'Record Updated';
        updateToastData( cur => [...cur, { heading, id: Date.now() }] );
      } )
      .catch( () => {
        updateLoadingState( false );
      } );
  };

  const submitCompanyData = async () => {
    const data = { roles:  convertCompanyUserAccessFieldsForApi( extractValues( companyUserData ) ) };
    if ( id === 'new' || props.newUser ) {
      return Promise.resolve();
    } else {
      try {
        await updateRecord( `users/${id}`, data );
      } catch ( e ) {
        updateToastData( cur => [...cur, { heading: 'Error', body: e.response.data, id: Date.now() }] );
        return Promise.reject();
      }
    }
  };

  const submitUserData = async () => {
    const data = { user: extractValues( userData ) };
    if ( props.newUser ) {
      try {
        await createRecord( 'users', data );
        props.onSubmit();
      } catch ( e ) {
        updateToastData( cur => [...cur, { heading: 'Error', body: e.response.data, id: Date.now() }] );
        return Promise.reject();
      }
    } else if ( id === 'new' ) {
      try {
        data.user.roles = convertCompanyUserAccessFieldsForApi( extractValues( companyUserData ) );
        await createRecord( 'pendingUsers', data );
        updateSuccessModalMessage( `An email has been sent to ${data.user.email} to complete the user creation process` );
        updateShowSuccessModal( true );
      } catch ( e ) {
        updateToastData( cur => [...cur, { heading: 'Error', body: e.response.data, id: Date.now() }] );
        return Promise.reject();
      }
    } else {
      if ( data.user.email !== existingEmail ) {
        try {
          let user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes( user, {
            email: data.user.email
          } );
          updateNewEmail( data.user.email );
          updateShowEmailVerificationModal( true );
        } catch ( e ) {
          updateToastData( cur => [...cur, { heading: 'Error', body: e.response.data, id: Date.now() }] );
          return Promise.reject();
        }
      }
      try {
        const response = await updateRecord( `users/userData/${id}`, data );
        if ( props.onSubmit ) {
          props.onSubmit();
        } else {
          updateUserData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateExistingEmail( response.email );
        }
      } catch ( e ) {
        console.log( 'Error: ', e );
        updateToastData( cur => [...cur, { heading: 'Error', body: e.response.data, id: Date.now() }] );
        return Promise.reject();
      }
    }
  };

  const submitVerificationCode = async () => {
    updateValidationFormSubmitted( true );
    const { code } = extractValues( codeValidationData );
    if ( validationFormIsValid && validationFormEdited ) {
      try {
        await Auth.verifyCurrentUserAttributeSubmit( 'email', code );
        updateShowEmailVerificationModal( false );
        updateValidationFormSubmitted( false );
        updateValidationFormEdited( false );
        updateShowSuccessModal( true );
      } catch ( e ) {
        updateCodeValidationData( cur => {
          const updatedData = { ...cur };
          const code = { ...updatedData.code };
          code.isValid = false;
          code.errors = [{ message: e.message, key: 'amp-er' }];
          updatedData.code = code;
          return updatedData;
        } );
      }
    }
  };

  const closeSuccessModal = () => {
    updateShowSuccessModal( false );
    if ( id === 'new' ) {
      history.push( '/company/users' );
    }
  };

  let companyFields = null;
  if ( history.location.pathname !== '/profile' && !props.newUser && !props.profileUpdateNeeded ) {
    companyFields = (
      <Aux>
        <h2>Company Permissions</h2>
        <FormFieldGenerator
          formData={companyUserData}
          formDataInputHandler={updateCompanyUserData}
          formEditedHandler={updateCompanyDataEdited}
          formSubmitted={formSubmitted}
          updateFormIsValid={updateFormIsValid}
        />
      </Aux>
    );
  }

  const button = recordPermissions.users_update || id === props.userId ?
    <Form.Row className='pt-3'>
      <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Container>
      <LoadingSpinner loading={loadingState}>
        {heading}
        <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''} noValidate>
          <FormFieldGenerator
            formData={userData}
            formDataInputHandler={updateUserData}
            formEditedHandler={updateUserDataEdited}
            formSubmitted={formSubmitted}
            updateFormIsValid={updateFormIsValid}
          />
          {companyFields}
          {button}
        </Form>
      </LoadingSpinner>
      <UserEmailChangedVerificationModal
        show={showEmailVerificationModal}
        confirm={submitVerificationCode}
        email={newEmail}
        onHide={() => updateShowEmailVerificationModal( false )}
        formSubmitted={validationFormSubmitted}
        updateFormIsValid={updateValidationFormIsValid}
        updateFormEdited={updateValidationFormEdited}
        updateValidationData={updateCodeValidationData}
        validationData={codeValidationData}
      />
      <SuccessModal
        show={showSuccessModal}
        onHide={closeSuccessModal}
        message={successModalMessage}
      />
      {toasts}
    </Container>
  );
};

User.propTypes = {
  companyId: PropTypes.string,
  newUser: PropTypes.bool,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  username: PropTypes.string,
  profileUpdateNeeded: PropTypes.bool
};

export default User;
