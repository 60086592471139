import React  from 'react';
import PropTypes from 'prop-types';

const base_url = 'https://maps.google.com/maps?q=';

const GoogleMap = props => {

  if ( !props.data.property_address1.length ) {
    return null;
  }

  const address = props.data.property_address1.split( ' ' ).join( '+' );
  const query_param = address.concat( `,+${props.data.property_city},+${props.data.property_state},+USA&output=embed` );

  const onLoad = () => {
    if ( typeof props.onLoad === 'function' ) {
      props.onLoad();
    }
  };

  return (
    props.data.property_address1 &&
    <iframe
      allowFullScreen={props.allowFullScreen || true}
      frameBorder='0'
      height={props.height || '100%'}
      onLoad={onLoad}
      src={`${base_url}${query_param}`}
      style={{ border: 0, display: props.display ? 'block' : 'none' }}
      width={props.width || '100%'}
    />
  );
};

GoogleMap.propTypes = {
  allowFullScreen: PropTypes.bool,
  data: PropTypes.shape( {
    property_address1: PropTypes.string,
    property_address2: PropTypes.string,
    property_city: PropTypes.string,
    property_state: PropTypes.string,
  } ).isRequired,
  display: PropTypes.bool.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  onLoad: PropTypes.func
};

export default GoogleMap;
