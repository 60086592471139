import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PropTypes from 'prop-types';

import Attachments from './Attachments';
import Customers from './Customers';
import Events from './Events';
import Equipment from './Equipment';
import FormFieldGenerator from '../../Shared/FormFieldGenerator';
import Payments from './Payments';

const AccountTabContainer = props => {
  const paymentsTab = props.recordPermissions.accounts_view_payments ?
    <Tab eventKey="payments" title="Payments" disabled={props.disabled}>
      <Payments
        acceptPayments={props.recordPermissions.accounts_take_payments}
        processors={props.paymentProcessors}
      />
    </Tab> :
    null;

  const accountingTab = props.recordPermissions.accounts_view_accounting ?
    <Tab eventKey="accounting" title="Accounting" disabled={props.disabled}>
      <div>Accounting tab</div>
    </Tab> :
    null;

  const attachmentsTab = props.recordPermissions.accounts_view_attachments ?
    <Tab eventKey="attachments" title="Attachments" disabled={props.disabled}>
      <Attachments
        accountId={props.accountId}
        allowCreateAttachments={props.recordPermissions.accounts_create_attachments}
        allowDeleteAttachments={props.recordPermissions.accounts_delete_attachments}
        allowDownloadAttachments={props.recordPermissions.accounts_download_attachments}
        attachmentData={props.attachmentData}
        updateAttachmentData={props.updateAttachmentData}
      />
    </Tab> :
    null;

  return (
    <Tabs fill defaultActiveKey="details" id="account-tabs" onSelect={props.tabSelectHandler} mountOnEnter activeKey={props.activeTab} className='mb-3'>
      <Tab eventKey="customers" title="Customers" disabled={props.disabled} tabClassName={props.customerTabError ? 'tab-error' : ''}>
        <Customers
          allowAccountsUpdate={props.recordPermissions.accounts_update}
          allowCreateCustomer={props.recordPermissions.accounts_create_customers}
          customerRemoveHandler={props.customerRemoveHandler}
          customersData={props.customersData}
          customerSelectHandler={props.addExistingCustomerHandler}
          formEditedHandler={props.updateCustomersEdited}
          formSubmitted={props.formSubmitted}
          handleAddNewCustomer={props.addNewCustomerHandler}
          inputHandler={props.updateCustomersData}
          updateCustomersFormIsValid={props.updateCustomersFormIsValid}
        />
      </Tab>
      <Tab eventKey="details" title="Details" tabClassName={props.detailTabError ? 'tab-error' : ''}>
        <FormFieldGenerator
          formData={props.accountData}
          formDataInputHandler={props.updateAccountData}
          formSubmitted={props.formSubmitted}
          updateFormIsValid={props.updateDetailsFormIsValid}
          formEditedHandler={props.updateAccountEdited}
        />
      </Tab>
      <Tab eventKey="events" title="Events" disabled={props.disabled}>
        <Events
          accountAddress={props.accountData.property_address1.value}
          accountId={props.accountData.id.value}
          customFields={props.customFields.hasOwnProperty( 'events' ) ? props.customFields.events : []}
          allowEventsCreate={props.recordPermissions.accounts_create_events}
          eventsData={props.eventsData}
          allowEventsDelete={props.recordPermissions.accounts_delete_events}
          refreshEvents={props.refreshEvents}
        />
      </Tab>
      <Tab eventKey="equipment" title="Equipment" disabled={props.disabled}>
        <Equipment
          accountId={props.accountId}
          accountPackage={props.accountData.package_id.value}
          allowAccountsUpdate={props.recordPermissions.accounts_update}
          equipmentData={props.equipmentData}
          updateAccountData={props.updateAccountData}
          updateAccountEdited={props.updateAccountEdited}
          updateEquipmentData={props.updateEquipmentData}
          updateEquipmentEdited={props.updateEquipmentEdited}
        />
      </Tab>
      {paymentsTab}
      {accountingTab}
      {attachmentsTab}
    </Tabs>
  );
};

AccountTabContainer.propTypes = {
  accountData: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  addExistingCustomerHandler: PropTypes.func.isRequired,
  addNewCustomerHandler: PropTypes.func.isRequired,
  attachmentData: PropTypes.array.isRequired,
  customFields: PropTypes.object.isRequired,
  customerRemoveHandler: PropTypes.func.isRequired,
  customersData: PropTypes.array,
  customerTabError: PropTypes.bool.isRequired,
  detailTabError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  equipmentData: PropTypes.array,
  eventsData: PropTypes.array,
  formSubmitted: PropTypes.bool.isRequired,
  paymentProcessors: PropTypes.arrayOf( PropTypes.string ).isRequired,
  recordPermissions: PropTypes.shape( {
    accounts_create_attachments: PropTypes.number,
    accounts_create_customers: PropTypes.number,
    accounts_create_events: PropTypes.number,
    accounts_delete_attachments: PropTypes.number,
    accounts_delete_events: PropTypes.number,
    accounts_download_attachments: PropTypes.number,
    accounts_take_payments: PropTypes.number,
    accounts_update: PropTypes.number,
    accounts_update_accounting: PropTypes.number,
    accounts_view_accounting: PropTypes.number,
    accounts_view_attachments: PropTypes.number,
    accounts_view_payments: PropTypes.number,
  } ),
  refreshEvents: PropTypes.func.isRequired,
  tabSelectHandler: PropTypes.func.isRequired,
  updateAccountData: PropTypes.func.isRequired,
  updateAccountEdited: PropTypes.func.isRequired,
  updateAttachmentData: PropTypes.func.isRequired,
  updateCustomersData: PropTypes.func.isRequired,
  updateCustomersEdited: PropTypes.func.isRequired,
  updateCustomersFormIsValid: PropTypes.func.isRequired,
  updateDetailsFormIsValid: PropTypes.func.isRequired,
  updateEquipmentData: PropTypes.func.isRequired,
  updateEquipmentEdited: PropTypes.func.isRequired,
};

export default AccountTabContainer;
