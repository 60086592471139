import moment from 'moment';

export const setValuesFromResponse = ( form, responseObj, updateFormValid ) => {
  const data = { ...form };
  let formValid = true;
  for ( const key in data ) {
    if ( data.hasOwnProperty( key ) && responseObj.hasOwnProperty( key ) ) {
      if ( key.endsWith( '_id' ) ) {
        const substr = key.substring( 0, key.length - 2 ).concat( 'label' );
        const hasValue = typeof responseObj[key] === 'string' ?
          responseObj[key].trim().length :
          typeof responseObj[key] === 'number' ?
            responseObj[key] >= 0 :
            false;
        data[key].value = hasValue ? {
          label: responseObj.hasOwnProperty( substr ) ? responseObj[substr] : '',
          value: responseObj[key]
        } : null;
      } else {
        data[key].value = responseObj[key];
      }
      const display = checkDisplay( data, key );
      const isValid = display ? checkValidity( data[key] ) : true;
      data[key].isValid = isValid;
      data[key].display = display;
      formValid = formValid && isValid;
    } else if ( key.startsWith( 'custom_' ) ) {
      const display = checkDisplay( data, key );
      const isValid = display ? checkValidity( data[key] ) : true;
      data[key].isValid = isValid;
      data[key].display = display;
      formValid = formValid && isValid;
    }
  }
  if ( formValid ) {
    updateFormValid( true );
  }
  return data;
};

export const createFormArray = formObj => {
  const formElementsArray = [];
  for ( let key in formObj ) {
    if ( formObj.hasOwnProperty( key ) && ( !formObj[key].hasOwnProperty( 'display' ) || formObj[key].display ) ) {
      if ( key.endsWith( '_heading' ) && !checkDisplay( formObj, key ) ) {
        continue;
      }
      formElementsArray.push( {
        id: key,
        config: formObj[key]
      } );
    }
  }
  return formElementsArray;
};

const handleCreatableSelectInputEvents = ( updatedObj, value, e ) => {
  let val;
  const { valuesArray, inputValue } = updatedObj.value;
  if ( value && value.hasOwnProperty( 'action' ) ) {
    if ( value.action === 'input-change' ) {
      val = { valuesArray, inputValue: e };
    } else if ( value.action === 'menu-close' || value.action === 'input-blur' ) {
      return;
    } else if ( value.action === 'remove-value' || value.action === 'clear' ) {
      val = { valuesArray: e || [], inputValue };
    }
  } else if ( e.hasOwnProperty( 'key' ) ) {
    if ( e.key === 'Enter' || e.key === 'Tab' ) {
      if ( !inputValue ) {
        return;
      }
      if ( valuesArray.find( el => el.value === inputValue ) ) {
        e.preventDefault();
        // @todo add toast error if duplicate
        return;
      }
      val = { valuesArray: [...valuesArray, { label: inputValue, value: inputValue }], inputValue: '' };
      e.preventDefault();
    } else {
      return;
    }
  }
  return val;
};

export const inputHandler = ( field, { e, value }, formData, updateFormData, updateFormIsValid, formEdited ) => {
  const updatedForm = { ...formData };
  const updatedObj = { ...updatedForm[field] };
  let updatedValue;
  if ( updatedObj.type === 'creatable-select' ) {
    updatedValue = handleCreatableSelectInputEvents( updatedObj, value, e );
    if ( !updatedValue ) {
      return;
    }
  } else if ( value  ) {
    if ( value.hasOwnProperty( 'action' ) ) {
      updatedValue = e;
    } else {
      updatedValue = value;
    }
  } else if ( e == null ) {
    updatedValue = null;
  } else if ( e.hasOwnProperty( 'target' ) ) {
    if ( e.currentTarget.type === 'checkbox' ) {
      updatedValue = e.currentTarget.checked;
    } else if ( e.currentTarget.type === 'tel' ) {
      const val = e.target.value.replace( /\D+/gm, '' );
      let num;
      if ( val.length === 0 ) {
        num = '';
      } else if ( val.length < 4 ) {
        num = `(${val}`;
      } else if ( val.length < 7 ) {
        num = `(${val.substring( 0, 3 )}) ${val.substring( 3, 6 )}`;
      } else {
        num = `(${val.substring( 0, 3 )}) ${val.substring( 3, 6 )}-${val.substring( 6, 10 )}`;
      }
      updatedValue = num.trim();
    } else {
      updatedValue = e.target.value;
    }
  } else {
    updatedValue = e;
  }
  updatedObj.value = updatedValue;
  updatedObj.errors = [];
  updatedObj.isValid = checkValidity( updatedObj );
  updatedForm[field] = updatedObj;
  updateFormFieldDisplay( updatedForm );
  updateFormData( cur => {
    if ( Array.isArray( cur ) ) {
      const index = cur.findIndex( el => el.id.value === updatedForm.id.value );
      return [
        ...cur.slice( 0, index ),
        { ...updatedForm, updated: { display: false, isValid: true, validation: {}, value: true } },
        ...cur.slice( index + 1 )
      ];
    } else {
      return updatedForm;
    }
  } );

  let updatedFormValidity = true;
  for ( let field in updatedForm ) {
    if ( updatedForm.hasOwnProperty( field ) ) {
      updatedFormValidity = updatedFormValidity && updatedForm[field].isValid;
    }
  }
  if ( formEdited ) {
    formEdited( true );
  }
  updateFormIsValid( updatedFormValidity );
  if ( updatedObj.hasOwnProperty( 'customChangeHandler' ) ) {
    updatedObj.customChangeHandler();
  }
};

export const checkValidity = ( obj ) => {
  let isValid = true;
  obj.errors = [];
  if ( obj.hasOwnProperty( 'display' ) && !obj.display ) {
    return true;
  }
  if ( obj.validation.required ) {
    const passRequired =
      obj.value == null ?
        false :
        typeof obj.value === 'string' ?
          obj.value.trim() !== '' :
          typeof obj.value === 'number' ?
            obj.value >= 0 :
            moment.isMoment( obj.value ) ?
              true :
              typeof obj.value === 'object' ?
                typeof obj.value.value === 'number' ?
                  obj.value.value >= 0 :
                  obj.value.value.trim() !== '' :
                false;
    if ( !passRequired ) {
      obj.errors = [...obj.errors, obj.errorMessages.required];
    }
    isValid = isValid && passRequired;
  }
  if ( obj.validation.minLength && obj.value != null && obj.value.trim() !== '' ) {
    const passMinLength = obj.value.length >= obj.validation.minLength;
    if ( !passMinLength ) {
      obj.errors = [...obj.errors, obj.errorMessages.minLength];
    }
    isValid = isValid && passMinLength;
  }
  if ( obj.validation.maxLength && obj.value != null ) {
    const passMaxLength = obj.value.length <= obj.validation.maxLength;
    if ( !passMaxLength ) {
      obj.errors = [...obj.errors, obj.errorMessages.maxLength];
    }
    isValid = isValid && passMaxLength;
  }
  if ( obj.validation.email && obj.value != null && obj.value.length ) {
    const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    const passEmail = pattern.test( obj.value );
    if ( !passEmail ) {
      obj.errors = [...obj.errors, obj.errorMessages.email];
    }
    isValid = isValid && passEmail;
  }
  if ( obj.validation.password && obj.value != null && obj.value.length ) {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    const passPassword = pattern.test( obj.value );
    if ( !passPassword ) {
      obj.errors = [...obj.errors, obj.errorMessages.password];
    }
    isValid = isValid && passPassword;
  }
  if ( obj.validation.number && obj.value != null && obj.value.length ) {
    const pattern = /^\d+$/;
    const passNumber =  pattern.test( obj.value );
    if ( !passNumber ) {
      obj.errors = [...obj.errors, obj.errorMessages.number];
    }
    isValid = isValid && passNumber;
  }
  if ( obj.validation.positiveNumber && obj.value != null ) {
    const passPositiveNumber = typeof obj.value === 'number' ?
      obj.value >= 0 :
      typeof obj.value === 'string' ?
        !obj.value.startsWith( '-' ) :
        false;
    if ( !passPositiveNumber ) {
      obj.errors = [...obj.errors, obj.errorMessages.positiveNumber];
    }
    isValid = isValid && passPositiveNumber;
  }
  if ( obj.validation.phone && obj.value != null && obj.value.length ) {
    const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i;
    const passPhone = pattern.test( obj.value );
    if ( !passPhone ) {
      obj.errors = [...obj.errors, obj.errorMessages.phone];
    }
    isValid = isValid && passPhone;
  }
  if ( obj.validation.currency && obj.value != null && obj.value.length ) {
    const pattern = /^\d+\.?\d{0,2}?$/;
    // const pattern = /^[0-9]*\.[0-9][0-9]$/;
    const passCurrency = pattern.test( obj.value );
    if ( !passCurrency ) {
      obj.errors = [...obj.errors, obj.errorMessages.currency];
    }
    isValid = isValid && passCurrency;
  }
  if ( obj.validation.arrayMinLength ) {
    const arrayPassMinLength = Array.isArray( obj.value ) && obj.value.length >= obj.validation.arrayMinLength;
    if ( !arrayPassMinLength ) {
      obj.errors = [...obj.errors, obj.errorMessages.arrayMinLength];
    }
    isValid = isValid && arrayPassMinLength;
  }
  if ( obj.validation.creatableMinLength ) {
    const arrayPassMinLength = obj.value && Array.isArray( obj.value.valuesArray ) && obj.value.valuesArray.length >= obj.validation.creatableMinLength;
    if ( !arrayPassMinLength ) {
      obj.errors = [...obj.errors, obj.errorMessages.creatableMinLength];
    }
    isValid = isValid && arrayPassMinLength;
  }
  return isValid;
};

export const extractValues = formObj => {
  const formElementsValues = {};
  for ( let key in formObj ) {
    if ( formObj.hasOwnProperty( key ) && formObj[key].hasOwnProperty( 'value' ) && ( !formObj[key].hasOwnProperty( 'display' ) || formObj[key].display ) ) {
      if ( moment.isMoment( formObj[key].value ) ) {
        formElementsValues[key] = formObj[key].value.format( key.endsWith( '_on' ) ? 'MM-DD-YYYY' : 'YYYY-MM-DDTHH:mm:ss.SSSZ' );
      } else {
        formElementsValues[key] = formObj[key].value == null ?
          '' :
          formObj[key].type === 'multi-select' || formObj[key].type === 'async-multi-select' ?
            formObj[key].value.map( el => el.value ) :
            formObj[key].type === 'creatable-select' ?
              formObj[key].value.valuesArray.map( el => el.value ) :
              typeof formObj[key].value === 'object' && !Array.isArray( formObj[key].value ) ?
                formObj[key].value.value :
                formObj[key].value;
      }
    }
  }
  return formElementsValues;
};

export const checkDisplay = ( data, key ) => {
  if ( data[key].hasOwnProperty( 'displayCondition' ) && data[key].displayCondition.length ) {
    // eslint-disable-next-line
    return eval( data[key].displayCondition );
  }
  return true;
};

export const updateFormFieldDisplay = data => {
  for ( const key in data ) {
    if ( data.hasOwnProperty( key ) ) {
      const prevDisplay = data[key].display;
      data[key].display = checkDisplay( data, key );
      if ( !prevDisplay && data[key].display && !key.endsWith( '_heading' ) ) {
        data[key].isValid = checkValidity( data[key] );
      }
      if ( !data[key].display ) {
        data[key].isValid = true;
      }
    }
  }
};
