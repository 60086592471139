export default {
  user_id_array: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Users...',
      noOptionsMessage: () => 'No User Found',
      placeholder: 'Search for a user',
      isMulti: true
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      arrayMinLength: { message: 'Please select a user from the list', key: 'mwc-req' },
    },
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Share with',
    type: 'async-multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  }
};
