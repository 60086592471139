export default {
  code: {
    attributes: {
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      required: { message: 'Verification Code is required', key: 'vc-req' }
    },
    errors: [
      { message: 'Verification Code is required', key: 'vc-req' }
    ],
    isValid: false,
    label: 'Verification Code',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  }
};
