import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import Aux from '../../../../hoc/Aux';
import ScheduleModalRowContent from '../ScheduleModalRowContent';
import ScheduleModalButton from '../ScheduleModalButton';

const UserTab = props => (
  <Aux>
    <Row style={{ display: props.display ? 'flex' : 'none' }}>
      <ScheduleModalRowContent name='Name'>{props.data.user_first_name} {props.data.user_last_name}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Phone'>{props.data.user_phone}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Email'>{props.data.user_email}</ScheduleModalRowContent>
    </Row>
    {
      props.display && props.showLink && props.data.user_id &&
        <ScheduleModalButton link={`/users/${props.data.user_id}`}>View User</ScheduleModalButton>
    }
  </Aux>
);

UserTab.propTypes = {
  data: PropTypes.shape( {
    user_first_name: PropTypes.string.isRequired,
    user_last_name: PropTypes.string.isRequired,
    user_phone: PropTypes.string.isRequired,
    user_email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
  } ),
  display: PropTypes.bool.isRequired,
  showLink: PropTypes.number.isRequired
};

export default UserTab;
