export default {
  password: {
    attributes: {
      placeholder: 'Password',
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      required: { message: 'Password is required', key: 'pw-req' },
      minLength: { message: 'Password must be at least 8 characters', key: 'pw-min' },
      maxLength: { message: 'Password cannot be longer than 32 characters', key: 'pw-max' },
      password: { message: 'Password must contain at least 1 lowercase character, 1 uppercase character, 1 numeric character and 1 special character', key: 'pw-weak' }
    },
    errors: [
      { message: 'Password is required', key: 'pw-req' }
    ],
    isValid: false,
    label: 'New Password',
    type: 'password',
    validation: {
      maxLength: 32,
      minLength: 8,
      password: true,
      required: true
    },
    value: ''
  },
  first_heading: {
    element: 'Row',
    isValid: true,
    text: ''
  },
  confirmPassword: {
    attributes: {
      placeholder: 'Password',
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      required: { message: 'Password is required', key: 'pw-req' },
      minLength: { message: 'Password must be at least 8 characters', key: 'pw-min' },
      maxLength: { message: 'Password cannot be longer than 32 characters', key: 'pw-max' },
      password: { message: 'Password must contain at least 1 lowercase character, 1 uppercase character, 1 numeric character and 1 special character', key: 'pw-weak' }
    },
    errors: [
      { message: 'Password is required', key: 'pw-req' }
    ],
    isValid: false,
    label: 'New Password',
    type: 'password',
    validation: {
      maxLength: 32,
      minLength: 8,
      password: true,
      required: true
    },
    value: ''
  },
  second_heading: {
    element: 'Row',
    isValid: true,
    text: ''
  },
  code: {
    attributes: {
      size: 'lg'
    },
    css: {
      xs: 12,
      md: 12,
      lg: 12
    },
    errorMessages: {
      required: { message: 'Verification Code is required', key: 'vc-req' }
    },
    errors: [
      { message: 'Verification Code is required', key: 'vc-req' }
    ],
    isValid: false,
    label: 'Verification Code',
    type: 'number',
    validation: {
      required: true
    },
    value: ''
  }
};
