import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Async from 'react-select/async/dist/react-select.esm';
import { useParams, useHistory } from 'react-router-dom';

import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import {
  cloneDeep,
  createRecord,
  dateTimeFormatter,
  disableFormFields,
  getSingleRecord,
  search,
  selectComponentStyles,
  storePrevious,
  tableDeleteButton,
  updateRecord
} from '../../helpers/functions/utilities';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import { GROUP } from '../../helpers/constants/initialData';
import initialGroupFormFields from './GroupFormFields';
import Aux from '../../hoc/Aux';
import AddButton from '../Shared/AddButton';
import Toasts from '../Shared/Toasts';

const Group = () => {

  const { id } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [groupData, updateGroupData] = useState( cloneDeep( initialGroupFormFields ) );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );
  const [selectedUser, updateSelectedUser] = useState( {} );
  const [members, updateMembers] = useState( [] );
  const [managers, updateManagers] = useState( [] );
  const [recordPermissions, updateRecordPermissions] = useState( { groups_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Group</h3> : <h3>Last Updated: <em>{dateTimeFormatter( groupData.updated_at )}</em></h3>;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( !recordPermissions.groups_update ) {
      updateGroupData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getGroup = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateGroupData( cur => setValuesFromResponse( cur, { ...GROUP }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `groups/${id}` );
        if ( response ) {
          updateGroupData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          updateMembers( response.members );
          updateManagers( response.managers );
          const { groups_update } = response.user_auth;
          updateRecordPermissions( { groups_update } );
        }
      }
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getGroup()
        .then( () => {} );
    }
  }, [id, prevId] );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const data = {
        group: { ...extractValues( groupData ) },
        managers,
        members
      };
      if ( id === 'new' ) {
        const response = await createRecord( 'groups', data );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          history.push( `${response.id}` );
        }
      } else {
        const response = await updateRecord( `groups/${id}`, data );
        if ( response ) {
          updateFormSubmitted( false );
          updateFormEdited( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const addMemberHandler = () => {
    const existingMember = members.find( el => el.value === selectedUser.value );
    if ( !existingMember ) {
      updateMembers( cur => ( [...cur, selectedUser] ) );
    }
    updateSelectedUser( {} );
    updateFormEdited( true );
  };

  const addManagerHandler = () => {
    const existingManager = managers.find( el => el.value === selectedUser.value );
    if ( !existingManager ) {
      updateManagers( cur => ( [...cur, selectedUser] ) );
    }
    updateSelectedUser( {} );
    updateFormEdited( true );
  };

  const removeMemberHandler = ( member ) => {
    updateMembers( cur => {
      let members = [...cur];
      const index = cur.findIndex( el => el.value === member.value );
      if ( index !== -1 ) {
        members.splice( index, 1 );
        updateFormEdited( true );
      }
      return members;
    } );
  };

  const removeManagerHandler = ( manager ) => {
    updateManagers( cur => {
      let managers = [...cur];
      const index = cur.findIndex( el => el.value === manager.value );
      if ( index !== -1 ) {
        managers.splice( index, 1 );
        updateFormEdited( true );
      }
      return managers;
    } );
  };

  const userSearch = selectedUser.value ?
    <Select
      isClearable
      value={selectedUser}
      options={[selectedUser]}
      onChange={() => updateSelectedUser( {} )}
      styles={selectComponentStyles}
    /> :
    <Async
      cacheOptions
      value={selectedUser.value}
      noOptionsMessage={() => 'No Users Found'}
      loadOptions={( val, cb ) => search( val, cb, ['users'] )}
      onChange={val => updateSelectedUser( val )}
      loadingMessage={() => 'Getting Users...'}
      placeholder='Search for a user'
      styles={selectComponentStyles}
    />;

  const addUserRow = recordPermissions.groups_update ?
    <Row className='pb-3'>
      <Col xs={12} md={6} lg={6}>
        {userSearch}
      </Col>
      <Col xs={12} sm={6} md='auto' className='add-member-container'>
        <AddButton size='28px' text='Member' clickHandler={addMemberHandler} disabled={!selectedUser.value} />
      </Col>
      <Col xs={12} sm={6} md='auto' className='add-manager-container'>
        <AddButton size='28px' text='Manager' clickHandler={addManagerHandler} disabled={!selectedUser.value} />
      </Col>
    </Row> :
    null;

  const button = recordPermissions.groups_update ?
    <Form.Row>
      <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
    </Form.Row> :
    null;

  const membersList = (
    <ListGroup>
      <ListGroup.Item><strong>Members</strong></ListGroup.Item>
      {members.map( member => {
        const deleteButton = recordPermissions.groups_update ?
          <Col xs={3}>
            {tableDeleteButton( () => removeMemberHandler( member ) )}
          </Col> :
          null;

        return (
          <ListGroup.Item key={member.value}>
            <Row className='align-items-center'>
              <Col xs={recordPermissions.groups_update ? 9 : 12}>{member.label}</Col>
              {deleteButton}
            </Row>
          </ListGroup.Item>
        );
      } )
      }
    </ListGroup>
  );

  const managersList = (
    <ListGroup>
      <ListGroup.Item><strong>Managers</strong></ListGroup.Item>
      {managers.map( manager => {
        const deleteButton = recordPermissions.groups_update ?
          <Col xs={3}>
            {tableDeleteButton( () => removeManagerHandler( manager ) )}
          </Col> :
          null;

        return (
          <ListGroup.Item key={manager.value}>
            <Row className='align-items-center'>
              <Col xs={recordPermissions.groups_update ? 9 : 12}>{manager.label}</Col>
              {deleteButton}
            </Row>
          </ListGroup.Item>
        );
      } )
      }
    </ListGroup>
  );

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      <Container>
        <LoadingSpinner loading={loadingState}>
          {heading}
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''} noValidate>
            <FormFieldGenerator
              formData={groupData}
              formDataInputHandler={updateGroupData}
              formEditedHandler={updateFormEdited}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
            />
            {addUserRow}
            <Row>
              <Col xs={12} md={6} className='pb-3'>
                {membersList}
              </Col>
              <Col xs={12} md={6} className='pb-3'>
                {managersList}
              </Col>
            </Row>
            {button}
          </Form>
        </LoadingSpinner>
      </Container>
      {toasts}
    </Aux>
  );
};

export default Group;
