import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './AddButton.css';

const AddButton = props => {
  return props.route ?
    <Link to={`/${props.route}/new${props.tab}`} className='AddNew'>
      <Button variant='outline-success' disabled={props.disabled ? props.disabled : false} className='w-100'>
        <span className='pr-2 AddButtonText'>{props.text ? props.text : 'Add New'}</span>
        <svg key='plus' className="bi bi-plus-square-fill addButtonIcon" width={props.size ? props.size : '1em'} height={props.size ? props.size : '1em'} viewBox="0 0 16 16" fill={props.color ? props.color : '#28a745'} xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd" />
        </svg>
      </Button>
    </Link> :
    <Button variant='outline-success' className='AddNew w-100' onClick={props.clickHandler} disabled={props.disabled ? props.disabled : false}>
      <span className='pr-2 AddButtonText'>{props.text ? props.text : 'Add New'}</span>
      <svg key='plus' className="bi bi-plus-square-fill addButtonIcon" width={props.size ? props.size : '1em'} height={props.size ? props.size : '1em'} viewBox="0 0 16 16" fill={props.color ? props.color : '#28a745'} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd" />
      </svg>
    </Button>;
};

AddButton.defaultProps = {
  tab: ''
};

AddButton.propTypes = {
  clickHandler: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  route: PropTypes.string,
  size: PropTypes.string,
  tab: PropTypes.string,
  text: PropTypes.string
};

export default AddButton;
