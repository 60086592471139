import React  from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SuccessModal = ( props ) => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
  >
    <Modal.Body className='mx-auto text-center'>
      <h2>Success!</h2>
      <svg className="bi bi-check-circle" width="10em" height="10em" viewBox="0 0 16 16" fill="#28a745"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
          d="M15.354 2.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3-3a.5.5 0 11.708-.708L8 9.293l6.646-6.647a.5.5 0 01.708 0z"
          clipRule="evenodd"/>
        <path fillRule="evenodd"
          d="M8 2.5A5.5 5.5 0 1013.5 8a.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63.5.5 0 11-.5.865A5.472 5.472 0 008 2.5z"
          clipRule="evenodd"/>
      </svg>
      <p>{props.message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Row className='w-100'>
        <Col xs={12} sm='auto' className='mx-auto pt-3'>
          <Button onClick={props.onHide} size='lg' variant='success' className='w-100'>OK</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
);

SuccessModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string
};

export default SuccessModal;
