import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/actions/actions';
import classes from '../../containers/Layout/SideDrawer/SideDrawer.module.css';

import FilterGenerator from '../SideDrawerFilters/FilterGenerator';
import SideDrawerToggle from '../../containers/Layout/SideDrawer/SideDrawerToggle';

const SideDrawerFilterContainer = props => {

  const [filtersModified, updateFiltersModified] = useState( false );

  const { onComponentChanged, location, customFields } = props;
  useEffect( () => {
    const arr = location.split( ' ' );
    onComponentChanged( location, customFields[arr[arr.length - 1]] );
    updateFiltersModified( false );
  }, [onComponentChanged, location, updateFiltersModified, customFields] );

  const handleFilterUpdate = ( field, prop, value ) => {
    updateFiltersModified( true );
    props.onFilterUpdate( field, prop, value );
  };

  const handleSubmit = ( e ) => {
    e.preventDefault();
    props.onApplyFilters();
    updateFiltersModified( false );
  };

  const component = props.filterType === location ?
    <FilterGenerator
      changeHandler={handleFilterUpdate}
      filtersValues={props.filters}
    /> : null;

  return (
    <div className={props.wrapperClasses}>
      <div className={classes.SideDrawerContainer}>
        <Form onSubmit={handleSubmit}>
          <Form.Text>
            <h5>Filter <span className={classes.Capitalize}>{location.replace( '_', ' ' )}</span> By</h5>
          </Form.Text>
          {component}
          <Form.Row>
            <Form.Group as={Col} controlId="submit" xs={12} sm='auto' className='mx-auto'>
              <Button variant="primary" type="submit" disabled={!filtersModified} className='w-100'>
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      <SideDrawerToggle sideDrawerToggleHandler={props.sideDrawerToggleHandler} showSideDrawer={props.showSideDrawer} buttonText='Filters' />
    </div>
  );
};
// @todo create a saga for apply filters
SideDrawerFilterContainer.propTypes = {
  customFields: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  filterType: PropTypes.string,
  location: PropTypes.string.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onComponentChanged: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  showSideDrawer: PropTypes.bool.isRequired,
  sideDrawerToggleHandler: PropTypes.func.isRequired,
  wrapperClasses: PropTypes.string.isRequired
};

const mapStateToProps = state => ( {
  customFields: state.company.customFields,
  filters: state.sideDrawer.filters,
  filterType: state.sideDrawer.filterType
} );

const mapDispatchToProps = dispatch => ( {
  onFilterUpdate: ( field, prop, value ) => dispatch( { type: actionTypes.UPDATE_SIDE_DRAWER_FILTERS, payload: { field, prop, value } } ),
  onComponentChanged: ( component, customFields ) => dispatch( { type: actionTypes.SIDE_DRAWER_COMPONENT_CHANGED, payload: { component, customFields } } ),
  onApplyFilters: () => dispatch( actionTypes.applySideDrawerFilters() )
} );

export default connect( mapStateToProps, mapDispatchToProps )( SideDrawerFilterContainer );
