import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Timeline as ReactTimeline, TimelineEvent } from 'react-event-timeline';
import { BsPencil, BsPower } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { getSingleRecord, storePrevious } from '../../helpers/functions/utilities';
import FileEarmarkPlus from '../Shared/FileEarmarkPlus';
import FileDiff from '../Shared/FileDiff';
import Aux from '../../hoc/Aux';
import classes from '../../App.module.css';
import sideDrawerClasses from '../../containers/Layout/SideDrawer/SideDrawer.module.css';
import SideDrawerToggle from '../../containers/Layout/SideDrawer/SideDrawerToggle';

const Timeline = props => {

  const [history, updateHistory] = useState( [] );
  const [loading, updateLoading] = useState( false );
  const [getInitialHistory, updateGetInitialHistory] = useState( false );
  const [haveAllRecords, updateHaveAllRecords] = useState( false );

  const innerRef = useRef( null );
  // console.log( 'location: ', props.location );
  let [type, id = props.userId, third] = props.location.split( ' ' );
  // console.log( 'type: ', type );
  // console.log( 'id: ', id );
  // console.log( 'third: ', third );

  if ( type === 'company' ) {
    type = 'companies';
    id = props.companyId;
  }
  const prevId = storePrevious( id );
  const prevType = storePrevious( type );

  useEffect( () => {
    if ( ( id && prevId !== id ) || ( type && prevType !== type ) ) {
      updateHistory( [] );
      updateGetInitialHistory( false );
      updateHaveAllRecords( false );
    }
  }, [id, prevId, type, prevType] );

  useEffect( () => {
    const getHistory = async () => {
      updateLoading( true );
      const response = await getSingleRecord( `/history/${type}/${id}/${history.length}` );
      if ( response ) {
        console.log( 'response: ', response );
        if ( response.length < 20 ) {
          updateHaveAllRecords( true );
        }
        updateHistory( cur => [...cur, ...response] );
        updateLoading( false );
      }
    };

    const handleOnScroll = ( e ) => {
      const scrollHeight = e.target.scrollTop;
      const clientHeight = div.clientHeight;
      const elHeight = div.scrollHeight;

      if ( scrollHeight + clientHeight >= elHeight && !loading && !haveAllRecords ) {
        console.log( 'get more events' );
        getHistory()
          .then( () => {} );
      }
    };

    if ( history.length === 0 && getInitialHistory && !loading && !haveAllRecords ) {

      getHistory()
        .then( () => {} );
    }

    const div = innerRef.current;
    div.addEventListener( 'scroll', handleOnScroll );

    return () => {
      div.removeEventListener( 'scroll', handleOnScroll );
    };
  }, [type, id, loading, updateLoading, getInitialHistory, history, updateHistory, haveAllRecords, updateHaveAllRecords] );

  const historyComponent = id === 'new' ? null : getInitialHistory ?
    <ReactTimeline>
      {history.length ?
        history.map( event => {
          const icon = event.data.hasOwnProperty( 'created at' ) ?
            <BsPower /> :
            event.data.hasOwnProperty( 'note created' ) ?
              <FileEarmarkPlus /> :
              event.data.hasOwnProperty( 'message' ) ?
                <FileDiff /> :
                <BsPencil />;
          return (
            <TimelineEvent
              icon={<IconContext.Provider value={{ size: 18 }} >{icon}</IconContext.Provider>}
              key={event.id}
              title={`By ${event.name}`.concat( event.hasOwnProperty( 'company_name' ) ? ` of ${event.company_name}` : '' )}
              collapsible
              showContent={true}
              createdAt={moment.utc( event.created_at, 'YYYY-MM-DDTHH:mm:ss.SSS' ).local().format( 'l LT' )}
            >
              {Object.entries( event.data ).map( ( [key, value] ) => {
                return ( key === 'created at' || key === 'company id' ) ? null :
                  <p key={event.id + key}>
                    <span className={classes.TimelineKey}>{key.endsWith( ' at' ) || key.endsWith( ' on' ) ? key.substring( 0, key.length - 3 ) : key}</span>
                    {key === 'note created' || key === 'signup complete' ?
                      null :
                      Array.isArray( value ) ?
                        value.map( ( val, index ) => (
                          <Aux key={index}>
                            {index === 0 ?
                              <Aux><br /><span>{val}</span><br /></Aux> :
                              <Aux><span>{val}</span><br /></Aux>}</Aux>
                        ) ) :
                        `: ${key.endsWith( ' at' ) ? moment.utc( value, 'YYYY-MM-DDTHH:mm:ss.SSS' ).local().format( 'l LT' ) : value}` }
                  </p>;
              } )}
            </TimelineEvent>
          );
        } ) :
        <TimelineEvent title='No History Available' /> }
    </ReactTimeline> :
    <Row className='py-3 justify-content-center'>
      <Col xs={12} sm={6} md='auto'>
        <Button
          variant="primary"
          onClick={() => updateGetInitialHistory( true )}
          className='w-100'
        >
          Get History
        </Button>
      </Col>
    </Row>;

  return (
    <div className={props.wrapperClasses}>
      <div className={sideDrawerClasses.SideDrawerContainer} ref={innerRef}>
        {historyComponent}
      </div>
      <SideDrawerToggle sideDrawerToggleHandler={props.sideDrawerToggleHandler} showSideDrawer={props.showSideDrawer} buttonText={`${type} history`} />
    </div>
  );
};

Timeline.propTypes = {
  showSideDrawer: PropTypes.bool.isRequired,
  sideDrawerToggleHandler: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  wrapperClasses: PropTypes.string.isRequired
};

export default Timeline;

