import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import classes from '../../App.module.css';
import SideDrawer from './SideDrawer/SideDrawer';
import Routes from '../../Routes/Routes';
import ErrorModal from '../../components/Modal/ErrorModal';
import SuccessModal from '../../components/Modal/SuccessModal';
import * as actionTypes from '../../store/actions/actions';
import { storePrevious } from '../../helpers/functions/utilities';

const routeNames = [
  'accounts',
  'company',
  'customers',
  'events',
  'groups',
  'leaderboard',
  'leadProviders',
  'leads',
  'packages',
  'pendingUsers',
  'products',
  'users'
];

const Main = ( props ) => {

  const [showSideDrawer, updateShowSideDrawer] = useState( false );
  const [displaySideDrawer, updateDisplaySideDrawer] = useState( true );
  const [match, updateMatch] = useState( null );

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect( () => {
    updateMatch( matchPath( pathname, { path: '/:name/:id', exact: false, strict: false } ) );
  }, [location, pathname] );

  const { historyAuth } = props;
  useEffect( () => {
    let updatedDisplaySideDrawer = true;
    if ( location.pathname === '/home' || location.pathname === '/leaderboard' || location.pathname === '/notifications' ) {
      updatedDisplaySideDrawer = false;
      if ( showSideDrawer ) {
        updateShowSideDrawer( false );
      }
    } else if ( match && routeNames.includes( match.params.name ) ) {
      if ( match.params.name === 'company' ) {
        if ( ( match.params.id === 'details' && !historyAuth.details_history ) ||
          ( match.params.id === 'reporting' || match.params.id === 'payroll' || match.params.id === 'fields' ) ) {
          updatedDisplaySideDrawer = false;
          if ( showSideDrawer ) {
            updateShowSideDrawer( false );
          }
        }
      } else if ( match.params.id ) {
        if ( !historyAuth[`${match.params.name}_history`] ) {
          updatedDisplaySideDrawer = false;
          if ( showSideDrawer ) {
            updateShowSideDrawer( false );
          }
        }
      }
    }
    updateDisplaySideDrawer( updatedDisplaySideDrawer );
  }, [historyAuth, match, showSideDrawer, location.pathname] );

  const { currentCompanyId } = props;
  const prevCompanyId = storePrevious( currentCompanyId );

  useEffect( () => {
    if ( prevCompanyId && prevCompanyId !== currentCompanyId ) {
      history.push( '/home' );
    }
  }, [currentCompanyId, prevCompanyId, history] );

  let mainClasses = [classes.MainContainer];
  if ( showSideDrawer && displaySideDrawer ) {
    mainClasses.push( classes.SideDrawerOpen );
  } else if ( displaySideDrawer ) {
    mainClasses.push( classes.MainPadding );
  }

  const sideDrawer = props.isSignedIn && displaySideDrawer ?
    <SideDrawer
      historyAuth={props.historyAuth}
      pathMatch={match}
      pathname={location.pathname}
      showSideDrawer={showSideDrawer}
      sideDrawerToggleHandler={updateShowSideDrawer}
      companyId={props.currentCompanyId}
      userId={props.userId}
    /> :
    null;

  const handleCloseErrorModal = () => {
    props.onCloseErrorModal();
    history.push( '/home' );
  };

  return (
    <main className='text-center'>
      {sideDrawer}
      <div className={ mainClasses.join( ' ' ) }>
        <Routes />
      </div>
      <ErrorModal
        show={props.showErrorModal}
        onHide={handleCloseErrorModal}
        message={props.detailedErrorMessage}
      >
        {props.serverErrorMessage}
      </ErrorModal>
      <SuccessModal
        show={props.showSuccessModal}
        onHide={props.onCloseSuccessModal}
        message={props.successMessage}
      />
    </main>
  );
};

Main.propTypes = {
  currentCompanyId: PropTypes.string,
  detailedErrorMessage: PropTypes.string,
  historyAuth: PropTypes.shape( {
    accounts_history: PropTypes.number,
    customers_history: PropTypes.number,
    details_history: PropTypes.number,
    events_history: PropTypes.number,
    groups_history: PropTypes.number,
    lead_providers_history: PropTypes.number,
    leads_history: PropTypes.number,
    packages_history: PropTypes.number,
    products_history: PropTypes.number,
    users_history: PropTypes.number
  } ),
  isSignedIn: PropTypes.bool.isRequired,
  onCloseErrorModal: PropTypes.func.isRequired,
  onCloseSuccessModal: PropTypes.func.isRequired,
  serverErrorMessage: PropTypes.string,
  showErrorModal: PropTypes.bool.isRequired,
  showSuccessModal: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ( {
  detailedErrorMessage: state.error.detailedMessage,
  historyAuth: state.company.auth,
  serverErrorMessage: state.error.serverMessage,
  showErrorModal: state.error.showModal,
  showSuccessModal: state.success.showModal,
  successMessage: state.success.message,
  userId: state.user.id
} );

const mapDispatchToProps = dispatch => ( {
  onCloseErrorModal: () => dispatch( { type: actionTypes.ERROR_RESET } ),
  onCloseSuccessModal: () => dispatch( { type: actionTypes.SUCCESS_RESET } )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Main );
