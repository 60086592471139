import React from 'react';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import PropTypes from 'prop-types';

import Aux from '../../../../hoc/Aux';
import ScheduleModalRowContent from '../ScheduleModalRowContent';
import ScheduleModalButton from '../ScheduleModalButton';
import { addressFormatter } from '../../../../helpers/functions/utilities';

const EventTab = props => (
  <Aux>
    <Row style={{ display: props.display ? 'flex' : 'none' }}>
      <ScheduleModalRowContent name='Property Address' >
        {addressFormatter( {
          address1: props.data.property_address1,
          address2: props.data.property_address2,
          city: props.data.property_city,
          state: props.data.property_state,
          zip: props.data.property_zip
        } )}
      </ScheduleModalRowContent>
      <ScheduleModalRowContent name='Duration'>{props.data.duration ? `${props.data.duration} Hours` : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Date Completed'>{props.data.completed_at ? moment( props.data.completed_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Estimated On Site At'>{props.data.estimate_on_site_at ? moment( props.data.estimate_on_site_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='In Route At'>{props.data.in_route_at ? moment( props.data.in_route_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='On Site At'>{props.data.on_site_at ? moment( props.data.on_site_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Event Created'>{props.data.created_at ? moment( props.data.created_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
      <ScheduleModalRowContent name='Event Last Updated'>{props.data.updated_at ? moment( props.data.updated_at ).local().format( 'l LT' ) : ''}</ScheduleModalRowContent>
    </Row>
    {
      props.display && props.showLink && props.data.id &&
        <ScheduleModalButton link={`/events/${props.data.id}`}>View Event</ScheduleModalButton>
    }
  </Aux>
);

EventTab.propTypes = {
  data: PropTypes.shape( {
    completed_at: PropTypes.string,
    created_at: PropTypes.string,
    duration: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
    estimate_on_site_at: PropTypes.string,
    id: PropTypes.string,
    in_route_at: PropTypes.string,
    on_site_at: PropTypes.string,
    property_address1: PropTypes.string,
    property_address2: PropTypes.string,
    property_city: PropTypes.string,
    property_state: PropTypes.string,
    property_zip: PropTypes.string,
    updated_at: PropTypes.string,
  } ),
  display: PropTypes.bool.isRequired,
  showLink: PropTypes.number.isRequired
};

export default EventTab;
