import { ACCESS_OPTIONS, ACCESS_BOOLEAN } from '../../helpers/constants/options';

export default {
  is_active: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    errors: [],
    isValid: true,
    label: 'Active',
    type: 'select',
    validation: {},
    value: 'No'
  },

  leads_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    text: 'Leads',
    isValid: true
  },
  acc_str_view_leads: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_create_leads: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_update_leads: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_delete_leads: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_leads_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_assign_leads: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No" && data.acc_str_update_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Assign Leads',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_convert_leads: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_leads.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Convert To Account',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },

  accounts_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    isValid: true,
    text: 'Accounts'
  },
  acc_str_view_accounts: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_create_accounts: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_update_accounts: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_delete_accounts: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_accounts_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_assign_accounts: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No" && data.acc_str_update_accounts.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Assign Accounts',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_create_accounts_customers: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create Account Customers',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_update_accounts_customers: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update Account Customers',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_create_accounts_events: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create Account Events',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_delete_accounts_events: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete Account Events',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_view_accounts_payments: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'View Payment History',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_take_accounts_payments: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Accept Payments',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_place_accounts_online: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Place Online',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_place_accounts_on_test: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Place On Test',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_accounts_accounting: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View Accounting Tab',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_accounts_accounting: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update Accounting',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_view_accounts_attachments: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View Attachments Tab',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_create_accounts_attachments: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create Attachments',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_download_accounts_attachments: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Download Attachments',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_str_delete_accounts_attachments: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_accounts.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete Attachments',
    type: 'select',
    validation: {},
    value: 'No'
  },

  customers_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    isValid: true,
    text: 'Customers'
  },
  acc_bool_view_customers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_customers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_customers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_customers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_view_customers.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_customers_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_view_customers.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  events_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    isValid: true,
    text: 'Events'
  },
  acc_str_view_events: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_create_events: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_str_update_events: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },
  acc_bool_delete_events: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_events_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_str_view_events.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  schedule_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    isValid: true,
    text: 'Schedule'
  },
  acc_str_view_schedule: {
    attributes: {
      options: ACCESS_OPTIONS
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'Assigned To User'
  },

  company_heading: {
    displayCondition: 'data.is_active.value.value !== "No"',
    isValid: true,
    text: 'Company'
  },
  acc_bool_company_tab_access: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View Company In Navigation Menu',
    type: 'select',
    validation: {},
    value: 'No'
  },

  company_details_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Details'
  },
  acc_bool_update_company_details: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_company_details_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  users_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Users'
  },
  acc_bool_view_users: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_users: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_users.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_users: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_users.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_users: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_users.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_users_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_users.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  lead_providers_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Lead Providers'
  },
  acc_bool_view_lead_providers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_lead_providers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_lead_providers.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_lead_providers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_lead_providers.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_lead_providers: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_lead_providers.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_lead_providers_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_lead_providers.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  c_fields_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Custom Fields'
  },
  acc_bool_view_custom_fields: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_custom_fields: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_custom_fields.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_custom_fields: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_custom_fields.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },

  groups_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Groups'
  },
  acc_bool_view_groups: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_groups: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_groups.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_groups: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_groups.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_groups: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_groups.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_groups_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_groups.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  packages_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Packages'
  },
  acc_bool_view_packages: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_packages: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_packages.value.value !== "No"',
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_packages: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_packages.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_packages: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_packages.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_packages_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_packages.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  products_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Products'
  },
  acc_bool_view_products: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_products: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_products.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_products: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_products.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_delete_products: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_products.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Delete',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_view_products_history: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No" && data.acc_bool_view_products.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View History',
    type: 'select',
    validation: {},
    value: 'No'
  },

  payroll_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Payroll'
  },
  acc_bool_view_payroll: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_create_payroll: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Create',
    type: 'select',
    validation: {},
    value: 'No'
  },
  acc_bool_update_payroll: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'Update',
    type: 'select',
    validation: {},
    value: 'No'
  },

  reports_heading: {
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    isValid: true,
    text: 'Reporting'
  },
  acc_bool_view_reports: {
    attributes: {
      options: ACCESS_BOOLEAN
    },
    displayCondition: 'data.is_active.value.value !== "No" && data.acc_bool_company_tab_access.value.value !== "No"',
    errorMessages: {},
    errors: [],
    isValid: true,
    label: 'View',
    type: 'select',
    validation: {},
    value: 'No'
  },
};
