import { REPORTING_TABLES } from '../../helpers/constants/reportingOptions';

export default {
  record_type: {
    attributes: {
      options: [...REPORTING_TABLES]
    },
    errorMessages: {
      required: { message: 'Please select an option from the list', key: 'mrt-req' },
    },
    errors: [],
    isValid: false,
    label: 'Report on',
    type: 'select',
    validation: {
      required: true
    },
    value: {
      label: '',
      value: '',
      where: []
    }
  },
  record_fields: {
    attributes: {
      options: []
    },
    css: {
      xs: 12,
      md: 9,
      lg: 9
    },
    displayCondition: 'data.record_type.value.value && data.record_type.value.value.length',
    errorMessages: {
      arrayMinLength: { message: 'Please select an option from the list', key: 'rfs-req' },
    },
    errors: [],
    isValid: false,
    label: 'Retrieve Fields',
    type: 'multi-select',
    validation: {
      arrayMinLength: 1
    },
    value: []
  }
};
