import React from 'react';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import Aux from '../../hoc/Aux';
import './DateFilter.css';

const DateFilter = props => {

  const optionFields = ['All', 'On', 'Before', 'After', 'Between'];
  const options = optionFields.map( option => <option key={option}>{option}</option> );

  const valueInput = props.filterValue === 'All' ? null : (
    <Form.Group as={Col} xs={6} className='mx-auto'>
      <Datetime
        onChange={val => props.onChangeHandler( props.id, 'value', val )}
        id={props.id + '_value'}
        timeFormat={false}
        closeOnSelect
        className='display-above'
      />
    </Form.Group>
  );

  const secondValueInput = props.filterValue === 'Between' ? (
    <Form.Row>
      <Form.Group as={Col} xs={{ span: 6, offset: 6 }}>
        <span>and</span>
        <Datetime
          onChange={val => props.onChangeHandler( props.id, 'secondValue', val )}
          id={props.id + '_secondValue'}
          timeFormat={false}
          closeOnSelect
          className='display-above'
        />
      </Form.Group>
    </Form.Row>
  ) : null;

  return (
    <Aux>
      <Form.Row className='align-items-start'>
        <Form.Group controlId={props.id + '_expand'}>
          <Form.Check
            id={props.id + '_expand'}
            type="switch"
            label={props.label}
            onChange={() => props.onChangeHandler( props.id, 'show', !props.expand )}
          />
        </Form.Group>
      </Form.Row>
      <Collapse in={props.expand}>
        <Form.Row>
          <Form.Group as={Col} xs={12} className='mx-auto'>
            <Form.Row>
              <Form.Group as={Col} xs={6}>
                <Form.Control
                  id={props.id + '_filter'}
                  as="select"
                  size='sm'
                  onChange={e => props.onChangeHandler( props.id, 'filter', e.target.value )}
                >
                  {options}
                </Form.Control>
              </Form.Group>
              {valueInput}
            </Form.Row>
            {secondValueInput}
          </Form.Group>
        </Form.Row>
      </Collapse>
    </Aux>
  );
};

DateFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  filterValue: PropTypes.string.isRequired
};

export default DateFilter;
