import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import CompanyTabContainer from './CompanyTabs/CompanyTabContainer';
import {
  disableFormFields,
  getSingleRecord,
  updateRecord
} from '../../helpers/functions/utilities';
import { setValuesFromResponse, extractValues } from '../../helpers/functions/formValidator';
import initialCompanyFormFields from './CompanyTabs/Details/CompanyDetailsFormFields';
import { cloneDeep } from '../../helpers/functions/utilities';
import { COMPANY } from '../../helpers/constants/initialData';
import axios from '../../axios-instances/internal-api';

const Company = props => {

  let { id, tab } = useParams();
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [companyData, updateCompanyData] = useState( cloneDeep( initialCompanyFormFields ) );
  const [companyEdited, updateCompanyEdited] = useState( false );
  const [showUsersTable, updateShowUsersTable] = useState( false );
  const [showProvidersTable, updateShowProvidersTable] = useState( false );
  const [showGroupsTable, updateShowGroupsTable] = useState( false );
  const [showPackagesTable, updateShowPackagesTable] = useState( false );
  const [showFields, updateShowFields] = useState( false );
  const [formIsValid, updateFormIsValid] = useState( false );
  const [companyDetailsLoaded, updateCompanyDetailsLoaded] = useState( false );
  const [initialLoad, updateInitialLoad] = useState( true );
  const [pendingUsers, updatePendingUsers] = useState( false );
  const [showPendingUsersTable, updateShowPendingUsersTable] = useState( false );
  const [showProductsTable, updateShowProductsTable] = useState( false );
  const [companyPermissions, updateCompanyPermissions] = useState( { details_update: 1 } );
  const [currentTab, updateCurrentTab] = useState( tab );

  if ( props.companyId ) {
    id = props.companyId;
  }

  useEffect( () => {
    if ( !companyPermissions.details_update ) {
      updateCompanyData( cur => disableFormFields( cur ) );
    }
  }, [companyPermissions] );

  useEffect( () => {
    const checkPendingUsers = async () => {
      const response = await axios.get( 'pendingUsers/check' );
      if ( response && response.data ) {
        console.log( 'check pending users response: ', response.data );
        updatePendingUsers( response.data );
      }
    };
    checkPendingUsers()
      .then( () => {} );
  }, [id] );

  const getUsers = () => {
    updateShowUsersTable( true );
  };

  const getPendingUsers = () => {
    updateShowPendingUsersTable( true );
  };

  const getProviders = () => {
    updateShowProvidersTable( true );
  };

  const getFields = () => {
    updateShowFields( true );
  };

  const getGroups = () => {
    updateShowGroupsTable( true );
  };

  const getPackages = () => {
    updateShowPackagesTable( true );
  };

  const getProducts = () => {
    updateShowProductsTable( true );
  };

  const getDetails = async () => {
    updateLoadingState( true );
    if ( id === 'new' ) {
      updateCompanyData( cur => setValuesFromResponse( cur, { ...COMPANY }, updateFormIsValid ) );
      updateLoadingState( false );
    } else if ( !companyDetailsLoaded ) {
      updateCompanyDetailsLoaded( true );
      const response = await getSingleRecord( `companies/${id}` );
      if ( response ) {
        updateCompanyData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
        const { details_update } = response.user_auth;
        updateCompanyPermissions( { details_update } );
      }
      updateLoadingState( false );
    } else {
      updateLoadingState( false );
    }
  };

  const keyMap = {
    get_users: getUsers,
    get_lead_providers: getProviders,
    get_fields: getFields,
    get_groups: getGroups,
    get_packages: getPackages,
    get_products: getProducts,
    get_details: getDetails,
    get_pending_users: getPendingUsers
  };

  const tabSelectHandler = useCallback( ( tab ) => {
    console.log( 'tab select: ', tab );
    // history.push( tab );
    history.replace( { pathname: `/company/${tab}` } );
    updateCurrentTab( tab );
    const func = keyMap[`get_${tab}`];
    console.log( typeof func );
    if ( typeof func === 'function' ) {
      func();
    } else {
      console.log( 'function does not exist' );
    }
  }, [history, keyMap] );

  useEffect( () => {
    if ( initialLoad ) {
      updateInitialLoad( false );
      tabSelectHandler( tab );
    }
  }, [tabSelectHandler, tab, initialLoad] );

  const handleSubmit = async e => {
    e.preventDefault();
    updateFormSubmitted( true );
    console.log( 'form is valid: ', formIsValid );
    if ( !formIsValid ) {
      return;
    }
    updateLoadingState( true );
    console.log( 'company edited: ', companyEdited );
    if ( companyEdited ) {
      await updateCompany();
    }
  };

  const updateCompany = async () => {
    const company = extractValues( companyData );
    const response = await updateRecord( `companies/${id}`, { company } );
    if ( response ) {
      updateCompanyData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
      updateCompanyEdited( false );
    }
  };

  return (
    <Container>
      <CompanyTabContainer
        activeTab={currentTab}
        companyData={companyData}
        customFields={props.customFields}
        formSubmitHandler={handleSubmit}
        formSubmitted={formSubmitted}
        loading={loadingState}
        showFields={showFields}
        showGroupsTable={showGroupsTable}
        showPackagesTable={showPackagesTable}
        showPendingUsersTab={pendingUsers}
        showPendingUsersTable={showPendingUsersTable}
        showProductsTable={showProductsTable}
        showProvidersTable={showProvidersTable}
        showUsersTable={showUsersTable}
        tabSelectHandler={tabSelectHandler}
        updateCompanyData={updateCompanyData}
        updateCompanyEdited={updateCompanyEdited}
        updateFormIsValid={updateFormIsValid}
        updateFormSubmitted={updateFormSubmitted}
      />
    </Container>
  );
};

Company.propTypes = {
  companyId: PropTypes.string.isRequired,
  customFields: PropTypes.object.isRequired
};

export default Company;
