import React from 'react';
import PropTypes from 'prop-types';

import SignedInLeftNavItems from './SignedInNav/SignedInLeftNavItems';
// import SignedOutLeftNavItems from './SignedOutNav/SignedOutLeftNavItems';

const LeftItems = props => (
  props.isSignedIn ?
    <SignedInLeftNavItems
      companyAuth={props.companyAuth}
      linkSelectedHandler={props.linkSelectedHandler}
    /> :
    // <SignedOutLeftNavItems />
    null
);

LeftItems.propTypes = {
  companyAuth: PropTypes.shape( {
    accounts: PropTypes.number.isRequired,
    company: PropTypes.number,
    customers: PropTypes.number,
    events: PropTypes.number,
    leads: PropTypes.number,
    schedule: PropTypes.number
  } ),
  isSignedIn: PropTypes.bool.isRequired,
  linkSelectedHandler: PropTypes.func.isRequired
};

export default LeftItems;
