import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../axios-instances/internal-api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actions';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Aux from '../../hoc/Aux';
import './Schedule.css';
import classes from '../../App.module.css';
import ScheduleModal from '../Modal/ScheduleModal/ScheduleModal';
import Toasts from '../Shared/Toasts';
import { concatCustomers, selectComponentStyles } from '../../helpers/functions/utilities';
import PropTypes from 'prop-types';
import Select from 'react-select';
const localizer = momentLocalizer( moment );

const formats = {
  dayHeaderFormat: 'MMMM Do YYYY',
  dayRangeHeaderFormat: ( { start, end }, culture, localizer ) => localizer.format( start, 'MMMM Do', culture ) + ' — ' + localizer.format( end, 'MMMM Do YYYY', culture ),
  agendaDateFormat: 'MMMM Do',
  dayFormat: 'ddd Do'
};

const Schedule = props => {

  const [initialLoad, updateInitialLoad] = useState( true );
  const [calendarData, updateCalendarData] = useState( [] );
  const [loadingState, updateLoadingState] = useState( false );
  const [selectedDate, updateSelectedDate] = useState( new Date() );
  const [selectedTimeframe, updateSelectedTimeframe] = useState( 'week' );
  const [eventDetails, updateEventDetails] = useState( {} );
  const [showModal, updateShowModal] = useState( false );
  const [toastData, updateToastData] = useState( [] );
  const [styleEventsBy, updateStyleEventsBy] = useState( { label: 'None', value: 'none' } );

  const [styleEventsByOptions] = useState( [{ label: 'None', value: 'none' }, { label: 'Status', value: 'status' }, { label: 'Type', value: 'type' }] );

  const { applyFilters, filters, filtersApplied } = props;

  const getData = useCallback( async ( date = selectedDate, timeframe = selectedTimeframe ) => {
    if ( !loadingState ) {
      updateLoadingState( true );
      const validFilters = {};
      for ( const filter in filters ) {
        if ( filters.hasOwnProperty( filter ) ) {
          if ( filters[filter].show && filters[filter].filter !== 'All' ) {
            validFilters[filter] = filters[filter];
          }
        }
      }
      const data = {
        date: moment( date ).format( 'YYYY-MM-DD' ),
        filters: validFilters,
        offset: moment().utcOffset(),
        timeframe
      };
      const response = await axios.post( 'schedule', data );
      if ( response.data ) {
        const events = response.data.map( row => {
          concatCustomers( row );
          return {
            ...row,
            start_at: new Date( row.start_at ),
            end_at: new Date( row.end_at )
          };
        } );
        updateCalendarData( events );
      }
      if ( applyFilters ) {
        filtersApplied();
      }
      updateLoadingState( false );
    }
  }, [applyFilters, filtersApplied, filters, loadingState, selectedDate, selectedTimeframe] );

  useEffect( () => {
    if ( applyFilters ) {
      getData( selectedDate, selectedTimeframe )
        .then( () => {} );
    }
  }, [getData, applyFilters, selectedDate, selectedTimeframe] );

  if ( initialLoad ) {
    updateInitialLoad( false );
    getData()
      .then( () => {} );
  }

  const onNavigateHandler = ( date, prop2 ) => {
    console.log( 'navigate handler prop2: ', prop2 );
    updateSelectedDate( date );
    getData( date, selectedTimeframe )
      .then( () => {} );
  };

  const onViewHandler = ( timeframe ) => {
    updateSelectedTimeframe( timeframe );
    getData( selectedDate, timeframe )
      .then( () => {} );
  };

  const selectEventHandler = async ( event ) => {
    if ( event.user_auth.event_view ) {
      updateShowModal( true );
      const response = await axios.get( `schedule/${event.id}` );
      if ( response.data ) {
        updateEventDetails( response.data );
      } else {
        updateShowModal( false );
      }
    } else {
      updateToastData( cur => [...cur, { heading: 'Restricted', body: 'You do not have access to view this event.', id: Date.now() }] );
    }
  };

  const titleBuilder = ( event ) => {
    if ( selectedTimeframe === 'week' || selectedTimeframe === 'month' ) {
      return `${event.customer_first_name} ${event.customer_last_name} - ${event.type}`;
    } else if ( selectedTimeframe === 'day' || selectedTimeframe === 'agenda' ) {
      return `${event.customer_first_name} ${event.customer_last_name} - ${event.type} \n Address: ${event.property_address1} ${event.property_address2} \n Phone: ${event.customer_phone}`;
    } else {
      return '';
    }
  };

  const closeModal = () => {
    updateShowModal( false );
    updateEventDetails( {} );
  };

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  const drillDownViewHandler = ( event, prop2 ) => console.log( 'drill down view Handler: ', event, prop2 );
  const selectSlotHandler = ( event, prop2 ) => console.log( 'select Slot Handler: ', event, prop2 );

  const changeStyleEventsByHandler = value => {
    updateStyleEventsBy( value );
  };

  const CustomizeEvent = event => {
    if ( styleEventsBy.value === 'none' ) {
      return;
    }
    let color = '#3174ad';
    switch ( event[styleEventsBy.value] ) {
      case 'Requested' :
      case 'Install' :
        color = '#ffc107';
        break;
      case 'Scheduled' :
      case 'Service' :
        color = '#198754';
        break;
      case 'In Route' :
      case 'Call' :
        color = '#d63384';
        break;
      case 'On Site' :
        color = '#fd7e14';
        break;
      case 'Complete' :
        color = '#8f8f8f';
        break;
      case 'Cancelled' :
        color = '#dc3545';
        break;
      case 'Rescheduled' :
      case 'Estimate' :
        color = '#0dcaf0';
        break;
      default : break;
    }
    return { style: { 'backgroundColor': color } };
  };

  const legend = styleEventsBy.value === 'status' ?
    <Aux>
      <div className='d-inline-flex pr-4'><p className='pr-1'>Requested</p><div style={{ backgroundColor: '#ffc107' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>Scheduled</p><div style={{ backgroundColor: '#198754' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>In Route</p><div style={{ backgroundColor: '#d63384' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>On Site</p><div style={{ backgroundColor: '#fd7e14' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>Complete</p><div style={{ backgroundColor: '#8f8f8f' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>Cancelled</p><div style={{ backgroundColor: '#dc3545' }} /></div>
      <div className='d-inline-flex pr-4'><p className='pr-1'>Rescheduled</p><div style={{ backgroundColor: '#0dcaf0' }} /></div>
    </Aux> : styleEventsBy.value === 'type' ?
      <Aux>
        <div className='d-inline-flex pr-4'><p className='pr-1'>Install</p><div style={{ backgroundColor: '#ffc107' }} /></div>
        <div className='d-inline-flex pr-4'><p className='pr-1'>Service</p><div style={{ backgroundColor: '#198754' }} /></div>
        <div className='d-inline-flex pr-4'><p className='pr-1'>Call</p><div style={{ backgroundColor: '#d63384' }} /></div>
        <div className='d-inline-flex'><p className='pr-1'>Estimate</p><div style={{ backgroundColor: '#0dcaf0' }} /></div>
      </Aux> :
      null;

  return (
    <div className={loadingState ? 'full-width ' + classes.Loading : 'full-width'}>
      <Row className='flex-wrap pl-2 pl-lg-5'>
        <Form.Group as={Col} className='left-label' xs={12} md={6} lg={2}>
          <Form.Label>Events By:</Form.Label>
          <Select
            options={styleEventsByOptions}
            value={styleEventsBy}
            onChange={changeStyleEventsByHandler}
            styles={selectComponentStyles}
          />
        </Form.Group>
        <Col xs={12} md={6} lg={10} className='d-flex mt-auto flex-wrap schedule-legend'>
          {legend}
        </Col>
      </Row>
      <Calendar
        localizer={localizer}
        events={calendarData}
        startAccessor="start_at"
        endAccessor="end_at"
        eventPropGetter={CustomizeEvent}
        defaultView='week'
        titleAccessor={titleBuilder}
        onNavigate={onNavigateHandler}
        onView={onViewHandler}
        getDrillDownView={drillDownViewHandler}
        onSelectEvent={selectEventHandler}
        onSelectSlot={selectSlotHandler}
        formats={formats}
        popup
      />
      <ScheduleModal
        onHide={closeModal}
        show={showModal}
        data={eventDetails}
      />
      {toasts}
    </div>
  );
};

Schedule.propTypes = {
  applyFilters: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.func.isRequired
};

const mapStateToProps = state => ( {
  applyFilters: state.sideDrawer.applyFilters,
  filters: state.sideDrawer.filters
} );

const mapDispatchToProps = dispatch => ( {
  filtersApplied: () => dispatch( { type: actionTypes.SIDE_DRAWER_FILTERS_APPLIED } )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Schedule );
