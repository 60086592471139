import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { EVENT } from '../../helpers/constants/initialData';
import LoadingSpinner from '../../hoc/LoadingSpinner';
import classes from '../../App.module.css';
import {
  disableFormFields,
  getSingleRecord,
  updateRecord,
  createRecord,
  cloneDeep,
  storePrevious,
  convertCustomFieldsForDisplay
} from '../../helpers/functions/utilities';
import ComponentNotes from '../Shared/ComponentNotes';
import Aux from '../../hoc/Aux';
import {
  extractValues,
  setValuesFromResponse
} from '../../helpers/functions/formValidator';
import FormFieldGenerator from '../Shared/FormFieldGenerator';
import initialEventFormFields from './EventFormFields';
import Toasts from '../Shared/Toasts';
import Watchlist from '../Shared/Watchlist';
import SendNotificationsModal from '../Modal/SendNotificationsModal/SendNotificationsModal';

const Event = props => {

  const formFields = cloneDeep( { ...initialEventFormFields, ...convertCustomFieldsForDisplay( props.customFields ) } ); // this is done here to allow for conditionally setting fields values and attributes
  const event = cloneDeep( EVENT );

  if ( props.accountId ) {  // if the event is being displayed from outside the /events/:id route, set that fields values and disable the field
    event.account_id = props.accountId;
    event.account_label = props.accountAddress;
    formFields.account_id.attributes.disabled = true;
  }

  let { id } = useParams();
  if ( props.eventId ) {  // if the event is being displayed from outside the /events/:id route, the event id must be passed in and will be set here
    id = props.eventId === '-1' ? 'new' : props.eventId;
  }
  const history = useHistory();

  const [loadingState, updateLoadingState] = useState( false );
  const [eventData, updateEventData] = useState( formFields );

  const [formIsValid, updateFormIsValid] = useState( false );
  const [formSubmitted, updateFormSubmitted] = useState( false );
  const [formEdited, updateFormEdited] = useState( false );

  const [recordPermissions, updateRecordPermissions] = useState( { events_update: 1 } );
  const [toastData, updateToastData] = useState( [] );

  const [disabledNotificationButton, updateDisableNotificationButton] = useState( true );
  const [disabledNotificationMessage, updateDisabledNotificationMessage] = useState( [] );
  const [showSendNotificationModal, updateShowSendNotificationModal] = useState( false );

  const buttonText = id === 'new' ? 'Create' : 'Update';
  const heading = id === 'new' ? <h3>Create New Event</h3> : <Watchlist recordId={id} recordType='events' />;

  const prevId = storePrevious( id );

  useEffect( () => {
    if ( id === 'new' ) {
      return;
    }
    let message = [];
    let validDates = true;
    if ( !moment.isMoment( eventData.start_at.value ) ) {
      validDates = false;
      message.push( 'Select a start date' );
    }
    if ( !moment.isMoment( eventData.end_at.value ) ) {
      validDates = false;
      message.push( 'Select an end date' );
    }
    if ( validDates ) {
      if ( eventData.end_at.value.isBefore( eventData.start_at.value ) ) {
        message.push( 'End date must be after the start date' );
      }
      if ( eventData.start_at.value.isBefore() ) {
        message.push( 'Start date is in the past' );
      }
    }
    if ( !eventData.account_id.value ||
      !eventData.account_id.value.value ||
      !eventData.account_id.value.value.length
    ) {
      message.push( 'Select an account' );
    }
    if ( !eventData.user_id.value ||
      !eventData.user_id.value.value ||
      !eventData.user_id.value.value.length
    ) {
      message.push( 'Assign a user' );
    }
    if ( !formIsValid ) {
      message.push( 'Invalid event, check fields above' );
    }
    if ( formEdited ) {
      message.push( 'Changes must be saved' );
    }
    updateDisableNotificationButton( message.length );
    updateDisabledNotificationMessage( message );
  }, [id, formIsValid, formEdited, eventData.end_at.value, eventData.start_at.value, eventData.account_id.value, eventData.user_id.value, eventData.user_id.value] );

  useEffect( () => {
    if ( !recordPermissions.events_update ) {
      updateEventData( cur => disableFormFields( cur ) );
    }
  }, [recordPermissions] );

  useEffect( () => {
    const getEvent = async () => {
      updateLoadingState( true );
      if ( id === 'new' ) {
        updateEventData( cur => setValuesFromResponse( cur, { ...event }, updateFormIsValid ) );
      } else {
        const response = await getSingleRecord( `events/${id}` );
        if ( response ) {
          updateEventData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          const { events_update } = response.user_auth;
          updateRecordPermissions( { events_update } );
        }
      }
      updateFormEdited( false );
      updateLoadingState( false );
    };
    if ( id && prevId !== id ) {
      getEvent()
        .then( () => {} );
    }
  }, [id, event, prevId] );

  useEffect( () => {
    if ( moment.isMoment( eventData.start_at.value ) && moment.isMoment( eventData.end_at.value ) ) {
      let value = moment( eventData.end_at.value ).diff( moment( eventData.start_at.value ) );
      value = parseFloat( ( value / 3600000 ).toFixed( 2 ) );
      const updated = value !== eventData.duration.value;
      if ( updated ) {
        updateEventData( cur => {
          const data = { ...cur };
          const duration = { ...data.duration };
          duration.value = value;
          return { ...data, duration };
        } );
      }
    }
  }, [eventData.start_at.value, eventData.end_at.value, eventData.duration.value] );

  const checkDates = () => {  // since it is currently not possible to check one field against another, these checks will have to be done separately
    if ( moment.isMoment( eventData.end_at.value ) && moment.isMoment( eventData.start_at.value ) ) {
      let val = eventData.end_at.value.diff( eventData.start_at.value );
      return val > 0;
    } else {
      return true;
    }
  };

  const endDateError = () => {
    updateEventData( cur => {
      const data = { ...cur };
      const end = { ...data.end_at };
      end.errors = [{ message: 'End Date must be after the Start Date', key: 'ed-pos' }];
      end.isValid = false;
      return { ...data, end_at: { ...end } };
    } );
  };

  const handleSubmit = async ( e ) => {
    e.preventDefault();
    e.stopPropagation();
    updateFormSubmitted( true );
    if ( !checkDates() ) {
      endDateError();
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formIsValid ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Invalid input(s).  Check fields for errors.', id: Date.now() }] );
      return;
    }
    if ( !formEdited ) {
      updateToastData( cur => [...cur, { heading: 'Error', body: 'No changes have been made.', id: Date.now() }] );
      return;
    }
    updateLoadingState( true );
    try {
      const event = { ...extractValues( eventData ) };
      if ( id === 'new' ) {
        const response = await createRecord( 'events', { event } );
        if ( response ) {
          updateToastData( cur => [...cur, { heading: 'Record Created', id: Date.now() }] );
          updateFormEdited( false );
          updateFormSubmitted( false );
          if ( props.accountEventUpdatedHandler ) {
            props.accountEventUpdatedHandler();
          } else {
            history.push( `${response.id}` );
          }
        }
      } else {
        const response = await updateRecord( `events/${id}`, { event } );
        if ( response ) {
          updateFormEdited( false );
          updateFormSubmitted( false );
          updateToastData( cur => [...cur, { heading: 'Record Updated', id: Date.now() }] );
          if ( props.accountEventUpdatedHandler ) {
            props.accountEventUpdatedHandler();
          } else {
            updateEventData( cur => setValuesFromResponse( cur, response, updateFormIsValid ) );
          }
        }
      }
      updateLoadingState( false );
    } catch ( error ) {
      updateLoadingState( false );
      updateToastData( cur => [...cur, { heading: 'Error', body: 'Please try again.', id: Date.now() }] );
    }
  };

  const showNotificationModal = () => {
    updateShowSendNotificationModal( true );
  };

  const hideNotificationModal = ( success = false ) => {
    updateShowSendNotificationModal( false );
    if ( typeof success === 'boolean' && success ) {
      updateToastData( cur => [...cur, { heading: 'Notifications Sent', id: Date.now() }] );
    }
  };

  const sendNotificationButton = disabledNotificationButton ?
    <OverlayTrigger trigger="click" placement="top" overlay={
      <Popover id='notification-popover'>
        <Popover.Title as='p'><strong>Unable To Send Notifications</strong></Popover.Title>
        <Popover.Content>
          <ul>
            {disabledNotificationMessage.map( ( msg, index ) => <li key={index}>{msg}</li> )}
          </ul>
        </Popover.Content>
      </Popover>
    }>
      <Button variant='outline-success' type='button' className='w-100'>
        Send Notifications
      </Button>
    </OverlayTrigger> :
    <Button onClick={showNotificationModal} variant='success' type='button' className='w-100'>
      Send Notifications
    </Button>;

  const sendNotificationButtonContainer = id === 'new' ?
    null :
    <Form.Group controlId='submit' as={Col} xs={12} sm={6} md='auto' className='mx-auto add-member-container'>
      {sendNotificationButton}
    </Form.Group>;

  const eventNotes = id === 'new' ? null : <ComponentNotes id={id} component='events' />;

  const eventUpdateButton = recordPermissions.events_update ?
    <Form.Row className='pt-3'>
      <Form.Group controlId='submit' as={Col} xs={12} sm={6} md='auto' className='mx-auto add-member-container'>
        <Button variant='primary' type='submit' disabled={loadingState} className='w-100'>
          {buttonText}
        </Button>
      </Form.Group>
      {sendNotificationButtonContainer}
    </Form.Row> :
    null;

  const toasts = toastData.length ? <Toasts updateData={updateToastData} dataArray={toastData} /> : null;

  return (
    <Aux>
      {heading}
      <Container>
        <LoadingSpinner loading={loadingState}>
          <Form onSubmit={handleSubmit} className={loadingState ?  classes.Loading : ''}>
            <FormFieldGenerator
              formData={eventData}
              formDataInputHandler={updateEventData}
              formEditedHandler={updateFormEdited}
              formSubmitted={formSubmitted}
              updateFormIsValid={updateFormIsValid}
            />
            {eventUpdateButton}
          </Form>
        </LoadingSpinner>
      </Container>
      {eventNotes}
      {toasts}
      <SendNotificationsModal
        onHide={hideNotificationModal}
        eventId={id}
        show={showSendNotificationModal}
      />
    </Aux>
  );
};

Event.propTypes = {
  accountAddress: PropTypes.string,
  accountEventUpdatedHandler: PropTypes.func,
  accountId: PropTypes.string,
  customFields: PropTypes.array.isRequired,
  eventId: PropTypes.string
};

export default Event;
