import { EVENT_STATUSES, EVENT_TYPES } from '../../helpers/constants/options';
import { decimalFormatter } from '../../helpers/functions/utilities';

export default {
  user_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Users...',
      noOptionsMessage: () => 'No Users Found',
      placeholder: 'Search for a user'
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {},
    errors: [],
    getOptionsParams: ['users'],
    isValid: false,
    label: 'Assigned User',
    type: 'async-select',
    validation: {},
    value: ''
  },
  account_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Accounts...',
      noOptionsMessage: () => 'No Accounts Found',
      placeholder: 'Search by property address'
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {
      required: { message: 'An account is required', key: 'ac-req' }
    },
    errors: [],
    getOptionsParams: ['accounts'],
    isValid: false,
    label: 'Account Property Address',
    type: 'async-select',
    validation: {
      required: true
    },
    value: ''
  },
  type: {
    attributes: {
      options: EVENT_TYPES.map( el => ( { label: el, value: el } ) ),
      placeholder: 'Type'
    },
    errorMessages: {
      required: { message: 'Event Type is required', key: 'et-req' }
    },
    errors: [],
    isValid: false,
    label: 'Type',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  status: {
    attributes: {
      options: EVENT_STATUSES.map( el => ( { label: el, value: el } ) ),
      placeholder: 'Status'
    },
    errorMessages: {
      required: { message: 'Account Status is required', key: 'es-req' }
    },
    errors: [],
    isValid: false,
    label: 'Status',
    type: 'select',
    validation: {
      required: true
    },
    value: ''
  },
  start_at: {
    attributes: {
      closeOnSelect: true,
      dateFormat: 'l',
    },
    errorMessages: {
      required: { message: 'Event start date is required', key: 'sd-req' }
    },
    errors: [],
    isValid: false,
    label: 'Start Date',
    type: 'datetime',
    validation: {
      required: true
    },
    value: ''
  },
  end_at: {
    attributes: {
      closeOnSelect: true,
      dateFormat: 'l',
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'End Date',
    type: 'datetime',
    validation: {
      required: false
    },
    value: ''
  },
  duration: {
    attributes: {
      step: 0.01,
      disabled: true
    },
    errorMessages: {
      positiveNumber: { message: 'Start Date cannot be before the End Date', key: 'ed-pos' }
    },
    displayFormatter: decimalFormatter,
    errors: [],
    isValid: false,
    label: 'Duration (Hours)',
    type: 'text',
    validation: {
      positiveNumber: true
    },
    value: ''
  },
  completed_at: {
    attributes: {
      disabled: true
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Completed Date',
    type: 'datetime',
    validation: {},
    value: ''
  },
  estimate_on_site_at: {
    attributes: {
      disabled: true
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Estimated On Site Date',
    type: 'datetime',
    validation: {},
    value: ''
  },
  in_route_at: {
    attributes: {
      disabled: true
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'In Route Date',
    type: 'datetime',
    validation: {},
    value: ''
  },
  on_site_at: {
    attributes: {
      disabled: true
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'On Site Date',
    type: 'datetime',
    validation: {},
    value: ''
  }
};
