export default {
  assigned_to_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Users...',
      noOptionsMessage: () => 'No Users Found',
      placeholder: 'Search for a user'
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {},
    errors: [],
    getOptionsParams: ['users'],
    // getOptionsParams: ['users', '&role=sales'],  // @todo fix this
    isValid: false,
    label: 'Assigned To',
    type: 'async-select',
    validation: {},
    value: ''
  },
  provider_id: {
    attributes: {
      cacheOptions: true,
      isClearable: true,
      loadingMessage: () => 'Getting Lead Providers...',
      noOptionsMessage: () => 'No Lead Providers Found',
      placeholder: 'Search for a lead provider'
    },
    css: {
      xs: 12,
      md: 6,
      lg: 6
    },
    errorMessages: {
      required: { message: 'Lead Provider is required', key: 'lp-req' }
    },
    errors: [],
    getOptionsParams: ['leadProviders'],
    isValid: false,
    label: 'Lead Provider',
    type: 'async-select',
    validation: {
      required: true
    },
    value: ''
  },
  first_name: {
    errorMessages: {
      maxLength: { message: 'First Name cannot be longer than 20 characters', key: 'fn-max' },
      minLength: { message: 'First Name must be at least 2 characters', key: 'fn-min' },
      required: { message: 'First Name is required', key: 'fn-req' }
    },
    errors: [],
    isValid: false,
    label: 'First Name',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 2,
      required: true
    },
    value: ''
  },
  last_name: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Last Name',
    type: 'text',
    validation: {},
    value: ''
  },
  phone: {
    attributes: {
      placeholder: '(555) 555-5555'
    },
    errorMessages: {
      phone: { message: 'Invalid phone number', key: 'ph-inv' }
    },
    errors: [],
    isValid: false,
    label: 'Phone',
    type: 'tel',
    validation: {
      phone: true
    },
    value: ''
  },
  address_heading: {
    text: 'Address',
    isValid: true
  },
  address1: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address',
    type: 'text',
    validation: {},
    value: ''
  },
  address2: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  city: {
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'City',
    type: 'text',
    validation: {},
    value: ''
  },
  state: {
    attributes: {
      clearable: true
    },
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'State',
    type: 'state',
    validation: {},
    value: ''
  },
  zip: {
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' }
    },
    errors: [],
    isValid: false,
    label: 'Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      number: true
    },
    value: ''
  },
  account_id: {
    display: false,
    displayCondition: 'false',
    validation: {},
    value: ''
  },
};
