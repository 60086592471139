import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import MainTable from '../../../Tables/MainTable';
import AddButton from '../../../Shared/AddButton';
import Aux from '../../../../hoc/Aux';
import DeleteModal from '../../../Modal/DeleteModal';
import {
  closeDeleteModalHandler,
  confirmDeleteHandler,
  currencyFormatter,
  dateTimeFormatterFromString,
  tableDeleteButton,
  tableDeleteHandler,
  tableEditButton,
} from '../../../../helpers/functions/utilities';

const route = 'packages';

const Packages = props => {

  const [showDeleteModal, updateShowDeleteModal] = useState( false );
  const [packageDeleted, updatePackageDeleted] = useState( false );
  const [recordToDelete, updateRecordToDelete] = useState( {} );

  const headings = [
    { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, route ) },
    { dataField: 'id', text: 'ID', hidden: true },
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'price', text: 'Price', sort: true, formatter: currencyFormatter },
    { dataField: 'num_products', text: 'Number of Products', sort: true },
    { dataField: 'is_active', text: 'Active', sort: true, formatter: cell => cell ? 'Yes' : 'No' },
    { dataField: 'created_at', text: 'Date Created', sort: true, formatter: dateTimeFormatterFromString }
  ];

  if ( props.auth.del ) {
    headings.push( {
      dataField: 'delete',
      text: 'Delete',
      isDummyField: true,
      formatter: ( cell, row ) => tableDeleteButton( () => tableDeleteHandler( row, updateRecordToDelete, updateShowDeleteModal ) )
    } );
  }

  const table = props.showPackagesTable ?
    <MainTable
      headings={ headings }
      route={route}
      remote={{ filter: true, pagination: true, sort: true, cellEdit: false }}
      refreshNeeded={packageDeleted}
      updateRefreshNeeded={updatePackageDeleted}
    /> :
    null;

  const addButton = props.auth.create ?
    <Row className='py-3 justify-content-start'>
      <Col xs={12} sm='auto'>
        <AddButton route={route} size='28px' />
      </Col>
    </Row> :
    null;

  return (
    <Aux>
      {addButton}
      {table}
      <DeleteModal
        idToDelete={recordToDelete.id}
        message={<div><p>You are about to delete {recordToDelete.name}.</p><p>@todo implement soft deletes to maintain account integrity.  Are you sure you want to continue?</p></div>}
        onConfirmDelete={() => confirmDeleteHandler( `${route}/${recordToDelete.id}`, updateShowDeleteModal, updateRecordToDelete, updatePackageDeleted )}
        onHide={() => closeDeleteModalHandler( updateShowDeleteModal, updateRecordToDelete )}
        show={showDeleteModal}
      />
    </Aux>
  );
};

Packages.propTypes = {
  auth: PropTypes.shape( {
    create: PropTypes.number,
    del: PropTypes.number
  } ),
  showPackagesTable: PropTypes.bool.isRequired,
};

export default Packages;
