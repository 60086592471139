import { USER_NOTIFICATION_OPTIONS } from '../../helpers/constants/options';

export default {
  username: {
    attributes: {
      disabled: true
    },
    errorMessages: {
      maxLength: { message: 'Username cannot be longer than 20 characters', key: 'un-max' },
      minLength: { message: 'Username must be at least 6 characters', key: 'un-min' },
      required: { message: 'Username is required', key: 'un-req' }
    },
    errors: [],
    isValid: false,
    label: 'Username',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 6,
      required: true
    },
    value: ''
  },
  first_name: {
    attributes: {},
    errorMessages: {
      maxLength: { message: 'First Name cannot be longer than 20 characters', key: 'fn-max' },
      minLength: { message: 'First Name must be at least 2 characters', key: 'fn-min' },
      required: { message: 'First Name is required', key: 'fn-req' }
    },
    errors: [],
    isValid: false,
    label: 'First Name',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 2,
      required: true
    },
    value: ''
  },
  last_name: {
    attributes: {},
    errorMessages: {
      maxLength: { message: 'Last Name cannot be longer than 20 characters', key: 'ln-max' },
      minLength: { message: 'Last Name must be at least 2 characters', key: 'ln-min' },
      required: { message: 'Last Name is required', key: 'ln-req' }
    },
    errors: [],
    isValid: false,
    label: 'Last Name',
    type: 'text',
    validation: {
      maxLength: 20,
      minLength: 2,
      required: true
    },
    value: ''
  },
  email: {
    attributes: {},
    errorMessages: {
      email: { message: 'Invalid Email address', key: 'em-inv' },
      required: { message: 'Email is required', key: 'em-req' }
    },
    errors: [],
    isValid: false,
    label: 'Email',
    type: 'email',
    validation: {
      email: true,
      required: true
    },
    value: ''
  },
  phone: {
    attributes: {
      placeholder: '(555) 555-5555'
    },
    errorMessages: {
      phone: { message: 'Invalid phone number', key: 'ph-inv' },
      required: { message: 'Phone is required', key: 'ph-req' }
    },
    errors: [],
    isValid: false,
    label: 'Phone',
    type: 'tel',
    validation: {
      phone: true,
      required: true
    },
    value: ''
  },
  notification_preference: {
    attributes: {
      options: USER_NOTIFICATION_OPTIONS
    },
    errors: [],
    isValid: true,
    label: 'Notification Preference',
    type: 'select',
    validation: {},
    value: 'Email & Internal'
  },
  address1: {
    attributes: {},
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address',
    type: 'text',
    validation: {},
    value: ''
  },
  address2: {
    attributes: {},
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'Address 2',
    type: 'text',
    validation: {},
    value: ''
  },
  city: {
    attributes: {},
    errorMessages: {},
    errors: [],
    isValid: false,
    label: 'City',
    type: 'text',
    validation: {},
    value: ''
  },
  state: {
    attributes: {},
    errorMessages: {
      required: { message: 'State is required', key: 'st-req' },
    },
    errors: [],
    isValid: false,
    label: 'State',
    type: 'state',
    validation: {
      required: true
    },
    value: ''
  },
  zip: {
    attributes: {},
    errorMessages: {
      maxLength: { message: 'Zip cannot be longer than 5 numbers', key: 'zc-max' },
      minLength: { message: 'Zip must be at least 5 numbers', key: 'zc-min' },
      number: { message: 'Zip should only be numbers', key: 'zc-num' },
      required: { message: 'Zip is required', key: 'zc-req' }
    },
    errors: [],
    isValid: false,
    label: 'Zip',
    type: 'text',
    validation: {
      maxLength: 5,
      minLength: 5,
      number: true,
      required: true
    },
    value: ''
  }
};
