import * as actionTypes from '../actions/actions';

const initialErrorState = {
  detailedMessage: null,
  serverMessage: null,
  showModal: false,
  statusCode: null
};

const internalErrorOccurred = ( state, action ) => {
  return {
    detailedMessage: action.payload.message,
    serverMessage: action.payload.serverMessage,
    showModal: true,
    statusCode: action.payload.statusCode
  };
};

const errorReducer = ( state = initialErrorState, action ) => {
  switch ( action.type ) {
    case actionTypes.INTERNAL_API_ERROR_OCCURRED :
      return internalErrorOccurred( state, action );
    case actionTypes.ERROR_RESET :
      return initialErrorState;
    default :
      return state;
  }
};

export default errorReducer;
