import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { BsTrash } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import Form from 'react-bootstrap/Form';

import store from '../../store/store';
import * as actionTypes from '../../store/actions/actions';
import axios from '../../axios-instances/internal-api';
import Pencil from '../../components/Shared/Pencil';

export const optionBuilder = arr => {
  return arr.map( option => {
    let value = option;
    let label = option;
    if ( typeof option === 'object' ) {
      value = option.hasOwnProperty( 'value' ) ? option.value : option.hasOwnProperty( 'id' ) ? option.id : option.label;
      label = option.hasOwnProperty( 'label' ) ? option.label : option.hasOwnProperty( 'name' ) ? option.name : '';
    }
    return <option key={value} value={value}>{label}</option>;
  } );
};

export const decimalFormatter = ( val, precision = 2 ) => {
  val = isNaN( val ) || val == null ? '0' : val;
  return  parseFloat( val ).toFixed( precision );
};

export const dateTimeFormatter = date => {
  return moment.isMoment( date ) ? moment( date ).format( 'l LT' ) : '';
};

export const dateTimeFormatterFromString = dateString => {
  return dateString ? dateTimeFormatter( moment( dateString ) ) : '';
};

export const dateFormatter = date => {
  return moment.isMoment( date ) ?  moment( date ).format( 'MM/DD/YYYY' ) : '';
};

export const dateStringFormatter = dateString => {
  return dateString ? dateFormatter( moment( dateString ) ) : '';
};

export const timeElapsed = date => {
  return moment.isMoment( date ) ? moment( date ).fromNow() : '';
};

export const timeElapsedFromString = dateString => {
  return timeElapsed( moment( dateString ) );
};

/**
 *  Main table functions
 */

export const tableEditButton = ( cell, row, route, routeSuffix = '', newTab = false ) => (
  <Link to={`/${route}/${row.id}${routeSuffix}`} target={newTab ? '_blank' : '_self'}>
    <IconContext.Provider value={24}>
      <Pencil />
    </IconContext.Provider>
  </Link>
);

export const tableCheckbox = ( row, field, changeHandler ) => (
  row[field] == null ?
    null :
    <Form.Check.Input
      type='checkbox'
      checked={row[field]}
      onChange={e => changeHandler( e.target.checked, row, field )}
      className='ml-0 position-static'
    />
);

export const tableDeleteButton = ( clickHandler ) => (
  <Button size="sm" variant='danger' onClick={clickHandler}>
    <IconContext.Provider value={{ size: 20 }}>
      <BsTrash />
    </IconContext.Provider>
  </Button>
);

export const tableDeleteHandler = ( row, updateRecord, showModal ) => {
  updateRecord( row );
  showModal( true );
};

export const confirmDeleteHandler = async ( route, updateModalState, updateRecordToDelete, updateRecordDeleted ) => {
  try {
    const response = await axios.delete( route );
    console.log( 'delete response: ', response );
    updateModalState( false );
    updateRecordToDelete( {} );
    store.dispatch( {
      type: actionTypes.DELETE_SUCCESSFUL,
      payload: { message: 'Record deleted successfully' }
    } );
    updateRecordDeleted( true );
  } catch ( e ) {
    console.log( 'error deleting record: ', e.response.data );
    throw e;
  }
};

export const closeDeleteModalHandler = ( updateModalState, updateRecordToDelete ) => {
  updateModalState( false );
  updateRecordToDelete( {} );
};

export const numberInputHandler = ( inputHandler, field, value, precision = 0 ) => {
  inputHandler( field, decimalFormatter( value, precision ) );
};

/**
 *  CRUD functions (minus the Delete)
 */

export const getSingleRecord = async ( route ) => {
  try {
    const response = await axios.get( route );
    convertTimeStringsToMoments( response.data );
    console.log( 'get record response: ', response.data );
    return response.data;
  } catch ( e ) {
    console.log( 'error retrieving record: ', e.response.data );
    throw e;
  }
};

export const createRecord = async ( route, data ) => {
  try {
    const response = await axios.post( `${route}/new`, data );
    console.log( 'new record response: ', response );
    return response.data;
  } catch ( e ) {
    console.log( 'error creating record: ', e.response.data );
    throw e;
  }
};

export const updateRecord = async ( route, data ) => {
  try {
    const response = await axios.patch( route, data );
    convertTimeStringsToMoments( response.data );
    console.log( 'updated record response: ', response.data );
    return response.data;
  } catch ( e ) {
    console.log( 'error updating record: ', e.response.data );
    throw e;
  }
};

export const getSingularText = ( str ) => {
  return str.endsWith( 's' ) ? str.substr( 0, str.length - 1 ) : str;
};

export const convertTimeStringsToMoments = obj => {
  for ( let name in obj ) {
    if ( obj.hasOwnProperty( name ) && obj[name] !== '' ) {
      if ( name.endsWith( '_at' ) ) {
        obj[name] = moment.utc( obj[name], 'YYYY-MM-DDTHH:mm:ss.SSS' ).local();
      } else if ( name.endsWith( '_on' ) ) {
        obj[name] = moment( obj[name], 'MM-DD-YYYY' );
      }
    }
  }
};

export const cloneDeep = obj => {
  if ( Array.isArray( obj ) ) {
    return obj.map( el => cloneDeep( el ) );
  }
  if ( obj == null ) {
    return obj;
  }
  if ( typeof obj === 'object' ) {
    const newObj = {};
    for ( const key in obj ) {
      if ( obj.hasOwnProperty( key ) ) {
        newObj[key] = cloneDeep( obj[key] );
      }
    }
    return newObj;
  }
  return obj;
};

export const search = ( inputValue, callback, params ) => {
  let url = `/${params[0]}/search?value=${inputValue}`;
  if ( params[1] ) {
    url = url.concat( params[1] );
  }
  axios.get( url )
    .then( response => {
      if ( response.data ) {
        callback( response.data.map( el => ( { value: el.id, label: el.label } ) ) );
      }
    } );
};

export const storePrevious = value => (
  function ( val ) {
    const ref = useRef();
    useEffect( () => {
      ref.current = val;
    } );
    return ref.current;
  }( value )
);

export const concatCustomers = obj => {
  if ( obj.hasOwnProperty( 'customers' ) && Array.isArray( obj.customers ) ) {
    obj.customers.forEach( customer => {
      for ( const prop in customer ) {
        if ( customer.hasOwnProperty( prop ) ) {
          if ( obj.hasOwnProperty( prop ) && customer[prop] && customer[prop].length ) {
            obj[prop] = obj[prop].concat( ` & \n${customer[prop]}` );
          } else {
            obj[prop] = customer[prop];
          }
        }
      }
    } );
  }
};

export const convertCustomFieldsForDisplay = arr => {
  if ( Array.isArray( arr ) && arr.length ) {
    let obj = {};
    arr.forEach( field => {
      obj[field.field_name] = field.field_data;
    } );
    return obj;
  } else {
    return {};
  }
};

export const selectComponentStyles = {
  control: base => ( {
    ...base,
    height: 38
  } )
};

export const defaultStyles = {
  backgroundColor: 'transparent',
  color: '#212529',
  textDecoration: 'inherit'
};

export const convertCompanyUserAccessFieldsForDisplay = obj => {
  for ( const key in obj ) {
    if ( obj.hasOwnProperty( key ) ) {
      if ( key.startsWith( 'acc_bool_' ) || key === 'is_active' ) {
        obj[key] = obj[key] ? 'Yes' : 'No';
      }
      if ( key.startsWith( 'acc_bool_' ) || key === 'is_active' || key.startsWith( 'acc_str_' ) ) {
        obj[key] = { label: obj[key], value: obj[key] };
      }
    }
  }
};

export const convertCompanyUserAccessFieldsForApi = obj => {
  for ( const key in obj ) {
    if ( obj.hasOwnProperty( key ) && ( key.startsWith( 'acc_bool_' ) || key === 'is_active' ) ) {
      obj[key] = obj[key] === 'Yes' ? 1 : 0;
    }
  }
  return obj;
};

export const disableFormFields = ( formFieldObject, excludedFields = [] ) => {
  for ( const prop in formFieldObject ) {
    if ( formFieldObject.hasOwnProperty( prop ) ) {
      if ( excludedFields.includes( prop ) ) {
        continue;
      }
      if ( formFieldObject[prop].hasOwnProperty( 'attributes' ) ) {
        formFieldObject[prop].attributes.disabled = true;
      } else {
        formFieldObject[prop].attributes = {
          disabled: true
        };
      }
    }
  }
  return formFieldObject;
};

export const addressFormatter = ( { address1, address2, city, state, zip } ) => {
  let address = '';
  if ( address1 ) {
    address = address.concat( address1 );
  }
  if ( address2 ) {
    if ( address.length ) {
      address = address.concat( '\n' );
    }
    address = address.concat( address2 );
  }
  if ( city ) {
    if ( address.length ) {
      address = address.concat( '\n' );
    }
    address = address.concat( city );
  }
  if ( state ) {
    if ( address.length ) {
      address = address.concat( city ? ', ' : '\n' );
    }
    address = address.concat( state );
  }
  if ( zip ) {
    if ( address.length ) {
      address = address.concat( city || state ? ' ' : '\n' );
    }
    address = address.concat( zip );
  }
  return address;
};

export const addressColumnFormatter = ( cell, row, rowIndex ) => `text-left ${rowIndex === 0 ? 'border-top-0' : ''}`;

export const currencyFormatter = cell => `$${parseFloat( cell ).toFixed( 2 )}`;
