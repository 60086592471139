import React from 'react';

import SingleCollapse from '../Shared/SingleCollapse';
import PropTypes from 'prop-types';
import MainTable from '../Tables/MainTable';
import {
  addressColumnFormatter,
  addressFormatter,
  dateTimeFormatterFromString,
  tableEditButton
} from '../../helpers/functions/utilities';

const leadTableHeadings = [
  { dataField: 'actions', text: 'View', isDummyField: true, formatter: ( cell, row ) => tableEditButton( cell, row, 'leads' ) },
  { dataField: 'id', text: 'ID', hidden: true },
  { dataField: 'first_name', text: 'Name', sort: true, formatter: ( cell, row ) => `${row.first_name} ${row.last_name}` },
  { dataField: 'address1', text: 'Address', sort: true, formatter: ( cell, row ) => addressFormatter( row ), classes: addressColumnFormatter },
  { dataField: 'user_first_name', text: 'Assigned To', sort: true, formatter: ( cell, row ) => `${row.user_first_name} ${row.user_last_name}` },
  { dataField: 'created_at', text: 'Date Created', sort: true, formatter: dateTimeFormatterFromString },
  { dataField: 'updated_at', text: 'Date Last Updated', sort: true, formatter: dateTimeFormatterFromString },
];

const LeadProviderLeadsCollapse = props => {

  const table = props.showLeads &&
    <MainTable
      headings={leadTableHeadings}
      route='leads'
      routeSuffix={props.leadProviderId}
      remote={{ filter: false, pagination: true, sort: true, cellEdit: false }}
      sizePerPageList={[5, 10, 25, 100]}
      defaultSizePerPage={10}
    />;

  return (
    <SingleCollapse openByDefault={props.showLeads} titleText='Leads' accordionToggle={props.showLeadsHandler} cardBodyClasses='p-0'>
      {table}
    </SingleCollapse>
  );
};

LeadProviderLeadsCollapse.propTypes = {
  showLeads: PropTypes.bool.isRequired,
  showLeadsHandler: PropTypes.func.isRequired,
  leadSelectionHandler: PropTypes.func.isRequired,
  leadProviderId: PropTypes.string.isRequired
};

export default LeadProviderLeadsCollapse;
